import { ColDef, ICellRendererParams } from "ag-grid-community";
import { GridHelper, IMenuItems, ListViewModelBase } from "digiwall-lib";
import CustomButtonRenderer from "resources/renderer/custom-button-renderer";
import * as Constants from '../../constants';
import * as breeze from 'breeze-client';
import { Container } from "aurelia-framework";
import { I18N } from "aurelia-i18n";



export class GridHelperMerlin<T extends breeze.Entity> {
  public getMenuItems(params: ICellRendererParams): Array<IMenuItems> {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [{
          label: (params as any).i18n.tr("menu.open"),
          icon: "digi-open",
          handler: () => {
            let url = (params as any).pathDetail + params.data.id;
            if ((<any>params).router?.currentInstruction?.queryString?.length > 0) {
              url += '?' + (<any>params).router.currentInstruction.queryString;
            }
            (params as any).router.navigate(url);
          }
        }]
      },
      {
        label: (params as any).i18n.tr("menu.delete"),
        icon: "digi-delete-bin-line",
        handler: async () => {
          let result = await (params as any).service.deleteEntities([params.data], true);
          if (result) {
            (params as any).gridOptions.api.forEachNode((rowNode) => {
              if (rowNode.data != null)
                if (rowNode.data.isSelected == true)
                  rowNode.data.isSelected = false;
            });
            (params as any).gridOptions.api.refreshInfiniteCache();
          }
        },
        disabled: () => {
          if (typeof (params as any)?.disabledMenuItem == 'function') {
            return !(params as any).disabledMenuItem(params.data);
          }
          return false;
        }
      }];
  }
  public contextMenuColdDef(parentThis: ListViewModelBase<T>, menuItems = null): ColDef[] {
    (parentThis as any).gridOptions.components.customButtonRenderer = CustomButtonRenderer;
    let result: Array<ColDef> = [
      {
        headerName: "",
        field: "menuItems",
        maxWidth: Constants.AGGridColumnsWidth.IsSelected,
        minWidth: Constants.AGGridColumnsWidth.IsSelected,
        cellRendererParams: {
          i18n: parentThis.i18n,
          gridOptions: (parentThis as any).gridOptions,
          router: parentThis.router,
          service: parentThis.service,
          pathDetail: (parentThis as any).pathDetail,
          menuItems: menuItems != null ? menuItems : this.getMenuItems,
          disabledMenuItem: (parentThis as any).isSelectable
        },
        cellRenderer: "customButtonRenderer",
        suppressColumnsToolPanel: true,
        sortable: false,
        resizable: false,
        filter: false,
        suppressMenu: true,
        suppressMovable: true,
        pinned: "left",
      }];
    result.push(this.selectedColDef(parentThis));
    return result;

  }
  public selectedColDef(vm: ListViewModelBase<T>): ColDef {
    return GridHelper.getSelectedColDef<T>(vm);

  }
  public baseEntityColDef(parenThis: ListViewModelBase<T>): ColDef[] {
    let i18n = Container.instance.get(I18N);
    return GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.MerlinUser, { baseColDef: { showRowGroup: i18n.tr("groupTabPanel.metadata") } });
  }
}
