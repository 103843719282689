import { autoinject, BindingEngine, computedFrom, Container, Disposable } from "aurelia-framework";
import { Router } from 'aurelia-router';
import { FloatingBoxViewModel } from "digiwall-lib";
import moment = require('moment');
import { RealizationDateDTO } from "../realization-summary";
import { ProjectApiService } from "services/project-api-service";
import { RealizationApiService } from "services/realization-api-service";

@autoinject
export class FloatingDate extends FloatingBoxViewModel {
  private bindingEngine: BindingEngine;
  private projectApiService: ProjectApiService;
  private realizationApiService: RealizationApiService
  public disposables: Array<Disposable> = [];

  constructor(private realizationDate: RealizationDateDTO, private projectId: any) {
    super('./floating-date.html', 'sm');
    this.bindingEngine = Container.instance.get(BindingEngine);
    this.projectApiService = Container.instance.get(ProjectApiService);
    this.realizationApiService = Container.instance.get(RealizationApiService);
  }

  async activate(config: any): Promise<void> {
    await super.activate(config);

    this.loadObserver();
  }

  public async close() {
    this.disposables.forEach(d => d.dispose());
    await this.dialogController.close(false);
  }

  private async loadRealizationDate() {
    this.disposables.forEach(d => d.dispose());
    this.realizationDate = await this.realizationApiService.getRealizationDateDTO(this.projectId);
    this.loadObserver();
  }

  private loadObserver() {
    this.disposables.push(this.bindingEngine.propertyObserver(this.realizationDate, "weatherDelayDays").subscribe(async (newVal, oldVal) => {
      if (newVal != oldVal) {
        await this.projectApiService.updateWeatherDelayDays(this.projectId, newVal);
        await this.loadRealizationDate();
      }
    }));
    this.disposables.push(this.bindingEngine.propertyObserver(this.realizationDate, "approvedStopDays").subscribe(async (newVal, oldVal) => {
      if (newVal != oldVal) {
        await this.projectApiService.updateApprovedStopDays(this.projectId, newVal);
        await this.loadRealizationDate();
      }
    }));
    this.disposables.push(this.bindingEngine.propertyObserver(this.realizationDate, "provisionalAcceptanceDate").subscribe(async (newVal, oldVal) => {
      if (newVal != oldVal) {
        await this.projectApiService.updateProvisionalAcceptanceDate(this.projectId, newVal);
        await this.loadRealizationDate();
      }
    }));
    this.disposables.push(this.bindingEngine.propertyObserver(this.realizationDate, "finalAcceptanceDate").subscribe(async (newVal, oldVal) => {
      if (newVal != oldVal) {
        await this.projectApiService.updateFinalAcceptanceDate(this.projectId, newVal);
        await this.loadRealizationDate();
      }
    }));
  }
}
