import moment = require('moment');
import { autoinject } from 'aurelia-framework';
import { I18N } from "aurelia-i18n";
import { Moment } from 'moment';

@autoinject
export class TimeConverter {
  constructor(private i18n: I18N) {

  }
  public getHoursAndMinuteFromString(startTime: string): { hour: number, minute: number } {
    let timeSplit = startTime.split(':');
    let hour: number = parseInt(timeSplit[0]);
    let minute: number = parseInt(timeSplit[1]);
    return { hour, minute };
  }

  public formatTime(h: number, m: number, formatH: boolean, isNegative: boolean = false) {
    return (isNegative ? "-" : "") + (h > 9 ? h : "0" + h) + (formatH ? "h" : ":") + (m > 9 ? m : "0" + m);
  }

  public getStringFromHoursMinute(value: number, formatH: boolean) {
    let isNegative = value < 0;
    value = Math.abs(value);
    let h = Math.trunc(value);
    let m = Math.round((value - h) * 60);

    // If rounding causes minutes to equal 60, adjust hours and minutes
    if (m === 60) {
      h += 1;
      m = 0;
    }

    return this.formatTime(h, m, formatH, isNegative);
  }

  public formatDays(nbDays: number) {
    return nbDays + " " + this.i18n.tr("absence.day") + (nbDays < -1 || nbDays > 1 ? "s" : "");
  }

  public getHoursFromString(time) {
    return parseInt(time.slice(0, 2));
  }

  public getMinutesFromString(time) {
    return parseInt(time.slice(3, 5));
  }

  public getHoursMinutesFromTimeField(time: string): number {
    if (time == "") {
      return 0;
    }
    return this.getHoursFromString(time) + (this.getMinutesFromString(time) / 60);
  }


  public isWeekend(date: Date) {
    const weekend = [0, 6];
    return weekend.includes(date.getDay());
  }


  public getNbDaysBetweenTwoDates(start: Date, end: Date) {
    let a = moment(start);
    let b = moment(end);
    return (b.diff(a, 'days') + 1); // +1 to include the start
  }

  public getDuration(startTime: string, endTime: string) {
    let start: number = this.getHoursMinutesFromTimeField(startTime);
    let end: number = this.getHoursMinutesFromTimeField(endTime);
    let duration: number = end - start;

    return { duration };
  }

  public getDurationHoursMinutesSeconds(startDate: Date | Moment, endDate: Date | Moment) {
    if (startDate instanceof Date) {
      startDate = moment(startDate);
    }
    if (endDate instanceof Date) {
      endDate = moment(endDate);
    }

    let hours = endDate.diff(startDate, 'hours');
    let hoursMinutes = moment.utc(moment(endDate, "HH:mm:ss").diff(moment(startDate, "HH:mm:ss")));
    let mins = parseInt(hoursMinutes.format("mm"));
    let secs = parseInt(hoursMinutes.format("ss"));

    return { hours, mins, secs };
  }

  public sumHoursMinutes(startH: number, startM: number, hoursToAdd: number, minToAdd: number) {
    let endH = 0;
    let endM = startM + minToAdd;
    if (endM >= 60) {
      endH++;
      endM = endM - 60;
    }
    endH += startH + hoursToAdd;

    return { endH, endM };
  }
}
