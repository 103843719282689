import { HttpClient } from 'aurelia-fetch-client';
import { DialogController } from 'aurelia-dialog';
import { CustomLogger, ServiceBase, ListViewModelBase, FieldType, ActionDialogBoxInputParameters, DialogBoxViewModel, GlobalLoaderService, Various } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import * as Constants from '../../constants';
import { Merlin } from 'generated';
import { GridHelperMerlin } from 'resources/elements/grid-helper';
import { ColDef } from 'ag-grid-community';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { SellingInvoiceStatusId } from '../../constants';
import { ReconciliationApiService } from 'services/reconciliation-service';

@autoinject
export class AddTransaction extends ListViewModelBase<Merlin.Web.Model.BankTransaction> {
  projectId: any;

  get ribbonHeaderText() {
    return ""
  }

  private agGrid: any;

  private selectedTransactions: Array<TransactionForReconciliation> = [];
  private nbDecimalForPriceDisplay: number;
  private appParameterService: ServiceBase<Merlin.Web.Model.ApplicationParameter>;
  private bankAccountService: ServiceBase<Merlin.Web.Model.BankAccount>;

  private targetId: number
  private workQuotationVersionId: number;
  private priceOfferVersionId: number;
  private supplierOfferId: number;
  private clientAdditionalWorkVersionId: number;
  private itemCompostionId: number;
  private parent: any;
  private title: string;

  private selectedItemsUITable: HTMLElement;
  private viewModule: string;
  private invoiceId: number;


  constructor(router: Router, logger: CustomLogger, private dialogController: DialogController, private httpClient: HttpClient, private globalLoaderService: GlobalLoaderService, private reconciliationApiService: ReconciliationApiService) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.BankTransaction>(Constants.EntityTypeNames.BankTransaction));
    this.appParameterService = new ServiceBase<Merlin.Web.Model.ApplicationParameter>(Constants.EntityTypeNames.ApplicationParameter);
    this.service.gridDataSource.expands = ['status']
  }

  public ressourceName: string = Constants.EntityTypeNames.RequestSupplierOffer;

  public async activate(params: any) {
    if (params.invoiceId != null) {
      this.invoiceId = params.invoiceId;
    }

    this.nbDecimalForPriceDisplay = (await this.appParameterService.getEntityById(1)).nbDecimalForPriceDisplay;

    this.title = this.i18n.tr("sellinginvoice.linkInvoiceWithTransaction")

  }

  public async attached() {
    await super.attached();
    this.agGrid.calculateBodyHeight(0);
  }

  async close() {
    if (this.selectedTransactions.length > 0) {
      let buttonYes: ActionDialogBoxInputParameters =
      {
        label: this.i18n.tr("general.yes", { ns: "common" }),
        title: this.i18n.tr("general.yes", { ns: "common" }),
        theme: 'primary',
        type: 'solid',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.ok();
        }
      };
      let buttonNo: ActionDialogBoxInputParameters =
      {
        label: this.i18n.tr("general.no", { ns: "common" }),
        title: this.i18n.tr("general.no", { ns: "common" }),
        theme: 'dark',
        type: 'ghost',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.cancel();
        }
      };
      await this.box.showQuestion(this.i18n.tr('priceofferline.cancelAddItem'), this.i18n.tr('menu.question'), [buttonNo, buttonYes]).whenClosed(
        async (result) => {
          if (!result.wasCancelled) {
            await this.dialogController.close(true);
          }
        }
      )
    }
    else {
      await this.dialogController.close(true);
    }

  }

  async ok() {
    this.globalLoaderService.allow();
    let result = await this.reconciliationApiService.linkTransactionsToInvoice(this.invoiceId, this.selectedTransactions.map(tr => tr.transactionId));
    if (result) {
      this.dialogController.close(true, result);
    }

    return [];
  }

  onCellClicked(entity: Merlin.Web.Model.BankTransaction): boolean | null {
    let newLine = this.createLineFromItem(entity);
    this.selectedTransactions.push(newLine);

    setTimeout(() => {
      this.selectedItemsUITable?.scrollTo({ top: this.selectedItemsUITable.scrollHeight });
    }, 300);

    return true;
  }

  public getMenuItems(params) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.remove"),
            icon: "digi-trash",
            handler: () => {
              this.removeItem(params);
            }
          }
        ]
      },
      {
        group: "2",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.removeAll"),
            icon: "digi-trash",
            handler: () => {
              this.removeAllItems();
            }
          }
        ]
      }
    ];
  }

  removeAllItems() {
    this.selectedTransactions.splice(0);
  }
  removeItem(record: TransactionForReconciliation) {
    this.selectedTransactions.splice(this.selectedTransactions.indexOf(record), 1);
  }

  createLineFromItem(transaction: Merlin.Web.Model.BankTransaction): TransactionForReconciliation {
    if (this.selectedTransactions.findIndex(x => x.transactionId == transaction.id) == -1) {
      return {
        transactionId: transaction.id,
        transaction: transaction,
      };
    }
  }

  public initializeGridOptions() {
    super.initializeGridOptions(false, false);
  }

  public afterFilterChanged() {
    setTimeout(() => {
      this.agGrid.calculateBodyHeight(0);
    }, 1);
  }

  public getDataGridColumns() {
    this.bankAccountService = new ServiceBase<Merlin.Web.Model.BankAccount>(Constants.EntityTypeNames.BankAccount);
    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this, this.getMenuItems),
      {
        headerName: this.i18n.tr("banktransaction.counterpartName"),
        field: "counterpartName",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("banktransaction.counterpartReference"),
        field: "counterpartReference",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("banktransaction.communication"),
        field: "communication",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("banktransaction.amount"),
        field: "amount",
        cellClass: (params) => {
          if (params?.data?.amount >= 0) {
            return 'transaction__cell--amount positive';
          }
          else {
            return 'transaction__cell--amount negative';
          }
        },
        type: FieldType.Currency,
        valueGetter: (params) => {
          return Math.abs(params?.data?.amount);
        }
      },
      {
        headerName: this.i18n.tr("banktransaction.reconciledAmount"),
        field: "reconciledAmount",
        type: FieldType.Currency
      },
      {
        headerName: this.i18n.tr("banktransaction.statusId"),
        field: "status.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.TransactionStatus,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.TransactionStatus,
        }
      },
      {
        headerName: this.i18n.tr("banktransaction.valueDate"),
        field: "valueDate",
        type: FieldType.Date
      },
      {
        headerName: this.i18n.tr("banktransaction.bankreference"),
        field: "bankAccount.iBAN",
        type: FieldType.OneToMany,
        valueGetter: (params) => {
          const uri = params?.data?.bankAccount?.logoUri;
          const iban = params?.data?.bankAccount?.iBAN;
          return `<img style='max-height: 70%; margin-right: 5px; vertical-align: middle' src='${uri}'></img>${iban}`;
        },
        cellRenderer: (data) => {
          if (data.value != null) {
            return data.value;
          }
          return;
        },
        filterParams: {
          service: this.bankAccountService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: this.bankAccountService
        }
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }
}

export class TransactionForReconciliation {
  transactionId: number;
  transaction: Merlin.Web.Model.BankTransaction;
}
