
import { ListViewModelBase, SelectAllHeaderComponent, CustomLogger, ServiceBase, FieldType, Various, IMenuItems, EnumerationTypeService } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Merlin } from "../generated";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { GridHelperMerlin } from 'resources/elements/grid-helper';

@autoinject
export class ProjectList extends ListViewModelBase<Merlin.Web.Model.Project> {
  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project));
    this.service.gridDataSource.expands = [
      'mainThirdParty', 'projectOwner', 'projectStatus', 'projectType', 'communicationLanguage',
      'temporaryCompany', 'temporaryCompanyPartner', 'projectUsers', 'projectClass', 'approval',
      'projectUsers.projectUserSales', 'projectUsers.projectUserPreparation', 'projectUsers.projectUserSecretary',
      'projectUsers.projectUserFinance', 'projectUsers.projectUserExecutiveManagement', 'projectUsers.projectUserTechnicalManagement',
      'projectUsers.projectUserSecurity', 'projectUsers.projectUserPriceOffer.merlinUser', 'projectUsers.projectUserBuyings.merlinUser', 'projectUsers.projectUserExecutions.merlinUser',
      'projectActivities.activity'
    ];
  }

  public ressourceName: string = Constants.EntityTypeNames.Project;
  public buttonLabel: string = this.i18n.tr("project.addProject");
  public pathDetail: string;


  public getDetailsUrl(_self, entity: Merlin.Web.Model.Project) {
    if (entity?.id) {
      return this.pathDetail + entity.id;
    }
    return null;
  }

  public initializeGridOptions() {
    this.pathDetail = '/projects/';
    super.initializeGridOptions(false, false);
  }

  public getDataGridColumns() {

    let merlinUserService: ServiceBase<Merlin.Web.Model.MerlinUser> = new ServiceBase<Merlin.Web.Model.MerlinUser>(Constants.EntityTypeNames.MerlinUser);

    let thirdPartiesService: ServiceBase<Merlin.Web.Model.ThirdParty> = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
    thirdPartiesService.gridDataSource.queryParameters = { onlyActive: false, onlyCompany: true };

    let thirdPartiesPartnersService: ServiceBase<Merlin.Web.Model.ThirdParty> = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
    thirdPartiesPartnersService.gridDataSource.queryParameters = { onlyActive: false, onlyCompany: true, onlyPartner: true };

    let thirdPartiesTempoparyCompanyService: ServiceBase<Merlin.Web.Model.ThirdParty> = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
    thirdPartiesTempoparyCompanyService.gridDataSource.queryParameters = { onlyActive: false, onlyCompany: true, onlyTemporaryCompany: true };

    let projectUsersSalesService: ServiceBase<Merlin.Web.Model.ProjectUserPriceOffer> = new ServiceBase<Merlin.Web.Model.ProjectUserPriceOffer>(Constants.EntityTypeNames.ProjectUserPriceOffer);
    projectUsersSalesService.gridDataSource.expands = ['merlinUser'];

    let activityService: EnumerationTypeService = new EnumerationTypeService(Constants.EnumerationTypes.ActivityCode);

    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this, this.getMenuItems),
      // {
      //   headerName: this.i18n.tr("project.projectId"),
      //   field: "id",
      //   type: FieldType.Number,
      //   showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
      //   filter: false,
      //   suppressMenu: true,
      //   hide: true
      // },
      // Project General Informations
      {
        headerName: this.i18n.tr("project.projectName"),
        field: "projectName",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("project.mainThirdPartyLastName"),
        field: "mainThirdParty.lastNameCompanyName",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("project.mainThirdPartyFirstName"),
        field: "mainThirdParty.firstName",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("project.offerDeadlineDate"),
        field: "offerDeadlineDate",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormatWithHours
        },
        type: FieldType.Date,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("project.projectStatusId"),
        field: "projectStatus.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.ProjectStatus,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.ProjectStatus,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("project.projectOwnerId"),
        field: "projectOwner.fullName",
        type: FieldType.OneToMany,
        filterParams: {
          service: thirdPartiesService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: thirdPartiesService
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("project.projectOwnerIsMainContact"),
        field: "projectOwnerIsMainContact",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("project.projectTypeId"),
        field: "projectType.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.ProjectType,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.ProjectType,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("project.projectClientRef"),
        field: "projectClientRef",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("project.plannedWorkingSiteStartingDate"),
        field: "plannedWorkingSiteStartingDate",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormat
        },
        type: FieldType.Date,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("project.estimatedProjectDurationInMonth"),
        field: "estimatedProjectDurationInMonth",
        type: FieldType.Number,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("project.workingSiteStartDate"),
        field: "workingSiteStartDate",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormat
        },
        type: FieldType.Date,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("project.communicationLanguageId"),
        field: "communicationLanguage.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.Language,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.Language,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("project.approvalId"),
        field: "approval.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.Language,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.Language,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("project.projectClassId"),
        field: "projectClass.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.Language,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.Language,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("project.estimatedSales"),
        field: "estimatedSales",
        type: FieldType.Currency,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("project.projectActivities"),
        field: "projectActivities.activity.denomination._translation",
        type: FieldType.ManyToMany,
        cellRenderer: "manyToManyTagRenderer",
        filterParams: {
          service: activityService,
          arrayField: "projectActivities",
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: activityService,
          arrayField: "projectActivities",
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      // Temporary Company
      {
        headerName: this.i18n.tr("project.isTemporaryCompany"),
        field: "isTemporaryCompany",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("project.temporaryCompanyId")
      },
      {
        headerName: this.i18n.tr("project.temporaryCompanyPartnerId"),
        field: "temporaryCompanyPartner.fullName",
        type: FieldType.OneToMany,
        filterParams: {
          service: thirdPartiesService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: thirdPartiesService
        },
        showRowGroup: this.i18n.tr("project.temporaryCompanyId")
      },
      {
        headerName: this.i18n.tr("project.temporaryCompanyId"),
        field: "temporaryCompany.fullName",
        type: FieldType.OneToMany,
        filterParams: {
          service: thirdPartiesTempoparyCompanyService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: thirdPartiesTempoparyCompanyService
        },
        showRowGroup: this.i18n.tr("project.temporaryCompanyId")
      },
      // Internal Team
      {
        headerName: this.i18n.tr("project.projectUserSales"),
        field: "projectUsers.projectUserSales.fullName",
        type: FieldType.OneToMany,
        filterParams: {
          service: merlinUserService,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: merlinUserService,
        },
        showRowGroup: this.i18n.tr("project.internalTeam")
      },
      {
        headerName: this.i18n.tr("project.userPreparationId"),
        field: "projectUsers.projectUserPreparation.fullName",
        type: FieldType.OneToMany,
        filterParams: {
          service: merlinUserService,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: merlinUserService,
        },
        showRowGroup: this.i18n.tr("project.internalTeam")
      },
      {
        headerName: this.i18n.tr("project.projectUserSecretary"),
        field: "projectUsers.projectUserSecretary.fullName",
        type: FieldType.OneToMany,
        filterParams: {
          service: merlinUserService,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: merlinUserService,
        },
        showRowGroup: this.i18n.tr("project.internalTeam")
      },
      {
        headerName: this.i18n.tr("project.projectUserFinance"),
        field: "projectUsers.projectUserFinance.fullName",
        type: FieldType.OneToMany,
        filterParams: {
          service: merlinUserService,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: merlinUserService,
        },
        showRowGroup: this.i18n.tr("project.internalTeam")
      },
      {
        headerName: this.i18n.tr("project.projectUserExecutiveManagement"),
        field: "projectUsers.projectUserExecutiveManagement.fullName",
        type: FieldType.OneToMany,
        filterParams: {
          service: merlinUserService,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: merlinUserService,
        },
        showRowGroup: this.i18n.tr("project.internalTeam")
      },
      {
        headerName: this.i18n.tr("project.projectUserTechnicalManagement"),
        field: "projectUsers.projectUserTechnicalManagement.fullName",
        type: FieldType.OneToMany,
        filterParams: {
          service: merlinUserService,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: merlinUserService,
        },
        showRowGroup: this.i18n.tr("project.internalTeam")
      },
      {
        headerName: this.i18n.tr("project.projectUserSecurity"),
        field: "projectUsers.projectUserSecurity.fullName",
        type: FieldType.OneToMany,
        filterParams: {
          service: merlinUserService,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: merlinUserService,
        },
        showRowGroup: this.i18n.tr("project.internalTeam")
      },
      {
        headerName: this.i18n.tr("project.projectUserPriceOffer"),
        field: "projectUsers.projectUserPriceOffer.merlinUser.fullName",
        type: FieldType.ManyToMany,
        filterParams: {
          service: merlinUserService,
          arrayField: "projectUserPriceOffer"
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: merlinUserService,
          arrayField: "projectUserPriceOffer",
        },
        showRowGroup: this.i18n.tr("project.internalTeam")
      },
      {
        headerName: this.i18n.tr("project.projectUserBuyings"),
        field: "projectUsers.projectUserBuyings.merlinUser.fullName",
        type: FieldType.ManyToMany,
        filterParams: {
          service: merlinUserService,
          arrayField: "projectUserBuyings",
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: merlinUserService,
          arrayField: "projectUserBuyings",
        },
        showRowGroup: this.i18n.tr("project.internalTeam")
      },
      {
        headerName: this.i18n.tr("project.projectUserExecutions"),
        field: "projectUsers.projectUserExecutions.merlinUser.fullName",
        type: FieldType.ManyToMany,
        filterParams: {
          service: merlinUserService,
          arrayField: "projectUserExecutions",
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: merlinUserService,
          arrayField: "projectUserExecutions",
        },
        showRowGroup: this.i18n.tr("project.internalTeam")
      },
      {
        headerName: this.i18n.tr("project.provisionalAcceptanceDate"),
        field: "provisionalAcceptanceDate",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormat
        },
        type: FieldType.Date,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("project.finalAcceptanceDate"),
        field: "finalAcceptanceDate",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormat
        },
        type: FieldType.Date,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }

  public getMenuItems(params: ICellRendererParams): Array<IMenuItems> {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [{
          label: (params as any).i18n.tr("menu.open"),
          icon: "digi-open",
          handler: () => {
            let url = (params as any).pathDetail + params.data.id;
            if ((<any>params).router?.currentInstruction?.queryString?.length > 0) {
              url += '?' + (<any>params).router.currentInstruction.queryString;
            }
            (params as any).router.navigate(url);
          }
        }]
      },
      {
        label: (params as any).i18n.tr("menu.delete"),
        icon: "digi-delete-bin-line",
        handler: async () => {
          let result = await (params as any).service.deleteEntities([params.data], true);
          if (result) {
            (params as any).gridOptions.api.forEachNode((rowNode) => {
              if (rowNode.data != null)
                if (rowNode.data.isSelected == true)
                  rowNode.data.isSelected = false;
            });
            (params as any).gridOptions.api.refreshInfiniteCache();
          }
        },
        disabled: () => {
          return params.data.projectStatusId != Constants.ProjectStatusId.Open;
        }
      }];
  }

  async delete(detachInverseFKEntities?: boolean): Promise<boolean> {
    if (this.selectedEntities.filter(x => x.projectStatusId != Constants.ProjectStatusId.Open).length > 0) {
      this.box.showError(this.i18n.tr("project.notOpenProjectError"), this.i18n.tr('general.error', { ns: "common" }), null, false);
      return false;
    }
    return super.delete(detachInverseFKEntities);
  }
}

