import { ItemApiService, ItemCompositionApiService } from './../services/item-api-service';
import { BindingEngine } from 'aurelia-binding';
import { ServiceBase, CustomLogger, EntityDetailViewModelBase, IMenuGroup, GlobalLoaderService, CustomColDef, FieldType, ActionDialogBoxInputParameters, DialogBoxViewModel } from 'digiwall-lib';
import { autoinject } from 'aurelia-framework';
import * as Constants from '../constants';
import { Merlin } from 'generated';
import { Router } from 'aurelia-router';
import { DialogController } from 'aurelia-dialog';
import { MerlinApiService } from 'services/merlin-api-service';
import { ModuleListTreeData } from 'module-list-tree-data/module-list-tree-data';
import { CellValueChangedEvent, ColDef, ColSpanParams, GridOptions, ICellRendererParams, RowNode } from 'ag-grid-community';
import { AddItems } from 'projects/quotes/add-items/add-items';
import { PriceOfferLineMoveAction } from '../constants';
import { CompositionOverlayComp } from './composition-overlay-comp';
import MerlinHtmlHeaderRenderer from 'resources/renderer/merlin-html-header-renderer';
import { ProposedQuantityEditor } from 'resources/renderer/proposed-quantity-editor';
import * as toastr from 'toastr';

@autoinject
export class ItemComposition extends EntityDetailViewModelBase<Merlin.Web.Model.ItemComposition> {
  public ressourceName: string = Constants.EntityTypeNames.ItemComposition;
  public listTreeData: ModuleListTreeData;
  private compositionLineService: ServiceBase<Merlin.Web.Model.ItemCompositionLine>;
  private unitService: ServiceBase<Merlin.Web.Model.Unit>;
  public readonly tooltipLabel_QuantityFormulaLocked: string;

  private applicationParameterService: ServiceBase<Merlin.Web.Model.ApplicationParameter>;
  private applicationParameter: Merlin.Web.Model.ApplicationParameter;

  private nbDecimalForPriceDisplay: number
  private nbDecimalForQuantityDisplay: number

  public screenExpand: boolean = false;
  public gridOptions: GridOptions = {};
  private unitList: Array<Merlin.Web.Model.Unit>;
  public noDataLabel = this.i18n.tr("grid.noRowsToShow");
  private isPressingShift: boolean = false;
  private lastRowCellSelected: number;


  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private dialogController: DialogController, private itemApiService: ItemApiService, private merlinApiService: MerlinApiService, public itemCompositionApiService: ItemCompositionApiService, protected globalLoaderService: GlobalLoaderService, public element: Element) {
    super(router, logger);
    this.compositionLineService = new ServiceBase<Merlin.Web.Model.ItemCompositionLine>(Constants.EntityTypeNames.ItemCompositionLine);
    this.unitService = new ServiceBase<Merlin.Web.Model.Unit>(Constants.EntityTypeNames.Unit);
    this.applicationParameterService = new ServiceBase<Merlin.Web.Model.ApplicationParameter>(Constants.EntityTypeNames.ApplicationParameter);
    this.tooltipLabel_QuantityFormulaLocked = this.i18n.tr('itemcompositionline.quantityFormulaLocked');
    super.initialize(new ServiceBase<Merlin.Web.Model.ItemComposition>(Constants.EntityTypeNames.ItemComposition));
  }

  public documentTitle = /*this.i18n.tr("itemcomposition.itemcomposition")*/ "";
  public get ribbonHeaderText() {
    return this.i18n.tr("itemcomposition.itemDetail") + " " + this.entity.item.articleCode + (this.entity.item.description?._translation != null ? " - " + this.entity.item.description._translation : '');
  }

  public async activate(params: any) {
    let id: number = params.param1;
    await super.activate(params);
    await this.loadEntity(id);
    this.unitList = await this.unitService.getAllEntities();
    this.applicationParameter = await this.applicationParameterService.firstEntity(null);
    if (this.applicationParameter != null) {
      this.nbDecimalForPriceDisplay = this.applicationParameter.nbDecimalForPriceDisplay;
      this.nbDecimalForQuantityDisplay = this.applicationParameter.nbDecimalForQuantityDisplay;
    }
  }

  private async loadEntity(id: number) {
    this.service.manager.clear();
    this.entity = await this.service.getEntityById(id, 'item');
    await this.computeCompositionPrices();
    this.bindObservers();
  }

  detached(): void {
    this.disposeObservers();
    return super.detached();
  }

  private bindObservers() {
    this.disposeObservers();
    this.disposables.push(
      this.bindingEngine.propertyObserver(this.entity, 'marginCoefficient').subscribe(async (newValue, oldValue) => {
        if (oldValue != newValue) {
          await this.computeCompositionPrices();
          await this.save();
        }
      })
    );
  }

  private disposeObservers() {
    this.disposables.forEach(d => d.dispose());
  }

  public getMenuItems(itemCompo: Merlin.Web.Model.ItemCompositionLine) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("itemcompositionline.goToItem"),
            icon: "digi-search-eye-line",
            handler: () => {
              this.goToItem(itemCompo);
            }
          },
          {
            label: this.i18n.tr("menu.remove"),
            icon: "digi-trash",
            handler: () => {
              this.removeCompositionLine(itemCompo);
            },
            hidden: () => {
              return this.entity.itemCompositionLines.length <= 1
            }
          }
        ]
      }
    ];
  }

  //#region lines
  public async removeCompositionLine(line: Merlin.Web.Model.ItemCompositionLine) {
    if (this.entity.itemCompositionLines.length > 1) {
      if (line.entityAspect.entityState.isAdded()) {
        line.entityAspect.setDetached();
      } else {
        await this.compositionLineService.deleteEntities([line], true);
      }
      this.computeCompositionPrices();
    }
  }

  public goToItem(itemCompo: Merlin.Web.Model.ItemCompositionLine) {
    if (itemCompo.itemId != null) {
      this.dialogController.ok();
      this.router.navigate(`items/${itemCompo.itemId}`);
    }
  }
  //#endregion


  private async updateItemCompositionLine(id: number): Promise<void> {
    await this.compositionLineService.getEntityById(id);
    //await this.computeCompositionPrices();
    //await this.save(true);
  }

  private async computeCompositionPrices() {
    this.globalLoaderService.allow();
    let computedEntity = await this.itemApiService.computePrices(this.entity);
    if (computedEntity != null) {
      this.entity.totalBuyingPrice = computedEntity.totalBuyingPrice;
      this.entity.totalSellingPrice = computedEntity.totalSellingPrice;
    }
  }

  public getDataGridColumns(): ColDef[] {
    let defs: CustomColDef[] = [
      {
        colId: "selected",
        headerName: '',
        field: "isSelected",
        width: Constants.AGGridColumnsWidth.IsSelected,
        maxWidth: Constants.AGGridColumnsWidth.IsSelected,
        suppressMovable: true,
        suppressSizeToFit: true,
        suppressAutoSize: true,
        resizable: false,
        cellRenderer: "customHtmlRendererEditor",
        cellClass: (params) => {
          let returnClass = 'ag-cell-center ag-selection';

          // For metering line color, set a style property for the last child line.
          setTimeout(() => {
            if (params.node.alreadyRendered && params.node.lastChild) {
              let rowElementHtml = this.element.querySelector(`[row-id="${params.node.id}"]`);
              if (rowElementHtml != null) {
                let cellSelected = rowElementHtml.querySelector(`[col-id="${params.colDef.colId}"]`)
                if (cellSelected != null) {
                  let spanLine = cellSelected.querySelector(`[class="metering-line-color"]`) as HTMLElement;
                  if (spanLine != null && params.node.parent != null) {
                    let parentTop = params.node.rowTop - params.node.parent.rowTop - params.node.parent.rowHeight;
                    spanLine.style.setProperty("--parentTop", parentTop + "px");
                  }
                }
              }
            }
          }, 100);
          return returnClass;
        },
        cellRendererParams: {
          canComment: false,
          getHtml: (currentThis) => {
            return `<span class="metering-line-color"></span><ui-checkbox style="margin: auto" class="cell-selected" checked.bind="params.data.isSelected" change.delegate='params.change(params, $event)'></ui-checkbox>`;
          },
          change: async (params, event) => {
            await this.listTreeData.shiftSelect(params, null);
            (<RowNode>params.node).setSelected(event.detail.checked);
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        colSpan: (params: ColSpanParams) => {
          if (params?.node?.rowPinned == 'bottom') {
            return 3;
          }
          return 1;
        }
      },
      {
        colId: "lineRef",
        headerName: this.i18n.tr("itemcompositionline.lineRef"),
        field: "lineRef",
        type: FieldType.String,
        editable: false,
        suppressMenu: true,
        pinned: "left",
        lockPosition: 'left',
        hideInParameter: true,
        suppressMovable: true,
        sortable: false,
        cellClass: "metering-cell-description ag-cell-column-resize-pinned-left",
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          canComment: false,
          suppressCount: true,
          suppressDoubleClickExpand: true
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },

      {
        colId: "label",
        headerName: this.i18n.tr("itemcompositionline.itemId"),
        field: "label",
        type: FieldType.String,
        editable: (params) => params?.data?.lineLevel == 1 && params?.data?.compositionLineCategoryId != Constants.PriceOfferLineCategory.Data,
        suppressMenu: true,
        hideInParameter: true,
        suppressMovable: true,
        sortable: false,
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            const lineDesc = currentThis.params.data.label;
            if (currentThis.params.data.id == 0) {
              return `
              <div class="metering-line-description-footer">
                <div class="metering-line-type-description-overflow">${lineDesc}</div>
              </div>
            `;
            }
            if (currentThis.params.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Comment) {
              return `
              <div class="metering-line-description metering-description-before-toggle">
                <ui-icon icon="digi-information-line"></ui-icon>
                <div class="metering-line-type-description-overflow">${lineDesc}</div>
              </div>
            `;
            }
            let result = `<div class="metering-line-type-description">`;
            if (currentThis.params.data.itemId > 0) {
              result += `<ag-cell-item-tooltip item-id.bind="${currentThis.params.data.itemId}" quantity.bind="${currentThis.params.data.Quantity}"></ag-cell-item-tooltip>`
            }
            if (lineDesc != null) {
              result += `<div title="${lineDesc}">${lineDesc}</div>`;
            }
            result += `</div>`;
            return result;
          },
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "description",
        headerName: this.i18n.tr("itemcompositionline.description"),
        field: "description",
        type: FieldType.String,
        editable: (params) => false,
        suppressMenu: true,
        hideInParameter: true,
        suppressMovable: true,
        sortable: false,
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "unitId",
        headerName: this.i18n.tr("itemcompositionline.unitId"),
        field: "unitId",
        valueGetter: (params) => params?.data?.unitTranslatedString,
        //valueSetter: (params) => ModuleListTreeData.customEnumsValueSetter(params, this.unitList, ["unit", "_translation"]),
        type: FieldType.OneToMany,
        filterParams: {
          service: this.unitService,
          customFieldPath: ['unitName', '_translation']
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: this.unitService,
          customFieldPath: ['unitName', '_translation']
        },
        suppressMenu: true,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => params?.data?.lineLevel == 1 && params.data.compositionLineCategoryId != Constants.PriceOfferLineCategory.Comment,
        sortable: false,
        cellClass: (params) => {
          return 'metering-cell-select';
        },
        cellEditor: "customHtmlRendererEditor",
        cellEditorParams: {
          isPopup: true,
          getHtml: (currentThis) => {
            return `<ui-field no-label style="min-width: ${currentThis.params.column.actualWidth > 200 ? currentThis.params.column.actualWidth : 200}px;"> 
              <ui-select value.one-time="params.data.unitId" on-change.call="params.change(value, params)">
                <option repeat.for="unit of params.unitList(params.data.itemUnitTypeId)" value.bind="unit.id">
                  \${unit.unitName._translation}
                </option>
              </ui-select>
            </ui-field>`;
          },
          unitList: (itemUnitTypeId: number) => this.unitList.filter(u => u.unitTypeId == itemUnitTypeId),
          change: async (value, params) => {
            //params.value = value;
            params.data["unitId"] = value;
            this.cellValueChanged(params);
            this.gridOptions?.api?.stopEditing();
            this.gridOptions?.api?.setFocusedCell(params.node.rowIndex, params.colDef.field);
          }
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "quantity",
        headerName: this.i18n.tr("itemcompositionline.quantity"),
        field: "quantity",
        type: FieldType.Number,
        suppressMenu: true,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params),
        editable: (params) => params.data.lineLevel == 1 && !params.data.quantityFormula,
        sortable: false,
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            let html = "<div class='metering-price-origin'>";
            let quantity: number = currentThis.params.data['quantity'];
            let quantityFormula = currentThis.params.data['quantityFormula']?.toString() ?? "";
            if (quantity != null) {
              let tooltip: string;
              tooltip = this.i18n.tr("metering.quantityFormula");
              let formattedQuantity = new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(quantity);
              let formattedQuantityFormula = new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(quantityFormula);
              if (quantityFormula != "" && formattedQuantity != formattedQuantityFormula)
                html += `<span ui-tooltip="value:${tooltip}" class="span-tooltip"><ui-icon icon='digi-calculator-line'></ui-icon></span>`;
              html += `<span>${(!isNaN(formattedQuantity) ? formattedQuantity : quantity.toString().replace(/[.]/g, ','))}</span>`;
            }
            html += '</div>'
            return html;
          }
        },
        cellEditorParams: {
          fractionDigits: Constants.PrecisionParameter.MaxNbDecimalForQuantityDisplay
        },
      },
      {
        colId: "quantityFormula",
        headerName: this.i18n.tr("itemcompositionline.quantityFormula"),
        field: "quantityFormula",
        type: FieldType.String,
        suppressMenu: true,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params),
        editable: (params) => params.data.lineLevel == 1,
        sortable: false,
      },
      {
        colId: "originalQuantityFormula",
        headerName: this.i18n.tr("itemcompositionline.originalQuantityFormula"),
        field: "originalQuantityFormula",
        type: FieldType.Number,
        suppressMenu: true,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params),
        editable: (params) => false,
        sortable: false,
        valueSetter: (params) => {
          if (params.newValue != null) {
            params.newValue = params.newValue.toString().replace(/[.]/g, ',');
          }
          params.data["originalQuantityFormula"] = params.newValue;
          return true;
        },
        valueGetter: (params) => {
          if (params.data["originalQuantityFormula"] == null || params.data["originalQuantityFormula"] == "")
            return params.data["originalQuantity"];
          return params.data["originalQuantityFormula"];
        },
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            let html = "<div class='metering-price-origin'>";
            let quantity: number = currentThis.params.data['originalQuantity'];
            let quantityFormula = currentThis.params.data['originalQuantityFormula']?.toString() ?? "";
            if (quantity != null) {
              let tooltip: string;
              tooltip = this.i18n.tr("metering.quantityFormula");
              let formattedQuantity = new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(quantity);
              let formattedQuantityFormula = new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(quantityFormula);

              if (quantityFormula != "" && formattedQuantity != formattedQuantityFormula)
                html += `<span ui-tooltip="value:${tooltip}" class="span-tooltip"><ui-icon icon='digi-calculator-line'></ui-icon></span>`;
              html += `<span>${(!isNaN(formattedQuantity.replace(/[.]/g, ',')) ? formattedQuantity : quantity.toString().replace(/[,]/g, '.'))}</span>`;
            }
            html += '</div>'
            return html;
          }
        },
      },
      {
        colId: "unitBuyingPrice",
        headerName: this.i18n.tr("itemcompositionline.unitBuyingPrice"),
        field: "unitBuyingPrice",
        type: FieldType.Number,
        suppressMenu: true,
        sortable: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR" }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            let html = "<div class='metering-price-origin'>";
            let price = currentThis.params.data.unitBuyingPrice;
            if (price != null) {
              let tooltip: string;
              switch (currentThis.params.data.priceOrigin) {
                case Constants.PriceOrigin.Manual:
                  tooltip = this.i18n.tr("metering.buyingUnitPriceManual");
                  html += `<span  ui-tooltip="value:${tooltip}" class="span-tooltip"><ui-icon icon='digi-edit'></ui-icon></span>`;
                  break;
                case Constants.PriceOrigin.Item:
                  tooltip = this.i18n.tr("metering.buyingUnitPriceItem");
                  html += `<span  ui-tooltip="value:${tooltip}" class="span-tooltip"><ui-icon icon='digi-database-2-line'></ui-icon></span>`;
                  break;
                case Constants.PriceOrigin.Offer:
                  tooltip = this.i18n.tr("metering.buyingUnitPriceOffer");
                  html += `<span  ui-tooltip="value:${tooltip}" class="span-tooltip"><ui-icon icon='digi-clipboard-line'></ui-icon></span>`;
                  break;
              }
              html += `<span>${(!isNaN(price) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR" }).format(price) : price)}</span>`;
            }
            html += '</div>'
            return html;
          }
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "totalBuyingPrice",
        headerName: this.i18n.tr("itemcompositionline.totalBuyingPrice"),
        field: "totalBuyingPrice",
        type: FieldType.Number,
        suppressMenu: true,
        sortable: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR" }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
    ];



    if (this.getGridMenuItems != null) {
      defs.unshift({
        colId: "menuIems",
        headerName: "",
        field: "",
        maxWidth: Constants.AGGridColumnsWidth.IsSelected,
        minWidth: Constants.AGGridColumnsWidth.IsSelected,
        suppressMovable: true,
        cellRendererParams: {
          canComment: false,
          i18n: this.i18n,
          gridOptions: this.gridOptions,
          router: this.router,
          menuItems: (params: ICellRendererParams) => this.getGridMenuItems != null ? this.getGridMenuItems(params) : [],
        },
        cellRenderer: "customButtonRenderer",
        suppressColumnsToolPanel: true,
        resizable: false,
        filter: false,
        pinned: "left",
        lockPosition: 'left'
      });
    }

    return defs;
  }

  public async afterAddItems() {
    this.service.manager.clear();
    await this.loadEntity(this.entity.id);
  }

  public async save(silentSave?: boolean, byPassLocking?: boolean, navigateBack?: boolean) {
    // Use the api and business logic to save compositions
    this.globalLoaderService.allow();
    await this.itemApiService.updateItemCompositions([this.entity]);

    // Then refresh breeze cache by saving
    // Because totalBuyingPrice is getter only, we must reset it after breeze saved this entity
    let totalBuyingPrice = this.entity.totalBuyingPrice;
    let toReturn = super.save(silentSave, byPassLocking, navigateBack);
    this.entity.totalBuyingPrice = totalBuyingPrice;
    this.entity.entityAspect.setUnchanged();
    return toReturn;
  }

  afterInitGridOption(gridOptions: GridOptions) {
    gridOptions.getRowId = (params) => params.data.uniqueId ?? params.data.id;
    gridOptions.components = Object.assign({},
      gridOptions.components,
      {
        "merlinHtmlHeaderRenderer": MerlinHtmlHeaderRenderer,
        "proposedQuantityEditor": ProposedQuantityEditor
      }
    );
    gridOptions.noRowsOverlayComponent = CompositionOverlayComp;
    gridOptions.noRowsOverlayComponentParams = {
      listTreeData: this.listTreeData,
      noDataLabel: this.noDataLabel,
      itemCompositionController: this
    };
  }

  private getColSpanOfColDef(params: ColSpanParams) {
    if (params.data.compositionLineCategoryId == Constants.PriceOfferLineCategory.Comment) {
      if (params.column.isPinned()) {
        return 1;
      }
      return params.columnApi.getColumns().filter(x => !x.isPinned()).length;
    }
    return 1;
  }

  getGroupKey(data) {
    return data.id;
  }

  public async refreshVisibleNodes(ids: number[], flashCells = true): Promise<Array<RowNode>> {
    return await this.listTreeData.refreshVisibleNodes(ids, flashCells);
  }

  public async deactivate() {
    await this.listTreeData.deactivate();
  }

  public getGridMenuItems(params: ICellRendererParams): Array<IMenuGroup> {
    if (params.node.data?.lineLevel != 1) return [];
    return [{
      group: "0",
      hiddenLabel: true,
      items: [
        {
          id: Constants.CommonMeterginDataLineMenuItems.ADD_ITEM,
          label: this.i18n.tr("versionmetering.addItems"),
          icon: "digi-database-2-line",
          items: [
            {
              id: Constants.CommonMeterginDataLineMenuItems.ADD_ITEM_UP,
              label: this.i18n.tr("versionmetering.onTopOfLine"),
              handler: async () => {
                this.addItems(parseInt(params.node.id), Constants.PriceOfferLineMoveAction.Up);
              },
              icon: "digi-arrow-up-line"
            },
            {
              id: Constants.CommonMeterginDataLineMenuItems.ADD_ITEM_DOWN,
              label: this.i18n.tr("versionmetering.belowLine"),
              handler: async () => {
                this.addItems(parseInt(params.node.id), Constants.PriceOfferLineMoveAction.Down);
              },
              icon: "digi-arrow-down-line"
            },
          ]
        },
        {
          label: this.i18n.tr("versionmetering.addComment"),
          icon: "digi-chat-4-line",
          items: this.getCreateLineMenuItem(parseInt(params.node.id), Constants.PriceOfferLineCategory.Comment)
        },
        {
          id: Constants.CommonMeterginDataLineMenuItems.MOVES,
          icon: "digi-arrow-up-down-line",
          label: this.i18n.tr("versionmetering.move"),
          disabled: () => {
            return this.cantMoveToThisNode(params.node.id) /*|| (this.cantMoveToThisNodeUpDown(params.node.id) && this.cantMoveToThisNodeChildren(params.node.id))*/;
          },
          items: [
            {
              id: Constants.CommonMeterginDataLineMenuItems.MOVE_UP,
              label: this.i18n.tr("versionmetering.moveUp"),
              icon: "digi-arrow-up-line",
              handler: async () => {
                await this.moveNode(params.node.id, Constants.PriceOfferLineMoveAction.Up);
              },
            },
            {
              id: Constants.CommonMeterginDataLineMenuItems.MOVE_DOWN,
              label: this.i18n.tr("versionmetering.moveDown"),
              icon: "digi-arrow-down-line",
              handler: async () => {
                await this.moveNode(params.node.id, Constants.PriceOfferLineMoveAction.Down);
              },
            },
          ],
        },
        {
          group: "4",
          hiddenLabel: true,
          items: [
            {
              id: Constants.CommonMeterginDataLineMenuItems.DELETE,
              label: this.i18n.tr("versionmetering.deleteLine"),
              icon: "digi-trash",
              disabled: () => params.node.data.originalMeasurementXlsLineNumber != null,
              handler: async () => {
                await this.deleteLine(params.node.data.id)
              }
            }
          ]
        }
      ]
    }]
  }

  public triggerExpand() {
    this.screenExpand = !this.screenExpand;
  }

  public async cellValueChanged(event: CellValueChangedEvent) {
    if (event?.colDef?.field == null) return;

    // Patch model
    let colField: string;
    let value: any;

    if (colField == null)
      colField = event.colDef.field;
    if (value == null)
      value = event.data[colField];
    if (colField == "quantityFormula" && event.data.hasChildren && await this.questionUpdateQuantityChildren()) {
      value += "child"
    }

    // Call api
    let result = await this.itemCompositionApiService.patch(this.entity.id, parseInt(event.data.id), colField, value);

    // Manage api result: all modified entities
    if (result != null && result.length) {
      if ((colField == "quantityFormula" || colField == "quantity") /*&& event.data.hasChildren*/) {
        await this.listTreeData.refreshServerSideRows(result, true);
      } else {
        await this.refreshVisibleNodes(result);
      }
      await this.afterAddItems();
      await this.save(true);
      // this.updateItemCompositionLine(event.data.id);
    } else {
      toastr.error(this.i18n.tr("metering.errorSave"));
    }
  }

  async questionUpdateQuantityChildren() {
    let result: boolean = false;
    let buttonYes: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.yes", { ns: "common" }),
      title: this.i18n.tr("general.yes", { ns: "common" }),
      theme: 'primary',
      type: 'solid',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(true);
      }
    };
    let buttonNo: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.no", { ns: "common" }),
      title: this.i18n.tr("general.no", { ns: "common" }),
      theme: 'dark',
      type: 'ghost',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(false);
      }
    };
    await this.box.showQuestion(this.i18n.tr('metering.updateChildrenQuantityQuestion'), this.i18n.tr('menu.question'), [buttonNo, buttonYes]).whenClosed(
      async (resultQuestion) => {
        result = resultQuestion.output
      }
    )
    return result
  }

  private async addItems(targetId: number, action: Constants.PriceOfferLineMoveAction) {
    let targetNode = this.listTreeData.gridOptions.api.getRowNode(targetId.toString());
    if (!targetNode) {
      console.warn('Target node not found!');
      return;
    }

    await this.box.showCustomDialog(AddItems, targetId, null, {
      model: {
        action: action,
        itemCompostionId: this.entity.id,
        api: this.itemCompositionApiService,
        parent: action == PriceOfferLineMoveAction.Into && targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data ? targetNode.data : null
      },
      size: "xl"
    }).whenClosed(async (result) => {
      if (result.output) {
        this.listTreeData.refreshServerSideRows(result.output, targetNode.data.parentId == null && action != PriceOfferLineMoveAction.Into);
        if (action == PriceOfferLineMoveAction.Into) {
          targetNode.setExpanded(true);
        }
        this.afterAddItems();
      }
    });
  }

  private async moveNode(targetRowId: string, action: Constants.PriceOfferLineMoveAction) {
    let selectedNodes = this.listTreeData.gridOptions.api.getSelectedNodes();

    let targetNode = this.listTreeData.gridOptions.api.getRowNode(targetRowId)!;
    if (!targetNode) {
      console.warn('Target node not found!');
      return;
    }

    // Unselect all nodes.
    selectedNodes.forEach(n => n.data.isSelected = false);
    this.listTreeData.gridOptions.api.redrawRows({ rowNodes: selectedNodes });

    // Remove nodes who move inner itself or own children.
    let targetParentsIds: Array<number> = this.parentsIds(targetNode).map(n => n.data.id);
    selectedNodes = selectedNodes.filter(n => targetParentsIds.find(tp => tp == n.data.id) == null);

    // Move nodes
    //this.priceOfferLinesGrid.gridOptions.api.showLoadingOverlay();
    this.globalLoaderService.allow(true, 100);

    let ids = await this.itemCompositionApiService.move(this.entity.id, selectedNodes.map(n => n.data.id), targetNode.data.id, action);
    this.listTreeData.gridOptions.api.hideOverlay();

    // Apply all remove transactions in grid to avoid duplicated row ids when refreshing modified rows.
    let transactions = [];
    selectedNodes.forEach(selected => {
      let parentRoute = selected.parent?.getRoute();
      let tr;
      if ((tr = transactions.find(t => t.route == parentRoute)) != null) {
        tr.remove.push(selected.data)
      } else {
        transactions.push({ route: parentRoute, remove: [selected.data] });
      }
    });

    transactions.forEach(tr => this.listTreeData.gridOptions.api.applyServerSideTransaction(tr));

    // Refresh modified rows
    this.listTreeData.refreshServerSideRows(ids, targetNode.data.parentId == null || selectedNodes.some(n => n.data.parentId == null));
  }

  private parentsIds(node: RowNode): Array<RowNode> {
    let nodes = [];
    while (node != null) {
      if (node.parent != null && node.parent.data != null) {
        nodes.push(node.parent);
        node = node.parent;
      } else {
        node = null;
      }
    }
    return nodes;
  }

  private cantMoveToThisNode(targetRowId: string) {
    let selectedNodes = this.listTreeData.gridOptions.api.getSelectedNodes();
    if (selectedNodes?.length == 0) {
      return true;
    }
    let targetNode = this.listTreeData.gridOptions.api.getRowNode(targetRowId);
    if (!targetNode) {
      console.warn('Target node not found!');
      return true;
    }

    let hasSameParent = false;
    selectedNodes.forEach(selectedNode => {
      if (selectedNode.data.id == targetNode.data.id) {
        hasSameParent = true;
      }
    });
    return hasSameParent;
  }

  private getCreateLineMenuItem(targetId: number, category: Constants.PriceOfferLineCategory) {
    return [
      {
        label: this.i18n.tr("versionmetering.onTopOfLine"),
        handler: () => {
          this.createLine(targetId, category, Constants.PriceOfferLineMoveAction.Up);
        },
        // disabled: () => {
        //   return this.cannotCreateLine(targetId, category, Constants.PriceOfferLineMoveAction.Up);
        // },
        icon: "digi-arrow-up-line"
      },
      {
        label: this.i18n.tr("versionmetering.belowLine"),
        handler: () => {
          this.createLine(targetId, category, Constants.PriceOfferLineMoveAction.Down);
        },
        // disabled: () => {
        //   return this.cannotCreateLine(targetId, category, Constants.PriceOfferLineMoveAction.Down);
        // },
        icon: "digi-arrow-down-line"
      },
      // {
      //   label: this.i18n.tr("versionmetering.childOfLine"),
      //   handler: () => {
      //     this.createLine(targetId, category, Constants.PriceOfferLineMoveAction.Into);
      //   },
      //   disabled: () => {
      //     let targetNode = this.listTreeData.gridOptions.api.getRowNode(targetId.toString());
      //     return targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Comment
      //   },
      //   icon: "digi-arrow-right-line"
      // },
    ]
  }

  private async createLine(targetId: number, categoryId: number, action: Constants.PriceOfferLineMoveAction) {
    let targetNode = this.listTreeData.gridOptions.api.getRowNode(targetId.toString());
    if (!targetNode) {
      console.warn('Target node not found!');
      return;
    }

    targetNode.data.isSelected = false;

    this.listTreeData.gridOptions.api.showLoadingOverlay();
    this.globalLoaderService.allow();

    let ids = await this.itemCompositionApiService.create(this.entity.id, targetId, categoryId, action);
    this.listTreeData.gridOptions.api.hideOverlay();

    this.listTreeData.refreshServerSideRows(ids, targetNode.data.parentId == null && action != PriceOfferLineMoveAction.Into);
    if (action == PriceOfferLineMoveAction.Into) {
      targetNode.setExpanded(true);
    }
  }

  private async deleteLine(id: number) {
    this.globalLoaderService.allow();
    this.listTreeData.gridOptions.api.showLoadingOverlay();

    let idsToDelete = this.listTreeData.gridOptions.api.getSelectedNodes().map(x => x.data.id);
    if (idsToDelete.length == 0 || idsToDelete.findIndex(x => x == id) == -1)
      idsToDelete.push(id);

    let ids = await this.itemCompositionApiService.delete(this.entity.id, idsToDelete);
    this.listTreeData.gridOptions.api.hideOverlay();

    idsToDelete.forEach(id => {
      let node = this.listTreeData.gridOptions.api.getRowNode(id.toString());
      this.listTreeData.refreshServerSideRows(ids, node?.data?.parentId == null);
    });

    this.afterAddItems();
  }

  //#endregion

}
