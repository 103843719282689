import { I18N } from 'aurelia-i18n';
import { computedFrom } from 'aurelia-binding';
import { RouterConfiguration, Router } from 'aurelia-router';
import { ServiceBase, UIInternal } from 'digiwall-lib';
import * as Constants from '../../constants';
import { Merlin } from 'generated';
import { autoinject } from 'aurelia-framework';
import { activationStrategy } from 'aurelia-router';
import { IndexHelper } from 'resources/elements/index-helper';

@autoinject
export class ProjectIndex {
  router: Router;
  entityId: number = null;
  priceOfferId: number;
  projectService: ServiceBase<Merlin.Web.Model.Project>;
  refreshMenu = true;

  get baseUrl() {
    return this.router.parent.currentInstruction?.fragment;
  };

  constructor(private i18n: I18N) {
    this.projectService = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);
  }

  attached() {
    UIInternal.subscribe('router:navigation:complete', () => {
      IndexHelper.setFocusRoute(this.router);
    });
  }



  async activate(params) {
    this.entityId = params.projectId;
    if (this.entityId > 0) {

      let ent = await this.projectService.getEntityById(this.entityId);
      this._ribbonHeaderText = ent.projectName + ' (' + this.entityId + ')';
    } else {
      this._ribbonHeaderText = this.i18n.tr('project.newProject');
    }

    this.router.routes.forEach(r => {
      r.disabled = this.entityId < 0;
      r.params = {
        projectId: this.entityId
      }

    });
  }

  private _ribbonHeaderText: string;
  @computedFrom("_ribbonHeaderText")
  public get ribbonHeaderText(): string {
    return this._ribbonHeaderText;
  }

  get currentRoute() {
    return this.router?.currentInstruction?.config.route;
  }

  configureRouter(config: RouterConfiguration, router: Router, params, routeConfig, currentInstruction): void {
    this.router = router;

    config.map([
      {
        route: [""],
        redirect: "invoices/all",
        nav: false
      },
      {
        route: "invoices/all",
        moduleId: "invoices/invoice-list",
        name: Constants.Routes.RoutesList.InvoiceList,
        title: this.i18n.tr('sellinginvoice.sellinginvoices'),
        nav: true,
      },
      {
        route: "invoices/:invoiceId",
        moduleId: "invoices/invoice-detail",
        title: this.i18n.tr('sellinginvoice.sellinginvoices'),
        nav: false,
        name: "invoices-detail",
        focusRoute: Constants.Routes.RoutesList.InvoiceList
      },
      {
        route: "credit-notes/all",
        moduleId: "invoices/invoice-list",
        name: Constants.Routes.RoutesList.CreditNoteList,
        title: this.i18n.tr('sellinginvoice.creditNotes'),
        nav: true
      },
      {
        route: "credit-notes/:creditNoteId",
        moduleId: "invoices/invoice-detail",
        title: this.i18n.tr('sellinginvoice.creditNotes'),
        nav: false,
        name: "credit-notes-detail",
        focusRoute: Constants.Routes.RoutesList.CreditNoteList
      },
      {
        route: "billing-vouchers/all",
        moduleId: "invoices/invoice-list",
        name: Constants.Routes.RoutesList.BillingVoucherList,
        title: this.i18n.tr('sellinginvoice.billingVouchers'),
        nav: true
      },
      {
        route: "billing-vouchers/:billingVoucherId",
        moduleId: "invoices/invoice-detail",
        title: this.i18n.tr('sellinginvoice.billingVouchers'),
        nav: false,
        name: "billing-vouchers-detail",
        focusRoute: Constants.Routes.RoutesList.BillingVoucherList
      },
      {
        route: "payment/all",
        moduleId: "banks/transaction-list",
        name: Constants.Routes.RoutesList.PaymentList,
        title: this.i18n.tr('banktransaction.banktransactions'),
        nav: true
      },
    ]);
  }
}
