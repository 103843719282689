import { UIInternal } from "digiwall-lib";
import * as Constants from '../constants';
import { MeteringOverlayComp } from 'resources/metering/metering-overlay-comp';
import { MeteringFullWidthCellRenderer } from 'resources/renderer/metering-full-width-cell-renderer';


export class CostCenterOverlayComp extends MeteringOverlayComp {
  setupContentGui(): string {
    let html: string;
    if (this.hasFilterApply || this.priceOfferLinesGrid.versionHasLines) {
      html = `<template><div>${this.i18n.tr("importmetering.noSearchResult")}</div></template>`;
    } else {
      html = `
      <template>
        <div>
          <ui-button type="outline" ui-theme="primary" t="[label]costcenter.addCostCenter" icon="digi-plus-cercle" click.delegate="addPost()"></ui-button>
        </div>
      </template>`;
    }
    return html
  }

  async addPost() {
    this.priceOfferLinesGrid.gridOptions.api.showLoadingOverlay();
    let ids = await this.dataLineApi.create(parseInt(this.priceOfferLinesGrid.versionId), 0, Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Up);
    this.priceOfferLinesGrid.gridOptions.api.hideOverlay();

    this.priceOfferLinesGrid.refreshServerSideRows(ids, true);
  }
}
export class CostCenterFullWidthCellRenderer extends MeteringFullWidthCellRenderer {
  setupContentGui(): string {
    const template =
      `<ui-button type="outline" ui-theme="primary" t="[label]costcenter.addCostCenter" icon="digi-plus-cercle" click.delegate="addPost()"></ui-button>`;

    return template;
  }

  async addPost() {
    this.listTree.gridOptions.api.showLoadingOverlay();
    let ids = await this.apiService.create(this.versionId, this.getLastRowIdLevelOne(), Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Down);
    this.listTree.gridOptions.api.hideOverlay();
    this.listTree.refreshServerSideRows(ids, true);
  }

  private getLastRowIdLevelOne() {
    return this.listTree.gridOptions.api.getRenderedNodes().filter(x => x.data.parentId == null && x.data.uniqueId != 0).last().data.id;
  }
}
