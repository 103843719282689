import { MenuLineCategory } from '../../../resources/metering/metering-menu-items/menu-line-category';
import { PriceOfferLineCategory } from '../../../constants';
import { WorkQuotationVersionApiService } from 'services/work-quotation-version-api-service';
import { Router } from 'aurelia-router';
import { CustomLogger, ServiceBase, EnumerationTypeService, IMenuGroup, PictureHelper, IMenuItems, Various, EditingModeEnum, UIInternal, GlobalLoaderService, ActionDialogBoxInputParameters, DialogBoxViewModel } from 'digiwall-lib';
import * as Constants from '../../../constants';
import { Merlin } from 'generated';
import { autoinject, TaskQueue } from 'aurelia-framework';
import { CommonMetering } from '../common-metering';
import { HttpClient } from 'aurelia-fetch-client';
import { PriceOfferVersionApiService } from 'services/price-offer-version-api-service';
import { FullWidthCellRenderer, VersionOfferOverlayComp } from '../version-offer-overlay-comp';
//import { ImportService } from 'import-data/service/import-service';
import { SelectMappingInject } from '../select-mapping-inject/select-mapping-inject';
import { SendOffer, GenerationApiParams, ReturnParams, GenerationParams } from '../send-offers/send-offer';
import { ICellRendererParams } from 'ag-grid-community';
import { ReportApiService, ReportResult } from 'services/report-api-service';
import { ColumnVisible } from 'module-list-tree-data/module-list-tree-data';
import { PriceOfferLineCommentApiService } from 'services/price-offer-line-comment-api-service';
import { ProjectApiService } from 'services/project-api-service';
import { PriceRepartitionService } from 'services/price-repartition-service';
import { StartProject } from 'utils/start-project';
import { DocumentTypeDiscriminator } from 'request-supplier-offers/request-supplier-offer-detail';
import { MeteringMenuItems } from 'resources/metering/metering-menu-items';
import moment from 'moment';
import { UpdateItemType } from '../update-items/update-items';
import { CanEditCellDto } from 'price-offer-lines/price-offer-lines-grid';
import { ImportManager } from 'resources/utilities/import-manager';
@autoinject
export class PriceOfferVersionDetail extends CommonMetering<Merlin.Web.Model.PriceOfferVersion> {
  public ressourceName: string = Constants.EntityTypeNames.PriceOffer;
  private menuItems: Array<IMenuGroup>;
  private priceOfferStatusService: EnumerationTypeService;
  private vatLevelService: ServiceBase<Merlin.Web.Model.VATLevel>;
  private signatoryService: ServiceBase<Merlin.Web.Model.OfferVersionSignatory>;
  private parameterService: ServiceBase<Merlin.Web.Model.ApplicationParameter>;
  private hasOneFinishedSession: boolean = false;
  private hasStatusOpen = true;

  private priceOfferMobilizationDelayService: EnumerationTypeService
  private priceOfferPaymentDelayService: EnumerationTypeService
  private priceOfferDepositService: EnumerationTypeService
  private priceOfferPriceRevisionTechnicService: EnumerationTypeService

  public columnVisible: Array<ColumnVisible> =
    [
      { colId: "menuIems", visibility: true },
      { colId: "selected", visibility: true },
      { colId: "merlinRef", visibility: true },
      { colId: "merlinRefMetering", visibility: true },
      { colId: "merlinRefContext", visibility: true },
      { colId: "lineDescription", visibility: true },
      { colId: "lineRef", visibility: true },
      { colId: "originalMeasurementXlsLineNumber", visibility: true },
      { colId: "sheetIndex", visibility: true },
      { colId: "importFileName", visibility: true },
      { colId: "quantityTypeId", visibility: true },
      { colId: "unitId", visibility: true },
      { colId: "proposedQuantity", visibility: true },
      { colId: "proposedQuantityFormula", visibility: true },
      { colId: "priceOfferLineFileCount", visibility: true },
      { colId: "additionalDescriptionMultiline", visibility: true },
      { colId: "requestedQuantity", visibility: true },
      { colId: "dataLineDeliveries", visibility: true },
      { colId: "dataLineActivities", visibility: true },
      { colId: "buyingUnitPrice", visibility: true },
      { colId: "definedSellingUnitPrice", visibility: true },
      { colId: "definedDirectMarginPrice", visibility: true },
      { colId: "diffMarginPrice", visibility: true },
      { colId: "diffIndirectCostFromPAR", visibility: true },
      { colId: "sellingUnitPriceWithIndirectCostFromPAR", visibility: true },
      { colId: "indirectCostFromPAR", visibility: true },
      { colId: "totalBuyingPrice", visibility: true },
      { colId: "totalSellingPrice", visibility: true },
      { colId: "tags", visibility: true },
      { colId: "features", visibility: true },
      { colId: "supplierId", visibility: true },
      { colId: "supplierPrice", visibility: false },
      { colId: "supplierQuantity", visibility: false },
      { colId: "supplierQuantityTypeId", visibility: false },
      { colId: "supplierTotal", visibility: false },
      { colId: "supplierComment", visibility: false },
      { colId: "acceptedPriceOfferBuyingUnitPrice", visibility: false },
      { colId: "unitQuantityDetailItemComposed", visibility: true },
      { colId: "unitQuantityFormulaDetailItemComposed", visibility: true },
      { colId: "overheadMarginCoefficient", visibility: true },
      { colId: "overheadMarginAmount", visibility: true },
      { colId: "benefitMarginCoefficient", visibility: true },
      { colId: "benefitMarginAmount", visibility: true },
      { colId: "riskMarginCoefficient", visibility: true },
      { colId: "riskMarginAmount", visibility: true },
      { colId: "buildingCostsId", visibility: true },
    ];


  private nbDecimalForPriceDisplay: number
  private nbDecimalForQuantityDisplay: number

  constructor(router: Router, logger: CustomLogger, httpClient: HttpClient, private projectApiService: ProjectApiService, public priceOfferVersionApiService: PriceOfferVersionApiService, private workQuotationVersionApiService: WorkQuotationVersionApiService, private fileHelper: PictureHelper, private gls: GlobalLoaderService, private reportApiService: ReportApiService, priceOfferLineCommentApiService: PriceOfferLineCommentApiService, priceRepartitionService: PriceRepartitionService, private meteringMenuItems: MeteringMenuItems, private taskQueue: TaskQueue, public importManager: ImportManager) {
    super(router, logger, httpClient, priceOfferVersionApiService, gls, priceOfferLineCommentApiService, priceRepartitionService);
    this.priceRepartitionService.type = DocumentTypeDiscriminator.PriceOfferVersion;;
    this.priceOfferStatusService = new EnumerationTypeService(Constants.EnumerationTypes.PriceOfferStatus);
    this.vatLevelService = new ServiceBase<Merlin.Web.Model.VATLevel>(Constants.EntityTypeNames.VATLevel);
    this.signatoryService = new ServiceBase<Merlin.Web.Model.OfferVersionSignatory>(Constants.EntityTypeNames.OfferVersionSignatory);
    this.parameterService = new ServiceBase<Merlin.Web.Model.ApplicationParameter>(Constants.EntityTypeNames.ApplicationParameter);

    this.priceOfferMobilizationDelayService = new EnumerationTypeService(Constants.EnumerationTypes.PriceOfferMobilizationDelay);
    this.priceOfferPaymentDelayService = new EnumerationTypeService(Constants.EnumerationTypes.PriceOfferPaymentDelay);
    this.priceOfferDepositService = new EnumerationTypeService(Constants.EnumerationTypes.PriceOfferDeposit);
    this.priceOfferPriceRevisionTechnicService = new EnumerationTypeService(Constants.EnumerationTypes.PriceOfferPriceRevisionTechnic);

    super.initialize(new ServiceBase<Merlin.Web.Model.PriceOfferVersion>(Constants.EntityTypeNames.PriceOfferVersion));
  }

  public documentTitle = this.i18n.tr("priceoffer.priceoffer");

  public get ribbonHeaderText(): string {
    return this.documentTitle;
  }

  private async getEntity(id) {
    this.entity = await this.service.getEntityById(id, 'priceOfferStatus', 'vatLevel', 'workQuotationVersion', 'offerVersionSignatories.signatoryUser', 'offerMail', 'priceOfferMobilizationDelay', 'priceOfferPaymentDelay', 'priceOfferDeposit', 'priceOfferPriceRevisionTechnic', 'offerVersionSignatories');
    this.hasStatusOpen = this.entity.priceOfferStatusId == Constants.PriceOfferStatusId.Open;
  }

  public async activate(params: any) {
    await super.activate(params);
    this.projectId = params.projectId;
    this.workQuotationVersionId = params.workQuotationVersionId;
    this.priceOfferVersionId = params.priceOfferVersionId;
    if (parseInt(this.priceOfferVersionId) == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      this.entity.workQuotationVersionId = parseInt(this.workQuotationVersionId);
      this.entity.priceOfferStatusId = Constants.PriceOfferStatusId.Open;
      this.entity.priceOfferStatus = await this.priceOfferStatusService.getEntityById(this.entity.priceOfferStatusId);
      let parameter: Merlin.Web.Model.ApplicationParameter = (await this.parameterService.getEntities())[0];
      this.entity.validUntilDate = moment().add(parameter.clientPriceOfferValidityDelayNbDays, 'days').toDate();

    }
    else {
      this.editingMode = EditingModeEnum.Update;
      await this.getEntity(this.priceOfferVersionId);
      this.hasOneFinishedSession = (await this.getSessions(parseInt(this.workQuotationVersionId))).length > 0;
      this.getMeteringTotalPrices();

      await this.setMenuItems();
      this.priceOfferLineTypeList = await (new EnumerationTypeService(Constants.EnumerationTypes.PriceOfferLineType)).getAll();

    }

    let precisionParameter = await this.projectApiService.getPrecisionParameter(parseInt(params.projectId));
    this.nbDecimalForPriceDisplay = precisionParameter.nbDecimalForPriceDisplay;
    this.nbDecimalForQuantityDisplay = precisionParameter.nbDecimalForQuantityDisplay;

    this.controller.addObject(this.entity);
    super.activate(params);
  }

  public async bind() {
    if (this.entity.errorCode == Constants.PriceOfferErrorCode.TotalInconsistency) {
      await this.handleErrorPriceVersion();
    }
    this.taskQueue.queueTask(async () => {
      if (this.editingMode == EditingModeEnum.Create) {
        await this.save();
      }
    })
  }

  async deactivate() {
    await this.priceOfferLinesGrid?.deactivate();
  }

  getBottomRowRenderer() {
    return FullWidthCellRenderer;
  }

  public canEditCell(line: CanEditCellDto, colField: string): boolean {
    if (line == null || colField == null) return false;
    if (this.entity.priceOfferStatusId != Constants.PriceOfferStatusId.Open) return false;

    // Restrict actions on original lines
    if (line.workQuotationVersionLineId != null) {
      switch (colField) {
        case 'overheadMarginCoefficient':
          return line.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Comment && !this.entity.overheadMarginCoefficientIsLock && !line.parentIsMaster;
        case 'benefitMarginCoefficient':
          return line.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Comment && !this.entity.benefitMarginCoefficientIsLock && !line.parentIsMaster;
        case 'riskMarginCoefficient':
          return line.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Comment && !this.entity.riskMarginCoefficientIsLock && !line.parentIsMaster;
        case 'definedSellingUnitPrice':
        case 'lineDescription':
        case 'proposedQuantity':
        case "unitQuantityFormulaDetailItemComposed":
        case 'additionalDescriptionMultiline':
        case 'buyingUnitPrice':
        case 'totalSellingPrice':
          return super.canEditCell(line, colField);
        default:
          return false;
      }
    } else {
      switch (colField) {
        case 'overheadMarginCoefficient':
          return line.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Comment && !this.entity.overheadMarginCoefficientIsLock && !line.parentIsMaster;
        case 'benefitMarginCoefficient':
          return line.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Comment && !this.entity.benefitMarginCoefficientIsLock && !line.parentIsMaster;
        case 'riskMarginCoefficient':
          return line.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Comment && !this.entity.riskMarginCoefficientIsLock && !line.parentIsMaster;
      }
      return super.canEditCell(line, colField);
    }
  }

  public getGridMenuItems(params: ICellRendererParams): Array<IMenuGroup> {
    return [
      this.meteringMenuItems.getAdd(params, this.priceOfferLinesGrid, this.priceOfferVersionApiService, this.priceOfferVersionId, this.workQuotationVersionId, this.priceOfferVersionId, this.computeMeteringTotalPrice, this),
      {
        group: "1",
        hiddenLabel: true,
        items: [
          this.meteringMenuItems.getDuplicate(params, this.priceOfferVersionApiService, this.priceOfferLinesGrid, this.priceOfferVersionId, this.computeMeteringTotalPrice, this),
          this.meteringMenuItems.getCopy(params, this.priceOfferLinesGrid, Constants.LocalStorageName.PriceOffer),
          this.meteringMenuItems.getPaste(params, Constants.LocalStorageName.PriceOffer, this.priceOfferLinesGrid, this.priceOfferVersionApiService, this.priceOfferVersionId, this.computeMeteringTotalPrice, this),
          this.meteringMenuItems.getMove(params, this.priceOfferVersionApiService, this.priceOfferLinesGrid, this.listIsFlat, this.priceOfferVersionId, this.computeMeteringTotalPrice, this)
        ],
      },
      {
        group: "2",
        hiddenLabel: true,
        items: [
          this.meteringMenuItems.getTypeItems(params, this.priceOfferLineTypeList, this.priceOfferVersionApiService, this.priceOfferVersionId, this.priceOfferLinesGrid, this.computeMeteringTotalPrice, this),
          {
            // id: Constants.CommonMeterginDataLineMenuItems.COPY,
            label: this.i18n.tr("versionmetering.disabledIsMaster"),
            icon: "digi-copy",
            handler: async () => {
              let result = await this.priceOfferVersionApiService.patch(parseInt(this.priceOfferVersionId), params.node.data.id, "isMaster", false);
              this.priceOfferLinesGrid.refreshServerSideRows(result, false);
            },
            disabled: () => {
              return !params.node?.data?.isMaster;
            }
          },
          this.meteringMenuItems.getUpdateItems(params, this.dataLineApiService, this.priceOfferLinesGrid, this.projectId, this.priceOfferVersionId, this.columnVisible, UpdateItemType.Other, this.router.currentInstruction.config.moduleId, !this.hasStatusOpen, this.computeMeteringTotalPrice, this),
          this.meteringMenuItems.getAddDocument(params, this.projectService, this.priceOfferLinesGrid, this.projectId, this.workQuotationVersionId, this.priceOfferVersionId)
        ]
      },
      {
        group: "5",
        hiddenLabel: true,
        items: [
          this.meteringMenuItems.getCommentCell(params, this.priceOfferLineCommentApiService, this.priceOfferLinesGrid)
        ]
      },
      {
        group: "3",
        hiddenLabel: true,
        items: [
          ...this.meteringMenuItems.getPriceRepartition(params, this.priceRepartitionService, DocumentTypeDiscriminator.PriceOfferVersion, this.priceOfferVersionId, this.priceOfferLinesGrid, this.computeMeteringTotalPrice, this),
          this.meteringMenuItems.getDisplayHidden(params, this.priceOfferVersionApiService, this.priceOfferLinesGrid, this.priceOfferVersionId)
        ]
      },
      {
        group: "4",
        hiddenLabel: true,
        items: [
          this.meteringMenuItems.getDeleteLine(params, this.priceOfferVersionApiService, this.priceOfferLinesGrid, this.priceOfferVersionId, this.computeMeteringTotalPrice, this)
        ]
      }
    ]
  }

  internalCustomSettingMenuItems(): IMenuItems[] {
    return [
      {
        group: "3",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("priceofferversion.showOnlyNoCategory"),
            hidden: () => (this.dataLineApiService as PriceOfferVersionApiService).getNoCategoryLines,
            handler: async () => {
              (this.dataLineApiService as PriceOfferVersionApiService).getNoCategoryLines = true;
              this.priceOfferLinesGrid.refreshServerSideRows([], true, true);
            }
          },
          {
            label: this.i18n.tr("priceofferversion.showAll"),
            hidden: () => !(this.dataLineApiService as PriceOfferVersionApiService).getNoCategoryLines,
            handler: async () => {
              (this.dataLineApiService as PriceOfferVersionApiService).getNoCategoryLines = false;
              this.priceOfferLinesGrid.refreshServerSideRows([], true, true);
            }
          },
        ]
      },
      {
        group: "2",
        hiddenLabel: true,
        items: [
          {

            label: this.i18n.tr("priceofferversion.lockBenefitMarginCoefficient"), //lock benefis
            hidden: () => this.entity.benefitMarginCoefficientIsLock,
            handler: async () => {
              if (this.entity.riskMarginCoefficientIsLock && this.entity.overheadMarginCoefficientIsLock) {
                this.logError('priceofferversion.notLockAllCoefficient', null, true);
              } else {
                this.entity.benefitMarginCoefficientIsLock = true;
                await super.save(true);
              }
            }
          },
          {
            label: this.i18n.tr("priceofferversion.unlockBenefitMarginCoefficient"),//unlock benefis
            hidden: () => !this.entity.benefitMarginCoefficientIsLock,
            handler: async () => {
              this.entity.benefitMarginCoefficientIsLock = false;
              await super.save(true);
            }
          },

          {

            label: this.i18n.tr("priceofferversion.lockRiskMarginCoefficient"),//lock frais
            hidden: () => this.entity.riskMarginCoefficientIsLock,
            handler: async () => {
              if (this.entity.benefitMarginCoefficientIsLock && this.entity.overheadMarginCoefficientIsLock) {
                this.logError('priceofferversion.notLockAllCoefficient', null, true);
              } else {
                this.entity.riskMarginCoefficientIsLock = true;
                await super.save(true);
              }
            }
          },
          {
            label: this.i18n.tr("priceofferversion.unlockRiskMarginCoefficient"),//unlock frais
            hidden: () => !this.entity.riskMarginCoefficientIsLock,
            handler: async () => {
              this.entity.riskMarginCoefficientIsLock = false;
              await super.save(true);
            }
          },

          {

            label: this.i18n.tr("priceofferversion.lockOverheadMarginCoefficient"),//lock risque
            hidden: () => this.entity.overheadMarginCoefficientIsLock,
            handler: async () => {
              if (this.entity.benefitMarginCoefficientIsLock && this.entity.riskMarginCoefficientIsLock) {
                this.logError('priceofferversion.notLockAllCoefficient', null, true);
              } else {
                this.entity.overheadMarginCoefficientIsLock = true;
                await super.save(true);
              }
            }
          },
          {
            label: this.i18n.tr("priceofferversion.unlockOverheadMarginCoefficient"),//unlock risque
            hidden: () => !this.entity.overheadMarginCoefficientIsLock,
            handler: async () => {
              this.entity.overheadMarginCoefficientIsLock = false;
              await super.save(true);
            }
          },
        ]
      },
    ];
  }

  private async setMenuItems() {

    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("priceoffer.summary"),//Passer en vue récap
            icon: "digi-calculator-line",
            handler: () => {
              this.goToSummary(this.entity.id);
            }
          },
          {
            label: this.i18n.tr("priceoffer.computePAR"),
            icon: "digi-calculator-line",
            disabled: () => !this.hasStatusOpen,
            handler: async () => {
              await this.computePAR();
            }
          },
          {
            label: this.i18n.tr("priceoffer.changeStatus"),
            icon: "digi-exchange-line",
            items: await this.getStatusItem()
          },
          {
            label: this.i18n.tr("priceoffer.import"),
            icon: "digi-file-download-line",
            handler: () => {
              this.goToImport();
            }
          }
        ]
      },
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("priceofferversion.previewOffer"),
            icon: "digi-search",
            handler: async () => {
              await this.previewOffer();
            },
          },
          {
            label: this.i18n.tr("priceofferversion.sendOffer"),
            icon: "digi-mail-send-line",
            handler: async () => {
              await this.sendGenerateXlsx();
            },
          }
        ]
      },
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("general.duplicate"),
            icon: "digi-duplicate",
            handler: () => {
              this.duplicateOffer(this.entity.id)
            },
            disabled: () => this.isCreationMode
          },
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-trash",
            handler: async () => {
              this.globalLoaderService.allow();
              await this.delete();
            },
            disabled: () => !this.hasStatusOpen
          }
        ]
      }
    ];
  }
  async goToImport() {
    await this.importManager.importFile(Constants.ImportContext.PriceOffer, this.entity.id, this.priceOfferLinesGrid, null, this.router, {
      "context": Constants.ImportContext.PriceOffer,
      "customParameter": this.entity.id,
      "projectId": this.projectId,
      "errorView": "projects/quotes/import-metering-offer-error-table.html",
      configView: "projects/quotes/metering-config.html",
      sessionId: null,
      importMode: null,
      fileSessionId: null,
      disclaimerView: "projects/quotes/metering-import-disclaimer.html",
      importStep6View: "projects/quotes/import-step-6.html",
      lastStepFooterView: "projects/quotes/last-step-footer-view.html",
      callbackUrl: `/projects/${this.projectId}/quote/price-offer-version-detail/${this.entity.id}`
    });
  }
  private goToSummary(id: number) {
    this.router.navigate(`price-offer-version-summary/${id}`);
  }

  public override async getMeteringTotalPrices() {
    super.getMeteringTotalPrices();
    await this.getEntity(this.priceOfferVersionId);
  }

  goToDetail(id: number | null) {
    if (id == null) {
      id = -100;
    }
    let url = this.router.generate("price-offer-version-detail", { priceOfferVersionId: id });
    if (this.router?.currentInstruction?.queryString?.length > 0) {
      url += '?' + this.router.currentInstruction.queryString;
    }
    this.router.navigate(url);
  }

  private async duplicateOffer(id: number) {
    if (id == null) return;
    let newId = await this.priceOfferVersionApiService.duplicateVersion(id);
    if (newId != null && typeof newId == 'number')
      this.goToDetail(newId);
  }

  private async getStatusItem() {
    let itemsStatus: Array<IMenuItems> = new Array();
    let statuses = await this.priceOfferStatusService.getAll();
    for (let index = 0; index < statuses.length; index++) {
      const stat = statuses[index];
      let canBeSelected = true;
      if (stat.id == Constants.PriceOfferStatusId.Accepted) {
        let response = await this.httpClient.get(Constants.Application.PriceOfferVersionController.CanBeAccepted.format(this.priceOfferVersionId))
        canBeSelected = await response.json();
      }

      itemsStatus.push({
        label: stat.denomination._translation,
        handler: async () => {
          this.gls.allow();
          await this.httpClient.post(Constants.Application.PriceOfferVersionController.SetStatus.format(this.priceOfferVersionId, stat.id.toString()));
          if (this.entity != null) {
            await this.getEntity(this.entity.id);
            // If status is not Open anymore, all menus must change
            this.priceOfferLinesGrid?.refreshServerSideRows([], true);
            this.setMenuItems();
          }
          if (stat.id == Constants.PriceOfferStatusId.Accepted) {
            await StartProject.startProject(this.entity.workQuotationVersion.projectId,);
          }
        },
        disabled: !canBeSelected || stat.id === this.entity.priceOfferStatusId
      });
    }
    return itemsStatus;
  }

  async sendGenerateXlsx() {
    let result = await this.priceOfferVersionApiService.getPMThatNeedRepartion(parseInt(this.priceOfferVersionId));

    if (result.length > 0) {
      let buttonYes: ActionDialogBoxInputParameters =
      {
        label: this.i18n.tr("general.yes", { ns: "common" }),
        title: this.i18n.tr("general.yes", { ns: "common" }),
        theme: 'dark',
        type: 'ghost',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.ok();
        }
      };
      let buttonNo: ActionDialogBoxInputParameters =
      {
        label: this.i18n.tr("general.no", { ns: "common" }),
        title: this.i18n.tr("general.no", { ns: "common" }),
        theme: 'primary',
        type: 'solid',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.cancel();
        }
      };
      this.box.showQuestion(this.i18n.tr('priceofferversion.pmStillNeedRepartitionQuestionPart1') + result + this.i18n.tr('priceofferversion.pmStillNeedRepartitionQuestionPart2'), this.i18n.tr('menu.question'), [buttonYes, buttonNo])
        .whenClosed(result => {
          if (!result.wasCancelled) {
            this.showSendOfferBox();
          }
        });
    }
    else {
      this.showSendOfferBox();
    }
  }

  private showSendOfferBox() {
    this.box.showCustomDialog(
      SendOffer,
      this.entity.id,
      this.i18n.tr('priceofferversion.sendOffer'),
      {
        canSave: false,
        size: 'lg',
        model: {
          generationParams: {
            projectTypeId: this.entity.workQuotationVersion.project.projectTypeId,
            projectId: this.entity.workQuotationVersion.projectId,
            companyIds: [this.entity.workQuotationVersion.project.mainThirdPartyId],
            coverLetterContentHtml: this.entity.coverLetterContentHtml,
            annexContentHtml: this.entity.annexContentHtml,
            coverLetterContext: Constants.ContentTypeId.ClientOfferCoverLetter,
            annexContext: Constants.ContentTypeId.AnnexOfferClient,
            emailContext: Constants.EmailContextId.Project,
            languageId: this.entity.workQuotationVersion.project.communicationLanguageId,
            signatoryIds: this.entity.offerVersionSignatories.map(x => x.signatoryUserId),
            previewRequestParams: {
              projectId: this.entity.workQuotationVersion.projectId,
              thirdPartyId: this.entity.workQuotationVersion.project.mainThirdPartyId,
              priceOfferVersionId: this.entity.id
            },
            afterStep2: async (data: ReturnParams) => {
              await this.handleGenerationReturnParams(data);
            },
            items: [
              {
                id: PriceOfferGroupId.Excel,
                name: this.i18n.tr("priceofferversion.excel"),
                items: [
                  {
                    id: ExcelGenerationId.Injecting,
                    icon: "digi-file-3-line",
                    title: this.i18n.tr("priceofferversion.originalFile"),
                    description: this.i18n.tr("priceofferversion.originalExcelModel"),
                    selected: true,
                    disabled: false,
                    handler: async (data: GenerationApiParams) => {
                      let result = null;
                      await this.box.showCustomDialog(SelectMappingInject,
                        null,
                        null,
                        {
                          canSave: false,
                          size: 'xl',
                          model: {
                            workQuotationVersionId: this.entity.workQuotationVersionId,
                            includeInLetter: data.includeTotalInLetter
                          }
                        }).whenClosed(async (output) => {
                          if (!output.wasCancelled) {
                            result = await this.reportApiService.getExternalExcel(this.entity.id, data.includeTotalInLetter, data.displayIncluded);
                          }
                        });
                      return result;
                    }
                  },
                  {
                    id: ExcelGenerationId.Internal,
                    icon: "digi-synchronization",
                    title: this.i18n.tr("priceofferversion.custom"),
                    description: this.i18n.tr("priceofferversion.internalExcelModel"),
                    selected: false,
                    disabled: this.entity.workQuotationVersion.project.projectTypeId == Constants.ProjectTypeId.Public,
                    handler: async (data: GenerationApiParams) => {
                      return await this.reportApiService.getInternalExcel(this.entity.id, data.includeTotalInLetter, data.selectedRefToDisplay, data.displayIncluded);
                    }
                  }
                ]
              },
              {
                id: PriceOfferGroupId.PDF,
                name: this.i18n.tr("priceofferversion.pdf"),
                items: [
                  {
                    id: 1,
                    icon: "digi-file-sync",
                    title: this.i18n.tr("priceofferversion.generate"),
                    description: this.i18n.tr("priceofferversion.pfdHasInternalModel"),
                    selected: false,
                    disabled: this.entity.workQuotationVersion.project.projectTypeId == Constants.ProjectTypeId.Public,
                    handler: async (data: GenerationApiParams) => {
                      return await this.reportApiService.getOfferPdf(this.entity.id, data.currentSelectionState[PriceOfferGroupId.Excel] == ExcelGenerationId.Injecting, data.includeTotalInLetter, data.selectedRefToDisplay, data.displayIncluded);
                    }
                  }
                ],
                defaultHandler: async (data: GenerationApiParams) => {
                  return await this.reportApiService.getCover(this.entity.id, data.currentSelectionState[PriceOfferGroupId.Excel] == ExcelGenerationId.Injecting, data.displayIncluded);
                }
              }
            ]
          }
        }
      }
    ).whenClosed(async (result) => {
      if (!result.wasCancelled) {
        await this.handleGenerationReturnParams(result.output)
      }
    });
  }

  private async handleGenerationReturnParams(data: ReturnParams) {
    this.entity.annexContentHtml = data.annexContentHtml;
    this.entity.coverLetterContentHtml = data.coverLetterContentHtml;
    if (data.notificationId != null) {
      this.entity.offerMailId = data.notificationId
    }
    await this.service.saveEntity(this.entity, true);

    if (this.entity.offerVersionSignatories.length > 0)
      await this.signatoryService.deleteEntities(this.entity.offerVersionSignatories, false, null, true);

    data.signatoryIds.forEach(async (signatoryId) => {
      this.entity.offerVersionSignatories.push(await this.signatoryService.createEntity({ signatoryUserId: signatoryId, priceOfferVersionId: this.entity.id }));
    });
    await this.signatoryService.saveEntities(this.entity.offerVersionSignatories, true);
  }

  private async previewOffer() {
    this.globalLoaderService.allow();
    var result = await this.reportApiService.getPreviewOfferPdf(this.entity.id);
    this.fileHelper.previewFile(result);
  }

  private getFileName(url: string) {
    let temp = url.split('/');
    let last = temp[temp.length - 1];
    return last;

  }

  private async canExport() {
    let canExport;
    let requestUri = Constants.Application.PriceOfferVersionController.CanExportXls.format(this.workQuotationVersionId);
    let query = await this.httpClient.get(requestUri);
    if (query.ok) {
      canExport = await query.json();
    }
    return canExport;
  }

  public async save(): Promise<any> {
    this.gls.allow(true, 0);
    await super.save(true);

    if (!this.isCreationMode) {
      this.priceOfferLinesGrid.refreshServerSideRows([], true);
      await this.getMeteringTotalPrices();
    }
  }

  protected async afterSave(): Promise<void> {
    if (this.isCreationMode) {
      this.priceOfferVersionId = this.entity.id.toString();
      await (this.priceOfferVersionApiService as PriceOfferVersionApiService).createPriceOfferVersion(this.entity.id);
    }

    await this.setMenuItems();
  }

  async computePAR() {
    if (!(typeof this.priceRepartitionService.computePAR == "function")) {
      console.warn('API computePAR not implemented !');
    }
    let finished = await this.priceRepartitionService.computePAR(parseInt(this.priceOfferVersionId));
    if (finished) {
      this.priceOfferLinesGrid.refreshServerSideRows(null, true);
      await this.getMeteringTotalPrices();
    }
  }

  async exportXls() {
    let wasCancelled = await this.getMapColumn();
    if (!wasCancelled) {
      if (await this.canExport()) {
        this.gls.allow();
        let result = await this.reportApiService.getExternalExcel(this.entity.id, false, false);
        this.fileHelper.downloadFile({ url: result.url });
      }
      else {
        this.logger.LogError(this.i18n.tr("priceofferversion.mappingExportError"), null, null, true);
      }
    }
  }
  private async getMapColumn() {
    let wasCancelled = false;
    await this.box.showCustomDialog(SelectMappingInject,
      null,
      null,
      {
        canSave: false,
        size: 'all',
        model: {
          workQuotationVersionId: this.workQuotationVersionId
        }
      }).whenClosed(async (result) => {
        wasCancelled = result.wasCancelled
      });
    return wasCancelled;
  }

  getOverlayComponent() {
    return VersionOfferOverlayComp;
  }

  private async getSessions(workQuotationVersionId: number) {
    let importSessions = await this.workQuotationVersionApiService.getSessions(workQuotationVersionId);
    return importSessions;
  }

  public async handleErrorPriceVersion() {
    let buttonYes: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.yes", { ns: "common" }),
      title: this.i18n.tr("general.yes", { ns: "common" }),
      theme: 'primary',
      type: 'solid',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(true);
      }
    };
    let buttonNo: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.no", { ns: "common" }),
      title: this.i18n.tr("general.no", { ns: "common" }),
      theme: 'dark',
      type: 'ghost',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(false);
      }
    };
    await this.box.showQuestion(this.i18n.tr('priceofferversion.recomputePriceQuestion'), this.i18n.tr('menu.question'), [buttonNo, buttonYes])
      .whenClosed(async result => {
        if (result.output) {
          await this.recomputeTotalVersion();
        }
        await this.getMeteringTotalPrices();
      })
  }

  private async recomputeTotalVersion() {
    await this.priceOfferVersionApiService.recomputeTotalVersion(this.entity.id);
    await this.getEntity(this.priceOfferVersionId);
  }
  public async getExcelFileName() {
    return (await this.projectService.getEntityById(Number.parseInt(this.projectId))).projectName + "_" + this.i18n.tr("projectindex.quote") + '_' + "V." + this.entity.workQuotationVersion.quotationVersionNum + '_' + this.entity.workQuotationVersion.quotationVersionName + '_' + this.i18n.tr("versionindex.offers") + "_" + this.entity.priceOfferVersionID
  }
  public override handleKeyDown(e) {
    if (e.key == "Delete") {
      this.meteringMenuItems.menuDeleteLine.deleteLine(0, this.priceOfferVersionApiService, this.priceOfferLinesGrid, this.priceOfferVersionId, this.computeMeteringTotalPrice, this)
    }
  }
}

export enum PriceOfferGroupId {
  Excel = 1,
  PDF
}

export enum ExcelGenerationId {
  Injecting = 1,
  Internal
}
