@import "../../../css/scss/variables";

ui-panel.no-border {
  > .ui-panel__header {
    border: none;
    padding: 0;
  }
  > .ui-panel__body {
    padding: 0;
  }
}

table.completeness {
  thead {
    tr {
      border-bottom: none;
      th {
        font-weight: 400;
        &:not(:first-child) {
          font-size: 12px;
          line-height: 15px;
          color: $gray-mid;
          text-align: center;
        }
      }
      &:nth-child(2) {
        > th {
          padding: 0;
        }
      }
    }
  }
  tbody {
    tr {
      border-bottom: none;
      td {
        color: $black-100;
        padding: 14px 0px;
        line-height: 20px;
        &:has(ui-input) {
          padding: 0px;
        }
        &:first-child {
          font-size: 15px;
          padding-left: 3px;
        }
        &:not(:first-child) {
          font-size: 14px;
          padding-left: 8px;
          padding-right: 8px;
          text-align: right;
        }
      }
      &.title {
        background: $purple-light;
        > td:first-child {
          font-weight: bold;
        }
        > td.margin-column {
          border-left: none;
        }
      }
      &.sub-title {
        &.sub-contractor {
          background: $purple-very-light;

          > td.margin-column {
            border-left: none;
          }
        }
        > td:first-child {
          padding-left: 25px;
        }
      }
      &:not(.title):not(.sub-title) {
        > td:first-child {
          padding-left: 50px;
        }
      }
    }
  }

  .margin-column {
    border-left: 1px $gray-lighter solid;
  }
}
