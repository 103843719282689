import { PriceOfferLineCategory } from './../../../constants';
import { Container } from "aurelia-framework";
import * as Constants from '../../../constants';
import { ICellRendererParams, RowNode } from "ag-grid-enterprise";
import { ActionDialogBoxInputParameters, Box, DialogBoxViewModel, IMenuItems, KeyboardShortcut } from "digiwall-lib";
import { I18N } from "aurelia-i18n";
import { PriceOfferLinesGrid } from 'price-offer-lines/price-offer-lines-grid';
import { IDataLineApiService } from 'services/i-data-line-api-service';

export class MenuLineCategory {
  public static getMenu(params: ICellRendererParams, dataLineApiService: IMeterigSwitchType, versionId: string, priceOfferLinesGrid: PriceOfferLinesGrid, getMeteringTotalPrices: (id: string, dataLineApiService: IDataLineApiService, _this: any) => any, _this: any): IMenuItems {
    let i18n = Container.instance.get(I18N);
    let menuItems = {
      id: Constants.CommonMeterginDataLineMenuItems.LINE_CATEGORY,
      label: i18n.tr("versionmetering.lineCategory"),
      icon: "digi-price-tag-3-line",
      items: [
        {
          group: i18n.tr("versionmetering.lineCategory"),
          items: MenuLineCategory.getLineCategories(params, i18n, dataLineApiService, versionId, priceOfferLinesGrid, getMeteringTotalPrices, _this)
        }
      ]
    } as IMenuItems;
    return menuItems;
  }

  public static getLineCategories(params: ICellRendererParams, i18n: I18N, dataLineApiService: IMeterigSwitchType, versionId: string, priceOfferLinesGrid: PriceOfferLinesGrid, getMeteringTotalPrices: (id: string, dataLineApiService: IDataLineApiService, _this: any) => any, _this: any): Array<IMenuItems> {
    return [
      {
        label: i18n.tr("versionmetering.categoryPost"),
        icon: "digi-folder-add-line",
        disabled: () => {
          var selectedLines = priceOfferLinesGrid.gridOptions.api.getSelectedNodes();
          return MenuLineCategory.linesCanSwitchType([params.node, ...selectedLines], PriceOfferLineCategory.Data);
        },
        handler: async () => {
          var selectedLines = priceOfferLinesGrid.gridOptions.api.getSelectedNodes();
          var ids = await MenuLineCategory.switchToPost([params.node, ...selectedLines], dataLineApiService, versionId);
          priceOfferLinesGrid.refreshVisibleNodes(ids, true);
          getMeteringTotalPrices(versionId, dataLineApiService as unknown as IDataLineApiService, _this)
        }
      },
      {
        label: i18n.tr("versionmetering.categoryChapter"),
        icon: "digi-folder-add-line",
        disabled: () => {
          var selectedLines = priceOfferLinesGrid.gridOptions.api.getSelectedNodes();
          return MenuLineCategory.linesCanSwitchType([params.node, ...selectedLines], PriceOfferLineCategory.Chapter);
        },
        handler: async () => {
          var selectedLines = priceOfferLinesGrid.gridOptions.api.getSelectedNodes();
          var ids = await MenuLineCategory.switchToChapter([params.node, ...selectedLines], dataLineApiService, versionId);
          priceOfferLinesGrid.refreshVisibleNodes(ids, true);
          getMeteringTotalPrices(versionId, dataLineApiService as unknown as IDataLineApiService, _this)
        }
      }, {
        label: i18n.tr("versionmetering.categoryComment"),
        icon: "digi-folder-add-line",
        disabled: () => {
          var selectedLines = priceOfferLinesGrid.gridOptions.api.getSelectedNodes();
          return MenuLineCategory.linesCanSwitchType([params.node, ...selectedLines], PriceOfferLineCategory.Comment);
        },
        handler: async () => {
          var selectedLines = priceOfferLinesGrid.gridOptions.api.getSelectedNodes();
          var ids = await MenuLineCategory.switchToComment([params.node, ...selectedLines], dataLineApiService, versionId);
          priceOfferLinesGrid.refreshVisibleNodes(ids, true);
          getMeteringTotalPrices(versionId, dataLineApiService as unknown as IDataLineApiService, _this)
        }
      }
    ];
  }
  static async switchToComment(nodes: RowNode<any>[], dataLineApiService: IMeterigSwitchType, versionId: string) {
    if (await this.confirmePost(nodes)) {
      return await dataLineApiService.switchToComment(parseInt(versionId), nodes.map(x => x.data.id));
    }

  }
  static async switchToChapter(nodes: RowNode<any>[], dataLineApiService: IMeterigSwitchType, versionId: string) {
    if (await this.confirmePost(nodes)) {
      return await dataLineApiService.switchToChapter(parseInt(versionId), nodes.map(x => x.data.id));
    }
  }
  static async switchToPost(nodes: RowNode<any>[], dataLineApiService: IMeterigSwitchType, versionId: string) {
    return await dataLineApiService.switchToPost(parseInt(versionId), nodes.map(x => x.data.id));
  }

  private static linesCanSwitchType(nodes: RowNode<any>[], newTypeId: number) {
    return nodes.some(x => MenuLineCategory.lineCanSwitchType(x, newTypeId))
  }
  private static lineCanSwitchType(node: RowNode<any>, newTypeId: number) {
    return node.parent.data?.priceOfferLineCategoryId == PriceOfferLineCategory.Data || node.data.hasChildren || node.data.isDetailedLine || node.data.isSubItem || node.data.priceOfferLineCategoryId == newTypeId
  }
  private static async confirmePost(nodes: RowNode<any>[]) {
    if (nodes.some(node => node.data.proposedQuantity != null || node.data.unitId != null || node.data.buyingUnitPrice)) {
      let box = Container.instance.get(Box);
      let i18n = Container.instance.get(I18N);
      let confirm = false;
      let buttonYes: ActionDialogBoxInputParameters =
      {
        label: i18n.tr("general.ok", { ns: "common" }),
        title: i18n.tr("general.ok", { ns: "common" }),
        theme: 'primary',
        type: 'solid',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.ok(true);
        },
        keyboardShortcut: KeyboardShortcut.Enter
      };
      let buttonNo: ActionDialogBoxInputParameters =
      {
        label: i18n.tr("general.cancel", { ns: "common" }),
        title: i18n.tr("general.cancel", { ns: "common" }),
        theme: 'dark',
        type: 'ghost',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.ok(false);
        },
        keyboardShortcut: KeyboardShortcut.Escape
      };
      await box.showWarning(i18n.tr("versionmetering.changePost"), i18n.tr("menu.confirmation"), [buttonNo, buttonYes])
        .whenClosed(async (result) => {
          if (!result.wasCancelled && result.output) {
            confirm = result.output;
          }
        });
      return confirm;
    }
    else {
      return true
    }
  }
}

export interface IMeterigSwitchType {
  switchToPost(versionId: number, lineIds: number[]): Promise<Array<number>>;
  switchToChapter(versionId: number, lineIds: number[]): Promise<Array<number>>;
  switchToComment(versionId: number, lineIds: number[]): Promise<Array<number>>;
}
