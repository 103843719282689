import { AgPromise, ICellRendererComp, ICellRendererParams } from "ag-grid-community";
import { autoinject, Container, createOverrideContext, View, ViewCompiler, ViewResources, ViewSlot } from "aurelia-framework";
import { EnumerationAllTypeList } from "enumeration-types/all-enumeration-type-list";
import { Router } from 'aurelia-router';
import { I18N } from "aurelia-i18n";

@autoinject
export default class CustomEnumGroupInnerRenderer implements ICellRendererComp {
  protected eGui: HTMLElement;

  protected view: View;
  protected viewSlot: ViewSlot;
  protected params: ICellRendererParams;
  public i18n: I18N;

  public init(params: ICellRendererParams,) {
    this.params = params;
    this.setupGui();
  }

  public setupGui() {
    this.eGui = document.createElement('div');
    this.eGui.style.width = '100%';
    this.eGui.style.height = '100%';
    this.eGui.innerHTML = this.params.valueFormatted;
    if (this.params.node.level == 1 && !EnumerationAllTypeList.IsCategoryCloseEnum(this.params.data.category)) {
      let html: string = this.setupContentGui();
      this.i18n = Container.instance.get(I18N);
      let viewCompiler = Container.instance.get(ViewCompiler);
      let viewResources = Container.instance.get(ViewResources);
      let viewFactory = viewCompiler.compile(html, viewResources);
      this.view = viewFactory.create(Container.instance);
      this.viewSlot = new ViewSlot(this.eGui, true);
      this.viewSlot.add(this.view);
      this.view.bind(this, createOverrideContext(this));
      this.viewSlot.attached();
    }
  }


  public getGui(): HTMLElement {
    return this.eGui;
  }

  public refresh(params): boolean {
    return true;
  }

  public destroy() {
    if (this.view) this.view.unbind();
    if (this.viewSlot) this.viewSlot.detached();
  }

  public setupContentGui(): string {
    let label = EnumerationAllTypeList.getAddEnumTraduction(this.params.data.category);
    return `<template>
        <ui-button class="all-enum-add-button" type="outline" icon="digi-plus" ui-theme="primary" click.delegate="temp(params.data.category)" label="${label}">
        </ui-button>
      </template>`
  }

  public temp(category: number) {
    (this.params as any).router.navigate((this.params as any).router.baseUrl + `enumeration-types/${EnumerationAllTypeList.getRouteName(category)}/${category}/-100`);
  }
}
