import { autoinject } from "aurelia-framework";
import * as Constants from '../constants';
import { ICellRendererParams, RowNode } from "ag-grid-community";
import { Box, GlobalLoaderService, IMenuGroup } from "digiwall-lib";
import { I18N } from "aurelia-i18n";
import { PriceOfferLinesGrid } from 'price-offer-lines/price-offer-lines-grid';
import { AddItems } from "projects/quotes/add-items/add-items";
import { SupplierOfferApiService } from "services/supplier-offer-api-service";
import { IDataLineApiService } from "services/i-data-line-api-service";


@autoinject
export class SupplierOfferMenuItem {
  constructor(private i18n: I18N, private globalLoaderService: GlobalLoaderService, private box: Box) {

  }

  public getSupplierOfferMenuItem(params: ICellRendererParams<any, any>, priceOfferLinesGrid: PriceOfferLinesGrid, notCanModifiedSupplierOffer: boolean, supplierOfferVersionApiService: SupplierOfferApiService, entityId: number, getMeteringTotalPrices: (dataLineApiService: IDataLineApiService, _this: any) => void, _this: any): IMenuGroup[] {
    return [
      {
        group: "0",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("versionmetering.detailPost"),
            icon: "digi-add-circle-line",
            disabled: () => {
              //return this.cannotCreateLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Into)
              let targetNode = priceOfferLinesGrid.gridOptions.api.getRowNode(params.node.id);
              return targetNode.data.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Data;
            },
            items: [
              {
                label: this.i18n.tr("versionmetering.detailPostAddItem"),
                disabled: () => {
                  return this.cannotCreateLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Into, priceOfferLinesGrid);
                },
                handler: async () => {
                  this.addItems(parseInt(params.node.id), Constants.PriceOfferLineMoveAction.Into, priceOfferLinesGrid, supplierOfferVersionApiService, entityId, getMeteringTotalPrices, _this);
                },
                icon: "digi-database-2-line"
              },
              {
                label: this.i18n.tr("versionmetering.detailPostAddPost"),
                handler: () => {
                  this.createLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Into, priceOfferLinesGrid, supplierOfferVersionApiService, entityId);
                },
                disabled: () => {
                  return this.cannotCreateLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Into, priceOfferLinesGrid);
                },
                icon: "digi-database-2-line"
              },
              {
                label: this.i18n.tr("versionmetering.detailPostAddComment"),
                handler: () => {
                  this.createLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Comment, Constants.PriceOfferLineMoveAction.Into, priceOfferLinesGrid, supplierOfferVersionApiService, entityId);
                },
                disabled: () => {
                  return this.cannotCreateLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Comment, Constants.PriceOfferLineMoveAction.Into, priceOfferLinesGrid);
                },
                icon: "digi-list-check"
              },
            ]
          },
          {
            label: this.i18n.tr("versionmetering.addPost"),
            icon: "digi-database-2-line",
            items: this.getCreateLineMenuItem(parseInt(params.node.data.id), Constants.PriceOfferLineCategory.Data, priceOfferLinesGrid, notCanModifiedSupplierOffer, supplierOfferVersionApiService, entityId),
            disabled: !params.node.data.isSubItem
          },
          {
            id: Constants.CommonMeterginDataLineMenuItems.ADD_ITEM,
            label: this.i18n.tr("versionmetering.addItems"),
            icon: "digi-database-2-line",
            items: [
              {
                id: Constants.CommonMeterginDataLineMenuItems.ADD_ITEM_UP,
                label: this.i18n.tr("versionmetering.onTopOfLine"),
                disabled: () => {
                  return notCanModifiedSupplierOffer || this.cannotCreateLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Up, priceOfferLinesGrid);
                },
                handler: async () => {
                  this.addItems(parseInt(params.node.id), Constants.PriceOfferLineMoveAction.Up, priceOfferLinesGrid, supplierOfferVersionApiService, entityId, getMeteringTotalPrices, _this);
                },
                icon: "digi-arrow-up-line"
              },
              {
                id: Constants.CommonMeterginDataLineMenuItems.ADD_ITEM_DOWN,
                label: this.i18n.tr("versionmetering.belowLine"),
                disabled: () => {
                  return notCanModifiedSupplierOffer || this.cannotCreateLine(parseInt(params.node.id), Constants.PriceOfferLineCategory.Data, Constants.PriceOfferLineMoveAction.Down, priceOfferLinesGrid);
                },
                handler: async () => {
                  this.addItems(parseInt(params.node.id), Constants.PriceOfferLineMoveAction.Down, priceOfferLinesGrid, supplierOfferVersionApiService, entityId, getMeteringTotalPrices, _this);
                },
                icon: "digi-arrow-down-line"
              },
            ],
            disabled: !params.node.data.isSubItem
          },
          {
            label: this.i18n.tr("versionmetering.addComment"),
            icon: "digi-chat-4-line",
            items: this.getCreateLineMenuItem(parseInt(params.node.id), Constants.PriceOfferLineCategory.Comment, priceOfferLinesGrid, notCanModifiedSupplierOffer, supplierOfferVersionApiService, entityId),
            disabled: !params.node.data.isSubItem
          }
        ]
      },
      {
        group: "4",
        hiddenLabel: true,
        items: [
          {
            id: Constants.CommonMeterginDataLineMenuItems.DELETE,
            label: this.i18n.tr("versionmetering.deleteLine"),
            icon: "digi-trash",
            disabled: () => {
              return notCanModifiedSupplierOffer || params.node.data.originalMeasurementXlsLineNumber != null;
            },
            handler: async () => {
              await this.deleteLine(params.node.data.id, priceOfferLinesGrid, entityId, supplierOfferVersionApiService, getMeteringTotalPrices, _this);
            }
          }
        ]
      }
    ];
  }
  private async duplicateLine(targetRowId: string, entityId: number, priceOfferLinesGrid: PriceOfferLinesGrid, supplierOfferVersionApiService: SupplierOfferApiService, getMeteringTotalPrices: () => void) {
    if (!(typeof supplierOfferVersionApiService.duplicateLine == "function")) {
      console.warn('API duplicateLine not implemented !');
      return;
    }

    let targetNode = priceOfferLinesGrid.gridOptions.api.getRowNode(targetRowId);
    if (!targetNode) {
      console.warn('Target node not found!');
      return;
    }

    targetNode.data.isSelected = false;

    this.globalLoaderService.allow();
    priceOfferLinesGrid.gridOptions.api.showLoadingOverlay();


    let ids = await supplierOfferVersionApiService.duplicateLine(entityId, parseInt(targetNode.data.id));
    priceOfferLinesGrid.gridOptions.api.hideOverlay();

    priceOfferLinesGrid.refreshServerSideRows(ids, targetNode.data.parentId == null);
    getMeteringTotalPrices();
  }

  private async deleteLine(id: number, priceOfferLinesGrid: PriceOfferLinesGrid, entityId: number, supplierOfferVersionApiService: SupplierOfferApiService, getMeteringTotalPrices: (dataLineApiService: IDataLineApiService, _this: any) => void, _this: any) {
    this.globalLoaderService.allow();
    priceOfferLinesGrid.gridOptions.api.showLoadingOverlay();

    let idsToDelete = this.getIdsToDelete(priceOfferLinesGrid, id);

    let ids = await supplierOfferVersionApiService.delete(entityId, idsToDelete);
    priceOfferLinesGrid.gridOptions.api.hideOverlay();

    let refreshAllTable = false;
    /*for (const id of ids) {
      let node = priceOfferLinesGrid.gridOptions.api.getRowNode(id.toString());
      if (node != null && node.data != null) {
        refreshAllTable = node.data.parentId == null
        if (refreshAllTable) {
          break;
        }
      }
    }*/

    priceOfferLinesGrid.refreshServerSideRows(ids, refreshAllTable);
    priceOfferLinesGrid.refreshVisibleNodes(ids);
    getMeteringTotalPrices(supplierOfferVersionApiService, _this);
  }

  private getIdsToDelete(priceOfferLinesGrid: PriceOfferLinesGrid, id: number) {
    let idsToDelete;
    let targetNode = priceOfferLinesGrid.gridOptions.api.getRowNode(id.toString());

    if (targetNode.data.supplierPrices == null) {
      idsToDelete = priceOfferLinesGrid.gridOptions.api.getSelectedNodes().map(x => x.data.id);
      if (idsToDelete.length == 0 || idsToDelete.findIndex(x => x == id) == -1)
        idsToDelete.push(id);
    }
    else {
      let supplierId = 0;
      targetNode.data.supplierPrices.forEach(supplier => {
        if (supplier.requestToSupplierId != 0 && supplierId == 0) {
          supplierId = supplier.requestToSupplierId;
        }
      });
      idsToDelete = priceOfferLinesGrid.gridOptions.api.getSelectedNodes().map(x => x.data.supplierPrices[supplierId].supplierOfferLineId);
      if (idsToDelete.length == 0 || idsToDelete.findIndex(x => x == id) == -1)
        idsToDelete.push(targetNode.data.supplierPrices[supplierId].supplierOfferLineId);
    }
    return idsToDelete;
  }

  private getCreateLineMenuItem(targetId: number, category: Constants.PriceOfferLineCategory, priceOfferLinesGrid: PriceOfferLinesGrid, notCanModifiedSupplierOffer: boolean, supplierOfferVersionApiService: SupplierOfferApiService, entityId: number) {
    return [
      {
        label: this.i18n.tr("versionmetering.onTopOfLine"),
        handler: () => {
          this.createLine(targetId, category, Constants.PriceOfferLineMoveAction.Up, priceOfferLinesGrid, supplierOfferVersionApiService, entityId);
        },
        disabled: () => {
          return notCanModifiedSupplierOffer || this.cannotCreateLine(targetId, category, Constants.PriceOfferLineMoveAction.Up, priceOfferLinesGrid);
        },
        icon: "digi-arrow-up-line"
      },
      {
        label: this.i18n.tr("versionmetering.childOfLine"),
        handler: () => {
          this.createLine(targetId, category, Constants.PriceOfferLineMoveAction.Into, priceOfferLinesGrid, supplierOfferVersionApiService, entityId);
        },
        disabled: () => {
          let targetNode = priceOfferLinesGrid.gridOptions.api.getRowNode(targetId.toString());
          return notCanModifiedSupplierOffer || targetNode.data.priceOfferLineCategoryId != Constants.PriceOfferLineCategory.Chapter
        },
        icon: "digi-arrow-right-line"
      },
      {
        label: this.i18n.tr("versionmetering.belowLine"),
        handler: () => {
          this.createLine(targetId, category, Constants.PriceOfferLineMoveAction.Down, priceOfferLinesGrid, supplierOfferVersionApiService, entityId);
        },
        disabled: () => {
          return notCanModifiedSupplierOffer || this.cannotCreateLine(targetId, category, Constants.PriceOfferLineMoveAction.Down, priceOfferLinesGrid);
        },
        icon: "digi-arrow-down-line"
      },

    ]
  }

  private async addItems(targetId: number, action: Constants.PriceOfferLineMoveAction, priceOfferLinesGrid: PriceOfferLinesGrid, supplierOfferVersionApiService: SupplierOfferApiService, entityId: number, getMeteringTotalPrices: (dataLineApiService: IDataLineApiService, _this: any) => void, _this: any) {
    let targetNode = priceOfferLinesGrid.gridOptions.api.getRowNode(targetId.toString());
    if (!targetNode) {
      console.warn('Target node not found!');
      return;
    }

    await this.box.showCustomDialog(AddItems, this.getDatalineId(targetNode), null, {
      model: {
        action: action,
        supplierOfferId: entityId,
        api: supplierOfferVersionApiService,
        parent: action == Constants.PriceOfferLineMoveAction.Into && targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data ? targetNode.data : null
      },
      size: "xl"
    }).whenClosed((result) => {
      if (result.output) {
        priceOfferLinesGrid.refreshServerSideRows(result.output, (targetNode.data.parentId == null && action != Constants.PriceOfferLineMoveAction.Into) /*|| targetNode.data.supplierPrices != null*/);
        if (action == Constants.PriceOfferLineMoveAction.Into) {
          targetNode.setExpanded(true);
        }
        getMeteringTotalPrices(supplierOfferVersionApiService, _this);
      }
    });
  }

  private cannotCreateLine(targetId: number, categoryId: Constants.PriceOfferLineCategory, moveType: Constants.PriceOfferLineMoveAction, priceOfferLinesGrid: PriceOfferLinesGrid) {

    let targetNode = priceOfferLinesGrid.gridOptions.api.getRowNode(targetId.toString());
    if (targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data) {
      if (moveType == Constants.PriceOfferLineMoveAction.Into &&

        (((!targetNode.data.isDetailedLine || !targetNode.data.isSubItem) && categoryId == Constants.PriceOfferLineCategory.Chapter) ||

          (targetNode.data.hasChildrenDetailed && categoryId != Constants.PriceOfferLineCategory.Comment) ||

          (targetNode.data.originalMeasurementXlsLineNumber == null && categoryId == Constants.PriceOfferLineCategory.Chapter))) {

        return true;
      }
      if (targetNode.data.isDetailedLine && categoryId != Constants.PriceOfferLineCategory.Comment) {
        return true;
      }
      if (targetNode.data.isSubItem && (categoryId == Constants.PriceOfferLineCategory.Chapter || (moveType == Constants.PriceOfferLineMoveAction.Into && categoryId != Constants.PriceOfferLineCategory.Comment))) {
        return true;
      }
    }
    if (targetNode.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Comment && categoryId == Constants.PriceOfferLineCategory.Chapter && targetNode.parent.data?.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data) {
      return true;
    }
    return false;
  }



  private async createLine(targetId: number, categoryId: number, action: Constants.PriceOfferLineMoveAction, priceOfferLinesGrid: PriceOfferLinesGrid, supplierOfferVersionApiService: SupplierOfferApiService, entityId: number) {
    let targetNode = priceOfferLinesGrid.gridOptions.api.getRowNode(targetId.toString());
    if (!targetNode) {
      console.warn('Target node not found!');
      return;
    }

    let rowIndex = targetNode.rowIndex;

    targetNode.data.isSelected = false;

    priceOfferLinesGrid.gridOptions.api.showLoadingOverlay();
    let ids = await supplierOfferVersionApiService.create(entityId, this.getDatalineId(targetNode), categoryId, action);

    priceOfferLinesGrid.gridOptions.api.hideOverlay();

    priceOfferLinesGrid.refreshServerSideRows(ids, (targetNode.data.parentId == null && action != Constants.PriceOfferLineMoveAction.Into) /*|| targetNode.data.supplierPrices != null*/);

    switch (action) {
      case Constants.PriceOfferLineMoveAction.Into:
        targetNode.setExpanded(true);
        setTimeout(() => {
          priceOfferLinesGrid.gridOptions.api.startEditingCell({ rowIndex: (targetNode.childStore as any).allRowNodes[(targetNode.childStore as any).allRowNodes.length - 1].rowIndex, colKey: "lineDescription" });
        }, 250)
        break;
      case Constants.PriceOfferLineMoveAction.Up:
        setTimeout(() => {
          let newLine = priceOfferLinesGrid.listTreeData.dataLines.filter(x => x.lineLevel == targetNode.data.lineLevel && x.parentId == targetNode.data.parentId && x.order == targetNode.data.order)[0];
          let newNode = priceOfferLinesGrid.gridOptions.api.getRowNode(newLine.id.toString());
          priceOfferLinesGrid.gridOptions.api.startEditingCell({ rowIndex: newNode.rowIndex, colKey: "lineDescription" });
        }, 750)
        break;
      case Constants.PriceOfferLineMoveAction.Down:
        setTimeout(() => {
          let newLine = priceOfferLinesGrid.listTreeData.dataLines.filter(x => x.lineLevel == targetNode.data.lineLevel && x.parentId == targetNode.data.parentId && x.order == targetNode.data.order + 1)[0];
          let newNode = priceOfferLinesGrid.gridOptions.api.getRowNode(newLine.id.toString());
          priceOfferLinesGrid.gridOptions.api.startEditingCell({ rowIndex: newNode.rowIndex, colKey: "lineDescription" });
        }, 750)
        break;
    }


  }

  private getDatalineId(node: RowNode<any>): number {
    return parseInt(node.data.workQuotationVersionLineId ?? node.data.buyingLineId ?? node.data.id)
  }
}
