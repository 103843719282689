import { DialogService } from 'aurelia-dialog';
import { RefToDisplay } from './../constants';
import { ExcelExportParams, ICellRendererComp, ICellRendererParams, ILoadingOverlayComp, ProcessCellForExportParams, ProcessHeaderForExportParams, RowGroupOpenedEvent } from 'ag-grid-community';
import { EditDialogAction } from './../resources/utilities/edit-dialog-actions';
import { I18N } from 'aurelia-i18n';
import { HighlightSearchTextValueConverter } from './../resources/value-converters/index';
import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { AgGridViewService, FieldType, IMenuGroup, ServiceBase, AgGridView, CustomAgGridOptions, EnumerationTypeService, EnumerationType, Config, CustomColDef, AuthService, Datacontext, Box, UIInternal, CustomLogger, GlobalLoaderService, ActionDialogBoxInputParameters, DialogBoxViewModel, IMenuItems, KeyboardShortcut } from "digiwall-lib";
import { Merlin } from "../generated";
import * as Constants from '../constants';
import { autoinject, bindable, Container, observable, TaskQueue } from 'aurelia-framework';
import { CellValueChangedEvent, ColDef, ColSpanParams, GridApi, GridOptions, RowNode } from 'ag-grid-community';
import MeteringPriceOfferLineReferenceRenderer from 'resources/renderer/metering-price-offer-line-reference-renderer';
import * as toastr from 'toastr';
import MeteringPriceOfferLineFileRenderer from 'resources/renderer/metering-price-offer-line-file-renderer';
import { EnumerationTypeDetail } from 'enumeration-types/enumeration-type-detail';
import { IDataLineApiService } from 'services/i-data-line-api-service';
import IGridCellCommentable from 'resources/elements/ag-cell-comment/grid-cell-commentable.interface';
import { PriceOfferLineCommentApiService } from 'services/price-offer-line-comment-api-service';
import MerlinHtmlHeaderRenderer from 'resources/renderer/merlin-html-header-renderer';
import { ColumnVisible, DATA_LOADED, ModuleListTreeData } from 'module-list-tree-data/module-list-tree-data';
import { ProposedQuantityEditor } from 'resources/renderer/proposed-quantity-editor';
import { MerlinApiService } from 'services/merlin-api-service';
import { AdditionalWorkTypeId } from '../constants';
import { MeteringApiService } from 'services/metering-api-service';
import { DebugTotalPricesError } from './debug-total-prices-error/debug-total-prices-error';
import { PriceOfferLineGridHelper } from './price-offer-lines-grid-helper';

export interface CanEditCellDto {
  priceOfferLineCategoryId: number;
  hasChildren?: boolean;
  hasChildrenSubItem?: boolean;
  hasChildrenDetailed: boolean;
  useInCompleteness: boolean;
  isSubItem: boolean;
  isDisplayHidden: boolean;
  isDisplayHiddenInherit: boolean;
  lineTypeId: number;
  parentIsMaster: boolean;
  isMaster: boolean;
  workQuotationVersionLineId: number | null;
}

@autoinject
export class PriceOfferLinesGrid implements IGridCellCommentable {

  public listTreeData: ModuleListTreeData;
  public gridOptions: GridOptions;
  public agGridViewList: Array<AgGridView>;
  public gridViewService: AgGridViewService;
  private priceOfferLines: Array<Merlin.Web.Model.DataLine>;
  private quantityTypeService: ServiceBase<Merlin.Web.Model.MarketType>;
  private quantityTypeList: Array<Merlin.Web.Model.MarketType>;
  private unitService: ServiceBase<Merlin.Web.Model.Unit>;
  private activityService: EnumerationTypeService;
  private deliveryService: EnumerationTypeService;
  private unitList: Array<Merlin.Web.Model.Unit>;
  private thirdPartyService: ServiceBase<Merlin.Web.Model.ThirdParty>;
  private tagsService: EnumerationTypeService;
  private featuresService: EnumerationTypeService;
  private itemDeliveryTypeList: Array<EnumerationType>;
  private nodesToRedraw: Array<number> = [];
  public nodesToRefresh: Array<number> = [];
  private cellsToRefresh: Array<{ node: RowNode, colId: string }> = [];
  private expandedNodes: Array<number> = [];
  private manuallyHandleFetchChildren = false;
  private searchLinesResult: Array<Merlin.Web.Model.DataLine>;

  private activityCodeList: Array<EnumerationType>;
  private buyingClientValidationStatusList: Array<EnumerationType>;
  private buildingCostsList: Array<EnumerationType>;
  private clientAdditionalWorkStatusList: Array<EnumerationType>;
  private priceOfferLineTypeList: Array<EnumerationType>;
  private priceOfferLineTagsList: Array<EnumerationType>;
  private dataLineFeaturesList: Array<EnumerationType>;
  private routeName: string;
  private highlightSearchTextValueConverter: HighlightSearchTextValueConverter;
  private flattenListParamsBackup: { colDef?: any, filterModel?: any, mustRestore: boolean } = { mustRestore: false };
  public versionHasLines: boolean
  public activityExpanded: { [id: number]: boolean } = {}

  @bindable
  public afterSetAgGridView = () => { };
  @bindable
  public onGridReady = () => { };
  @bindable
  public onDataLoaded = () => { };
  @bindable
  public canComment: (d: ColDef) => boolean;

  @bindable
  public canFlattenList = true;

  @bindable
  public title: string;

  @bindable
  public getHeaderMenuItems;

  @bindable
  public flattenList: boolean = false;

  @bindable
  public versionId: string;

  @bindable
  public workQuotationVersionId: string;

  @bindable
  public priceOfferVersionId: string;

  @bindable
  public projectId: string;
  @bindable
  public contractId: string;

  @bindable
  public getGridMenuItems: (params: ICellRendererParams) => Array<IMenuGroup>;

  @observable
  public quickFilter: string;

  @bindable
  public api: IDataLineApiService;

  @bindable
  private agGridViewModuleView: string;

  @bindable
  public triggerExpand = () => { };

  @bindable
  public getOverlayComponent: ILoadingOverlayComp = null;

  @bindable shouldExpand: boolean;

  @bindable
  public cellValueChanged: (colField: string) => {};

  @bindable
  public onRowGroupOpened: (event: RowGroupOpenedEvent<any>) => {};

  @bindable
  public disabledLineIsDetailed: boolean = false;

  @bindable
  public canEditCell: (cell: { line: { priceOfferLineCategoryId: number, hasChildren?: boolean, hasChildrenDetailed: boolean, useInCompleteness: boolean }, colField: string }) => boolean;

  @bindable
  public columnVisible: Array<ColumnVisible> = PriceOfferLineGridHelper.getBaseColumn();

  @bindable
  public allowCommentOnCell: boolean = true;
  @bindable
  public readonlyDocument: boolean = false;

  @bindable
  public showExpand: boolean = true;

  @bindable
  public canUseFilter: boolean = true;

  @bindable
  public noFetchChildren: boolean = false;

  @bindable
  private nbDecimalForPriceDisplay: number

  @bindable
  private nbDecimalForQuantityDisplay: number

  @bindable
  public showDisplayHidden: boolean = true;

  @bindable
  private condensed = true;

  @bindable
  public canNavigateBack: boolean = true;
  @bindable
  public showOriginalRefOption: boolean = false;
  @bindable
  public showSignedRefOption: boolean = false;

  @bindable
  public hideTotalChapter: boolean = false;

  @bindable
  public isSupplierOffer: boolean = false;

  @bindable
  public customSettingMenuItems: () => IMenuItems[];
  @bindable
  public bottomRowRenderer: ICellRendererComp = null
  @bindable
  public resetFooter: () => Promise<void>;
  @bindable
  public getExcelFileName: () => Promise<string>;
  @bindable
  public canExportExcel: boolean = true;

  private displayHiddenLine: boolean = false;
  private onColumnResizedTimer;
  private resizeAgCellTimer;
  private firstGridLoad = true;
  public displayIncluded: boolean = false;

  public noDataLabel = this.i18n.tr("grid.noRowsToShow");
  @bindable
  rowToOpen: number[];
  meteringFocusCell: MeteringFocusCell;

  MERLIN_REF = RefToDisplay.MerlinRef;
  ORIGINAL_EXCEL_REF = RefToDisplay.OriginalExcelRef;
  ORIGINAL_MERLIN_REF = RefToDisplay.OriginalMerlinRef;
  SIGNED_OFFER_REF = RefToDisplay.SignedOfferRef;

  @bindable
  public refToDisplay: RefToDisplay = RefToDisplay.MerlinRef

  private debugMode: boolean = false;

  @bindable
  public warningMode: boolean = false;

  constructor(
    public router: Router,
    public logger: CustomLogger,
    public httpClient: HttpClient,
    public i18n: I18N,
    public config: Config,
    public authService: AuthService,
    public datacontext: Datacontext,
    public box: Box,
    public taskQueue: TaskQueue,
    public element: Element,
    public priceOfferLineCommentApiService: PriceOfferLineCommentApiService,
    public merlinApiService: MerlinApiService,
    public meteringService: MeteringApiService,
    public dialogService: DialogService,
    public gls: GlobalLoaderService) {
    this.gridViewService = new AgGridViewService();
    this.highlightSearchTextValueConverter = new HighlightSearchTextValueConverter();
    this.quantityTypeService = new ServiceBase<Merlin.Web.Model.MarketType>(Constants.EntityTypeNames.MarketType);
    this.unitService = new ServiceBase<Merlin.Web.Model.Unit>(Constants.EntityTypeNames.Unit);
    this.thirdPartyService = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
    this.tagsService = new EnumerationTypeService(Constants.EnumerationTypes.PriceOfferLineTag);
    this.featuresService = new EnumerationTypeService(Constants.EnumerationTypes.ItemFeature);
    this.activityService = new EnumerationTypeService(Constants.EnumerationTypes.ActivityCode);
    this.deliveryService = new EnumerationTypeService(Constants.EnumerationTypes.ItemDeliveryType);
  }

  async bind() {
    let allEnum = await this.meteringService.getAllEnum();
    this.quantityTypeList = allEnum.quantityTypes;
    this.unitList = allEnum.units;
    this.itemDeliveryTypeList = allEnum.itemDeliveryType;
    this.activityCodeList = allEnum.activityCodes;
    this.priceOfferLineTypeList = allEnum.priceOfferLineTypes;
    this.priceOfferLineTagsList = allEnum.priceOfferLineTags;
    this.dataLineFeaturesList = allEnum.dataLineFeatures;
    this.buyingClientValidationStatusList = allEnum.buyingClientValidationStatus;
    this.buildingCostsList = allEnum.buildingCosts;
    this.clientAdditionalWorkStatusList = allEnum.clientAdditionalWorkStatus;
  }

  async attached() {
    if (this.canExportExcel) {
      if (this.getHeaderMenuItems == null) this.getHeaderMenuItems = [];

      this.getHeaderMenuItems.push(
        {
          group: "excel",
          hiddenLabel: true,
          items: [
            {
              label: this.i18n.tr("grid.excelExport"),
              icon: "digi-xls-file",
              handler: async () => {
                await this.exportToExcel();
              }
            }
          ]
        }
      );
    }


    if (typeof this.api?.versionHasLines == "function")
      this.versionHasLines = await this.api.versionHasLines(parseInt(this.versionId));

    const colsTypesDefs = CustomAgGridOptions.ColumnTypeDefinitions(this.i18n, this.config.globalConfig.defaultLocale);
    colsTypesDefs["Number"].cellEditor = 'numberCellEditor';
  }

  internalCustomSettingMenuItems() {
    let customSetting: IMenuItems[] = []
    if (this.customSettingMenuItems != null) {
      customSetting = this.customSettingMenuItems();
    }
    customSetting.push(
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("versionmetering.ref"),
            positionOfChild: 'tr',
            anchorPositionOfChild: 'tl',
            items: [
              {
                label: this.i18n.tr("versionmetering.merlinRef"),
                icon: "digi-functions",
                disabled: () => this.refToDisplay == this.MERLIN_REF,
                handler: () => {
                  this.refToDisplay = this.MERLIN_REF;
                }
              },
              {
                label: this.i18n.tr("versionmetering.originalExcelRef"),
                icon: "digi-edit",
                disabled: () => this.refToDisplay == this.ORIGINAL_EXCEL_REF,
                handler: () => {
                  this.refToDisplay = this.ORIGINAL_EXCEL_REF;
                }
              },
              {
                label: this.i18n.tr("versionmetering.originalMerlinRef"),
                icon: "digi-file-download-line",
                hidden: !this.showOriginalRefOption,
                disabled: () => this.refToDisplay == this.ORIGINAL_MERLIN_REF,
                handler: () => {
                  this.refToDisplay = this.ORIGINAL_MERLIN_REF
                }
              },
              {
                label: this.i18n.tr("versionmetering.signedMerlinRef"),
                icon: "digi-ball-pen-line",
                hidden: !this.showSignedRefOption,
                disabled: () => this.refToDisplay == this.SIGNED_OFFER_REF,
                handler: () => {
                  this.refToDisplay = this.SIGNED_OFFER_REF
                }
              }
            ]
          },
          {
            label: this.i18n.tr("metering.hideIncluded"),
            hidden: () => !this.displayIncluded,
            handler: () => {
              this.displayIncluded = false;
              this.listTreeData.refreshServerSideRows([], true);
            }
          },
          {
            label: this.i18n.tr("metering.displayIncluded"),
            hidden: () => this.displayIncluded,
            handler: () => {
              this.displayIncluded = true;
              this.listTreeData.refreshServerSideRows([], true);
            }
          }
        ]
      });
    return customSetting;
  }

  public async refreshVisibleNodes(ids: number[], flashCells = true): Promise<Array<RowNode>> {
    return await this.listTreeData.refreshVisibleNodes(ids, flashCells);
  }

  public refreshServerSideRows(ids: number[], refreshAllTable = false, forcePurge = false): Array<RowNode> {
    return this.listTreeData.refreshServerSideRows(ids, refreshAllTable, forcePurge);
  }

  public setDefaultColomnPinned() {
    let colDefs: ColDef[] = this.gridOptions.api.getColumnDefs();
    let columnPinned = colDefs.filter(x => x.field == "" || x.field == "isSelected" || x.field == "displayedRef" || x.field == "lineDescription" || x.field == "isVariantOrOptionSelected");
    columnPinned.forEach(x => {
      x.hide = false;
      x.pinned = 'left';
    });

    this.gridOptions.api.setColumnDefs(colDefs);
  }

  public async deactivate() {
    this.saveMeteringSetting();
    await this.listTreeData.deactivate();
  }

  public async internalOnGridReady() {
    if (typeof this.onGridReady == 'function') this.onGridReady();
    await this.loadMeteringSetting();
  }

  public async loadMeteringSetting() {
    let localStorage = window.localStorage.getItem('MeteringSetting');
    let meteringSetting = JSON.parse(localStorage) as Array<MeteringSetting>;
    let currentMeteringSetting = meteringSetting?.find(x => x.route.route == this.router.baseUrl && x.route.fragment == this.router.currentInstruction?.fragment
      && x.route.queryString == this.router.currentInstruction?.queryString) as MeteringSetting;

    if (currentMeteringSetting != null) {
      if (currentMeteringSetting.meteringGlobalSetting.screenExpand) {
        this.listTreeData.innerTriggerExpand();
      }
      if (currentMeteringSetting.meteringGlobalSetting.flattenList) {
        this.listTreeData.triggerFlattenList();
      }
      if (currentMeteringSetting.meteringGlobalSetting.showDisplayHidden) {
        this.listTreeData.changeDisplayHiddenLine();
      }
      if (!currentMeteringSetting.meteringGlobalSetting.condensed) {
        this.listTreeData.switchCondensed();
      }
      if (currentMeteringSetting.meteringGlobalSetting.refToDisplay != null) {
        this.listTreeData.refToDisplay = currentMeteringSetting.meteringGlobalSetting.refToDisplay;
      }
      this.rowToOpen = currentMeteringSetting.rowOpen.map(x => x.id);
      this.meteringFocusCell = currentMeteringSetting.focusCell;
    }
    await this.loadUserPreference();
  }

  private async loadUserPreference() {
    let userService = new ServiceBase<Merlin.Web.Model.MerlinUser>(Constants.EntityTypeNames.MerlinUser);
    let user = await userService.getEntityById(this.authService.currentUser.id);
    this.refToDisplay = user.favoriteRefToDisplay.toString() == "MerlinRef" || user.favoriteRefToDisplay.toString() == "0" ? Constants.RefToDisplay.MerlinRef : Constants.RefToDisplay.OriginalExcelRef;
  }

  public saveSettingAndReload() {
    this.saveMeteringSetting();
    this.gridOptions?.api?.refreshServerSide({ purge: true });
    this.loadMeteringSetting();
    setTimeout(() => {
      this.listTreeData?.computeRowToOpen(this.rowToOpen);
    }, 200)
  }

  public saveMeteringSetting() {
    if (this.gridOptions == null || this.gridOptions.api == null) return;
    let focusCell = this.gridOptions.api.getFocusedCell();
    let localStorage = window.localStorage.getItem('MeteringSetting');
    let meteringSetting = JSON.parse(localStorage) as Array<MeteringSetting>;
    if (meteringSetting == null) {
      meteringSetting = new Array();
    }

    let oldMeteringSetting = meteringSetting.findIndex(x => x.route.route == this.router.baseUrl && x.route.fragment == this.router.currentInstruction?.fragment
      && x.route.queryString == this.router.currentInstruction?.queryString);

    let currentMeteringSetting = {
      route: {
        route: this.router.baseUrl,
        fragment: this.router.currentInstruction?.fragment,
        queryString: this.router.currentInstruction?.queryString
      },
      focusCell: {
        rowId: focusCell != null ? this.gridOptions.api.getDisplayedRowAtIndex(focusCell?.rowIndex)?.data?.id : null,
        colId: focusCell?.column.getColId()
      },
      meteringGlobalSetting: {
        flattenList: this.flattenList,
        screenExpand: this.listTreeData.screenExpand,
        showDisplayHidden: this.listTreeData.displayHiddenLine,
        condensed: this.listTreeData.condensed,
        refToDisplay: this.listTreeData.refToDisplay
      },
      rowOpen: []
    } as MeteringSetting;
    this.gridOptions.api.forEachNode(node => {
      if (node.expanded) {
        currentMeteringSetting.rowOpen.push({ id: node.data.id, level: node.data.lineLevel });
      }
    });

    if (oldMeteringSetting != null && oldMeteringSetting != -1) {
      meteringSetting[oldMeteringSetting] = currentMeteringSetting;
    } else {
      meteringSetting.push(currentMeteringSetting);
    }

    window.localStorage.setItem('MeteringSetting', JSON.stringify(meteringSetting));
  }

  async setDataLineFeaturesList() {
    this.dataLineFeaturesList = await (new EnumerationTypeService(Constants.EnumerationTypes.ItemFeature)).getAll();
  }
  async setBuyingClientValidationStatusList() {
    this.buyingClientValidationStatusList = await (new EnumerationTypeService(Constants.EnumerationTypes.BuyingClientValidationStatus)).getAll();
  }

  async setClientAdditionalWorkStatusList() {
    this.clientAdditionalWorkStatusList = await (new EnumerationTypeService(Constants.EnumerationTypes.ClientAdditionalWorkStatusType)).getAll();
  }

  public callResizeAgCellLastLeftPinned() {
    this.listTreeData?.resizeAgCellLastLeftPinned()
  }

  private selectedCheckbox = async (node: RowNode<any>) => {
    try {
      let result: number[];
      const gridApi: GridApi = this.gridOptions.api;
      const filterModel = gridApi.getFilterModel();
      if (node.data.isSelected === true) {
        result = await this.api.select(node.data.id, filterModel);
      }
      else {
        result = await this.api.unselect(node.data.id, filterModel);
      }
      // Manage api result: all modified entities
      if (result != null && result.length) {
        this.refreshVisibleNodes(result); // result is array of modified ids
        if (this.cellValueChanged != null) this.cellValueChanged("isSelected");
      }
    }
    catch (error) {
      console.log(error);
      node.data.isSelected = !node.data.isSelected;
      toastr.error("Error during saving data");
    }
  }

  private internalOnRowGroupOpened(event: RowGroupOpenedEvent<any>) {
    if (this.onRowGroupOpened != null) this.onRowGroupOpened(event);
    this.saveMeteringSetting();
  }

  private flattenListChanged(oldVal, newVal) {
    this.saveMeteringSetting();
  }

  private showDisplayHiddenChanged(oldVal, newVal) {
    this.saveMeteringSetting();
  }

  private condensedChanged(oldVal, newVal) {
    this.saveMeteringSetting();
  }

  afterInitGridOption(gridOptions: GridOptions) {
    gridOptions.getRowId = (params) => params.data.uniqueId ?? params.data.id;
    gridOptions.components = Object.assign({},
      gridOptions.components,
      {
        'meteringPriceOfferLineReferenceRenderer': MeteringPriceOfferLineReferenceRenderer,
        'meteringPriceOfferLineFileRenderer': MeteringPriceOfferLineFileRenderer,
        "merlinHtmlHeaderRenderer": MerlinHtmlHeaderRenderer,
        "proposedQuantityEditor": ProposedQuantityEditor
      }
    );
    gridOptions.rowClassRules = {
      'ag-row-disabled': (params) => {
        return (((params.data?.lineTypeId == Constants.PriceOfferLineType.Option ||
          params.data?.lineTypeId == Constants.PriceOfferLineType.Variant) &&
          !params.data?.isVariantOrOptionSelected) || params.data?.isVariantOrOptionSelectedInherit == false || params.data?.notSelectable == true)
          || (params.data?.additionalWorkTypeId != null && params.data?.additionalWorkTypeId == AdditionalWorkTypeId.AdditionalWorkRejected)
          || params.data?.disabledLine
      },
      'ag-row-category-chapter': (params) => {
        return params.data?.lineLevel == 1 &&
          params.data?.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter
      },
      'ag-row-chapter-not-level-1': (params) => {
        return params.data?.lineLevel != 1 &&
          params.data?.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter
      },
      'ag-row-chapter': (params) => {
        return params.data?.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter
      },
      'ag-row-post-detail': (params) => {
        return params.data?.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data &&
          (params.data?.isDetailedLine || params.data?.isSubItem)
      },
      'ag-row-post': (params) => {
        return params.data?.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data &&
          !params.data?.isDetailedLine && !params.data?.isSubItem
      },
      'ag-row-error-light': (params) => {
        if (this.debugMode) {
          return ((params.data?.supplierOfferLineId != null &&
            ((params.data?.previousTotalBuyingPrice != null && params.data?.supplierTotal != null && params.data?.previousTotalBuyingPrice != params.data?.supplierTotal) ||
              (params.data?.previousDefinedSellingUnitPrice != null && params.data?.supplierPrice != null && params.data?.previousDefinedSellingUnitPrice != params.data?.supplierPrice)))
            ||
            (params.data?.supplierOfferLineId == null &&
              ((params.data?.previousTotalBuyingPrice != null && params.data?.totalBuyingPrice != null && params.data?.previousTotalBuyingPrice != params.data?.totalBuyingPrice) ||
                (params.data?.previousDefinedSellingUnitPrice != null && params.data?.definedSellingUnitPrice != null && params.data?.previousDefinedSellingUnitPrice != params.data?.definedSellingUnitPrice) ||
                (params.data?.previousTotalSellingPrice != null && params.data?.totalSellingPrice != null && params.data?.previousTotalSellingPrice != params.data?.totalSellingPrice))));
        }
        else if (this.warningMode) {
          return params.data?.importLineErrorType == Constants.ImportLineErrorType.ChildrenHasError;
        }
      },
      'ag-row-error': (params) => {
        return this.warningMode && params.data?.importLineErrorType == Constants.ImportLineErrorType.BrokenOrder;
      },
      'ag-row-warning-light': (params) => {
        return this.warningMode && params.data?.importLineErrorType == Constants.ImportLineErrorType.ChildrenHasWarning;
      },
      'ag-row-warning': (params) => {
        return this.warningMode &&
          (params.data?.importLineErrorType == Constants.ImportLineErrorType.EmptyChapter ||
            params.data?.importLineErrorType == Constants.ImportLineErrorType.DetailledWithDifferentMarketType ||
            params.data?.importLineErrorType == Constants.ImportLineErrorType.DetailledWithFM ||
            params.data?.importLineErrorType == Constants.ImportLineErrorType.AutoChapter ||
            params.data?.importLineErrorType == Constants.ImportLineErrorType.DetailledWithDifferentUnitType);
      }
    };
    gridOptions.noRowsOverlayComponent = this.getOverlayComponent;

    gridOptions.noRowsOverlayComponentParams = {
      priceOfferLinesGrid: this,
      noDataLabel: this.noDataLabel,
      meteringApi: this.api
    };

    gridOptions.onCellFocused = () => this.saveMeteringSetting();

    gridOptions.excelStyles = [
      {
        id: 'currencyFormat',
        numberFormat: {
          format: '#,##0.00 €',
        },
      },
      {
        id: 'booleanType',
        dataType: 'Boolean',
      },
      {
        id: 'stringType',
        dataType: 'String',
      },
      {
        id: 'dateType',
        dataType: 'DateTime',
        numberFormat: {
          format: 'dd/mm/yy'
        }
      },
    ];
  }

  private getColSpanOfColDef(params: ColSpanParams) {
    if (params.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Comment) {
      if (params.column.isPinned()) {
        return 1;
      }
      return params.columnApi.getColumns().filter(x => !x.isPinned()).length;
    }
    return 1;
  }
  timeoutId: NodeJS.Timeout | null = null;
  events: {
    lineId: number,
    propertyName: string,
    propertyValue: any
  }[] = [];
  async onCellValueChanged(event: CellValueChangedEvent) {
    if (event?.colDef == null) return;
    // Patch model
    let colField: string;
    let value: any;

    if (colField == null)
      colField = event.colDef.field;
    if (colField == "proposedQuantity")
      colField = "proposedQuantityFormula"
    if (colField == "unitQuantityDetailItemComposed")
      colField = "unitQuantityFormulaDetailItemComposed"
    if (value == null)
      value = event.data[colField];

    if ((colField == "proposedQuantityFormula" || colField == "supplierQuantity" || colField == "unitQuantityFormulaDetailItemComposed") && event.data.hasChildrenSubItem /*&& await this.questionUpdateQuantityChildren()*/) {
      value += "child"
    }
    if (colField == "quantityTypeId" && value == Constants.MarketType.PM && !(await this.questionUpdateQuantityTypeId())) {
      this.refreshVisibleNodes([event.data.id], false);
      return;
    }
    if (colField == "requestedQuantity" && !(await this.questionUpdateRequestedQuantity())) {
      this.refreshVisibleNodes([event.data.id], false);
      return;
    }
    this.events.push({ lineId: parseInt(event.data.id), propertyName: colField, propertyValue: value });

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(async () => {
      // Call api
      if (this.events.length == 0) return;

      let patchEvents = this.events

      this.events = [];
      this.timeoutId = null;

      let result = patchEvents.length == 1 ?
        await this.api.patch(parseInt(this.versionId), patchEvents[0].lineId, patchEvents[0].propertyName, patchEvents[0].propertyValue) :
        await this.api.bulkPatch(parseInt(this.versionId), patchEvents);
      // Manage api result: all modified entities
      if (result != null && result.length) {
        this.refreshVisibleNodes(result);
        if (this.cellValueChanged != null) this.cellValueChanged(colField);
      } else {
        toastr.error(this.i18n.tr("metering.errorSave"));
      }
    }, 600);
  }

  // async questionUpdateQuantityChildren() {
  //   return await this.questionBeforeUpdate(this.i18n.tr('metering.updateChildrenQuantityQuestion'), this.i18n.tr("general.yes", { ns: "common" }), this.i18n.tr("general.no", { ns: "common" }))
  // }

  async questionUpdateQuantityTypeId() {
    return await this.questionBeforeUpdate(this.i18n.tr('metering.changeToPMWarning'), this.i18n.tr("general.yes", { ns: "common" }), this.i18n.tr("general.no", { ns: "common" }))
  }

  async questionUpdateRequestedQuantity() {
    return await this.questionBeforeUpdate(this.i18n.tr('metering.changeRequestedQuantityWarning'), this.i18n.tr("general.yes", { ns: "common" }), this.i18n.tr("general.no", { ns: "common" }))
  }

  private async questionBeforeUpdate(question: string, confirmLabel: string, refuseLabel: string): Promise<boolean> {
    let result: boolean = false;
    let buttonYes: ActionDialogBoxInputParameters =
    {
      label: confirmLabel,
      title: confirmLabel,
      theme: 'primary',
      type: 'solid',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(true);
      }
    };
    let buttonNo: ActionDialogBoxInputParameters =
    {
      label: refuseLabel,
      title: refuseLabel,
      theme: 'dark',
      type: 'ghost',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(false);
      }
    };
    await this.box.showQuestion(question, this.i18n.tr('menu.question'), [buttonNo, buttonYes]).whenClosed(
      async (resultQuestion) => {
        result = resultQuestion.output
      }
    )
    return result;
  }

  async patchDatePicker(date, id, colField) {
    let nodes = await this.api.patch(parseInt(this.versionId), parseInt(id), colField, date);
    this.refreshVisibleNodes(nodes);
  }

  getGroupKey(data) {
    return data.merlinRefContext;
  }

  private async recomputeLines() {
    let previousTotalPrices = await this.api.getMeteringTotalPrices(parseInt(this.versionId));
    this.gls.allow("", 0);
    let newTotalPrices = await this.api.recomputeLines(parseInt(this.versionId));
    await this.dialogService.open({
      viewModel: DebugTotalPricesError,
      model: {
        previousTotalPrices: previousTotalPrices,
        newTotalPrices: newTotalPrices,
        nbDecimalForPriceDisplay: this.nbDecimalForPriceDisplay
      },
      lock: true,
      keyboard: false,
      rejectOnCancel: true,
      position(dialogContainer, dialogOverlay?) {
        dialogContainer.classList.add("dialog-container-debug-total-price");
      },
    });
    this.refreshServerSideRows(null, true, true);

    if (this.resetFooter != null) await this.resetFooter();
  }

  public internCanEditCell(cell: { line: { quantityTypeId: number, priceOfferLineCategoryId: number, hasChildren?: boolean, hasChildrenDetailed: boolean, useInCompleteness: boolean }, colField: string }): boolean {
    if (cell.line.quantityTypeId == Constants.MarketType.PM &&
      (cell.colField == "buyingUnitPrice" || cell.colField == "unitId" || cell.colField == "overheadMarginCoefficient"
        || cell.colField == "benefitMarginCoefficient" || cell.colField == "riskMarginCoefficient" || cell.colField == "definedDirectMarginPrice" || cell.colField == "totalSellingPrice" || cell.colField == "definedSellingUnitPrice" || cell.colField == "unitQuantityFormulaDetailItemComposed" || cell.colField == "proposedQuantity")) {
      return false;
    }
    return this.canEditCell({ line: cell.line, colField: cell.colField });
  }
  public async exportToExcel() {
    let buttonOk: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("grid.export", { ns: "common" }),
      title: this.i18n.tr("grid.export", { ns: "common" }),
      theme: 'primary',
      type: 'solid',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok();
      },
      keyboardShortcut: this.config.globalConfig.keyboardShortcutFwBox ? KeyboardShortcut.Enter : null
    };
    let buttonCancel: ActionDialogBoxInputParameters =
    {
      label: this.i18n.tr("general.cancel", { ns: "common" }),
      title: this.i18n.tr("general.cancel", { ns: "common" }),
      theme: 'dark',
      type: 'ghost',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.cancel();
      },
      keyboardShortcut: this.config.globalConfig.keyboardShortcutFwBox ? [KeyboardShortcut.Escape, KeyboardShortcut.Backspace] : null
    };

    await this.box.showQuestion(this.i18n.tr("general.xlsExportConfirmation"), this.i18n.tr("general.xlsExport"), [buttonCancel, buttonOk]).whenClosed(async (result) => {
      if (!result.wasCancelled) {
        if (!this.flattenList) {
          this.listTreeData.triggerFlattenList();
          UIInternal.subscribeOnce(DATA_LOADED, () => {
            this.taskQueue.queueTask(async () => {
              this.gridOptions.api.exportDataAsExcel(await this.getExcelExportParams());
            })
          });
        }
        else {
          this.gridOptions.api.exportDataAsExcel(await this.getExcelExportParams());
        }
      }
    });
  }
  public async getExcelExportParams(): Promise<ExcelExportParams> {
    return {
      //columnSeparator: ';',
      fileName: this.getExcelFileName != null && this.getExcelFileName instanceof Function ? (await this.getExcelFileName()).replace(/ /g, "_") + ".xlsx" : this.i18n.tr(this.router.currentInstruction.config.title).replace('.', ''),
      processCellCallback: (params: ProcessCellForExportParams) => {
        let colDef = params.column.getColDef();
        if (colDef.cellRendererParams?.isComparisonColumn) {
          if (colDef.headerName == this.i18n.tr('offercomparison.unitPrice')) {
            return params.node?.data?.supplierPrices[colDef.field]?.unitPrice ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(params.node?.data?.supplierPrices[colDef.field]?.unitPrice) : ''
          }
          return params.node?.data?.supplierPrices[colDef.field]?.totalPrice ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(params.node?.data?.supplierPrices[colDef.field]?.unitPrice) : ''
        }
        switch (colDef.field) {
          case "isSelected":
            if (params.value === true) {
              return this.i18n.tr("general.yes", { ns: "common" });
            }
            else {
              return this.i18n.tr("general.no", { ns: "common" });
            }
          case "dataLineActivities":
            return this.computeEnumsIntoStringForExcel(this.activityCodeList ?? [], params.node.data.dataLineActivities ?? []);
          case "dataLineDeliveries":
            return this.computeEnumsIntoStringForExcel(this.itemDeliveryTypeList ?? [], params.node.data.dataLineDeliveries ?? []);
          case "tags":
            return this.computeEnumsIntoStringForExcel(this.priceOfferLineTagsList ?? [], params.node.data.tags ?? []);
          case "proposedQuantity":
            return params.node?.data?.proposedQuantity ?? null
          case "unitQuantityDetailItemComposed":
            return params.node?.data?.unitQuantityDetailItemComposed ?? null
        }
        return params.value;
      },
      processHeaderCallback: (params: ProcessHeaderForExportParams) => {
        let colDef = params.column.getColDef();
        if (colDef.field == "isSelected") {
          return this.i18n.tr("grid.isSelected", { ns: "common" });
        }
        return colDef.headerName;
      }
    };
  }
  private computeEnumsIntoStringForExcel(enumArray: EnumerationType[], data) {
    let enumsSelected = enumArray.filter(x => data.find(y => y == x.id) > -1);
    let result = "";
    for (let index = 0; index < enumsSelected.length; index++) {
      const ac = enumsSelected[index];
      result += (ac.denomination as any).auto
      if (enumsSelected.length > index + 1) {
        result += ", "
      }
    }
    return result;
  }

  //#region  column
  public getDataGridColumns(): ColDef[] {
    let defs: CustomColDef[] = [
      {
        colId: "selected",
        headerValueGetter: () => { return '' },
        field: "isSelected",
        width: Constants.AGGridColumnsWidth.IsSelected,
        maxWidth: Constants.AGGridColumnsWidth.IsSelected,
        suppressMovable: true,
        suppressSizeToFit: true,
        suppressAutoSize: true,
        resizable: false,
        cellRenderer: "customHtmlRendererEditor",
        cellClass: (params) => {
          let returnClass = ['ag-cell-center', 'ag-selection'];

          switch (params.data.lineTypeId) {
            case Constants.PriceOfferLineType.Option:
              returnClass.push('metering-line-type-option');
              break;
            case Constants.PriceOfferLineType.Variant:
              returnClass.push('metering-line-type-variant');
              break;
            case Constants.PriceOfferLineType.Omission:
              returnClass.push('metering-line-type-omission');
              break;
            case Constants.PriceOfferLineType.Reduction:
              returnClass.push('metering-line-type-reduction');
              break;
            default:
              returnClass.push('metering-line-type-normal');
              break;
          }

          // For metering line color, set a style property for the last child line.
          if (!this.flattenList) {
            setTimeout(() => {
              if (params.node.alreadyRendered && params.node.lastChild) {
                let rowElementHtml = this.element.querySelector(`[row-id="${params.node.id}"]`);
                if (rowElementHtml != null) {
                  let cellSelected = rowElementHtml.querySelector(`[col-id="${params.colDef.colId}"]`)
                  if (cellSelected != null) {
                    let spanLine = cellSelected.querySelector(`[class="metering-line-color"]`) as HTMLElement;
                    if (spanLine != null && params.node.parent != null) {
                      let parentTop = params.node.rowTop - params.node.parent.rowTop - params.node.parent.rowHeight;
                      spanLine.style.setProperty("--parentTop", parentTop + "px");
                    }
                  }
                }
              }
            }, 100);
          }

          return returnClass;
        },
        cellRendererParams: {
          canComment: false,
          getHtml: (currentThis) => {
            currentThis?.params?.node?.setSelected(currentThis?.params?.data?.isSelected);
            // currentThis?.params?.node?.removeEventListener('rowSelected', this.selectedCheckbox);
            // currentThis?.params?.node?.addEventListener('rowSelected', this.selectedCheckbox);
            if (currentThis?.params?.node?.rowPinned == 'bottom') {
              return `<span>${currentThis?.params?.data.lineDescription}</span>`;
            }
            let result = `<span class="metering-line-color"></span><ui-checkbox `;
            // if (this.disabledLineIsDetailed) {
            //   result += `disabled.bind="params.data.isDetailedLine || params.data.isSubItem" `;
            // }
            result += `style="margin: auto" class="cell-selected" checked.bind="params.data.isSelected" change.delegate='params.change(params, $event)' disabled.bind="params.data.notSelectable"></ui-checkbox>`;
            return result
          },
          change: async (params, event) => {
            await this.listTreeData.shiftSelect(params, this.selectedCheckbox);
            (<RowNode>params.node).setSelected(event.detail.checked);
            await this.selectedCheckbox(params);
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        colSpan: (params: ColSpanParams) => {
          if (params?.node?.rowPinned == 'bottom') {
            return 3;
          }
          return 1;
        }
      },
      {
        colId: "merlinRef",
        headerValueGetter: () => this.i18n.tr("metering.ref"),
        field: "displayedRef",
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          canComment: false,
          suppressCount: true,
          suppressDoubleClickExpand: true
        },
        cellClass: (params) => {
          return ['stringType']
        },
        pinned: 'left',
        suppressMovable: true,
        lockPosition: 'left',
        hideInParameter: true
      },
      {
        colId: "lineDescription",
        headerValueGetter: () => this.i18n.tr("metering.post"),
        field: "lineDescription",
        type: FieldType.String,
        filterParams: {
          showEmptyFieldsCheckbox: false
        },
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        suppressMenu: !this.canUseFilter,
        pinned: "left",
        lockPosition: 'left',
        hideInParameter: true,
        suppressMovable: true,
        cellEditor: "agTextCellEditor",
        cellEditorPopup: false,
        cellRenderer: "customHtmlRendererEditor",
        cellClass: ["metering-cell-description", "ag-cell-column-resize-pinned-left"],
        cellRendererParams: {
          getHtml: (currentThis) => {
            const lineDesc = this.highlightSearchTextValueConverter.toView(currentThis.params.data.lineDescription, this.quickFilter);
            if (currentThis.params.data.id == 0) {
              return `
              <div class="metering-line-description-footer">
                <div class="metering-line-type-description-overflow">${lineDesc}</div>
              </div>
            `;
            }
            if (currentThis.params.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Comment) {
              return `
              <div class="metering-line-description metering-description-before-toggle">
                <ui-icon icon="digi-information-line"></ui-icon>
                <div class="metering-line-type-description-overflow">${lineDesc}</div>
              </div>
            `;
            }
            let result = `<div class="metering-line-type-description">`;

            if (currentThis.params.data.isDisplayHidden) {
              let tooltip = this.i18n.tr("metering.hiddenLine")
              result += `<span ui-tooltip="value:${tooltip}"><ui-icon icon="digi-eye-off-line"></ui-icon></span>`;
            }

            if (currentThis.params.data.lineTypeId == Constants.PriceOfferLineType.FixedPrice) {
              let tooltip = this.i18n.tr("metering.isFixedPrice")
              result += `<span ui-tooltip="value:${tooltip}"><ui-icon icon="digi-lock"></ui-icon></span>`;
            }

            if (currentThis.params.data.itemId > 0) {
              result += `<ag-cell-item-tooltip item-id.bind="${currentThis.params.data.itemId}" quantity.bind="${currentThis.params.data.proposedQuantity}" is-validated.bind="${currentThis.params.data.itemIsValidated}"></ag-cell-item-tooltip>`
            }

            if (currentThis.params.data.hasPriceRepartition && currentThis.params.data.hasErrorPriceRepartition) {
              let tooltip = this.i18n.tr("metering.hasPriceRepartitionWithError");
              result += `
                <span  ui-tooltip="value:${tooltip}" class="has-price-repartition-error">
                  <ui-icon icon="digi-price-repartition-error"></ui-icon>
                </span>`;
            }

            if (this.warningMode && currentThis.params.data.importLineErrorType >= 100) {
              let tooltip = "";

              switch (currentThis.params.data.importLineErrorType) {
                case Constants.ImportLineErrorType.BrokenOrder:
                  tooltip = this.i18n.tr("workquotationversion.errorBrokenOrder")
                  break;
                case Constants.ImportLineErrorType.EmptyChapter:
                  tooltip = this.i18n.tr("workquotationversion.warningEmptyChapter")
                  break;
                case Constants.ImportLineErrorType.DetailledWithDifferentMarketType:
                  tooltip = this.i18n.tr("workquotationversion.warningDetailledWithDifferentMarketType")
                  break;
                case Constants.ImportLineErrorType.DetailledWithFM:
                  tooltip = this.i18n.tr("workquotationversion.warningDetailledWithFM")
                  break;
                case Constants.ImportLineErrorType.AutoChapter:
                  tooltip = this.i18n.tr("workquotationversion.warningAutoChapter")
                  break;
                case Constants.ImportLineErrorType.DetailledWithDifferentUnitType:
                  tooltip = this.i18n.tr("workquotationversion.warningDetailledWithDifferentUnitType")
                  break;
              }
              result += `<span ui-tooltip="value:${tooltip}"><ui-icon icon="digi-spam-line"></ui-icon></span>`;
            }

            result += `<div class="metering-description-before-toggle">` +
              `<div class="${currentThis.params.data.lineTypeId != Constants.PriceOfferLineType.Normal ? 'merlin-line-type-description-padding' : ''} metering-line-type-description-overflow" title="${lineDesc}">${lineDesc}</div>`;

            if (currentThis.params.data.lineTypeId != 0 && currentThis.params.data.lineTypeId != Constants.PriceOfferLineType.Normal) {
              let type = this.priceOfferLineTypeList.find(x => x.id == currentThis.params.data.lineTypeId);
              result += `<div style='background-color:${type.backgroundColor}; color:${type.textColor}; padding: 5px 15px;border-radius: 20px; line-height: 15px;'>${(type.id == Constants.PriceOfferLineType.FixedPrice ? "FIX" : (type.denomination as any).auto.substring(0, 3).toUpperCase()) + (type.id == Constants.PriceOfferLineType.Variant ? ' - ' + currentThis.params.data.variantGroupNum : '')}</div>`
            }
            if (currentThis.params.data.isDetailedLine || currentThis.params.data.isSubItem) {
              result += `<div class="detail-tag">${this.i18n.tr("metering.detailAbbreviated")}</div>`
            }
            if (currentThis.params.data.hasPriceRepartition && !currentThis.params.data.hasErrorPriceRepartition) {
              result += `<div class="repartition-tag">${this.i18n.tr("metering.repartitionAbbreviated")}</div>`
            }

            if (currentThis.params.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data && currentThis.params.data.additionalWorkTypeId != null) {
              switch (currentThis.params.data.additionalWorkTypeId) {
                case AdditionalWorkTypeId.AdditionalWorkToBeValidated:
                case AdditionalWorkTypeId.AdditionalWorkValidated:
                case AdditionalWorkTypeId.AdditionalWorkRejected:
                  result += `<div class="addition-work">${this.i18n.tr("metering.additionalWork")}</div>`;
                  break;
                case AdditionalWorkTypeId.Extra:
                  result += `<div class="extra">${this.i18n.tr("metering.extraAbbreviated")}</div>`;
                  break;
              }
            }

            if (currentThis.params.data.isAmendment) {
              result += `<div class="addition-work">${this.i18n.tr("metering.amendment")}</div>`;
            }

            if (currentThis.params.data.toReport) {
              result += `<div class="addition-work">${this.i18n.tr("metering.toReport")}</div>`;
            }

            if (currentThis.params.data.isAdditionalWorkLine) {
              result += `<div class="addition-work">${this.i18n.tr("metering.additionalWork")}</div>`;
            }

            result += `</div>`;
            if (currentThis.params.data.lineTypeId == Constants.PriceOfferLineType.Option ||
              currentThis.params.data.lineTypeId == Constants.PriceOfferLineType.Variant) {
              let toggleEditable = this.internCanEditCell({ line: currentThis.params.data, colField: currentThis.params.colDef.field });
              result += `<div class="metering-description-toggle"><ui-toggle disabled.bind="params.data.isVariantOrOptionSelectedInherit == false || ${toggleEditable} == false" checked.bind="params.data.isVariantOrOptionSelected" change.delegate="params.clickToggle(params, $event)"></ui-toggle></div>`;
            }
            result += `</div>`;
            return result;
          },
          clickToggle: async (params, event: CustomEvent) => {
            params.data.isVariantOrOptionSelected = !params.data.isVariantOrOptionSelected;
            this.gridOptions.api.showLoadingOverlay();
            let colField = "isVariantOrOptionSelected";
            let result = await this.api.patch(parseInt(this.versionId), parseInt(params.data.id), colField, params.data.isVariantOrOptionSelected);
            let nodes = await this.refreshVisibleNodes(result);
            params.node?.childStore?.forEachNodeDeep(node => nodes.push(node));
            this.gridOptions.api.redrawRows({ rowNodes: nodes });
            this.gridOptions.api.hideOverlay();
            if (this.cellValueChanged != null) this.cellValueChanged(colField);
            this.listTreeData.resizeAgCellLastLeftPinned();
            this.gridOptions.api.deselectAll();
          }
        },
      },
      {
        colId: "lineRef",
        headerValueGetter: () => this.i18n.tr("metering.excelRef"),
        field: "lineRef",
        type: FieldType.String,
        filterParams: {
          showEmptyFieldsCheckbox: false
        },
        suppressMenu: !this.canUseFilter,
        pinned: "left",
        lockPosition: 'left',
        comparator: (valueA: string, valueB: string, nodeA, nodeB, isDescending) => {
          if (valueA?.localeCompare != null && valueB != null)
            return valueA.localeCompare(valueB, undefined, { numeric: true, sensitivity: 'base' });
        },
        cellClass: (params) => {
          return ['stringType']
        },
        showRowGroup: this.i18n.tr("groupTabPanel.infoExcel"),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params),
        hide: true
      },
      {
        colId: "originalMeasurementXlsLineNumber",
        headerValueGetter: () => this.i18n.tr("metering.originalMeasurementXlsLineNumber"),
        field: "originalMeasurementXlsLineNumber",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        pinned: "left",
        lockPosition: 'left',
        showRowGroup: this.i18n.tr("groupTabPanel.infoExcel"),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params),
        hide: true
      },
      {
        colId: "sheetIndex",
        headerValueGetter: () => this.i18n.tr("metering.sheetIndex"),
        field: "sheetIndex",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        pinned: "left",
        lockPosition: 'left',
        showRowGroup: this.i18n.tr("groupTabPanel.infoExcel"),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params),
        hide: true
      },
      {
        colId: "importFileName",
        headerValueGetter: () => this.i18n.tr("metering.importFileName"),
        field: "importFileName",
        type: FieldType.String,
        suppressMenu: !this.canUseFilter,
        pinned: "left",
        lockPosition: 'left',
        showRowGroup: this.i18n.tr("groupTabPanel.infoExcel"),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params),
        hide: true
      },
      {
        colId: "merlinRefContext",
        headerValueGetter: () => this.i18n.tr("versionmetering.merlinRefContext"),
        field: "merlinRefContext",
        type: FieldType.String,
        suppressMenu: !this.canUseFilter,
        comparator: (valueA: string, valueB: string, nodeA, nodeB, isDescending) => {
          if (valueA?.localeCompare != null && valueB != null)
            return valueA.localeCompare(valueB, undefined, { numeric: true, sensitivity: 'base' });
        },
        cellClass: (params) => {
          return ['stringType']
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params),
        hide: true
      },
      {
        colId: "merlinRefMetering",
        headerValueGetter: () => this.i18n.tr("versionmetering.merlinRefMetering"),
        field: "merlinRefMetering",
        type: FieldType.String,
        suppressMenu: !this.canUseFilter,
        comparator: (valueA: string, valueB: string, nodeA, nodeB, isDescending) => {
          if (valueA?.localeCompare != null && valueB != null)
            return valueA.localeCompare(valueB, undefined, { numeric: true, sensitivity: 'base' });
        },
        cellClass: (params) => {
          return ['stringType']
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params),
        hide: true
      },
      {
        colId: "quantityTypeId",
        headerValueGetter: () => this.i18n.tr("metering.type"),
        field: "quantityTypeId",
        valueGetter: (params) => params?.data?.quantityTypeTranslatedString,
        valueSetter: (params) => ModuleListTreeData.customEnumsValueSetter(params, this.quantityTypeList, ["type", "_translation"]),
        type: FieldType.OneToMany,
        filterParams: {
          service: this.quantityTypeService,
          customFieldPath: ['type', '_translation']
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: this.quantityTypeService,
          customFieldPath: ['type', '_translation']
        },
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        cellClass: (params) => {
          return ['metering-cell-select'];
        },
        cellEditor: "customHtmlRendererEditor",
        cellEditorParams: {
          isPopup: true,
          getHtml: (currentThis) => {
            return `<ui-field no-label style="min-width: ${currentThis.params.column.actualWidth > 200 ? currentThis.params.column.actualWidth : 200}px;"> 
                <ui-select value.one-time="params.data.quantityTypeId & validate" on-change.call="params.change(value, params)" allow-clear="true">
                  <option repeat.for="quantity of params.quantityTypeList" value.bind="quantity.id">
                    \${quantity.type.auto}
                  </option>
                </ui-select>
              </ui-field>`;
          },
          quantityTypeList: this.quantityTypeList,
          change: async (value, params) => {
            params.value = value;
            this.gridOptions?.api?.stopEditing();
            this.gridOptions?.api?.setFocusedCell(params.node.rowIndex, params.colDef.field);
          }
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "unitId",
        headerValueGetter: () => this.i18n.tr("metering.unit"),
        field: "unitId",
        valueGetter: (params) => params?.data?.unitTranslatedString,
        valueSetter: (params) => ModuleListTreeData.customEnumsValueSetter(params, this.unitList, ["unitName", "_translation"]),
        type: FieldType.OneToMany,
        filterParams: {
          service: this.unitService,
          customFieldPath: ['unitName', '_translation']
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: this.unitService,
          customFieldPath: ['unitName', '_translation']
        },
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        cellClass: (params) => {
          return ['metering-cell-select'];
        },
        cellEditor: "customHtmlRendererEditor",
        cellEditorParams: {
          isPopup: true,
          getHtml: (currentThis) => {
            return `<ui-field no-label style="min-width: ${currentThis.params.column.actualWidth > 200 ? currentThis.params.column.actualWidth : 200}px;"> 
              <ui-select value.one-time="params.data.unitId" on-change.call="params.change(value, params)">
                <option repeat.for="unit of params.unitList" value.bind="unit.id">
                  \${unit.unitName.auto}
                </option>
              </ui-select>
            </ui-field>`;
          },
          unitList: this.unitList,
          change: async (value, params) => {
            params.value = value;
            this.gridOptions?.api?.stopEditing();
            this.gridOptions?.api?.setFocusedCell(params.node.rowIndex, params.colDef.field);
          }
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "unitQuantityDetailItemComposed",
        headerValueGetter: () => this.i18n.tr("metering.builderUnitQuantity"),
        field: "unitQuantityDetailItemComposed",
        type: FieldType.Number,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        suppressMenu: !this.canUseFilter,
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: "unitQuantityDetailItemComposed" }),
        valueSetter: (params) => {
          if (params.newValue != null) {
            params.newValue = params.newValue.toString().replace(/[.]/g, ',');
          }
          params.data["unitQuantityFormulaDetailItemComposed"] = params.newValue;
          return true;
        },
        valueGetter: (params) => {
          if (params.data["unitQuantityFormulaDetailItemComposed"] == null || params.data["unitQuantityFormulaDetailItemComposed"] == "")
            return params.data["unitQuantityDetailItemComposed"];
          return params.data["unitQuantityFormulaDetailItemComposed"];
        },
        cellEditor: 'proposedQuantityEditor',
        cellEditorParams: {
          formulaField: 'unitQuantityFormulaDetailItemComposed',
          fractionDigits: Constants.PrecisionParameter.MaxNbDecimalForQuantityDisplay
        },
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            let html = "<div class='metering-price-origin'>";
            let quantity: number = currentThis.params.data['unitQuantityDetailItemComposed'];
            let quantityFormula = currentThis.params.data['unitQuantityFormulaDetailItemComposed']?.toString() ?? "";
            if (quantity != null) {
              let tooltip: string;
              tooltip = this.i18n.tr("metering.formula");
              let formattedQuantity = new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: this.nbDecimalForQuantityDisplay }).format(quantity);
              let formattedQuantityFormula = new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: this.nbDecimalForQuantityDisplay }).format(quantityFormula);
              if (quantityFormula != "" && formattedQuantity != formattedQuantityFormula)
                html += `<span ui-tooltip="value:${tooltip}" class="span-tooltip"><ui-icon icon='digi-calculator-line'></ui-icon></span>`;
              html += `<span>${(!isNaN(formattedQuantity.replace(/[.]/g, ',')) ? formattedQuantity : quantity.toString().replace(/[.]/g, ','))}</span>`;
            }
            html += '</div>'
            return html;
          }
        },
      },
      {
        colId: "unitQuantityFormulaDetailItemComposed",
        headerValueGetter: () => this.i18n.tr("metering.unitQuantityFormulaDetailItemComposed"),
        field: "unitQuantityFormulaDetailItemComposed",
        type: FieldType.String,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "proposedQuantity",
        headerValueGetter: () => this.i18n.tr("metering.builderQuantity"),
        field: "proposedQuantity",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: "proposedQuantity" }),
        valueSetter: (params) => {
          if (params.newValue != null) {
            params.newValue = params.newValue.toString().replace(/[.]/g, ',');
          }
          params.data["proposedQuantityFormula"] = params.newValue;
          return true;
        },
        valueGetter: (params) => {
          if (params.data["proposedQuantityFormula"] == null || params.data["proposedQuantityFormula"] == "")
            return params.data["proposedQuantity"];
          return params.data["proposedQuantityFormula"];
        },
        cellEditor: 'proposedQuantityEditor',
        cellEditorParams: {
          formulaField: 'proposedQuantityFormula',
          fractionDigits: Constants.PrecisionParameter.MaxNbDecimalForQuantityDisplay
        },
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            let html = "<div class='metering-price-origin'>";
            let quantity: number = currentThis.params.data['proposedQuantity'];
            let quantityFormula = currentThis.params.data['proposedQuantityFormula']?.toString() ?? "";
            if (quantity != null) {
              let tooltip: string;
              tooltip = this.i18n.tr("metering.formula");
              let formattedQuantity = new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: this.nbDecimalForQuantityDisplay }).format(quantity);
              let formattedQuantityFormula = new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: this.nbDecimalForQuantityDisplay }).format(quantityFormula);

              if (quantityFormula != "" && formattedQuantity != formattedQuantityFormula)
                html += `<span ui-tooltip="value:${tooltip}" class="span-tooltip"><ui-icon icon='digi-calculator-line'></ui-icon></span>`;

              html += `<span>${(!isNaN(formattedQuantity.replace(/[.]/g, ',')) ? formattedQuantity : quantity.toString().replace(/[.]/g, ','))}</span>`;
            }
            html += '</div>'
            return html;
          }
        },
        cellClass: (params) => {
          let returnClass = ["numberType"];
          if (params.data.lineTypeId == Constants.PriceOfferLineType.FixedPrice) {
            returnClass.push("is-fixed-price")
          }
          return returnClass;
        },
      },
      {
        colId: "proposedQuantityFormula",
        headerValueGetter: () => this.i18n.tr("metering.proposedQuantityFormula"),
        field: "proposedQuantityFormula",
        type: FieldType.String,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "originalProposedQuantity",
        headerValueGetter: () => this.i18n.tr("metering.originalProposedQuantity"),
        field: "originalProposedQuantity",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        cellRendererParams: { maximumFractionDigits: this.nbDecimalForQuantityDisplay },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "priceOfferLineFileCount",
        headerValueGetter: () => this.i18n.tr("metering.documents"),
        field: "priceOfferLineFileCount",
        type: FieldType.Number,
        cellRenderer: "meteringPriceOfferLineFileRenderer",
        cellRendererParams: {
          refreshRowData: (params) => {
            if (params?.data?.id != null)
              this.refreshVisibleNodes([params.data.id], false);
          },
          readonlyDocument: this.readonlyDocument,
          versionId: this.versionId,
          projectId: this.projectId,
          workQuotationVersionId: this.workQuotationVersionId,
          priceOfferVersionId: this.priceOfferVersionId

        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "additionalDescriptionMultiline",
        headerValueGetter: () => this.i18n.tr("priceofferline.additionalDescriptionMultiline"),
        field: "additionalDescriptionMultiline",
        type: FieldType.String,
        filterParams: {
          showEmptyFieldsCheckbox: false
        },
        suppressMenu: !this.canUseFilter,
        cellEditor: "agLargeTextCellEditor",
        cellEditorPopup: false,
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            return this.highlightSearchTextValueConverter.toView(currentThis.params.data[currentThis.params.colDef.colId], this.quickFilter);
          }
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "requestedQuantity",
        headerValueGetter: () => this.i18n.tr("metering.authorQuantity"),
        field: "requestedQuantity",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        editable: (params) => this.internCanEditCell({ line: params.data, colField: "requestedQuantity" }),
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        cellRendererParams: { maximumFractionDigits: this.nbDecimalForQuantityDisplay },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "acceptedRequestedQuantity",
        headerValueGetter: () => this.i18n.tr("metering.acceptedRequestedQuantity"),
        field: "acceptedRequestedQuantity",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        cellRendererParams: { maximumFractionDigits: this.nbDecimalForQuantityDisplay },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "dataLineDeliveries",
        headerValueGetter: () => this.i18n.tr("metering.delivery"),
        field: "dataLineDeliveries",
        // valueGetter: (params) => params?.data?.itemDeliveryTypeTranslatedString,
        // valueSetter: (params) => ModuleListTreeData.customEnumsValueSetter(params, this.itemDeliveryTypeList, ["denomination", "_translation"]),
        type: FieldType.ManyToMany,
        filterParams: {
          service: this.deliveryService,
          customFieldPath: ['denomination', '_translation'],
        },
        // floatingFilterComponentParams: {
        //   suppressFilterButton: true,
        //   category: Constants.EnumerationTypes.ItemDeliveryType,
        //   customFieldPath: ['denomination', '_translation'],
        //   relationIdFieldName: "itemDeliveryTypeId"
        // },
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        cellClass: (params) => {
          let returnClass = ["metering-cell-select", "metering-cell-tags-container"];
          if (params.data.itemDeliveryTypeInherit) {
            returnClass.push("inherit-enum");
          }
          return returnClass;
        },
        cellEditor: "customHtmlRendererEditor",
        cellEditorParams: {
          isPopup: true,
          getHtml: (currentThis) => {
            return `<ui-field no-label class.bind="(params.data.itemDeliveryTypeInherit ? 'inherit-enum' : '')" style="min-width: ${currentThis.params.column.actualWidth > 200 ? currentThis.params.column.actualWidth : 200}px;"> 
              <ui-select value.one-time="params.data.itemDeliveryTypeId"  on-change.call="params.change(value, params)">
                <option repeat.for="item of params.itemDeliveryTypeList" value.bind="item.id">
                  \${item.denomination.auto}
                </option>
              </ui-select>
            </ui-field>`;
          },
          itemDeliveryTypeList: this.itemDeliveryTypeList,
          change: async (value, params) => {
            params.data.itemDeliveryTypeId = value;
            this.gridOptions?.api?.stopEditing();
            this.gridOptions?.api?.setFocusedCell(params.node.rowIndex, params.colDef.field);
            let nodes = await this.api.patch(parseInt(this.versionId), params.data.id, "itemDeliveryTypeId", value);
            this.refreshVisibleNodes(nodes);
          }
        },
        cellRenderer: "customHtmlRendererEditor",
        cellRendererParams: {
          getHtml: (currentThis) => {
            if (currentThis.params.data.dataLineDeliveries != null) {
              let deliverySelected = this.itemDeliveryTypeList.filter(x => currentThis.params.data.dataLineDeliveries.find(y => y == x.id) > -1);
              let result = "";
              deliverySelected.forEach(de => {
                result += `<ui-tag label="${(de.denomination as any).auto}"
                  text-color="${de.textColor}" background-color="${de.backgroundColor}"`
                if (!currentThis.params.data.useInCompleteness && currentThis.params.data.itemDeliveryTypeId == de.id) {
                  result += `icon="digi-arrow-down-s-line"`
                }
                result += `size="md">
                </ui-tag>`
              });
              return result;
            }
            return "";
          },
          activityCodeList: this.activityCodeList
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "dataLineActivities",
        headerValueGetter: () => this.i18n.tr("metering.activity"),
        field: "dataLineActivities",
        type: FieldType.ManyToMany,
        // valueGetter: (params) => params?.data?.activityCodeTranslatedString,
        // valueSetter: (params) => ModuleListTreeData.customEnumsValueSetter(params, this.activityCodeList, ["denomination", "_translation"]),
        filterParams: {
          service: this.activityService,
          customFieldPath: ['denomination', '_translation'],
        },
        // floatingFilterComponentParams: {
        //   suppressFilterButton: true,
        //   category: Constants.EnumerationTypes.ActivityCode,
        //   customFieldPath: ['denomination', '_translation'],
        //   relationIdFieldName: "activityCodeId"
        // },
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        cellClass: (params) => {
          let returnClass = ["metering-cell-select", "metering-cell-tags-container"];
          if (params.data.activityCodeInherit) {
            returnClass.push("inherit-enum")
          }
          return returnClass;
        },
        cellEditor: "customHtmlRendererEditor",
        cellEditorParams: {
          isPopup: true,
          getHtml: (currentThis) => {
            return `<ui-field no-label class.bind="(params.data.activityCodeInherit ? 'inherit-enum' : '')" style="min-width: ${currentThis.params.column.actualWidth > 200 ? currentThis.params.column.actualWidth : 200}px;"> 
              <ui-select value.one-time="params.data.activityCodeId & validate" on-change.call="params.change(value, params)">
                <option repeat.for="activity of params.activityCodeList" value.bind="activity.id">
                  \${activity.denomination.auto }
                </option>
                <!--<ui-select-footer-template>
                  <ui-button t="[label]general.add" icon="digi-plus" type="solid"
                    ui-theme="primary" click.delegate="params.addActivityCode(params)"></ui-button>
                </ui-select-footer-template>-->
              </ui-select>
            </ui-field>`;
          },
          activityCodeList: this.activityCodeList,
          addActivityCode: async (params) => {
            await this.box.showEditDialog(EnumerationTypeDetail, -100, this.i18n.tr('general.add'), (createdEntity: EnumerationType) => {
              createdEntity.category = Constants.EnumerationTypes.ActivityCode;
            }, {
              canSave: false,
              actions: [
                ...EditDialogAction.GetBaseAction(this.i18n)
              ]
            }).whenClosed(async (result) => {
              if (!result.wasCancelled) {
                let value = (result.output as EnumerationType);
                params.data.activityCodeId = value.id;

                this.activityCodeList.push(value);
                params.activityCodeList = this.activityCodeList;

                // Manually patch the value
                let nodes = await this.api.patch(parseInt(this.versionId), params.data.id, 'activityCodeId', params.data.activityCodeId);
                this.refreshVisibleNodes(nodes);
              }
            });
          },
          change: async (value, params) => {
            params.data.activityCodeId = value;
            this.gridOptions?.api?.stopEditing();
            this.gridOptions?.api?.setFocusedCell(params.node.rowIndex, params.colDef.field);
            let nodes = await this.api.patch(parseInt(this.versionId), params.data.id, "activityCodeId", value);
            this.refreshVisibleNodes(nodes);
          }
        },
        cellRenderer: "customHtmlRendererEditor",
        cellRendererParams: {
          getHtml: (currentThis) => {
            let expandColumn = this.activityExpanded[currentThis.params.data.id] ?? false
            if (currentThis.params.data.dataLineActivities != null) {
              let activitySelecteds = this.activityCodeList.filter(x => currentThis.params.data.dataLineActivities.find(y => y == x.id) > -1);
              let result = "";
              for (let index = 0; index < activitySelecteds.length; index++) {
                const ac = activitySelecteds[index];
                if ((index < 3 && !expandColumn) || expandColumn) {
                  result += `<ui-tag label="${(ac.denomination as any).auto}"
                  text-color="${ac.textColor}" background-color="${ac.backgroundColor}"`
                  if (!currentThis.params.data.useInCompleteness && currentThis.params.data.activityCodeId == ac.id) {
                    result += `icon="digi-arrow-down-s-line"`
                  }
                  result += `size="md">
                  </ui-tag>`
                }
                else if (index == 3 && !expandColumn) {
                  result += `<ui-tag on-click.call="params.expand(params)" label="..." text-color="#3C54E4" background-color="#F2F4FE" size="md"> </ui-tag>`
                }
              }
              return result;
            }
            return "";
          },
          expand: (params) => {
            this.activityExpanded[params.data.id] = true;
            params.api.redrawRows({ rowNodes: [params.node] })
          },
          activityCodeList: this.activityCodeList
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "buyingUnitPrice",
        headerValueGetter: () => this.i18n.tr("metering.unitPrice"),
        field: "buyingUnitPrice",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            let html = "<div class='metering-price-origin'>";
            let price = currentThis.params.data.buyingUnitPrice;
            if (price != null) {
              let tooltip: string;
              switch (currentThis.params.data.priceOrigin) {
                case Constants.PriceOrigin.Manual:
                  tooltip = this.i18n.tr("metering.buyingUnitPriceManual");
                  html += `<span  ui-tooltip="value:${tooltip}" class="span-tooltip"><ui-icon icon='digi-edit'></ui-icon></span>`;
                  break;
                case Constants.PriceOrigin.Item:
                  tooltip = this.i18n.tr("metering.buyingUnitPriceItem");
                  html += `<span  ui-tooltip="value:${tooltip}" class="span-tooltip"><ui-icon icon='digi-database-2-line'></ui-icon></span>`;
                  break;
                case Constants.PriceOrigin.Offer:
                  tooltip = this.i18n.tr("metering.buyingUnitPriceOffer");
                  html += `<span  ui-tooltip="value:${tooltip}" class="span-tooltip"><ui-icon icon='digi-clipboard-line'></ui-icon></span>`;
                  break;
                case Constants.PriceOrigin.Estim:
                  tooltip = this.i18n.tr("metering.buyingUnitPriceEstimated");
                  html += `<span  ui-tooltip="value:${tooltip}" class="span-tooltip"><ui-icon icon='digi-calculator-line'></ui-icon></span>`;
                  break;
              }
              html += `<span>${(!isNaN(price) ? (price == 0 && this.displayIncluded ? this.i18n.tr("metering.included") : new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(price)) : price)}</span>`;
            }
            html += '</div>'
            return html;
          },
          maximumFractionDigits: this.nbDecimalForPriceDisplay,
        },
        cellEditorParams: {
          fractionDigits: Constants.PrecisionParameter.MaxNbDecimalForPriceDisplay
        },
        cellClass: (params) => {
          let returnClass = ["currencyFormat"];
          if (params.data.lineTypeId == Constants.PriceOfferLineType.FixedPrice) {
            returnClass.push("is-fixed-price");
          }
          return returnClass;
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "definedSellingUnitPrice",
        headerValueGetter: () => this.i18n.tr("metering.definedSellingUnitPrice"),
        field: "definedSellingUnitPrice",
        type: FieldType.Number,
        cellEditorParams: { fractionDigits: Constants.PrecisionParameter.MaxNbDecimalForPriceDisplay },
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            if (this.debugMode)
              return this.listTreeData.getPriceWithTooltipHtml(currentThis.params.data.definedSellingUnitPrice, currentThis.params.data.previousDefinedSellingUnitPrice, this.nbDecimalForPriceDisplay);
            else
              return (this.displayIncluded && currentThis.params.data.definedSellingUnitPrice == 0 ? this.i18n.tr("metering.included") : currentThis.params.valueFormatted) ?? ""
          },
          maximumFractionDigits: this.nbDecimalForPriceDisplay,
        },
        cellClass: (params) => {
          let returnClass = ["currencyFormat"];
          if (this.debugMode && params.data.previousDefinedSellingUnitPrice != null && params.data.definedSellingUnitPrice != null && params.data.definedSellingUnitPrice != params.data.previousDefinedSellingUnitPrice) {
            returnClass.push("previous-price-error");
          }
          if (params.data.lineTypeId == Constants.PriceOfferLineType.FixedPrice) {
            returnClass.push("is-fixed-price");
          }
          return returnClass;
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "sellingUnitPriceWithIndirectCostFromPAR",
        headerValueGetter: () => this.isSupplierOffer ? this.i18n.tr("metering.unitPriceWithIndirectCostFromPAR") : this.i18n.tr("metering.sellingUnitPriceWithIndirectCostFromPAR"),
        field: "sellingUnitPriceWithIndirectCostFromPAR",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            return (this.displayIncluded && currentThis.params.data.sellingUnitPriceWithIndirectCostFromPAR == 0 ? this.i18n.tr("metering.included") : currentThis.params.valueFormatted) ?? ""
          },
          maximumFractionDigits: this.nbDecimalForPriceDisplay,
        },
        cellClass: (params) => {
          let returnClass = ["currencyFormat"];
          if (params.data.lineTypeId == Constants.PriceOfferLineType.FixedPrice) {
            returnClass.push("is-fixed-price");
          }
          return returnClass;
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "diffIndirectCostFromPAR",
        headerValueGetter: () => this.isSupplierOffer ? this.i18n.tr("metering.supplierDiffIndirectCostFromPAR") : this.i18n.tr("metering.diffIndirectCostFromPAR"),
        field: "diffIndirectCostFromPAR",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            return (this.displayIncluded && currentThis.params.data.diffIndirectCostFromPAR == 0 ? this.i18n.tr("metering.included") : currentThis.params.valueFormatted) ?? ""
          },
          maximumFractionDigits: this.nbDecimalForPriceDisplay,
        },
        cellClass: (params) => {
          let returnClass = ["currencyFormat"];
          if (params.data.lineTypeId == Constants.PriceOfferLineType.FixedPrice) {
            returnClass.push("is-fixed-price");
          }
          return returnClass;
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "definedDirectMarginPrice",
        headerValueGetter: () => this.i18n.tr("metering.definedDirectMarginPrice"),
        field: "definedDirectMarginPrice",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        cellClass: (params) => {
          let returnClass = ["currencyFormat"];
          if (params.data.lineTypeId == Constants.PriceOfferLineType.FixedPrice) {
            returnClass.push("is-fixed-price");
          }
          return returnClass;
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      //#region coef
      {
        colId: "overheadMarginCoefficient",
        headerValueGetter: () => this.i18n.tr("priceofferline.overheadMarginCoefficient"),
        field: "overheadMarginCoefficient",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        editable: (params) => this.internCanEditCell({ line: params.data, colField: "overheadMarginCoefficient" }),
        cellClass: (params) => {
          let returnClass = [];
          if (params.data.lineTypeId == Constants.PriceOfferLineType.FixedPrice) {
            returnClass.push("is-fixed-price");
          }
          return returnClass;
        },
        cellRenderer: "customHtmlRendererEditor",
        cellRendererParams: {
          getHtml: (currentThis) => {
            let html = `<div class='metering-price-origin ${currentThis.params.data.overheadMarginCoefficientInherit ? ' inherit-coefficient' : ' manual-coefficient'}'>`;
            let coef = currentThis.params.data.currentOverheadMarginCoefficient ?? currentThis.params.data.overheadMarginCoefficient;
            if (coef != null) {
              let tooltip: string;
              tooltip = this.i18n.tr("metering.imposedMarginCoef");
              if (currentThis.params.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter && !currentThis.params.data.overheadMarginCoefficientInherit && currentThis.params.data.overheadMarginCoefficient != null && currentThis.params.data.currentOverheadMarginCoefficient != null) {
                html += `<span  ui-tooltip="value:${tooltip}" class="span-tooltip"><ui-icon icon='digi-arrow-down-line'></ui-icon></span>`;
              }
              html += `<span>${coef}</span>`;
            }
            html += '</div>'
            return html;
          },
          maximumFractionDigits: 3
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        cellEditorParams: { fractionDigits: 3 },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "overheadMarginAmount",
        headerValueGetter: () => this.i18n.tr("priceofferline.overheadMarginAmount"),
        field: "overheadMarginAmount",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => false,
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        cellClass: (params) => {
          let returnClass = [];
          if (params.data.lineTypeId == Constants.PriceOfferLineType.FixedPrice) {
            returnClass.push("is-fixed-price");
          }
          return returnClass;
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },


      {
        colId: "benefitMarginCoefficient",
        headerValueGetter: () => this.i18n.tr("priceofferline.benefitMarginCoefficient"),
        field: "benefitMarginCoefficient",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        editable: (params) => this.internCanEditCell({ line: params.data, colField: "benefitMarginCoefficient" }),
        cellClass: (params) => {
          let returnClass = [];
          if (params.data.lineTypeId == Constants.PriceOfferLineType.FixedPrice) {
            returnClass.push("is-fixed-price");
          }
          return returnClass;
        },
        cellRenderer: "customHtmlRendererEditor",
        cellRendererParams: {
          getHtml: (currentThis) => {
            let html = `<div class='metering-price-origin ${currentThis.params.data.benefitMarginCoefficientInherit ? ' inherit-coefficient' : ' manual-coefficient'}'>`;

            let coef = currentThis.params.data.currentBenefitMarginCoefficient ?? currentThis.params.data.benefitMarginCoefficient;
            if (coef != null) {
              let tooltip: string;
              tooltip = this.i18n.tr("metering.imposedMarginCoef");
              if (currentThis.params.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter && !currentThis.params.data.benefitMarginCoefficientInherit && currentThis.params.data.benefitMarginCoefficient != null && currentThis.params.data.currentBenefitMarginCoefficient != null) {
                html += `<span  ui-tooltip="value:${tooltip}" class="span-tooltip"><ui-icon icon='digi-arrow-down-line'></ui-icon></span>`;
              }
              html += `<span>${coef}</span>`;
            }
            html += '</div>'
            return html;
          },
          maximumFractionDigits: 3
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        cellEditorParams: { fractionDigits: 3 },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "benefitMarginAmount",
        headerValueGetter: () => this.i18n.tr("priceofferline.benefitMarginAmount"),
        field: "benefitMarginAmount",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => false,
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        cellClass: (params) => {
          let returnClass = [];
          if (params.data.lineTypeId == Constants.PriceOfferLineType.FixedPrice) {
            returnClass.push("is-fixed-price");
          }
          return returnClass;
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },


      {
        colId: "riskMarginCoefficient",
        headerValueGetter: () => this.i18n.tr("priceofferline.riskMarginCoefficient"),
        field: "riskMarginCoefficient",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        editable: (params) => this.internCanEditCell({ line: params.data, colField: "riskMarginCoefficient" }),
        cellClass: (params) => {
          let returnClass = [];
          if (params.data.lineTypeId == Constants.PriceOfferLineType.FixedPrice) {
            returnClass.push("is-fixed-price");
          }
          return returnClass;
        },
        cellRenderer: "customHtmlRendererEditor",
        cellRendererParams: {
          getHtml: (currentThis) => {
            let html = `<div class='metering-price-origin ${currentThis.params.data.riskMarginCoefficientInherit ? ' inherit-coefficient' : ' manual-coefficient'}'>`;
            let coef = currentThis.params.data.currentRiskMarginCoefficient ?? currentThis.params.data.riskMarginCoefficient;
            if (coef != null) {
              let tooltip: string;
              tooltip = this.i18n.tr("metering.imposedMarginCoef");
              if (currentThis.params.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter && !currentThis.params.data.riskMarginCoefficientInherit && currentThis.params.data.riskMarginCoefficient != null && currentThis.params.data.currentRiskMarginCoefficient != null) {
                html += `<span  ui-tooltip="value:${tooltip}" class="span-tooltip"><ui-icon icon='digi-arrow-down-line'></ui-icon></span>`;
              }
              html += `<span>${coef}</span>`;
            }
            html += '</div>'
            return html;
          },
          maximumFractionDigits: 3
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        cellEditorParams: { fractionDigits: 3 },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "riskMarginAmount",
        headerValueGetter: () => this.i18n.tr("priceofferline.riskMarginAmount"),
        field: "riskMarginAmount",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => false,
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        cellClass: (params) => {
          let returnClass = [];
          if (params.data.lineTypeId == Constants.PriceOfferLineType.FixedPrice) {
            returnClass.push("is-fixed-price");
          }
          return returnClass;
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "diffMarginPrice",
        headerValueGetter: () => this.i18n.tr("priceofferline.diffMarginPrice"),
        field: "diffMarginPrice",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => false,
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        cellClass: (params) => {
          let returnClass = [];
          if (params.data.lineTypeId == Constants.PriceOfferLineType.FixedPrice) {
            returnClass.push("is-fixed-price");
          }
          return returnClass;
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },



      //#endregion

      {
        colId: "indirectCostFromPAR",
        headerValueGetter: () => this.i18n.tr("metering.indirectCostFromPAR"),
        field: "indirectCostFromPAR",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        cellClass: (params) => {
          let returnClass = ["currencyFormat"];
          if (params.data.lineTypeId == Constants.PriceOfferLineType.FixedPrice) {
            returnClass.push("is-fixed-price");
          }
          return returnClass;
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "totalBuyingPrice",
        headerValueGetter: () => this.i18n.tr("metering.totalPr"),
        field: "totalBuyingPrice",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        valueGetter: (data) => {
          if (this.hideTotalChapter && data.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter) return;
          return data.data?.[data.colDef.field];
        },
        valueFormatter: (data) => {
          if (this.hideTotalChapter && data.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter) return;
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            if (this.debugMode)
              return this.listTreeData.getPriceWithTooltipHtml(currentThis.params.data.totalBuyingPrice, currentThis.params.data.previousTotalBuyingPrice, this.nbDecimalForPriceDisplay);
            else
              return (this.displayIncluded && currentThis.params.data.totalBuyingPrice == 0 ? this.i18n.tr("metering.included") : currentThis.params.valueFormatted) ?? ""
          },
          maximumFractionDigits: this.nbDecimalForPriceDisplay,
        },
        cellClass: (params) => {
          let returnClass = ["currencyFormat"];
          if (this.debugMode && params.data.previousTotalBuyingPrice != null && params.data.totalBuyingPrice != null && params.data.totalBuyingPrice != params.data.previousTotalBuyingPrice) {
            returnClass.push("previous-price-error");
          }
          if (params.data.lineTypeId == Constants.PriceOfferLineType.FixedPrice) {
            returnClass.push("is-fixed-price");
          }
          return returnClass;
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "totalSellingPrice",
        headerValueGetter: () => this.i18n.tr("metering.totalPv"),
        field: "totalSellingPrice",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: "totalSellingPrice" }),
        cellClass: (params) => {
          let returnClass = ["currencyFormat"];
          if (params.data.hasPriceRepartition || params.data.hasParentPriceRepartition) {
            returnClass.push("has-price-repartition");
          }
          if (this.debugMode && params.data.previousTotalSellingPrice != null && params.data.totalSellingPrice != null && params.data.totalSellingPrice != params.data.previousTotalSellingPrice) {
            returnClass.push("previous-price-error");
          }
          if (params.data.lineTypeId == Constants.PriceOfferLineType.FixedPrice) {
            returnClass.push("is-fixed-price");
          }
          return returnClass;
        },
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            if (this.debugMode)
              return this.listTreeData.getPriceWithTooltipHtml(currentThis.params.data.totalSellingPrice, currentThis.params.data.previousTotalSellingPrice, this.nbDecimalForPriceDisplay);
            else
              return (this.displayIncluded && currentThis.params.data.totalBuyingPrice == 0 ? this.i18n.tr("metering.included") : currentThis.params.valueFormatted) ?? ""
          },
          maximumFractionDigits: this.nbDecimalForPriceDisplay,
        },
        valueGetter: (data) => {
          if (this.hideTotalChapter && data.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter) return;
          return data.data?.[data.colDef.field];
        },
        valueFormatter: (data) => {
          if (this.hideTotalChapter && data.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter) return;
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "features",
        headerValueGetter: () => this.i18n.tr("metering.features"),
        field: "features",
        type: FieldType.ManyToMany,
        filterParams: {
          service: this.featuresService,
          customFieldPath: ['denomination', '_translation'],
        },
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: true,
        cellClass: (params) => {
          return ['metering-cell-select'];
        },
        cellRenderer: "customHtmlRendererEditor",
        cellRendererParams: {
          getHtml: (currentThis) => {
            if (currentThis.params.data.features != null) {
              let featureSelected = this.dataLineFeaturesList.filter(x => currentThis.params.data.features.find(y => y == x.id) > -1);
              let result = "";
              featureSelected.forEach(feature => {
                result += `<ui-tag label="${(feature.denomination as any).auto}"
                  text-color="${feature.textColor}" background-color="${feature.backgroundColor}" size="md">
                </ui-tag>`
              });
              return result;
            }
            return "";
          },
          dataLineFeaturesList: this.dataLineFeaturesList
        },
        cellEditor: "customHtmlRendererEditor",
        cellEditorParams: {
          isPopup: true,
          getHtml: (currentThis) => {
            let features = JSON.parse(JSON.stringify(currentThis.params.data.features));
            currentThis.params.features = features;
            return `
          <ui-field no-label style="min-width: ${currentThis.params.column.actualWidth > 200 ? currentThis.params.column.actualWidth : 200}px;"> 
                <ui-select multiple features value.one-time="params.features" on-change.call="params.onChange(value, params)">
                  <option repeat.for="tag of params.dataLineFeaturesList" value.bind="tag.id">
                    \${tag.denomination.auto}
                    </option>
                </ui-select> 
          </ui-field>`;
          },
          dataLineFeaturesList: this.dataLineFeaturesList,

          onChange: async (value, params) => {
            if (!Array.isArray(value)) value = [value];
            params.value = value;

            this.gridOptions?.api?.stopEditing();
            this.gridOptions?.api?.setFocusedCell(params.node.rowIndex, params.colDef.field);
          }
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "tags",
        headerValueGetter: () => this.i18n.tr("metering.tags"),
        field: "tags",
        type: FieldType.ManyToMany,
        filterParams: {
          service: this.tagsService,
          customFieldPath: ['denomination', '_translation'],
        },
        // floatingFilterComponentParams: {
        //   service: this.tagsService,
        //   suppressFilterButton: true,
        //   customFieldPath: ['denomination', '_translation'],
        // },
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        cellClass: (params) => {
          return ['metering-cell-select'];
        },
        cellRenderer: "customHtmlRendererEditor",
        cellRendererParams: {
          getHtml: (currentThis) => {
            if (currentThis.params.data.tags != null) {
              let tagSelected = this.priceOfferLineTagsList.filter(x => currentThis.params.data.tags.find(y => y == x.id) > -1);
              let result = "";
              tagSelected.forEach(tag => {
                result += `<ui-tag label="${(tag.denomination as any).auto}"
                  text-color="${tag.textColor}" background-color="${tag.backgroundColor}" size="md">
                </ui-tag>`
              });
              return result;
            }
            return "";
          },
          priceOfferLineTagsList: this.priceOfferLineTagsList
        },
        cellEditor: "customHtmlRendererEditor",
        cellEditorParams: {
          isPopup: true,
          getHtml: (currentThis) => {
            let tags = JSON.parse(JSON.stringify(currentThis.params.data.tags));
            currentThis.params.tags = tags;
            return `
          <ui-field no-label style="min-width: ${currentThis.params.column.actualWidth > 200 ? currentThis.params.column.actualWidth : 200}px;"> 
                <ui-select multiple tags value.one-time="params.tags" on-change.call="params.onChange(value, params)">
                  <option repeat.for="tag of params.priceOfferLineTagsList" value.bind="tag.id">
                    \${tag.denomination.auto}
                    </option>
                </ui-select> 
          </ui-field>`;
          },
          priceOfferLineTagsList: this.priceOfferLineTagsList,

          onChange: async (value, params) => {
            if (!Array.isArray(value)) value = [value];

            // Check if is adding or removing tag
            let isAddingTag = value.length > params.data.tags?.length;

            if (isAddingTag) {
              let addedTag = (<[]>value).slice(-1)[0];
              // Check if it's a new created tag
              let addedTagExists = this.priceOfferLineTagsList.findIndex(t => t.id == addedTag) > -1;
              if (!addedTagExists) {
                // It's a new created tag, we must call the edit dialog to configure it. 
                // And because AgGrid lose the change detection due to the next async calling to api, once the tag is created, we patch the value manually.
                await this.box.showEditDialog(EnumerationTypeDetail, -100, this.i18n.tr('general.add'), (createdEntity: EnumerationType) => {
                  createdEntity.category = Constants.EnumerationTypes.PriceOfferLineTag;
                  createdEntity.denomination.lang1 = addedTag;
                }, {
                  canSave: false,
                  actions: [
                    ...EditDialogAction.GetBaseAction(this.i18n)
                  ]
                }).whenClosed(async (result) => {
                  if (!result.wasCancelled) {
                    let newValue = (result.output as EnumerationType);
                    if (!Array.isArray(params.value)) params.value = [];
                    params.value.push(newValue.id);
                    params.data.tags = params.value;

                    this.priceOfferLineTagsList.push(newValue);
                    params.priceOfferLineTagsList = this.priceOfferLineTagsList;

                    // Manually patch the value
                    let nodes = await this.api.patch(parseInt(this.versionId), params.data.id, 'tags', params.data.tags);
                    this.refreshVisibleNodes(nodes);
                  }
                });
              } else { // Adding existing tag, simply set the new value
                params.value = value;
              }
            } else { // Removing, simply set the new value
              params.value = value;
            }

            this.gridOptions?.api?.stopEditing();
            this.gridOptions?.api?.setFocusedCell(params.node.rowIndex, params.colDef.field);
          }
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "supplierId",
        headerValueGetter: () => this.i18n.tr("metering.supplier"),
        field: "supplierId",
        valueGetter: (params) => params?.data?.supplier,
        type: FieldType.OneToMany,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params),
        filterParams: {
          service: this.thirdPartyService,
          customFieldPath: ['fullName']
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: this.thirdPartyService,
          customFieldPath: ['fullName']
        }
      },
      {
        colId: "supplierPrice",
        headerValueGetter: () => this.i18n.tr("supplierofferline.supplierPrice"),
        field: "supplierPrice",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            if (this.debugMode)
              return this.listTreeData.getPriceWithTooltipHtml(currentThis.params.data.supplierPrice, currentThis.params.data.previousDefinedSellingUnitPrice, this.nbDecimalForPriceDisplay);
            else
              return (this.displayIncluded && currentThis.params.data.supplierPrice == 0 ? this.i18n.tr("metering.included") : currentThis.params.valueFormatted) ?? ""
          },
          maximumFractionDigits: this.nbDecimalForPriceDisplay,
        },
        cellClass: (params) => {
          let returnClass = ["currencyFormat"];
          if (this.debugMode && params.data.previousDefinedSellingUnitPrice != null && params.data.supplierPrice != null && params.data.supplierPrice != params.data.previousDefinedSellingUnitPrice) {
            returnClass.push("previous-price-error")
          }
          return returnClass;
        },
        cellEditorParams: { fractionDigits: Constants.PrecisionParameter.MaxNbDecimalForPriceDisplay },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "supplierQuantity",
        headerValueGetter: () => this.i18n.tr("supplierofferline.supplierQuantity"),
        field: "supplierQuantity",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        cellEditorParams: { fractionDigits: Constants.PrecisionParameter.MaxNbDecimalForQuantityDisplay },
        cellRendererParams: { maximumFractionDigits: this.nbDecimalForQuantityDisplay },
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: this.nbDecimalForQuantityDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        cellClass: (cellClassParams) => {
          if (cellClassParams.data.supplierQuantityModified) {
            return ["supplier-quantity-modified"];
          }
          return [];
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "supplierQuantityTypeId",
        headerValueGetter: () => this.i18n.tr("supplierofferline.supplierQuantityTypeId"),
        field: "supplierQuantityTypeId",
        valueGetter: (params) => {
          return params?.data?.supplierQuantityTypeTranslatedString
        },
        valueSetter: (params) => ModuleListTreeData.customEnumsValueSetter(params, this.quantityTypeList, ["type", "_translation"]),
        type: FieldType.OneToMany,
        filterParams: {
          service: this.quantityTypeService,
          customFieldPath: ['type', '_translation']
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: this.quantityTypeService,
          customFieldPath: ['type', '_translation']
        },
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        cellClass: (params) => {
          let returnClass = ["metering-cell-select"];
          return returnClass;
        },
        cellEditor: "customHtmlRendererEditor",
        cellEditorParams: {
          isPopup: true,
          getHtml: (currentThis) => {
            return `<ui-field no-label style="min-width: ${currentThis.params.column.actualWidth > 200 ? currentThis.params.column.actualWidth : 200}px;"> 
                <ui-select value.one-time="params.data.supplierQuantityTypeId" on-change.call="params.change(value, params)" allow-clear="true">
                  <option repeat.for="quantity of params.workQuotationQuantityType" value.bind="quantity.id">
                    \${quantity.type.auto}
                  </option>
                </ui-select>
              </ui-field>`;
          },
          workQuotationQuantityType: this.quantityTypeList,
          change: async (value, params) => {
            params.value = value;
            this.gridOptions?.api?.stopEditing();
            this.gridOptions?.api?.setFocusedCell(params.node.rowIndex, params.colDef.field);
          }
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "supplierTotal",
        headerValueGetter: () => this.i18n.tr("supplierofferline.supplierTotal"),
        field: "supplierTotal",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        cellClass: (params) => {
          let returnClass = ["currencyFormat"];
          if (params.data.hasPriceRepartition || params.data.hasParentPriceRepartition) {
            returnClass.push("has-price-repartition");
          }
          if (this.debugMode && params.data.previousTotalBuyingPrice != null && params.data.supplierTotal != null && params.data.supplierTotal != params.data.previousTotalBuyingPrice) {
            returnClass.push("previous-price-error");
          }
          return returnClass;
        },
        valueGetter: (data) => {
          if (this.hideTotalChapter && data.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter) return;
          return data.data?.[data.colDef.field];
        },
        valueFormatter: (data) => {
          if (this.hideTotalChapter && data.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter) return;

          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            if (this.debugMode)
              return this.listTreeData.getPriceWithTooltipHtml(currentThis.params.data.supplierTotal, currentThis.params.data.previousTotalBuyingPrice, this.nbDecimalForPriceDisplay);
            else
              return (this.displayIncluded && currentThis.params.data.supplierTotal == 0 ? this.i18n.tr("metering.included") : currentThis.params.valueFormatted) ?? ""
          },
          maximumFractionDigits: this.nbDecimalForPriceDisplay,
        },
        cellEditorParams: {
          fractionDigits: Constants.PrecisionParameter.MaxNbDecimalForPriceDisplay
        },
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "supplierComment",
        headerValueGetter: () => this.i18n.tr("supplierofferline.supplierCommnent"),
        field: "supplierComment",
        type: FieldType.String,
        filterParams: {
          showEmptyFieldsCheckbox: false
        },
        suppressMenu: !this.canUseFilter,
        cellEditor: "agLargeTextCellEditor",
        cellEditorPopup: false,
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            return this.highlightSearchTextValueConverter.toView(currentThis.params.data[currentThis.params.colDef.colId], this.quickFilter);
          }
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "acceptedPriceOfferBuyingUnitPrice",
        headerValueGetter: () => this.i18n.tr("metering.acceptedPriceOfferBuyingUnitPrice"),
        field: "acceptedPriceOfferBuyingUnitPrice",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        cellEditorParams: {
          fractionDigits: Constants.PrecisionParameter.MaxNbDecimalForPriceDisplay
        },
        cellClass: (params) => {
          let returnClass = ["currencyFormat"];
          return returnClass;
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "buyingClientValidationStatusId",
        headerValueGetter: () => this.i18n.tr("metering.buyingClientValidationStatusId"),
        field: "buyingClientValidationStatusId",
        type: FieldType.Enumeration,
        valueGetter: (params) => params?.data?.buyingClientValidationStatus,
        valueSetter: (params) => ModuleListTreeData.customEnumsValueSetter(params, this.buyingClientValidationStatusList, ["denomination", "_translation"]),
        filterParams: {
          category: Constants.EnumerationTypes.ActivityCode,
          customFieldPath: ['denomination', '_translation'],
          relationIdFieldName: "buyingClientValidationStatusId"
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.ActivityCode,
          customFieldPath: ['denomination', '_translation'],
          relationIdFieldName: "buyingClientValidationStatusId"
        },
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        cellClass: (params) => {
          let returnClass = ["metering-cell-select", "metering-cell-tags-container"];
          if (params.data.buyingClientValidationStatusInherit) {
            returnClass.push("inherit-enum");
          }
          return returnClass;
        },
        cellEditor: "customHtmlRendererEditor",
        cellEditorParams: {
          isPopup: true,
          getHtml: (currentThis) => {
            return `<ui-field no-label class.bind="(params.data.buyingClientValidationStatusInherit ? 'inherit-enum' : '')" style="min-width: ${currentThis.params.column.actualWidth > 200 ? currentThis.params.column.actualWidth : 200}px;"> 
              <ui-select value.one-time="params.data.buyingClientValidationStatusId & validate" on-change.call="params.change(value, params)">
                <option repeat.for="activity of params.buyingClientValidationStatusList" value.bind="activity.id">
                  \${activity.denomination.auto }
                </option>
              </ui-select>
            </ui-field>`;
          },
          buyingClientValidationStatusList: this.buyingClientValidationStatusList,
          change: (value, params) => {
            params.value = value;
            this.gridOptions?.api?.stopEditing();
            this.gridOptions?.api?.setFocusedCell(params.node.rowIndex, params.colDef.field);
          }
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "buyingClientValidationDate",
        headerValueGetter: () => this.i18n.tr("metering.buyingClientValidationDate"),
        field: "buyingClientValidationDate",
        type: FieldType.Date,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        cellClass: (params) => {
          let returnClass = ["metering-cell-inherit", "dateType"];
          if (params.data.buyingClientValidationDateInherit) {
            returnClass.push("inherit-enum");
          }
          return returnClass;
        },
        cellEditor: "customHtmlRendererEditor",
        cellEditorParams: {
          isPopup: true,
          getHtml: (currentThis) => {
            return `<ui-field no-label class.bind="(params.data.buyingClientValidationDateInherit ? 'inherit-enum' : '')" style="min-width: ${currentThis.params.column.actualWidth > 200 ? currentThis.params.column.actualWidth : 200}px;"> 
              <ui-date-picker date.bind="params.data.buyingClientValidationDate" date-only.one-time="true" on-change.call="params.change($event, params)" allow-clear.one-time="true"> 
              </ui-date-picker>
            </ui-field>`;
          },
          change: async (value, params) => {
            params.value = value.date;
            this.gridOptions?.api?.stopEditing();
            this.gridOptions?.api?.setFocusedCell(params.node.rowIndex, params.colDef.field);
            await this.patchDatePicker(value.date, params.data.id, params.colDef.field);

          }
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "buyingClientValidationDeadlineDate",
        headerValueGetter: () => this.i18n.tr("metering.buyingClientValidationDeadlineDate"),
        field: "buyingClientValidationDeadlineDate",
        type: FieldType.Date,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        cellClass: (params) => {
          let returnClass = ["metering-cell-inherit", "dateType"];
          if (params.data.buyingClientValidationDeadlineDateInherit) {
            returnClass.push("inherit-enum");
          }
          return returnClass;
        },
        cellEditor: "customHtmlRendererEditor",
        cellEditorParams: {
          isPopup: true,
          getHtml: (currentThis) => {
            return `<ui-field no-label class.bind="(params.data.buyingClientValidationDeadlineDateInherit ? 'inherit-enum' : '')" style="min-width: ${currentThis.params.column.actualWidth > 200 ? currentThis.params.column.actualWidth : 200}px;"> 
              <ui-date-picker date.bind="params.data.buyingClientValidationDeadlineDate" date-only.one-time="true" on-change.call="params.change($event, params)" allow-clear.one-time="true"> 
              </ui-date-picker>
            </ui-field>`;
          },
          change: async (value, params) => {
            params.value = value.date;
            this.gridOptions?.api?.stopEditing();
            this.gridOptions?.api?.setFocusedCell(params.node.rowIndex, params.colDef.field);
            await this.patchDatePicker(value.date, params.data.id, params.colDef.field);

          }
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "deliveryDeadlineDate",
        headerValueGetter: () => this.i18n.tr("metering.deliveryDeadlineDate"),
        field: "deliveryDeadlineDate",
        type: FieldType.Date,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        cellClass: (params) => {
          let returnClass = ["metering-cell-inherit", "dateType"];
          if (params.data.deliveryDeadlineDateInherit) {
            returnClass.push("inherit-enum");
          }
          return returnClass;
        },
        cellEditor: "customHtmlRendererEditor",
        cellEditorParams: {
          isPopup: true,
          getHtml: (currentThis) => {
            return `<ui-field no-label class.bind="(params.data.deliveryDeadlineDateInherit ? 'inherit-enum' : '')" style="min-width: ${currentThis.params.column.actualWidth > 200 ? currentThis.params.column.actualWidth : 200}px;"> 
              <ui-date-picker date.bind="params.data.deliveryDeadlineDate" date-only.one-time="true" on-change.call="params.change($event, params)" allow-clear.one-time="true"> 
              </ui-date-picker>
            </ui-field>`;
          },
          change: async (value, params) => {
            params.value = value.date;
            this.gridOptions?.api?.stopEditing();
            this.gridOptions?.api?.setFocusedCell(params.node.rowIndex, params.colDef.field);
            await this.patchDatePicker(value.date, params.data.id, params.colDef.field);

          }
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "quantityTypeSupplierTypeId",
        headerValueGetter: () => this.i18n.tr("metering.quantityTypeSupplierTypeId"),
        field: "quantityTypeSupplierTypeId",
        valueGetter: (params) => params?.data?.quantityTypeTranslatedString,
        valueSetter: (params) => ModuleListTreeData.customEnumsValueSetter(params, this.quantityTypeList, ["type", "_translation"]),
        type: FieldType.OneToMany,
        filterParams: {
          service: this.quantityTypeService,
          customFieldPath: ['type', '_translation']
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: this.quantityTypeService,
          customFieldPath: ['type', '_translation']
        },
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        cellClass: (params) => {
          return ['metering-cell-select'];
        },
        cellEditor: "customHtmlRendererEditor",
        cellEditorParams: {
          isPopup: true,
          getHtml: (currentThis) => {
            return `<ui-field no-label style="min-width: ${currentThis.params.column.actualWidth > 200 ? currentThis.params.column.actualWidth : 200}px;"> 
                <ui-select value.one-time="params.data.quantityTypeId & validate" on-change.call="params.change(value, params)" allow-clear="true">
                  <option repeat.for="quantity of params.quantityTypeList" value.bind="quantity.id">
                    \${quantity.type.auto}
                  </option>
                </ui-select>
              </ui-field>`;
          },
          quantityTypeList: this.quantityTypeList,
          change: async (value, params) => {
            params.value = value;
            this.gridOptions?.api?.stopEditing();
            this.gridOptions?.api?.setFocusedCell(params.node.rowIndex, params.colDef.field);
          }
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "totalQuantityDone",
        headerValueGetter: () => this.i18n.tr("metering.totalQuantityDone"),
        field: "totalQuantityDone",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        cellRendererParams: { maximumFractionDigits: this.nbDecimalForQuantityDisplay },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "totalQuantityPercentageDone",
        headerValueGetter: () => this.i18n.tr("metering.totalQuantityPercentageDone"),
        field: "totalQuantityPercentageDone",
        type: FieldType.Number,
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "percent", maximumFractionDigits: 2 }).format(data.data[data.colDef.field] / 100) : data.data[data.colDef.field]);
          }
          return;
        },
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "isFinalised",
        headerValueGetter: () => this.i18n.tr("metering.isFinalised"),
        field: "isFinalised",
        type: FieldType.Boolean,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            return `<div>
              <ui-checkbox if.bind=${currentThis.params?.data?.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data
              && currentThis.params?.data?.useInCompleteness} disabled.bind=${!currentThis.params?.data?.canBeFinalised}
                change.delegate="params.change(value, params)"
                checked.two-way="params.data.isFinalised" >
              </ui-checkbox>
            </div>`;
          },
          change: async (value, params) => {
            let result = await this.api.patch(parseInt(this.versionId), parseInt(params.data.id), params.column.colId, !params.data.isFinalised);
            // Manage api result: all modified entities
            if (result != null && result.length) {
              this.refreshVisibleNodes(result);
              if (this.cellValueChanged != null) this.cellValueChanged(params.column.colId);
            } else {
              toastr.error(this.i18n.tr("metering.errorSave"));
            }
          }
        },
        onCellClicked: () => {
          return false
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "deltaQuantityDone",
        headerValueGetter: () => this.i18n.tr("metering.deltaQuantityDone"),
        field: "deltaQuantityDone",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        cellRendererParams: { maximumFractionDigits: this.nbDecimalForQuantityDisplay },
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "deltaQuantityDonePercentage",
        headerValueGetter: () => this.i18n.tr("metering.deltaQuantityDonePercentage"),
        field: "deltaQuantityDonePercentage",
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "percent", maximumFractionDigits: 2 }).format(data.data[data.colDef.field] / 100) : data.data[data.colDef.field]);
          }
          return;
        },
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "absoluteQuantityDone",
        headerValueGetter: () => this.i18n.tr("metering.absoluteQuantityDone"),
        field: "absoluteQuantityDone",
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        cellRendererParams: { maximumFractionDigits: this.nbDecimalForQuantityDisplay },
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "absoluteQuantityDonePercentage",
        headerValueGetter: () => this.i18n.tr("metering.absoluteQuantityDonePercentage"),
        field: "absoluteQuantityDonePercentage",
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "percent", maximumFractionDigits: 2 }).format(data.data[data.colDef.field] / 100) : data.data[data.colDef.field]);
          }
          return;
        },
        type: FieldType.Number,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
      },

      {
        colId: "clientAdditionalWorkNum",
        headerValueGetter: () => this.i18n.tr("realizationmetering.clientAdditionalWorkNum"),
        field: "clientAdditionalWorkNum",
        type: FieldType.String,
        filterParams: {
          showEmptyFieldsCheckbox: false
        },
        suppressMenu: !this.canUseFilter,
        cellEditor: "agTextCellEditor",
        cellEditorPopup: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => false,//this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "clientAdditionalWorkStatusId",
        headerValueGetter: () => this.i18n.tr("realizationmetering.clientAdditionalWorkStatusId"),
        field: "clientAdditionalWorkStatusId",
        type: FieldType.Enumeration,
        valueGetter: (params) => params?.data?.clientAdditionalWorkStatus,
        valueSetter: (params) => ModuleListTreeData.customEnumsValueSetter(params, this.clientAdditionalWorkStatusList, ["denomination", "_translation"]),
        filterParams: {
          category: Constants.EnumerationTypes.ClientAdditionalWorkStatusType,
          customFieldPath: ['denomination', '_translation'],
          relationIdFieldName: "clientAdditionalWorkStatusId"
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.ClientAdditionalWorkStatusType,
          customFieldPath: ['denomination', '_translation'],
          relationIdFieldName: "clientAdditionalWorkStatusId"
        },
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => false,//this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
      {
        colId: "contractNum",
        headerValueGetter: () => this.i18n.tr("metering.contractNum"),
        field: "contractNum",
        type: FieldType.String,
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params),
        hide: true
      },
      {
        colId: "buildingCostsId",
        headerValueGetter: () => this.i18n.tr("metering.buildingCosts"),
        field: "buildingCostsId",
        valueGetter: (params) => params?.data?.buildingCostsTranslatedString,
        valueSetter: (params) => ModuleListTreeData.customEnumsValueSetter(params, this.buildingCostsList, ['denomination', '_translation']),
        type: FieldType.OneToMany,
        filterParams: {
          category: Constants.EnumerationTypes.BuildingCosts,
          customFieldPath: ['denomination', '_translation'],
          relationIdFieldName: "buildingCostsId"
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.BuildingCosts,
          customFieldPath: ['denomination', '_translation'],
          relationIdFieldName: "buildingCostsId"
        },
        suppressMenu: !this.canUseFilter,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
        cellClass: (params) => {
          let returnClass = ['metering-cell-select'];
          if (params.data.buildingCostsInherit) {
            returnClass.push("inherit-enum");
          }
          return returnClass;
        },

        cellEditor: "customHtmlRendererEditor",
        cellEditorParams: {
          isPopup: true,
          getHtml: (currentThis) => {
            return `<ui-field no-label style="min-width: ${currentThis.params.column.actualWidth > 200 ? currentThis.params.column.actualWidth : 200}px;"> 
              <ui-select value.one-time="params.data.buildingCostsId" on-change.call="params.change(value, params)">
                <option repeat.for="buildingCost of params.buildingCostsList" value.bind="buildingCost.id">
                  \${buildingCost.denomination.auto}
                </option>
              </ui-select>
            </ui-field>`;
          },
          buildingCostsList: this.buildingCostsList,
          change: async (value, params) => {
            params.value = value;
            this.gridOptions?.api?.stopEditing();
            this.gridOptions?.api?.setFocusedCell(params.node.rowIndex, params.colDef.field);
          }
        },
        colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
      },
    ];

    defs.push(...this.getEstimatedColDef());

    if (this.getGridMenuItems != null) {
      defs.unshift({
        colId: "menuIems",
        headerName: "",
        field: "",
        maxWidth: Constants.AGGridColumnsWidth.IsSelected,
        minWidth: Constants.AGGridColumnsWidth.IsSelected,
        suppressMovable: true,
        cellRendererParams: {
          canComment: false,
          i18n: this.i18n,
          gridOptions: this.gridOptions,
          router: this.router,
          menuItems: (params: ICellRendererParams) => this.getGridMenuItems != null ? this.getGridMenuItems(params) : [],
        },
        cellRenderer: "customButtonRenderer",
        suppressColumnsToolPanel: true,
        resizable: false,
        filter: false,
        pinned: "left",
        lockPosition: 'left'
      });
    }

    defs = defs.filter(def => {
      let colVis = this.columnVisible.find(c => c.colId == def.colId);
      if (colVis?.visibility) {
        if (typeof colVis.overideHeaderValueGetter == 'function') {
          def.headerValueGetter = colVis.overideHeaderValueGetter;
        }
        if (def.headerValueGetter != null) {
          def.headerName = def.headerTooltip = (<any>def).headerValueGetter();
          def.sortable = false;
        }
        return true;
      }
      return false;
    });

    return defs;
  }
  //#endregion

  public getEstimatedColDef(): ColDef[] {
    return [
      this.getEstimatedBuyingUnitPriceColDef(),
      this.getEstimatedQuantityColDef(),
      this.getEstimatedTotalBuyingPriceColDef(),
      this.getEstimatedCommentColDef()
    ]
  }
  public getEstimatedBuyingUnitPriceColDef(): ColDef {
    return {
      colId: "estimatedBuyingUnitPrice",
      headerValueGetter: () => this.i18n.tr("metering.estimatedBuyingUnitPrice"),
      field: "estimatedBuyingUnitPrice",
      type: FieldType.Number,
      suppressMenu: !this.canUseFilter,
      showRowGroup: this.i18n.tr("metering.estimation"),
      editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
      valueFormatter: (data) => {
        if (data.data?.[data.colDef.field] != null) {
          return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
        }
        return;
      },
      cellEditorParams: { fractionDigits: Constants.PrecisionParameter.MaxNbDecimalForPriceDisplay },
      colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
    }
  }
  public getEstimatedQuantityColDef(): ColDef {
    return {
      colId: "estimatedQuantity",
      headerValueGetter: () => this.i18n.tr("metering.estimatedQuantity"),
      field: "estimatedQuantity",
      type: FieldType.Number,
      suppressMenu: !this.canUseFilter,
      showRowGroup: this.i18n.tr("metering.estimation"),
      editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
      cellEditorParams: { fractionDigits: Constants.PrecisionParameter.MaxNbDecimalForQuantityDisplay },
      cellRendererParams: { maximumFractionDigits: this.nbDecimalForQuantityDisplay },
      valueFormatter: (data) => {
        if (data.data?.[data.colDef.field] != null) {
          return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: this.nbDecimalForQuantityDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
        }
        return;
      },
      colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
    }
  }
  public getEstimatedTotalBuyingPriceColDef(): ColDef {
    return {
      colId: "estimatedTotalBuyingPrice",
      headerValueGetter: () => this.i18n.tr("metering.estimatedTotalBuyingPrice"),
      field: "estimatedTotalBuyingPrice",
      type: FieldType.Number,
      suppressMenu: !this.canUseFilter,
      showRowGroup: this.i18n.tr("metering.estimation"),
      cellClass: (params) => {
        let returnClass = ["currencyFormat"];
        return returnClass;
      },
      valueGetter: (data) => {
        if (this.hideTotalChapter && data.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter) return;
        return data.data?.[data.colDef.field];
      },
      valueFormatter: (data) => {
        if (this.hideTotalChapter && data.data.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Chapter) return;

        if (data.data?.[data.colDef.field] != null) {
          return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
        }
        return;
      },
      cellEditorParams: {
        fractionDigits: Constants.PrecisionParameter.MaxNbDecimalForPriceDisplay
      },
      editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
      colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
    }
  }
  public getEstimatedCommentColDef(): ColDef {
    return {
      colId: "estimatedComment",
      headerValueGetter: () => this.i18n.tr("metering.estimatedComment"),
      field: "estimatedComment",
      type: FieldType.String,
      filterParams: {
        showEmptyFieldsCheckbox: false
      },
      suppressMenu: !this.canUseFilter,
      cellEditor: "agLargeTextCellEditor",
      cellEditorPopup: false,
      cellRenderer: 'customHtmlRendererEditor',
      cellRendererParams: {
        getHtml: (currentThis) => {
          return this.highlightSearchTextValueConverter.toView(currentThis.params.data[currentThis.params.colDef.colId], this.quickFilter);
        }
      },
      showRowGroup: this.i18n.tr("metering.estimation"),
      editable: (params) => this.internCanEditCell({ line: params.data, colField: params.colDef.field }),
      colSpan: (params: ColSpanParams) => this.getColSpanOfColDef(params)
    }
  }

}

interface MeteringSetting {
  route: {
    route: string,
    fragment: string,
    queryString: string,
  },
  focusCell: MeteringFocusCell,
  meteringGlobalSetting: {
    flattenList: boolean,
    screenExpand: boolean,
    showDisplayHidden: boolean,
    condensed: boolean,
    refToDisplay: RefToDisplay
  },
  rowOpen: Array<{ id: number, level: number }>
}

export interface MeteringFocusCell {
  rowId: number | null;
  colId: string;
}
