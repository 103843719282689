import { Router } from 'aurelia-router';
import { autoinject, BindingEngine, computedFrom } from "aurelia-framework";
import { CustomLogger, EditingModeEnum, EntityDetailViewModelBase, EnumerationTypeService, ServiceBase, Various } from "digiwall-lib";
import * as Constants from '../constants';
import { Merlin } from 'generated';
import { TimeConverter } from 'utils/time-converter';
import { ValidationRules } from 'aurelia-validation';

@autoinject
export class WorkingTimeDetail extends EntityDetailViewModelBase<Merlin.Web.Model.WorkingTime> {
  public ressourceName: string = Constants.EntityTypeNames.WorkingTime;
  staffService: ServiceBase<Merlin.Web.Model.Staff>;
  workingTimeStatusService: EnumerationTypeService;

  public startTime: string;
  public endTime: string;
  projectService: ServiceBase<Merlin.Web.Model.Project>;
  itemActivityCodeService: ServiceBase<Merlin.Web.Model.EnumerationTypeMerlin>;
  private disableProjectSelection: boolean = false;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private timeConverter: TimeConverter) {
    super(router, logger);
    this.staffService = new ServiceBase<Merlin.Web.Model.Staff>(Constants.EntityTypeNames.Staff);
    this.workingTimeStatusService = new EnumerationTypeService(Constants.EnumerationTypes.WorkingTimeStatus);
    this.projectService = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);
    this.itemActivityCodeService = new ServiceBase<Merlin.Web.Model.EnumerationTypeMerlin>(Constants.EntityTypeNames.EnumerationTypeMerlin);
    this.itemActivityCodeService.gridDataSource.queryParameters = { category: Constants.EnumerationTypes.ActivityCode, onlyActifs: true };
    super.initialize(new ServiceBase<Merlin.Web.Model.WorkingTime>(Constants.EntityTypeNames.WorkingTime));
  }

  @computedFrom('editingMode')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("workingtime.addWorkingTime");
    }
    else {
      return "";
    }
  }

  public async activate(params: any) {
    let id = params.param1;
    await super.activate(params);
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      this.entity.workingTimeStatusId = Constants.WorkingTimeStatusId.Valided;
      this.entity.workingTimeStatus = await this.workingTimeStatusService.getEntityById(Constants.WorkingTimeStatusId.Valided);
      this.entity.date = new Date();
      if (params.callback != null) params.callback(this.entity);
      if (params.projectId) {
        this.entity.projectId = params.projectId
        this.disableProjectSelection = true;
      }
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id, 'workingTimeStatus', 'person', 'project', 'activity');
      this.startTime = this.entity.startDateTime ? `${this.entity.startDateTime.getHours()}:${this.entity.startDateTime.getMinutes()}` : null;
      this.endTime = this.entity.endDateTime ? `${this.entity.endDateTime.getHours()}:${this.entity.endDateTime.getMinutes()}` : null;
      this.controller.addObject(this.entity);
    }
    ValidationRules
      .ensure('workingCode')
      .required()
      .on(this.entity);
  }

  public attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this.entity, "personId").subscribe(async (newValue, oldValue) => {
        if (newValue != null && newValue != oldValue) {
          let person = await this.staffService.getEntityById(this.entity.personId, 'primaryStaffFunction');
          this.entity.hourlyCost = person.primaryStaffFunction.hourlyCost;
        }
      }),
      this.bindingEngine.propertyObserver(this, "startTime").subscribe((newValue, oldValue) => {
        if (newValue != null && this.endTime != null && newValue != oldValue) {
          this.setNbHoursFromTimeFields();
        }
      }),
      this.bindingEngine.propertyObserver(this, "endTime").subscribe((newValue, oldValue) => {
        if (newValue != null && this.startTime != null && newValue != oldValue) {
          this.setNbHoursFromTimeFields();
        }
      }),
    );
  }
  private setNbHoursFromTimeFields() {
    if (this.startTime != null && this.startTime != "") {
      let { hour, minute } = this.timeConverter.getHoursAndMinuteFromString(this.startTime);
      this.entity.startDateTime = new Date(1970, 1, 1, hour, minute, 0);
    }
    else {
      this.entity.startDateTime = null
    }
    if (this.endTime != null && this.endTime != "") {
      let { hour, minute } = this.timeConverter.getHoursAndMinuteFromString(this.endTime);
      this.entity.endDateTime = new Date(1970, 1, 1, hour, minute, 0);
    }
    else {
      this.entity.endDateTime = null
    }

    if (this.startTime != undefined && this.startTime != "" && this.endTime != undefined && this.endTime != "") {
      let { duration } = this.timeConverter.getDuration(this.startTime, this.endTime);
      if (duration != null) {
        this.entity.numberOfWorkingHours = duration;
      }
    }
    else {
      this.entity.numberOfWorkingHours = null;
    }
  }

}
