import { DialogController } from 'aurelia-dialog';
import { Merlin } from 'generated';
import { Router } from 'aurelia-router';
import { EditingModeEnum, ServiceBase, CustomLogger, EntityDetailViewModelBase, Various, IMenuGroup, validateEnumerationType } from 'digiwall-lib';
import { autoinject, observable, BindingEngine, computedFrom } from 'aurelia-framework';
import * as Constants from '../../constants';
import { DataFormat } from 'select2';
import { FilterQueryOp, Predicate } from 'breeze-client';

@autoinject
export class StaffFunctionEnmDetail extends EntityDetailViewModelBase<Merlin.Web.Model.StaffFunctionEnum> {
  private enumerationTypeColorService: ServiceBase<Merlin.Web.Model.EnumerationTypeColor>;

  @observable
  private enumerationTypeColor: DataFormat;
  private menuItems: Array<IMenuGroup>;
  private errors: string;
  paramType: string;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private dialogController: DialogController) {
    super(router, logger);
    this.enumerationTypeColorService = new ServiceBase<Merlin.Web.Model.EnumerationTypeColor>(Constants.EntityTypeNames.EnumerationTypeColor);
    super.initialize(new ServiceBase<Merlin.Web.Model.StaffFunctionEnum>(Constants.EntityTypeNames.StaffFunctionEnum));
  }

  public async activate(params): Promise<void> {
    let id = params.param1;
    await super.activate(params);
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      this.entity.active = true;
      this.entity.default = false;
      this.entity.backgroundColor = "#F2F4FE";
      this.entity.textColor = "#3C54E4";
      this.entity.category = Constants.EnumerationTypes.UserFunctions;
      if (params.callback != null) {
        params.callback(this.entity);
      }
      else {
      }
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id);
      this.controller.addObject(this.entity);
    }
    this.setParamType();
    this.setMenuItems();

    validateEnumerationType(this.entity);
  }
  private setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-trash",
            handler: () => {
              this.delete()
            },
            hidden: () => {
              return this.entity.uniqueCode != null
            }
          }
        ]
      },
    ];
  }

  public setParamType() {
    this.paramType = this.entity.category.toString();
  }

  @computedFrom('editingMode', 'entity.denomination._translation')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("enumerationtype.addStaffFunctionEnum");
    } else {
      if (this.entity)
        return (this.entity.denomination as any)._translation;
    }
  }
  public ressourceName = Constants.EntityTypeNames.StaffFunctionEnum;

  public async save() {
    await super.save(false, false, true);
    if (this.dialogController?.controller != null)
      this.dialogController.ok(this.entity);
  }

  public attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this, 'enumerationTypeColor').subscribe(async (newValue, oldValue) => {
        if (newValue != null) {
          let enumerationTypeColor = await this.enumerationTypeColorService.firstEntity(new Predicate("id", FilterQueryOp.Equals, newValue));
          if (enumerationTypeColor != null) {
            this.entity.textColor = enumerationTypeColor.textColor;
            this.entity.backgroundColor = enumerationTypeColor.backgroundColor;
          }
        }
      })
    )
  }
  public formatEnumerationTypeColor(event: any) {
    if (event.data != null) {
      return `<div style="width: fit-content; margin-right: 10px; border-radius: 10px; color: ${event.data.textColor}; background-color: ${event.data.backgroundColor};"><span style="margin: 0 10px; font-size: 15px;">${event.data.description._translation}<span></div>`;
    }
  }
}
