import { Router } from 'aurelia-router';
import { autoinject } from "aurelia-framework";
import { CustomLogger, FieldType, ListViewModelBase, ServiceBase } from "digiwall-lib";
import * as Constants from '../constants';
import { Merlin } from 'generated';
import { GridHelperMerlin } from 'resources/elements/grid-helper';
import { ColDef } from 'ag-grid-community';

@autoinject
export class WorkingTimeList extends ListViewModelBase<Merlin.Web.Model.WorkingTime> {
  public ressourceName: string = Constants.EntityTypeNames.Staff;
  public buttonLabel: string = this.i18n.tr("workingtime.addWorkingTime");
  public pathDetail: string;
  private projectId: number;


  constructor(router: Router, logger: CustomLogger) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.WorkingTime>(Constants.EntityTypeNames.WorkingTime));
  }
  public getDetailsUrl(_self: any, entity: Merlin.Web.Model.WorkingTime) {
    if (entity?.id) {
      return (this.pathDetail + entity.id);
    }
    return null;
  }
  override async activate(params: any) {

    await super.activate(params);
    if (params.projectId != null) {
      this.projectId = params.projectId;
    }
    if (this.projectId != null) {
      this.service.gridDataSource.queryParameters = { projectId: this.projectId };
    }
  }
  public initializeGridOptions() {
    this.pathDetail = this.router.baseUrl + '/working-times/';

    super.initializeGridOptions(false, false);
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this),
      {
        headerName: this.i18n.tr("workingtime.personId"),
        field: "person.fullName",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("workingtime.projectId"),
        field: "project.projectName",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("workingtime.activityId"),
        field: "activity.fullName._translation",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("workingtime.workingCode"),
        field: "workingCode",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("workingtime.date"),
        field: "date",
        type: FieldType.Date
      },
      {
        headerName: this.i18n.tr("workingtime.startTime"),
        field: "startDateTime",
        filterParams: {
          dateFormat: "HH:mm"
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: "HH:mm"
        },
        type: FieldType.Date
      },
      {
        headerName: this.i18n.tr("workingtime.endTime"),
        field: "endDateTime",
        filterParams: {
          dateFormat: "HH:mm"
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: "HH:mm"
        },
        type: FieldType.Date
      },
      {
        headerName: this.i18n.tr("workingtime.numberOfWorkingHours"),
        field: "numberOfWorkingHours",
        type: FieldType.Number,
        filter: null,
        sortable: false
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];

    return defs;
  }
}

