import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from "aurelia-framework";
import * as Constants from '../constants';
import { ChartGanttSerieDTO, ChartPieSerie, MonthlyExecutionChartData } from 'resources/charts/chart-mappers';
import { ProgressStatementStatusDTO } from 'projects/realization/realization-summary';

@autoinject
export class DashboardService {
  constructor(private httpClient: HttpClient) {

  }

  async getPreparationOverviewTask(projectId: number): Promise<Array<ChartPieSerie>> {
    let result = await this.httpClient.get(Constants.Application.DashboardController.PreparationOverviewTask.format(projectId.toString()));
    if (result.ok) {
      return await result.json();
    }
  }

  async getProgressStatementOverview(projectId: number): Promise<ProgressStatementStatusDTO> {
    let result = await this.httpClient.get(Constants.Application.DashboardController.ProgressStatementOverview.format(projectId.toString()));
    if (result.ok) {
      return await result.json();
    }
  }
  async getPreparationGanttTask(projectId: number): Promise<Array<ChartGanttSerieDTO>> {
    let result = await this.httpClient.get(Constants.Application.DashboardController.GanttTask.format(projectId.toString()));
    if (result.ok) {
      return await result.json();
    }
  }
  async getProgressStatementByMonth(projectId: number, startDate: Date, endDate: Date): Promise<ExecutionBarChartAndTotals> {
    let result = await this.httpClient.get(Constants.Application.DashboardController.GetProgressStatementByMonth.format(projectId?.toString() ?? "null", startDate.toJSON(), endDate.toJSON()));
    if (result.ok) {
      return await result.json();
    }
  }
  async getProgressStatementForProject(projectId: number): Promise<ExecutionForProject> {
    let result = await this.httpClient.get(Constants.Application.DashboardController.GetProgressStatementForProject.format(projectId?.toString() ?? "null"));
    if (result.ok) {
      return await result.json();
    }
  }
}
export interface ExecutionForProject {
  summaryByMonth: SummaryByActivityOrMonth[];
  summaryByActivity: SummaryByActivityOrMonth[];
  executionChartDTO: ExecutionChartDTO;
}
export interface SummaryByActivityOrMonth {
  label: string;
  expenses: number;
  incomeValidate: number;
  amountBilled: number;
  realMargin: number;
}

export interface ExecutionBarChartAndTotals {
  totalIncome: number;
  totalExpense: number;
  executionChartDTO: ExecutionChartDTO;
}

export interface ExecutionChartDTO {
  outgoingsAmounts: ChartColumnSerie[];
  progressStatesInitialAmounts: ChartColumnSerie[];
  progressStatesPayOutAmounts: ChartColumnSerie[];
  billedAmounts: ChartColumnSerie[];
  realMargins: ChartColumnSerie[];
  forecastMargin: number;

}

export interface ChartColumnSerie {
  date: Date | string;
  value: number;
}

export interface ExecutionBarChartAndTotals {
  totalIncome: number;
  totalExpense: number;
  executionChartDTO: ExecutionChartDTO;
}

export interface ExecutionChartDTO {
  outgoingsAmounts: ChartColumnSerie[];
  progressStatesInitialAmounts: ChartColumnSerie[];
  progressStatesPayOutAmounts: ChartColumnSerie[];
  billedAmounts: ChartColumnSerie[];
  realMargins: ChartColumnSerie[];
  forecastMargin: number;

}

export interface ChartColumnSerie {
  date: Date | string;
  value: number;
}


