import { Router, RouterConfiguration } from 'aurelia-router';
import { autoinject, PLATFORM } from "aurelia-framework";
import { ExtendedRouteConfig, UIInternal, Various } from 'digiwall-lib';
import * as Constants from '../constants';
import { I18N } from 'aurelia-i18n';

@autoinject
export class Parameters {

  router: Router;

  menuIsCollapse: boolean = false;

  routeByGroupName: Array<RouteByGroupName> = [];

  parametersRoute: Array<ExtendedRouteConfig> = [
    //#region  hidden route
    /* --- Reference Data --- */
    { route: 'sub-group', name: "sub-group", moduleId: 'parameters/sub-groups/sub-groups', title: "subgroup.subgroup", nav: false, settings: { resource: Constants.EntityTypeNames.MerlinUser } },
    { route: 'users/:param1', moduleId: 'users/user-detail', title: "common:user.addUser", nav: false, settings: { resource: Constants.EntityTypeNames.MerlinUser, parentName: Constants.Routes.RoutesList.UserList }, href: "/users/-100" },
    { route: 'enumeration-types/staff-function/:param2/:param1', moduleId: 'enumeration-types/staff-function-enum/staff-function-enum-detail', title: "enumerationtype.userFunction", nav: false, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, parentName: Constants.Routes.RoutesList.StaffFunction }, href: `enumeration-types/staff-function/${Constants.EnumerationTypes.UserFunctions}/-100` },
    { route: 'enumeration-types/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.addEnumerationType", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.EnumerationTypeList }/*, href: `/enumeration-types/civility/${Constants.EnumerationTypes.Civility}/-100`*/ },
    { route: 'enumeration-types/civility/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.addEnumerationType", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.CivilityList }, href: `/enumeration-types/civility/${Constants.EnumerationTypes.Civility}/-100` },
    { route: 'enumeration-types/company-type/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.addEnumerationType", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.CompanyTypeList }, href: `/enumeration-types/company-type/${Constants.EnumerationTypes.CompanyType}/-100` },
    { route: 'enumeration-types/unit-type/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.unitType", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.UnitTypeList }, href: `/enumeration-types/unit-type/${Constants.EnumerationTypes.UnitType}/-100` },
    { route: 'units/:param1', moduleId: 'units/unit-detail', title: "unit.unit", nav: false, settings: { parentName: Constants.Routes.RoutesList.UnitsList }, href: "/units/-100" },
    { route: 'enumeration-types/price-offer-status/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.priceOfferStatus", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.PriceOfferStatusList }, href: `/enumeration-types/price-offer-status/${Constants.EnumerationTypes.PriceOfferStatus}/-100` },
    { route: 'enumeration-types/third-party-type/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.addThirdPartyType", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.ThirdPartyTypeList }, href: `/enumeration-types/third-party-type/${Constants.EnumerationTypes.ThirdPartyType}/-100` },
    { route: 'enumeration-types/partner-type/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.addPartnerType", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.PartnerTypeList }, href: `/enumeration-types/partner-type/${Constants.EnumerationTypes.PartnerType}/-100` },
    { route: 'enumeration-types/third-party-tag/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.addThirdPartyTag", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.ThirdPartyTagList }, href: `/enumeration-types/third-party-tag/${Constants.EnumerationTypes.ThirdPartyTag}/-100` },
    { route: 'enumeration-types/company-function/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.addCompanyFunction", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.CompanyFunctionList }, href: `/enumeration-types/company-function/${Constants.EnumerationTypes.CompanyFunction}/-100` },
    { route: 'enumeration-types/item-delivery-type/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.addItemDeliveryType", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.ItemDeliveryTypeList }, href: `/enumeration-types/item-delivery-type/${Constants.EnumerationTypes.ItemDeliveryType}/-100` },
    { route: 'enumeration-types/item-feature/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.addItemFeature", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.ItemFeatureList }, href: `/enumeration-types/item-feature/${Constants.EnumerationTypes.ItemFeature}/-100` },
    { route: 'enumeration-types/container-type/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.addContainerType", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.ContainerTypeList }, href: `/enumeration-types/container-type/${Constants.EnumerationTypes.ContainerType}/-100` },
    { route: 'enumeration-types/unit-price-source-type/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.unitPriceSourceType", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.UnitPriceSourceTypeList }, href: `/enumeration-types/unit-price-source-type/${Constants.EnumerationTypes.UnitPriceSourceType}/-100` },
    { route: 'enumeration-types/price-offer-line-type/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.priceOfferLineType", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.PriceOfferLineTypeList }, href: `/enumeration-types/price-offer-line-type/${Constants.EnumerationTypes.PriceOfferLineType}/-100` },
    { route: 'enumeration-types/item-delivery-type/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-merlins/enumeration-type-merlin-detail', title: "enumerationtype.itemDeliveryType", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.ItemDeliveryTypeList }, href: `/enumeration-types/item-delivery-type/${Constants.EnumerationTypes.ItemDeliveryType}/-100` },
    { route: 'enumeration-types/activity-code/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-merlins/enumeration-type-merlin-detail', title: "enumerationtype.activityCode", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.ActivityCodeList }, href: `/enumeration-types/activity-code/${Constants.EnumerationTypes.ActivityCode}/-100` },
    { route: 'enumeration-types/task-type/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.taskType", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.TaskTypeList }, href: `/enumeration-types/task-type/${Constants.EnumerationTypes.TaskType}/-100` },
    { route: 'enumeration-types/task-criticity-level/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.taskCriticityLevel", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.TaskCriticityLevelList }, href: `/enumeration-types/task-criticity-level/${Constants.EnumerationTypes.TaskCriticityLevel}/-100` },
    { route: 'enumeration-types/task-tag/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.taskTag", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.TaskTagList }, href: `/enumeration-types/task-tag/${Constants.EnumerationTypes.TaskType}/-100` },
    { route: 'enumeration-types/building-costs/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.buildingCosts", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.BuildingCostsList }, href: `/enumeration-types/building-costs/${Constants.EnumerationTypes.BuildingCosts}/-100` },
    { route: 'enumeration-types/price-offer-line-tag/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.priceOfferLineTag", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.PriceOfferLineTagList }, href: `/enumeration-types/price-offer-line-tag/${Constants.EnumerationTypes.PriceOfferLineTag}/-100` },
    { route: 'enumeration-types/project-partner-role/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.projectPartnerRole", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.ProjectPartnerRole }, href: `/enumeration-types/project-partner-role/${Constants.EnumerationTypes.ProjectPartnerRole}/-100` },
    { route: 'enumeration-types/project-external-role/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.projectExternalRole", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.ProjectExternalRole }, href: `/enumeration-types/project-external-role/${Constants.EnumerationTypes.ProjectExternalRole}/-100` },
    { route: 'enumeration-types/project-internal-role/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.projectInternalRole", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.ProjectInternalRole }, href: `/enumeration-types/project-internal-role/${Constants.EnumerationTypes.ProjectInternalRole}/-100` },
    { route: 'enumeration-types/project-cancellation-reason/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.projectCancellationReason", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.ProjectCancellationReason }, href: `/enumeration-types/project-cancellation-reason/${Constants.EnumerationTypes.ProjectCancellationReason}/-100` },
    { route: 'enumeration-types/project-status/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.projectStatus", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.ProjectStatus }, href: `/enumeration-types/project-status/${Constants.EnumerationTypes.ProjectStatus}/-100` },
    { route: 'enumeration-types/project-type/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.projectType", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.ProjectType }, href: `/enumeration-types/project-status/${Constants.EnumerationTypes.ProjectType}/-100` },

    { route: 'enumeration-types/price-offer-mobilization-delay/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.priceOfferMobilizationDelay", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.PriceOfferMobilizationDelayList }, href: `/enumeration-types/price-offer-mobilization-delay/${Constants.EnumerationTypes.PriceOfferMobilizationDelay}/-100` },
    { route: 'enumeration-types/price-offer-payment-delay/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.priceOfferPaymentDelay", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.PriceOfferPaymentDelayList }, href: `/enumeration-types/price-offer-payment-delay/${Constants.EnumerationTypes.PriceOfferPaymentDelay}/-100` },
    { route: 'enumeration-types/price-offer-deposit/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.priceOfferDeposit", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.PriceOfferDepositList }, href: `/enumeration-types/price-offer-deposit/${Constants.EnumerationTypes.PriceOfferDeposit}/-100` },
    { route: 'enumeration-types/price-offer-price-revision-technic/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.priceOfferPriceRevisionTechnic", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.PriceOfferPriceRevisionTechnicList }, href: `/enumeration-types/price-offer-price-revision-tchnic/${Constants.EnumerationTypes.PriceOfferPriceRevisionTechnic}/-100` },

    { route: 'vat-levels/:param1', moduleId: 'vat-levels/vat-level-detail', title: "vatlevel.vatlevel", nav: false, settings: { parentName: Constants.Routes.RoutesList.VatLevelList }, href: "/vat-levels/-100" },
    // { route: 'enumeration-types/email-type/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.emailType", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, action: Actions.Create, parentName: Constants.Routes.RoutesList.EmailTypeList }, href: `/enumeration-types/email-type/${Constants.EnumerationTypes.EmailType}/-100` },
    { route: 'email-type/:param1', moduleId: 'email-types/email-type-detail', title: "enumerationtype.emailType", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.EmailTypeList }, href: `/email-type/-100` },
    { route: 'file-type/:param1', moduleId: 'file-types/file-type-detail', title: "enumerationtype.fileType", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.FileTypeList }, href: `/file-type/-100` },
    { route: 'enumeration-types/document-tag/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.documentTag", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.DocumentTag }, href: `/enumeration-types/document-tag/${Constants.EnumerationTypes.DocumentTag}/-100` },
    //{ route: 'enumeration-types/document-type/:param2/:param1', moduleId: 'enumeration-types/enumeration-type-detail', title: "enumerationtype.documentType", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.DocumentType }, href: `/enumeration-types/document-tag/${Constants.EnumerationTypes.DocumentType}/-100` },
    { route: 'market-types/:param1', moduleId: 'market-types/market-type-detail', title: "markettype.addMarketTypeAlias", nav: false, settings: { resource: Constants.EntityTypeNames.MarketType, parentName: Constants.Routes.RoutesList.MarketTypeList }, href: "/market-types/-100" },
    { route: 'working-site-preparation-template/:param1', moduleId: 'working-site-preparation-templates/working-site-preparation-template-detail', title: "workingsitepreparationtemplate.workingsitepreparationtemplate", nav: false, settings: { parentName: Constants.Routes.RoutesList.WorkingSitePreparationTemplateList }, href: "working-site-preparation-template/-100" },

    /* --- Template --- */
    { route: 'email-templates/:param1', moduleId: 'email-templates/email-template-detail', title: "emailtemplatemerlin.addEmailTemplate", nav: false, settings: { resource: Constants.EntityTypeNames.EmailTemplateMerlin, parentName: Constants.Routes.RoutesList.EmailTemplateMerlinList }, href: "/email-templates/-100" },
    { route: 'checkpoint-templates/:param1', moduleId: 'checkpoint-templates/checkpoint-template-detail', title: "checkpointtemplate.addCheckpointTemplate", nav: false, settings: { resource: Constants.EntityTypeNames.CheckPointTemplate, parentName: Constants.Routes.RoutesList.CheckPointTemplateList }, href: "/checkpoint-templates/-100" },

    { route: 'black-listed-domain-matchings/:param1', name: Constants.EntityTypeNames.BlackListedDomainMatching, moduleId: 'black-listed-domain-matchings/black-listed-domain-matching-detail', title: "blacklisteddomainmatching.blacklisteddomainmatching", nav: false, settings: { resource: Constants.EntityTypeNames.EnumerationType, parentName: Constants.Routes.RoutesList.BlackListedDomainMatchingList, }, href: "black-listed-domain-matchings/-100" },
    //#endregion

    //#region Menu routes
    //#region merlin
    {
      route: "",
      redirect: 'my-entreprise/all'
    },
    { route: 'my-entreprise/all', name: Constants.Routes.RoutesList.MyEntreprise, moduleId: 'application-parameters/my-entreprise', title: "applicationparameters.myEntreprise", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Merlin, resource: Constants.EntityTypeNames.ApplicationParameter, icon: "fas fa-user" }, description: "parameters.fakeDescription" },
    { route: 'users/all', name: Constants.Routes.RoutesList.UserList, moduleId: 'users/user-list', title: "common:user.users", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Merlin, resource: Constants.EntityTypeNames.MerlinUser, icon: "fas fa-user" }, description: "parameters.fakeDescription" },
    { route: 'application-parameters/all', name: Constants.Routes.RoutesList.ApplicationParametersList, moduleId: 'application-parameters/application-parameters-detail', title: "enumerationtype.applicationparameters", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Merlin, resource: Constants.EntityTypeNames.ApplicationParameter, icon: "fas fa-user" }, description: "parameters.fakeDescription" },
    { route: 'file-type/all', name: Constants.Routes.RoutesList.FileTypeList, moduleId: 'file-types/file-type-list', title: "enumerationtype.fileType", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.File, resource: Constants.EntityTypeNames.FileType, icon: "fas fa-user" }, href: `file-type/all`, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/document-tag/:param2/all', name: Constants.Routes.RoutesList.DocumentTag, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.documentTag", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Merlin, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/document-tag/${Constants.EnumerationTypes.DocumentTag}/all`, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/all', name: Constants.Routes.RoutesList.EnumerationTypeList, moduleId: 'enumeration-types/all-enumeration-type-list', title: "enumerationtype.enumerationtypes", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Merlin, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/all`, description: "parameters.fakeDescription" },
    { route: 'cost-centers', name: Constants.Routes.RoutesList.CostCenters, moduleId: 'cost-centers/cost-center', title: "costcenter.costcenter", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Merlin, resource: Constants.EntityTypeNames.ApplicationParameter, icon: "fas fa-user" }, href: `cost-centers`, description: "parameters.fakeDescription" },


    //{ route: 'enumeration-types/document-type/:param2/all', name: Constants.Routes.RoutesList.DocumentTag, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.documentType", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Merlin, resource: Constants.EntityTypeNames.EnumerationType }, href: `/enumeration-types/document-type/${Constants.EnumerationTypes.DocumentType}/all` },
    { route: 'enumeration-types/staff-function/all', name: Constants.Routes.RoutesList.StaffFunction, moduleId: 'enumeration-types/staff-function-enum/staff-function-enum-list', title: "enumerationtype.userFunction", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Merlin, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/staff-function/all`, description: "parameters.fakeDescription" },
    { route: 'content-templates/all', name: Constants.Routes.RoutesList.ContentTemplate, moduleId: 'content-templates/content-template-list', title: "contenttemplate.contenttemplate", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Merlin, resource: Constants.EntityTypeNames.ContentTemplate, icon: "fas fa-user" }, description: "parameters.fakeDescription" },
    { route: 'content-templates/:param1', moduleId: 'content-templates/content-template-detail', title: "contenttemplate.addContentTemplate", nav: false, settings: { resource: Constants.EntityTypeNames.ContentTemplate, parentName: Constants.Routes.RoutesList.ContentTemplate }, href: "specific-conditions/-100", description: "parameters.fakeDescription" },

    // { route: 'enumeration-types/email-type/:param2/all', name: Constants.Routes.RoutesList.EmailTypeList, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.emailType", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Merlin, resource: Constants.EntityTypeNames.EnumerationType, action: Actions.Create }, href: `/enumeration-types/email-type/${Constants.EnumerationTypes.EmailType}/all` },
    //#endregion

    //#region tiers
    { route: 'enumeration-types/civility/:param2/all', name: Constants.Routes.RoutesList.CivilityList, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.civilityId", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.ThirdParty, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-database" }, href: `enumeration-types/civility/${Constants.EnumerationTypes.Civility}/all`, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/company-type/:param2/all', name: Constants.Routes.RoutesList.CompanyTypeList, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.companyTypeId", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.ThirdParty, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-database" }, href: `enumeration-types/company-type/${Constants.EnumerationTypes.CompanyType}/all`, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/third-party-type/:param2/all', name: Constants.Routes.RoutesList.ThirdPartyTypeList, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.thirdPartyType", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.ThirdParty, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/third-party-type/${Constants.EnumerationTypes.ThirdPartyType}/all`, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/company-function/:param2/all', name: Constants.Routes.RoutesList.CompanyFunctionList, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.companyFunction", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.ThirdParty, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/company-function/${Constants.EnumerationTypes.CompanyFunction}/all`, description: "parameters.fakeDescription" },
    //#endregion

    //#region Article
    { route: 'enumeration-types/unit-type/:param2/all', name: Constants.Routes.RoutesList.UnitTypeList, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.unitType", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Items, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/unit-type/${Constants.EnumerationTypes.UnitType}/all`, description: "parameters.fakeDescription" },
    { route: 'units/all', name: Constants.Routes.RoutesList.UnitsList, moduleId: 'units/unit-list', title: "unit.units", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Items, resource: Constants.EntityTypeNames.Unit } },
    { route: 'enumeration-types/container-type/:param2/all', name: Constants.Routes.RoutesList.ContainerTypeList, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.containerType", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Items, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/container-type/${Constants.EnumerationTypes.ContainerType}/all`, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/unit-price-source-type/:param2/all', name: Constants.Routes.RoutesList.UnitPriceSourceTypeList, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.unitPriceSourceType", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Items, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/unit-price-source-type/${Constants.EnumerationTypes.UnitPriceSourceType}/all`, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/item-delivery-type/:param2/all', name: Constants.Routes.RoutesList.ItemDeliveryTypeList, moduleId: 'enumeration-types/enumeration-type-merlins/enumeration-type-merlin-list', title: "enumerationtype.itemDeliveryType", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Items, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/item-delivery-type/${Constants.EnumerationTypes.ItemDeliveryType}/all`, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/item-feature/:param2/all', name: Constants.Routes.RoutesList.ItemFeatureList, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.itemFeature", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Items, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/item-feature/${Constants.EnumerationTypes.ItemFeature}/all`, description: "parameters.fakeDescription" },
    //#endregion

    //#region Projet
    { route: 'enumeration-types/project-partner-role/:param2/all', name: Constants.Routes.RoutesList.ProjectPartnerRole, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.projectPartnerRole", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Project, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/project-partner-role/${Constants.EnumerationTypes.ProjectPartnerRole}/all`, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/project-external-role/:param2/all', name: Constants.Routes.RoutesList.ProjectExternalRole, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.projectExternalRole", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Project, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/project-external-role/${Constants.EnumerationTypes.ProjectExternalRole}/all`, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/project-internal-role/:param2/all', name: Constants.Routes.RoutesList.ProjectInternalRole, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.projectInternalRole", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Project, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/project-internal-role/${Constants.EnumerationTypes.ProjectInternalRole}/all`, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/project-cancellation-reason/:param2/all', name: Constants.Routes.RoutesList.ProjectCancellationReason, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.projectCancellationReason", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Project, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/project-cancellation-reason/${Constants.EnumerationTypes.ProjectCancellationReason}/all`, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/project-status/:param2/all', name: Constants.Routes.RoutesList.ProjectStatus, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.projectStatus", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Project, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/project-status/${Constants.EnumerationTypes.ProjectStatus}/all`, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/project-type/:param2/all', name: Constants.Routes.RoutesList.ProjectType, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.projectType", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Project, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/project-type/${Constants.EnumerationTypes.ProjectType}/all`, description: "parameters.fakeDescription" },
    { route: 'default-internal-project-users/all', name: Constants.Routes.RoutesList.DefaultInternalProjectUser, moduleId: 'default-internal-project-users/default-internal-project-users', title: "applicationparameters.defaultinternalprojectusers", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Project, resource: Constants.EntityTypeNames.DefaultInternalProjectUser, icon: "fas fa-user" }, href: `default-internal-project-users/all`, description: "parameters.fakeDescription" },

    //#endregion

    //#region devis
    { route: 'enumeration-types/price-offer-status/:param2/all', name: Constants.Routes.RoutesList.PriceOfferStatusList, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.priceOfferStatus", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.PriceOffer, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/price-offer-status/${Constants.EnumerationTypes.PriceOfferStatus}/all`, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/price-offer-line-type/:param2/all', name: Constants.Routes.RoutesList.PriceOfferLineTypeList, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.priceOfferLineType", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.PriceOffer, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/price-offer-line-type/${Constants.EnumerationTypes.PriceOfferLineType}/all`, description: "parameters.fakeDescription" },
    { route: 'market-types/all', name: Constants.Routes.RoutesList.MarketTypeList, moduleId: 'market-types/market-type-list', title: "markettype.markettype", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.PriceOffer, resource: Constants.EntityTypeNames.MarketType, icon: "fas fa-user" }, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/activity-code/:param2/all', name: Constants.Routes.RoutesList.ActivityCodeList, moduleId: 'enumeration-types/enumeration-type-merlins/enumeration-type-merlin-list', title: "enumerationtype.activityCode", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.PriceOffer, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/activity-code/${Constants.EnumerationTypes.ActivityCode}/all`, description: "parameters.fakeDescription" },
    { route: 'vat-levels/all', name: Constants.Routes.RoutesList.VatLevelList, moduleId: 'vat-levels/vat-level-list', title: "vatlevel.vatlevel", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.PriceOffer, resource: Constants.EntityTypeNames.VATLevel, icon: "fas fa-user" }, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/building-costs/:param2/all', name: Constants.Routes.RoutesList.BuildingCostsList, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.buildingCosts", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.PriceOffer, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/building-costs/${Constants.EnumerationTypes.BuildingCosts}/all`, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/price-offer-line-tag/:param2/all', name: Constants.Routes.RoutesList.PriceOfferLineTagList, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.priceOfferLineTag", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.PriceOffer, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/price-offer-line-tag/${Constants.EnumerationTypes.PriceOfferLineTag}/all`, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/price-offer-mobilization-delay/:param2/all', name: Constants.Routes.RoutesList.PriceOfferMobilizationDelayList, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.priceOfferMobilizationDelay", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.PriceOffer, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/price-offer-mobilization-delay/${Constants.EnumerationTypes.PriceOfferMobilizationDelay}/all`, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/price-offer-payment-delay/:param2/all', name: Constants.Routes.RoutesList.PriceOfferPaymentDelayList, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.priceOfferPaymentDelay", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.PriceOffer, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/price-offer-payment-delay/${Constants.EnumerationTypes.PriceOfferPaymentDelay}/all`, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/price-offer-deposit/:param2/all', name: Constants.Routes.RoutesList.PriceOfferDepositList, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.priceOfferDeposit", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.PriceOffer, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/price-offer-deposit/${Constants.EnumerationTypes.PriceOfferDeposit}/all`, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/price-offer-price-revision-technic/:param2/all', name: Constants.Routes.RoutesList.PriceOfferPriceRevisionTechnicList, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.priceOfferPriceRevisionTechnic", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.PriceOffer, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/price-offer-price-revision-technic/${Constants.EnumerationTypes.PriceOfferPriceRevisionTechnic}/all`, description: "parameters.fakeDescription" },

    //#endregion

    //#region purchases (achats)
    { route: 'purchases/particular-conditions', name: Constants.Routes.RoutesList.ParticularConditionsList, moduleId: 'purchases/particular-conditions/particular-condition-list', title: "purchases.particularConditions.particularConditions", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Purchases, resource: Constants.EntityTypeNames.ParticularCondition, icon: "fas fa-user" }, description: "parameters.fakeDescription" },
    //#endregion

    //#region tâches
    { route: 'enumeration-types/task-type/:param2/all', name: Constants.Routes.RoutesList.TaskTypeList, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.taskType", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Task, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/task-type/${Constants.EnumerationTypes.TaskType}/all`, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/task-criticity-level/:param2/all', name: Constants.Routes.RoutesList.TaskCriticityLevelList, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.taskCriticityLevel", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Task, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/task-criticity-level/${Constants.EnumerationTypes.TaskCriticityLevel}/all`, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/task-tag/:param2/all', name: Constants.Routes.RoutesList.TaskTagList, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.taskTag", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Task, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/task-tag/${Constants.EnumerationTypes.TaskTag}/all`, description: "parameters.fakeDescription" },
    { route: 'working-site-preparation-template/all', name: Constants.Routes.RoutesList.WorkingSitePreparationTemplateList, moduleId: 'working-site-preparation-templates/working-site-preparation-template-list', title: "workingsitepreparationtemplate.workingsitepreparationtemplates", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Task, resource: Constants.EntityTypeNames.WorkingSitePreparationTemplate, icon: "fas fa-user" }, description: "parameters.fakeDescription" },

    //#endregion

    //#region email
    { route: 'email-type/all', name: Constants.Routes.RoutesList.EmailTypeList, moduleId: 'email-types/email-type-list', title: "enumerationtype.emailType", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Email, resource: Constants.EntityTypeNames.EmailType, icon: "fas fa-user" }, href: `email-type/all`, description: "parameters.fakeDescription" },
    { route: 'black-listed-domain-matchings/all', name: Constants.Routes.RoutesList.BlackListedDomainMatchingList, moduleId: 'black-listed-domain-matchings/black-listed-domain-matching-list', title: "blacklisteddomainmatching.blacklisteddomainmatchings", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Email, resource: Constants.EntityTypeNames.EnumerationType, icon: "digi-broadcast-line" }, description: "parameters.fakeDescription" },
    //{ route: 'email-templates/all', name: Constants.Routes.RoutesList.EmailTemplateMerlinList, moduleId: 'email-templates/email-template-list', title: "emailtemplatemerlin.emailtemplatemerlin", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Email, resource: Constants.EntityTypeNames.EmailTemplateMerlin, icon: "fa fa-at" }, description: "parameters.fakeDescription" },
    //#endregion

    //#region other
    { route: 'enumeration-types/partner-type/:param2/all', name: Constants.Routes.RoutesList.PartnerTypeList, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.partnerType", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.ThirdParty, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/partner-type/${Constants.EnumerationTypes.PartnerType}/all`, description: "parameters.fakeDescription" },
    { route: 'enumeration-types/third-party-tag/:param2/all', name: Constants.Routes.RoutesList.ThirdPartyTagList, moduleId: 'enumeration-types/enumeration-type-list', title: "enumerationtype.thirdPartyTag", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.ThirdParty, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, href: `enumeration-types/third-party-tag/${Constants.EnumerationTypes.ThirdPartyTag}/all`, description: "parameters.fakeDescription" },
    { route: 'checkpoint-templates/all', name: Constants.Routes.RoutesList.CheckPointTemplateList, moduleId: 'checkpoint-templates/checkpoint-template-list', title: "checkpointtemplate.checkpointtemplate", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Other, resource: Constants.EntityTypeNames.CheckPointTemplate, icon: "fa fa-at" }, description: "parameters.fakeDescription" },

    {
      route: `localities/:param1`, name: 'locality-detail', moduleId: 'localities/locality-detail',
      title: "common:locality.locality", nav: false, settings: { resource: Constants.EntityTypeNames.Locality }, href: `localities/${Various.NewId}`
    },
    {
      route: `localities/all`, name: 'localities', moduleId: 'localities/locality-list',
      title: "common:locality.localities", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Other, resource: Constants.EntityTypeNames.Locality }, href: `localities/all`
    },
    {
      route: `countries/:param1`, name: 'country-detail', moduleId: 'countries/country-detail',
      title: "common:country.country", nav: false, settings: { resource: Constants.EntityTypeNames.Country }, href: `countries/${Various.NewId}`
    },
    {
      route: `countries/all`, name: 'countries', moduleId: 'countries/country-list',
      title: "common:country.countries", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Other, resource: Constants.EntityTypeNames.Country }, href: `countries/all`
    },

    { route: 'metadata', name: Constants.Routes.RoutesDetail.Metadata, moduleId: 'metadata/metadata', title: "metadata.metadata", nav: true, settings: { group: Constants.Routes.MenuGroupTitle.EnumerationTypes, subGroup: Constants.Routes.MenuSubGroup.Other, resource: Constants.EntityTypeNames.EnumerationType, icon: "fas fa-user" }, description: "parameters.fakeDescription" },

    //#endregion
    //#endregion

  ];

  selectedGroup: RouteByGroupName;

  constructor(private i18n: I18N) {
  }

  attached() {
    UIInternal.subscribe('router:navigation:complete', () => {
      if (this.selectedGroup) {
        this.selectedGroup.focus = false;
        this.selectedGroup = null;
      }
      this.routeByGroupName.forEach(group => group.childrenSelected = false);
      let group = this.routeByGroupName.filter(x => x.routes.filter(r => r.route == this.router.currentInstruction.config.route).length != 0);
      if (group.length != 0) {
        group[0].childrenSelected = true;
        group[0].viewChild = true;
      }
    });
  }

  get baseUrl() {
    return this.router.parent.currentInstruction?.fragment;
  };
  configureRouter(config: RouterConfiguration, router: Router, params, routeConfig, currentInstruction): void {
    this.router = router;

    config.map(this.parametersRoute);

    this.routeByGroupName.splice(0);
    this.parametersRoute.filter(x => x.nav).forEach(route => {
      let group = this.routeByGroupName.filter(x => x.groupName == route.settings.subGroup);
      if (group.length == 0) {
        group.push({
          groupName: route.settings.subGroup,
          focus: false,
          viewChild: false,
          childrenSelected: false,
          routes: []
        } as RouteByGroupName)
        this.routeByGroupName.push(...group);
      }

      group[0].routes.push(route);
    })

  }

  switchViewChild(group: RouteByGroupName) {
    group.viewChild = !group.viewChild;
  }

  showGroup(group: RouteByGroupName) {
    if (this.selectedGroup) {
      this.selectedGroup.focus = false;
    }
    this.selectedGroup = group;
    this.selectedGroup.focus = true;
  }

  collapseMenu() {
    this.menuIsCollapse = !this.menuIsCollapse
  }
}

export class RouteByGroupName {
  groupName: string;
  viewChild: boolean;
  routes: Array<ExtendedRouteConfig>;
  focus: boolean;
  childrenSelected: boolean;
}
