import { EditDocument } from './edit-document';
import { EnumerationType, EnumerationTypeService, Extensions, FilePreviewer, PictureHelper, UIInternal, Box, ValueSelected, CustomColDef } from 'digiwall-lib';
import { ColDef } from 'ag-grid-community';
import { Merlin } from 'generated';
import { CustomLogger, FieldType, ListViewModelBase, ServiceBase } from 'digiwall-lib';
import { autoinject, bindable, BindingEngine, computedFrom, Container, customElement, TaskQueue } from 'aurelia-framework';
import * as Constants from '../constants';
import { Router } from 'aurelia-router';
import { GridHelperMerlin } from 'resources/elements/grid-helper';
import CustomButtonRenderer from 'resources/renderer/custom-button-renderer';
import { DocumentService } from './document-service';
import { DialogService, DialogController } from 'aurelia-dialog';
import { AddDocument } from './add-document';
import { EditDialogAction } from 'resources/utilities/edit-dialog-actions';
import { debounce } from 'debounce';
import { I18N } from 'aurelia-i18n';
import { Parameters } from 'parameters/parameters';
import { HttpClient } from 'aurelia-fetch-client';
import { isNullOrUndefined } from 'util';

@autoinject
export class DocumentList extends ListViewModelBase<Merlin.Web.Model.DocumentView> {
  ressourceName = this.i18n.tr('document.documents')
  projectPhaseService: EnumerationTypeService;
  languageService: EnumerationTypeService;
  fileTypeService: EnumerationTypeService;
  documentStatusService: EnumerationTypeService;
  thirdPartyService: ServiceBase<Merlin.Web.Model.ThirdParty>;

  projectId: number;
  workQuotationVersionId: number;
  projectPhaseId: number;

  projectPhase: Array<EnumerationType>;
  allPhaseSelected: boolean = true;

  private calculatedHeight: string;
  priceOfferVersionId: number;

  isDialogView: boolean = false

  languages: Array<EnumerationType>;
  fileTypes: Array<EnumerationType>;
  documentStatus: Array<EnumerationType>;
  thirdParties: Array<Merlin.Web.Model.ThirdParty>;

  @computedFrom("isDialogView")
  get ribbonHeaderText(): string {
    if (!this.isDialogView)
      return this.i18n.tr('buyingindex.documents');
    else
      return this.i18n.tr('document.selectDocument');
  }

  constructor(router: Router, logger: CustomLogger, private bindinEngine: BindingEngine, private element: Element, private taskQueue: TaskQueue, private pictureHelper: PictureHelper, private dialogService: DialogService, private dialogController: DialogController, private httpClient: HttpClient) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.DocumentView>(Constants.EntityTypeNames.DocumentView));
    this.projectPhaseService = new EnumerationTypeService(Constants.EnumerationTypes.ProjectPhase);
    this.languageService = new EnumerationTypeService(Constants.EnumerationTypes.Language);
    this.fileTypeService = new EnumerationTypeService(Constants.EnumerationTypes.FileType);
    this.documentStatusService = new EnumerationTypeService(Constants.EnumerationTypes.DocumentStatus);
    this.thirdPartyService = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
    //this.service.gridDataSource.expands = ['projectPhase'];
  }

  public override filterChanged(newValue, oldValue) {
    let queryParameters = this.service.gridDataSource.queryParameters;
    queryParameters.searchString = newValue
    this.service.gridDataSource.queryParameters = queryParameters;
    this.gridOptions.api.onFilterChanged();
  }

  public onCellClicked(entity: Merlin.Web.Model.DocumentView) {
    if (entity?.id) {
      let documentService = new DocumentService(
        Container.instance.get(HttpClient),
        Container.instance.get(I18N),
        Container.instance.get(Box),
        Container.instance.get(CustomLogger),
      )
      documentService.projectId = this.projectId;
      documentService.workQuotationVersionId = this.workQuotationVersionId;
      this.box.showEditDialog(EditDocument, entity.id, this.i18n.tr('document.editDocument'),
        {
          canSave: false,
          size: "md",
          model: { id: entity.id, service: documentService, isFromProject: true },
          actions: [
            ...EditDialogAction.GetBaseAction(this.i18n)
          ]
        }).whenClosed(result => {
          if (!result.wasCancelled) {
            this.gridOptions.api.onFilterChanged();
          }
        });
    }
    return false;
  }

  async attached() {
    await super.attached();
    window.addEventListener('resize', debounce(() => {
      if (this.gridOptions.adjustColumns === true && this.gridOptions.api != null) {
        this.gridOptions.api.sizeColumnsToFit();
      }
      this.calculateBodyHeight();
    }));

    this.disposables.push(UIInternal.subscribe(CUSTOM_PAGING_ATTACHED, () => this.calculateBodyHeight()));
    this.calculateBodyHeight();

    this.taskQueue.queueTask(() => {
      this.setQueryParameter();
    })
  }

  async activate(params) {
    await super.activate(params);
    this.projectId = parseInt(params.projectId);
    this.workQuotationVersionId = parseInt(params.workQuotationVersionId);
    this.priceOfferVersionId = parseInt(params.priceOfferVersionId);
    this.projectPhaseId = parseInt(params.projectPhaseId);

    this.projectPhase = await this.projectPhaseService.getAll();
    this.languages = await this.languageService.getAll();
    this.fileTypes = await this.fileTypeService.getAll();
    this.documentStatus = await this.documentStatusService.getAll();
    this.thirdParties = await this.thirdPartyService.getAllEntities();

    if (this.projectId) {
      this.service.gridDataSource.queryParameters = { projectId: this.projectId }
      this.projectPhase.forEach(x => {
        (x as any).isSelected = false;
        this.disposables.push(
          this.bindinEngine.propertyObserver(x, "isSelected").subscribe((newValue, oldValue) => {
            if (newValue != oldValue && newValue) {
              this.setQueryParameter();
            }
          })
        );
      });
      if (this.workQuotationVersionId) {
        this.projectPhaseId = Constants.ProjectPhaseId.PriceOffer;
      }
      if (this.priceOfferVersionId) {
        this.projectPhaseId = Constants.ProjectPhaseId.PriceOffer;
      }
      if (this.projectPhaseId) {
        let defaultSelected = this.projectPhase.find(x => x.id == this.projectPhaseId);
        (defaultSelected as any).isSelected = true;
        this.allPhaseSelected = false;
      }
    }
    if (params.isDialogView) {
      this.isDialogView = params.isDialogView;
    }
  }


  public calculateBodyHeight(tries: number = 0) {
    let docElement = this.element.getElementsByClassName("document-list")[0];
    if (docElement?.parentElement == null) return;
    let availableHeight = docElement.parentElement.clientHeight;

    // Ignore division by 0
    if (availableHeight <= 0) {
      this.calculatedHeight = 'height: 100px';
      return;
    }

    // Remove padding top/bottom
    let parentStyle = getComputedStyle(docElement.parentElement);
    availableHeight -= this.getPadding(parentStyle, 'top') + this.getPadding(parentStyle, 'bottom')

    let headerHeight = (docElement.getElementsByTagName('ag-grid-aurelia')[0] as HTMLElement).offsetTop;
    let footerHeight = (docElement.getElementsByClassName("grid-footer-bar")[0] as HTMLElement).offsetHeight;

    if (footerHeight <= 0 && tries < 5) {
      // If height is 0, footer might not be fully attached
      // Can only be the case if all of the following are true:
      // 1. There is only a single page
      // 2. showSelectedElemCount is False
      // 3. showRowsCount is False
      // 4. left-paging / right-paging slots are empty OR not rendered (custom content)
      this.taskQueue.queueTask(() => this.calculateBodyHeight(++tries));
    } else {
      this.calculatedHeight = `height: ${availableHeight - headerHeight - footerHeight}px`;
    }
  }

  private getPadding(styles: CSSStyleDeclaration, side: 'top' | 'bottom'): number {
    return parseFloat(styles.getPropertyValue(`padding-${side}`).replace(/[^0-9.]/g, ''));
  }

  public getDataGridColumns() {
    let thirdPartiesService: ServiceBase<Merlin.Web.Model.ThirdParty> = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
    this.gridOptions.components.customButtonRenderer = CustomButtonRenderer;
    let documentTagService: EnumerationTypeService = new EnumerationTypeService(Constants.EnumerationTypes.DocumentTag);
    let columns: Array<CustomColDef> = [
      {
        headerName: "",
        field: "",
        maxWidth: Constants.AGGridColumnsWidth.IsSelected,
        minWidth: Constants.AGGridColumnsWidth.IsSelected,
        cellRendererParams: {
          i18n: this.i18n,
          gridOptions: (this as any).gridOptions,
          router: this.router,
          service: this.service,
          pathDetail: (this as any).pathDetail,
          listViewModel: this,
          menuItems: this.getMenuItems,
        },
        cellRenderer: "customButtonRenderer",
        suppressColumnsToolPanel: true,
        sortable: false,
        resizable: false,
        filter: false,
        suppressMenu: true,
        suppressMovable: true,
        pinned: "left",
      },
      {
        headerName: this.i18n.tr("document.documentName"),
        field: "documentName",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("document.name"),
        field: "name",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("document.isAnnex"),
        field: "toUseAsAnnex",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            // return `<div>
            //   <ui-toggle if.bind=${currentThis.params?.data?.toUseAsAnnex != null} checked.bind="params.data.toUseAsAnnex" 
            //   change.delegate="params.change(params, $event)" t="[label-on]document.isAnnex"></ui-toggle>
            // </div>`;


            return `<div>
              <ui-checkbox if.bind=${currentThis.params?.data?.toUseAsAnnex != null}
                change.delegate="params.change(params, $event)"
                checked.two-way="params.data.toUseAsAnnex" >
              </ui-checkbox>
            </div>`;
          },
          change: async (params, event: CustomEvent) => {
            params.data.toUseAsAnnex = !params.data.toUseAsAnnex;
            this.httpClient.patch(Constants.Application.DocumentController.UpdateToUseAsAnnex.format(params.data.id, params.data.toUseAsAnnex));
            //this.service.saveEntity(params.data, true);
          }
        },
        onCellClicked: () => {
          return false
        },
        cellClass: "document-annex"
      },
      {
        headerName: this.i18n.tr("document.isVisibleFromLancelot"),
        field: "isVisibleFromLancelot",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            return `<div>
              <ui-checkbox if.bind=${currentThis.params?.data?.isVisibleFromLancelot != null}
                change.delegate="params.change(params, $event)"
                checked.two-way="params.data.isVisibleFromLancelot" >
              </ui-checkbox>
            </div>`;
          },
          change: async (params, event: CustomEvent) => {
            params.data.isVisibleFromLancelot = !params.data.isVisibleFromLancelot;
            this.httpClient.patch(Constants.Application.DocumentController.UpdateIsVisisbleFromLancelot.format(params.data.id, params.data.isVisibleFromLancelot));
          }
        },
        onCellClicked: () => {
          return false
        },
        cellClass: "document-visiblefromlancelot"
      },
      {
        headerName: this.i18n.tr("document.description"),
        field: "description",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("document.receptionDate"),
        field: "receptionDate",
        type: FieldType.Date,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("document.languageId"),
        field: "language.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.Language,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.Language,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("document.fileTypeId"),
        field: "fileType.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.FileType,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.FileType,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("document.documentTags"),
        field: "tags._translation",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("document.documentStatusId"),
        field: "documentStatus.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.DocumentStatus,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.DocumentStatus,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("document.projectPhaseId"),
        field: "projectPhase.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.ProjectPhase,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.ProjectPhase,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        hide: true,
        hideInParameter: true
      },
      {
        headerName: this.i18n.tr("document.sourceId"),
        field: "source.fullName",
        type: FieldType.OneToMany,
        filterParams: {
          service: thirdPartiesService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: thirdPartiesService
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      new GridHelperMerlin().selectedColDef(this),
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return columns;
  }

  private previewFile(file) {
    let fileExtension: string = this.pictureHelper.getFileExtension(file.url);
    if (Extensions.file.includes(fileExtension)) {
      this.dialogService.open({ viewModel: FilePreviewer, model: { fileExtension: fileExtension, fileUrl: file.url }, lock: false });
    }
    else {
      this.pictureHelper.downloadFile(file);
    }
  }

  private getMenuItems(params) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.open"),
            icon: "digi-arrow-expand",
            handler: () => {
              let documentService = new DocumentService(
                Container.instance.get(HttpClient),
                Container.instance.get(I18N),
                Container.instance.get(Box),
                Container.instance.get(CustomLogger),
              )
              let box = Container.instance.get(Box);
              let i18n = Container.instance.get(I18N);

              documentService.projectId = this.projectId;
              documentService.workQuotationVersionId = this.workQuotationVersionId;

              box.showEditDialog(EditDocument, params.data.id, i18n.tr('document.editDocument'),
                {
                  canSave: false,
                  size: "md",
                  model: { id: params.data.id, service: documentService },
                  actions: [
                    ...EditDialogAction.GetBaseAction(i18n)
                  ],
                  overlayClass: "z-index-level-3",
                  containerClass: "z-index-level-3"
                }).whenClosed(result => {
                  if (!result.wasCancelled) {
                    this.gridOptions.api.onFilterChanged();
                  }
                });
            },
          }
        ]
      },
      {
        group: "2",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.preview"),
            icon: "digi-eye-line",
            disabled: () => {
              let pictureHelper = Container.instance.get(PictureHelper);
              let extension = pictureHelper.getFileExtension(params.data.url);
              return !Extensions.office.includes(extension) && !Extensions.other.includes(extension) && !Extensions.picture.includes(extension);
            },
            handler: () => {
              let pictureHelper = Container.instance.get(PictureHelper);
              pictureHelper.previewFile(params.data);
            },
          }
        ]
      },
      {
        group: "2",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.download"),
            icon: "digi-download-line",
            handler: () => {
              let pictureHelper = Container.instance.get(PictureHelper);
              pictureHelper.downloadFile(params.data);
            },
          }
        ]
      },
      {
        group: "3",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-delete-bin-6-line",
            handler: async () => {
              let documentService = new DocumentService(
                Container.instance.get(HttpClient),
                Container.instance.get(I18N),
                Container.instance.get(Box),
                Container.instance.get(CustomLogger),
              )
              documentService.projectId = (params as any).listViewModel.projectId;
              documentService.workQuotationVersionId = (params as any).listViewModel.workQuotationVersionId;
              if (!(params as any).listViewModel.allPhaseSelected) {
                await documentService.delete(params.data.id, ((params as any).listViewModel.projectPhase.find(x => (x as any).isSelected) as EnumerationType).id);
              }
              else {
                await documentService.delete(params.data.id);
              }
              // let result = await (params as any).service.deleteEntities([params.data], true);
              // if (result) {
              (params as any).gridOptions.api.forEachNode((rowNode) => {
                if (rowNode.data != null)
                  if (rowNode.data.isSelected == true)
                    rowNode.data.isSelected = false;
              });
              (params as any).gridOptions.api.refreshInfiniteCache();
              // }
            },
          }
        ]
      }
    ];
  }

  private async deleteFiles() {
    let isDeleted = false;
    if (this.selectedEntities.length > 0) {
      let documentService = new DocumentService(
        Container.instance.get(HttpClient),
        Container.instance.get(I18N),
        Container.instance.get(Box),
        Container.instance.get(CustomLogger),
      );
      let entitiesToDelete = this.selectedEntities;
      for (let index = 0; index < entitiesToDelete.length; index++) {
        const element = entitiesToDelete[index];
        isDeleted = await documentService.deleteDocument(element.id, this.projectPhase.find(x => (x as any).isSelected)?.id);
      }
      if (isDeleted) {
        this.refreshAfterDelete();
      }
    }
    return isDeleted;
  }

  private async selectPhase(phaseId: number) {
    this.changePhase(phaseId);
    await this.setQueryParameter();
  }

  private changePhase(phaseId: number) {
    this.projectPhase.filter(x => x.id != phaseId).forEach(x => (x as any).isSelected = false);
    if (phaseId != null) {
      (this.projectPhase.find(x => x.id == phaseId) as any).isSelected = true;
    }
    this.allPhaseSelected = phaseId == null;
  }

  private async setQueryParameter() {
    let projectPhaseId = this.projectPhase.find(x => (x as any).isSelected)?.id;

    let filter = this.gridOptions.api?.getFilterInstance('projectPhase.denomination._translation');
    if (filter != null) {
      if (((filter as any).filterValues as Array<ValueSelected>) == null || ((filter as any).filterValues as Array<ValueSelected>).length == 0) {
        await (filter as any).getValues();
      }

      let filterValues = ((filter as any).filterValues as Array<ValueSelected>);
      filterValues.forEach(x => x.selected = false);
      if (projectPhaseId != null) {
        filterValues.find(x => x.id == projectPhaseId).selected = true;
      }
    }
    this.gridOptions.api?.onFilterChanged();
  }

  public onFilterChanged(event) {
    let filters = this.gridOptions.api.getFilterModel();
    this.setPhaseFromFilter(filters)
  }

  setPhaseFromFilter(model) {
    let projectPhase = model["projectPhase.denomination._translation"];
    if (projectPhase != null) {
      let selectedPhaseId = projectPhase.filter.find(x => x.selected).id;
      this.changePhase(selectedPhaseId);
    }
    else {
      this.changePhase(null);
    }
  }


  async addDocument() {
    this.selectPhase(this.projectPhaseId);
    let documentService = new DocumentService(
      Container.instance.get(HttpClient),
      Container.instance.get(I18N),
      Container.instance.get(Box),
      Container.instance.get(CustomLogger),
    )
    documentService.projectId = this.projectId;
    documentService.workQuotationVersionId = this.workQuotationVersionId;
    documentService.projectPhaseId = this.projectPhaseId;
    documentService.lineId = null;
    let dialogService = Container.instance.get(DialogService);
    let defaultLanguageId: number;
    if (this.projectId != null) {
      let projectService = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);
      defaultLanguageId = (await projectService.getEntityById(documentService.projectId)).communicationLanguageId;
    }
    await dialogService.open({
      viewModel: AddDocument,
      model: {
        service: documentService,
        folderName: "project/" + this.projectId + "/version/" + this.workQuotationVersionId,
        defaultLanguageId: defaultLanguageId,
        documentModuleId: Constants.DocumentModuleId.Project
      },
      lock: true,
      keyboard: false,
      rejectOnCancel: true,
      position(dialogContainer, dialogOverlay?) {
        dialogContainer.classList.add("z-index-level-4");
        dialogOverlay.classList.add("z-index-level-4");
      },
    }).whenClosed(async result => {
      if (!result.wasCancelled) {
        this.gridOptions.api.onFilterChanged();
      }
    });
  }

  public async close() {
    await this.dialogController.close(false);
  }

  public async sendDocument() {
    let selectedDoc = this.selectedEntities;
    this.unselectAll();
    await this.dialogController.close(true, selectedDoc);
  }

  public afterSetAgGridView(agGridView: any) {
    this.usedView = agGridView
  }

}

const CUSTOM_PAGING_ATTACHED = "Custom-Paging-Attached";
