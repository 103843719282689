import { TaskDTO, TaskFileDTO } from './../model/module-task-model';
import { Merlin } from 'generated';
import { singleton, autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { HttpClient } from 'aurelia-fetch-client';
import * as Constants from '../../constants';
import { Box } from 'digiwall-lib';
import { JSONPatchOperation } from 'services/json-patch-operation';
import { JSONPatchOperationType } from 'services/json-patch-operation-type.enum';
import { FilterTask, TaskSettings } from './user-setting-service';

@singleton()
@autoinject()
export class TaskApiService {

  public projectId: number;
  public projectPhaseId: number

  constructor(private httpClient: HttpClient, private i18n: I18N, private box: Box) {

  }

  public async fetch(filterParams: any, quickFilter: string, taskSetting: TaskSettings): Promise<Array<TaskDTO>> {
    let requestParams: TaskRequestParams = {
      filterModel: filterParams,
      quickFilter: quickFilter ?? '',
      customTaskFilter: {
        filterTaskSelected: taskSetting?.filterTaskSelected ?? FilterTask.allTask,
        toDoSelected: taskSetting?.toDoSelected ?? false,
        inProcessSelected: taskSetting?.inProcessSelected ?? false,
        doneSelected: taskSetting?.doneSelected ?? false,
        cancelSelected: taskSetting?.cancelSelected ?? false,
        projectId: this.projectId,
        projectPhaseId: this.projectPhaseId
      } as CustomTaskFilter,

    }
    let result = await this.httpClient.post(Constants.Application.TaskController.GetAll, JSON.stringify(requestParams));
    if (result.ok) {
      let entities: Array<any> = await result.json();
      return entities;
    }
    else {
      return null;
    }
  }

  public async fetchTask(id: number): Promise<TaskDTO> {
    let result = await this.httpClient.get(Constants.Application.TaskController.GetTask.format(id.toString()));
    if (result.ok) {
      return await result.json();
    }
    else {
      return null;
    }
  }

  public async new(parentId: number | null): Promise<TaskDTO> {
    let result = await this.httpClient.get(parentId != null ? Constants.Application.TaskController.NewTaskParent.format(parentId.toString()) : Constants.Application.TaskController.NewTask);
    if (result.ok) {
      return await result.json();
    }
    else {
      return null;
    }
  }

  public async getChilren(parentId: number): Promise<Array<TaskDTO>> {
    let result = await this.httpClient.get(Constants.Application.TaskController.GetChildren.format(parentId.toString()));
    if (result.ok) {
      let entities: Array<any> = await result.json();
      return entities;
    }
    else {
      return null;
    }
  }

  public async patch(id: number, propertyName: string, propertyValue: any): Promise<number> {
    let patchResponse = await this.httpClient.patch(Constants.Application.TaskController.PatchTask.format(id.toString()), JSON.stringify(JSONPatchOperation.operateReplace(propertyName, propertyValue)));
    if (patchResponse.ok) {
      return await patchResponse.json();
    } else {
      let text = await patchResponse.text();
      this.box.showWarning(this.i18n.tr(text), this.i18n.tr('general.errorTitle'));
    }
  }

  //DELETE
  public async delete(id: number) {
    let requestUri = Constants.Application.TaskController.DeleteTask.format(id.toString());
    await this.httpClient.delete(requestUri);
  }

  public async getFiles(taskId: number): Promise<Array<TaskFileDTO>> {
    let result = await this.httpClient.get(Constants.Application.TaskController.GetFiles.format(taskId.toString()));
    if (result.ok) {
      let entities: Array<any> = await result.json();
      return entities;
    }
    else {
      return null;
    }
  }

  public async deleteFile(fileId: number): Promise<boolean> {
    let result = await this.httpClient.delete(Constants.Application.TaskController.DeleteFile.format(fileId.toString()));
    return result.ok;
  }

  public async getCounter(): Promise<TaskCounter> {
    let params = {
      projectId: this.projectId ?? null,
      projectPhaseId: this.projectPhaseId ?? null
    } as CounterParams;
    let result = await this.httpClient.post(Constants.Application.TaskController.GetCounter, JSON.stringify(params));
    if (result.ok) {
      let counter = await result.json();
      return counter;
    }
  }
}

export interface TaskRequestParams {
  filterModel: any;
  quickFilter: string;
  customTaskFilter: CustomTaskFilter;
}
export interface CustomTaskFilter {
  filterTaskSelected: number;
  toDoSelected: boolean;
  inProcessSelected: boolean;
  doneSelected: boolean;
  cancelSelected: boolean;
  projectId: number;
  projectPhaseId: number;
}

export interface TaskCounter {
  myTaskCounter: number;
  myTaskOpenCounter: number;
  myTaskInProcessCounter: number;

  assignTaskCounter: number;
  assignTaskOpenCounter: number;
  assignTaskInProcessCounter: number;

  allTaskCounter: number;
  allTaskOpenCounter: number;
  allTaskInProcessCounter: number;
}

export interface CounterParams {
  projectId: number | null;
  projectPhaseId: number | null;
}
