@import "./../../css/scss/variables";

.row.summary {
  height: 100%;
  .flex-container-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 450px 450px;
    grid-template-areas:
      "chart-1 chart-1 date-container task-container"
      "invoice-container invoice-container chart-2 chart-2";
    height: 100%;
    gap: 20px;
    .chart-1 {
      grid-area: chart-1;
      display: grid;
      grid-template-columns: 15% 85%;
      > .first-part {
        align-self: center;
        > div {
          text-align: center;
        }
        .label-primary {
          color: $primary;
        }
      }
    }
    .date-container {
      grid-area: date-container;
    }
    .task-container {
      grid-area: task-container;
    }
    div.rounded.invoice-container {
      grid-area: invoice-container;
    }
    .chart-2 {
      grid-area: chart-2;
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "left right";
      width: fit-content;
      > .left-info {
        grid-area: left;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 20px;
        min-width: 265px;
        .ps-client {
          ul {
            padding-left: revert;
            list-style-type: disc;
            margin: 0;
          }
        }
        .blue-text {
          color: $purple-mid;
        }
        .title {
          font-weight: 600;
        }
        .ps-sub-contractor,
        .additional-work {
          display: flex;
          justify-content: space-between;
          > div.content {
            display: flex;
            flex-direction: column;
            .info-text {
              padding-left: 15px;
            }
          }
          ui-button {
            width: 46px;
            height: 46px;
          }
        }
      }
      > .donut-chart {
        grid-area: right;
        min-width: 500px;
      }
    }
    .rounded {
      border: solid 1px #dddddd;
      border-radius: 24px;
      padding: 0.5rem;
    }
    ui-button[data-type="tool"] {
      padding: 5px;
      border-radius: 50%;
      &.btn-floating-project {
        position: absolute;
        bottom: 10px;
        right: 10px;
      }
    }
    div.rounded {
      border: solid 1px $gray-lighter;
      border-radius: $radius;
      padding: 1.5rem;
      position: relative;
      &.task-container,
      &.date-container,
      &.invoice-container {
        > div:first-child {
          display: flex;
          align-items: center;
          gap: 10px;
          ui-icon {
            color: $blue-mid;
            font-size: 18px;
          }
          span {
            font-size: 16px;
            font-weight: 600;
          }
        }
        > .date {
          display: flex;
          flex-direction: column;
          margin-top: 15px;
          span {
            &:first-child {
              color: $blue-mid;
            }
            font-size: 14px;
            font-weight: 400;
          }
        }
        > .task {
          display: flex;
          flex-direction: row;
          margin-top: 15px;
          justify-content: space-between;
          span {
            font-size: 14px;
            font-weight: 500;
            &:last-child {
              display: flex;
              width: 25px;
              height: 25px;
              border-radius: 100%;
              align-items: center;
              justify-content: center;
              color: $white;
            }
          }
          span.late {
            background-color: $red-warning;
          }
          span.urgent {
            background-color: $merlin-orange-dark;
          }
          span.soon {
            background-color: #ffc000;
          }
        }
        ui-table > .ui-table > tbody > tr > td:first-child > div,
        ui-table > .ui-table > tbody > tr > td:first-child a {
          width: unset;
        }
      }
    }
    span.placeholder {
      color: $gray-mid;
      font-style: italic;
    }
  }
}
