import { autoinject, observable } from 'aurelia-framework';
import { ActionDialogBoxInputParameters, EnumerationType, EnumerationTypeService, FileFlow, PictureHelper, ServiceBase } from "digiwall-lib";
import { DocumentData, IDocumentService } from "./document-service";
import * as Constants from '../constants';
import { DataFormat } from 'select2';
import { Merlin } from 'generated';
import { I18N } from 'aurelia-i18n';
import { Predicate, FilterQueryOp } from 'breeze-client';


@autoinject
export class EditDocument {
  entity: DocumentData;
  service: IDocumentService;
  typeService: EnumerationTypeService;
  languageService: EnumerationTypeService;
  tagService: EnumerationTypeService;
  documentStatusService: EnumerationTypeService;
  thirdPartyService: ServiceBase<Merlin.Web.Model.ThirdParty>

  tagSelected: Array<EnumerationType>;
  typeList: Array<EnumerationType>;
  @observable
  fileType: DataFormat;
  @observable
  language: DataFormat;
  @observable
  source: DataFormat;
  @observable
  documentStatus: DataFormat;

  folderName: string;

  isFromProject: boolean = false;

  constructor(private fileHelper: PictureHelper, private i18n: I18N) {
    this.typeService = new EnumerationTypeService(Constants.EnumerationTypes.FileType);
    this.languageService = new EnumerationTypeService(Constants.EnumerationTypes.Language);
    this.tagService = new EnumerationTypeService(Constants.EnumerationTypes.DocumentTag);
    this.documentStatusService = new EnumerationTypeService(Constants.EnumerationTypes.DocumentStatus);
    this.thirdPartyService = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
  }

  async activate(params) {
    this.service = params.service
    this.entity = await this.service.getDocument(params.id);

    let arrayFolder = this.entity.url.split("/");
    this.folderName = arrayFolder.splice(arrayFolder.length - 1).join('/');

    if (params.isFromProject) this.isFromProject = params.isFromProject;

    this.fileType = {
      id: this.entity.fileTypeId,
      text: (this.entity.fileType as any).denomination.auto
    }
    if (this.entity.languageId != null) {
      this.language = {
        id: this.entity.languageId,
        text: (await this.languageService.getEntityById(this.entity.languageId)).denomination._translation
      }
    }
    else {
      this.language = {
        id: "",
        text: ""
      }
    }

    if (this.entity.documentStatusId != null) {
      this.documentStatus = {
        id: this.entity.documentStatusId,
        text: (await this.documentStatusService.getEntityById(this.entity.documentStatusId)).denomination._translation
      }
    }
    else {
      this.documentStatus = {
        id: "",
        text: ""
      }
    }

    if (this.entity.sourceId != null) {
      this.source = {
        id: this.entity.sourceId,
        text: (await this.thirdPartyService.firstEntity(new Predicate('id', FilterQueryOp.Equals, this.entity.sourceId), [], { onlyActive: false }))?.fullName
      }
    }
    else {
      this.source = {
        id: "",
        text: ""
      }
    }


    this.tagSelected = [];
    for (let tagId of this.entity.documentTags) {
      let tag = await this.tagService.getEntityById(tagId)
      this.tagSelected.push(tag);
    }
  }

  fileTypeChanged(newValue, oldValue) {
    if (newValue != oldValue) {
      this.entity.fileTypeId = newValue.id;
    }
  }


  languageChanged(newValue, oldValue) {
    if (newValue != oldValue) {
      this.entity.languageId = newValue.id;
    }
  }

  private sourceChanged(newValue, oldValue) {
    if (newValue != oldValue) {
      this.entity.sourceId = newValue.id;
    }
  }

  private documentStatusChanged(newValue, oldValue) {
    if (newValue != oldValue) {
      this.entity.documentStatusId = newValue.id;
    }
  }

  async saveCurrentEntity(saveSilence: boolean) {
    await this.service.saveDocument(this.entity);
  }

  async cancel() {
  }

  private previewDocument() {
    this.fileHelper.previewFile(this.entity);
  }

  public async uploadFile(file: FileFlow) {
    //No action here, all action is in uploadedFile
  }

  public async uploadedFile(fileUploaded: FileFlow) {
    let file: FileFlow = (fileUploaded as any).fileUploaded
    this.entity.url = file.url;
    this.entity.name = file.name;
    this.entity.size = file.size;
  }

}
