import { ListViewModelBase, SelectAllHeaderComponent, CustomLogger, ServiceBase, EnumerationType, FieldType, Various } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Merlin } from "../generated";
import { ColDef, RowNode } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { GridHelperMerlin } from 'resources/elements/grid-helper';
import { EnumerationAllTypeList } from './all-enumeration-type-list';
import CustomButtonRenderer from 'resources/renderer/custom-button-renderer';

@autoinject
export class EnumerationTypeList extends ListViewModelBase<EnumerationType> {
  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<EnumerationType>(Constants.EntityTypeNames.EnumerationType));
  }
  public ressourceName: string = Constants.EntityTypeNames.EnumerationType;

  private paramType: string;
  private paramRoute: string;
  private isCloseEnum: boolean = false;
  public buttonLabel: string;

  public initializeGridOptions() {
    super.initializeGridOptions(false, false);
    this.gridOptions.components.customButtonRenderer = CustomButtonRenderer;
  }

  public async activate(params): Promise<void> {
    this.paramType = params.param2;
    this.service.gridDataSource.queryParameters = { category: this.paramType };
    switch (this.paramType) {
      case Constants.EnumerationTypes.Civility.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.Civility);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;

      case Constants.EnumerationTypes.CompanyType.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.CompanyType);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;

      case Constants.EnumerationTypes.UnitType.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.UnitType);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;

      case Constants.EnumerationTypes.PriceOfferStatus.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.PriceOfferStatus);
        this.isCloseEnum = true;
        break;

      case Constants.EnumerationTypes.ThirdPartyType.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.ThirdPartyType);
        this.isCloseEnum = true;
        break;

      case Constants.EnumerationTypes.PartnerType.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.PartnerType);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;

      case Constants.EnumerationTypes.ThirdPartyTag.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.ThirdPartyTag);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;

      case Constants.EnumerationTypes.CompanyFunction.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.CompanyFunction);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;

      case Constants.EnumerationTypes.ItemFeature.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.ItemFeature);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;

      case Constants.EnumerationTypes.ContainerType.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.ContainerType);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;

      case Constants.EnumerationTypes.UnitPriceSourceType.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.UnitPriceSourceType);
        this.isCloseEnum = true;
        break;

      case Constants.EnumerationTypes.PriceOfferLineType.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.PriceOfferLineType);
        this.isCloseEnum = true;
        break;

      case Constants.EnumerationTypes.ItemDeliveryType.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.ItemDeliveryType);
        this.isCloseEnum = true;
        break;

      case Constants.EnumerationTypes.ActivityCode.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.ActivityCode);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;

      case Constants.EnumerationTypes.EmailType.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.EmailType);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;

      case Constants.EnumerationTypes.FileType.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.FileType);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;

      case Constants.EnumerationTypes.TaskType.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.TaskType);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;

      case Constants.EnumerationTypes.TaskCriticityLevel.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.TaskCriticityLevel);
        this.isCloseEnum = true;
        break;

      case Constants.EnumerationTypes.PriceOfferLineTag.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.PriceOfferLineTag);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;

      case Constants.EnumerationTypes.TaskTag.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.TaskTag);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;
      case Constants.EnumerationTypes.DocumentTag.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.DocumentTag);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;
      case Constants.EnumerationTypes.UserFunctions.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.UserFunctions);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;

      case Constants.EnumerationTypes.DocumentType.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.DocumentType);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;

      case Constants.EnumerationTypes.ProjectExternalRole.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.ProjectExternalRole);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;

      case Constants.EnumerationTypes.ProjectPartnerRole.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.ProjectPartnerRole);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;

      case Constants.EnumerationTypes.ProjectInternalRole.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.ProjectInternalRole);
        this.isCloseEnum = true;
        break;

      case Constants.EnumerationTypes.ProjectCancellationReason.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.ProjectCancellationReason);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;

      case Constants.EnumerationTypes.ProjectStatus.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.ProjectStatus);
        this.isCloseEnum = true;
        break;

      case Constants.EnumerationTypes.ProjectType.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.ProjectType);
        this.isCloseEnum = true;
        break;

      case Constants.EnumerationTypes.PriceOfferMobilizationDelay.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.PriceOfferMobilizationDelay);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;

      case Constants.EnumerationTypes.PriceOfferPaymentDelay.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.PriceOfferPaymentDelay);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;

      case Constants.EnumerationTypes.PriceOfferDeposit.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.PriceOfferDeposit);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;

      case Constants.EnumerationTypes.PriceOfferPriceRevisionTechnic.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.PriceOfferPriceRevisionTechnic);
        this.buttonLabel = EnumerationAllTypeList.getAddEnumTraduction(this.paramType);
        break;

      case Constants.EnumerationTypes.BuildingCosts.toString():
        this.paramRoute = EnumerationAllTypeList.getRouteName(Constants.EnumerationTypes.BuildingCosts)
        this.isCloseEnum = true;
        break;

      default:
        break;
    }
  }

  public getDetailsUrl(self, entity: EnumerationType) {
    if (entity?.id) {
      return this.router.baseUrl + `enumeration-types/${this.paramRoute}/${this.paramType}/${entity.id}`;
    }
    return null;
  }

  public addEnumerationType() {
    this.router.navigate(this.router.baseUrl + `enumeration-types/${this.paramRoute}/${this.paramType}/-100`);
  }

  protected isSelectable(entity: EnumerationType): boolean {
    return !entity?.uniqueCode;
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this),
      {
        headerName: this.i18n.tr("enumerationtype.denomination") + this.i18n.tr("general.inBracketsFR"),
        field: "denomination.lang1",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            let tag: EnumerationType = currentThis.params.data
            if (tag == null) return ''
            return EnumerationAllTypeList.getTagHtml(tag.denomination.lang1, tag.textColor, tag.backgroundColor);
          }
        },
      },
      {
        headerName: this.i18n.tr("enumerationtype.denomination") + this.i18n.tr("general.inBracketsNL"),
        field: "denomination.lang2",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            let tag: EnumerationType = currentThis.params.data
            if (tag == null) return ''
            return EnumerationAllTypeList.getTagHtml(tag.denomination.lang2, tag.textColor, tag.backgroundColor);
          }
        },
      },
      {
        headerName: this.i18n.tr("enumerationtype.denomination") + this.i18n.tr("general.inBracketsEN"),
        field: "denomination.lang3",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            let tag: EnumerationType = currentThis.params.data
            if (tag == null) return ''
            return EnumerationAllTypeList.getTagHtml(tag.denomination.lang3, tag.textColor, tag.backgroundColor);
          }
        },
      },
      {
        headerName: this.i18n.tr("enumerationtype.active"),
        field: "active",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("enumerationtype.default"),
        field: "default",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }
}
