import { DialogController } from 'aurelia-dialog';
import { Merlin } from 'generated';
import { Router } from 'aurelia-router';
import { EditingModeEnum, ServiceBase, CustomLogger, EntityDetailViewModelBase, EnumerationType, Various, IMenuGroup, EnumerationTypeService, DialogBoxViewModel, ActionDialogBoxInputParameters } from 'digiwall-lib';
import { autoinject, observable, BindingEngine, computedFrom } from 'aurelia-framework';
import * as Constants from '../constants';
import { DataFormat } from 'select2';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { ValidationRules } from 'aurelia-validation';
import { EmailTemplateDetail } from 'email-templates/email-template-detail';
import { EditDialogAction } from 'resources/utilities/edit-dialog-actions';

@autoinject
export class EmailTypeDetail extends EntityDetailViewModelBase<Merlin.Web.Model.EmailType> {
  private enumerationTypeColorService: ServiceBase<Merlin.Web.Model.EnumerationTypeColor>;
  private emailTypeEnumService: EnumerationTypeService;
  private emailContextService: EnumerationTypeService;
  private emailTemplateService: ServiceBase<Merlin.Web.Model.EmailTemplateMerlin>;
  @observable
  private enumerationTypeColor: DataFormat;
  private menuItems: Array<IMenuGroup>;

  private emailTemplateList: Array<Merlin.Web.Model.EmailTemplateMerlin>

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private dialogController: DialogController) {
    super(router, logger);
    this.enumerationTypeColorService = new ServiceBase<Merlin.Web.Model.EnumerationTypeColor>(Constants.EntityTypeNames.EnumerationTypeColor);
    this.emailTypeEnumService = new EnumerationTypeService(Constants.EnumerationTypes.EmailType);
    this.emailContextService = new EnumerationTypeService(Constants.EnumerationTypes.EmailContext);
    this.emailTemplateService = new ServiceBase<Merlin.Web.Model.EmailTemplateMerlin>(Constants.EntityTypeNames.EmailTemplateMerlin);

    super.initialize(new ServiceBase<Merlin.Web.Model.EmailType>(Constants.EntityTypeNames.EmailType));
  }

  private paramType: string;

  public async activate(params): Promise<void> {
    let id = params.param1;
    await super.activate(params);
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      this.entity.type = await this.emailTypeEnumService.createEntity();
      this.entity.type.active = true;
      this.entity.type.default = false;
      this.entity.type.backgroundColor = "#F2F4FE";
      this.entity.type.textColor = "#3C54E4";
      if (params.callback != null) {
        params.callback(this.entity);
      }
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id, 'type', 'emailContext');
      this.controller.addObject(this.entity);
      this.emailTemplateList = [];
      await this.loadEmailTemplate();
    }
    this.setMenuItems();
  }

  private setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-trash",
            handler: () => {
              this.delete()
            }
          }
        ]
      },
    ];
  }

  private getEmailTemplateMenuItems(currentThis: any) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.edit"),
            icon: "digi-edit",
            handler: async () => {
              await this.openEmailTemplateDetail(currentThis.id)
            }
          },
          {
            label: this.i18n.tr("menu.remove"),
            icon: "digi-trash",
            handler: async () => {
              await this.emailTemplateService.deleteEntities([currentThis], true);
              await this.loadEmailTemplate();
            }
          }
        ]
      }
    ]
  }

  private async loadEmailTemplate() {
    let p1 = new Predicate('emailTypeId', FilterQueryOp.Equals, this.entity.id);
    this.emailTemplateList.splice(0);
    this.emailTemplateList.push(...await this.emailTemplateService.getEntities(p1, ['emailTemplate.language']));
  }

  private async addEmailTemplate() {
    await this.openEmailTemplateDetail(-100);
  }

  private async openEmailTemplateDetail(id: number) {
    await this.box.showEditDialog(
      EmailTemplateDetail,
      id,
      this.i18n.tr('emailtemplatemerlin.emailtemplatemerlin'), {
      canSave: false,
      actions: [
        ...EditDialogAction.GetBaseAction(this.i18n)
      ],
      size: 'lg',
      model: {
        emailTypeId: id == -100 ? this.entity.id : null
      }
    }).whenClosed(async (result) => {
      if (!result.wasCancelled) {
        await this.loadEmailTemplate();
      }
    });
  }

  @computedFrom('editingMode', 'entity.type.denomination._translation')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("emailtype.addEmailType");
    } else {
      if (this.entity)
        return (this.entity.type.denomination as any)._translation;
    }
  }

  public get ressourceName(): string {
    return Constants.EntityTypeNames.EnumerationType;
  }

  public async cancel() {
    this.enumerationTypeColor = { id: null, text: null };
    await super.cancel();
  }

  public async save() {
    await super.save();
    this.dialogController?.ok(this.entity);
  }

  public attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this, 'enumerationTypeColor').subscribe(async (newValue, oldValue) => {
        if (newValue != null) {
          let enumerationTypeColor = await this.enumerationTypeColorService.firstEntity(new Predicate("id", FilterQueryOp.Equals, newValue));
          this.entity.type.textColor = enumerationTypeColor.textColor;
          this.entity.type.backgroundColor = enumerationTypeColor.backgroundColor;
        }
      })
    )
  }

  public formatEnumerationTypeColor(event: any) {
    if (event.data != null) {
      return `<div style="width: fit-content; margin-right: 10px; border: 1px solid #6d7277; border-radius: 10px; color: ${event.data.textColor}; background-color: ${event.data.backgroundColor};"><span style="margin: 0 10px; font-size: 15px;">${event.data.description._translation}<span></div>`;
    }
  }
}
