import { ListViewModelBase, SelectAllHeaderComponent, CustomLogger, ServiceBase, EnumerationType, FieldType, Various } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Merlin } from "../../generated";
import { ColDef, RowNode } from "ag-grid-community";
import * as Constants from '../../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { GridHelperMerlin } from 'resources/elements/grid-helper';
import { EnumerationAllTypeList } from 'enumeration-types/all-enumeration-type-list';

@autoinject
export class EnumerationTypeMerlinList extends ListViewModelBase<Merlin.Web.Model.EnumerationTypeMerlin> {
  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.EnumerationTypeMerlin>(Constants.EntityTypeNames.EnumerationTypeMerlin));
  }
  public ressourceName: string = Constants.EntityTypeNames.EnumerationTypeMerlin;

  private paramType: string;
  private paramRoute: string;
  private isCloseEnum: boolean = false;
  public buttonLabel: string;

  public initializeGridOptions() {
    super.initializeGridOptions(false, false);
  }

  public async activate(params): Promise<void> {
    this.paramType = params.param2;
    this.service.gridDataSource.queryParameters = { category: this.paramType };
    switch (this.paramType) {

      case Constants.EnumerationTypes.ActivityCode.toString():
        this.paramRoute = 'activity-code';
        this.buttonLabel = this.i18n.tr("enumerationtype.addActivityCode");
        break;

      case Constants.EnumerationTypes.ItemDeliveryType.toString():
        this.paramRoute = 'item-delivery-type';
        this.buttonLabel = this.i18n.tr("enumerationtype.addItemDeliveryType");
        break;

      default:
        break;
    }
  }

  public getDetailsUrl(self, entity: EnumerationType) {
    if (entity?.id) {
      return this.router.baseUrl + `enumeration-types/${this.paramRoute}/${this.paramType}/${entity.id}`;
    }
    return null;
  }

  public addEnumerationType() {
    this.navigateTo(this.router.baseUrl + `enumeration-types/${this.paramRoute}/${this.paramType}/-100`);
  }

  protected isSelectable(entity: EnumerationType): boolean {
    return !entity?.uniqueCode;
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this),
      {
        headerName: this.i18n.tr("enumerationtype.denomination") + this.i18n.tr("general.inBracketsFR"),
        field: "denomination.lang1",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            let tag: EnumerationType = currentThis.params.data
            if (tag == null) return ''
            return EnumerationAllTypeList.getTagHtml(tag.denomination.lang1, tag.textColor, tag.backgroundColor);
          }
        },
      },
      {
        headerName: this.i18n.tr("enumerationtype.denomination") + this.i18n.tr("general.inBracketsNL"),
        field: "denomination.lang2",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            let tag: EnumerationType = currentThis.params.data
            if (tag == null) return ''
            return EnumerationAllTypeList.getTagHtml(tag.denomination.lang2, tag.textColor, tag.backgroundColor);
          }
        },
      },
      {
        headerName: this.i18n.tr("enumerationtype.denomination") + this.i18n.tr("general.inBracketsEN"),
        field: "denomination.lang3",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            let tag: EnumerationType = currentThis.params.data
            if (tag == null) return ''
            return EnumerationAllTypeList.getTagHtml(tag.denomination.lang3, tag.textColor, tag.backgroundColor);
          }
        },
      },
      {
        headerName: this.i18n.tr("enumerationtype.code"),
        field: "code",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("enumerationtype.active"),
        field: "active",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("enumerationtype.default"),
        field: "default",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }
}
