import { HttpClient } from 'aurelia-fetch-client';
import { ColDef } from 'ag-grid-community';
import { Router } from 'aurelia-router';
import { CustomLogger, ServiceBase, EnumerationTypeService, FieldType, IMenuItems, ListViewModelBase, GridHelper, GlobalLoaderService } from "digiwall-lib"
import { autoinject } from 'aurelia-framework';
import * as Constants from '../constants';
import { GridHelperMerlin } from 'resources/elements/grid-helper';
import { Merlin } from 'generated';

@autoinject
export class BankTransactionsList extends ListViewModelBase<Merlin.Web.Model.BankTransaction> {
  public ressourceName: string = Constants.EntityTypeNames.BankTransaction;


  private menuItems: Array<IMenuItems>;

  private bankAccountService: ServiceBase<Merlin.Web.Model.BankAccount>;
  private paymentTagService: EnumerationTypeService;

  constructor(router: Router, logger: CustomLogger, private httpClient: HttpClient, private gls: GlobalLoaderService) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.BankTransaction>(Constants.EntityTypeNames.BankTransaction));
    this.service.gridDataSource.expands = ['status']
    this.setMenuItems();
  }

  public async ignoreTransactions() {
    const ids = this.selectedEntities.map(x => x.id);
    let result = await this.httpClient.post(Constants.Application.PontoController.IgnoreTransactions,
      JSON.stringify(ids)
    )
    if (result.ok) {
      this.gridOptions.api.refreshInfiniteCache();
      this.unselectAll();
    }
    else {
      this.unselectAll();
    }
  }

  public getDetailsUrl(self, entity: Merlin.Web.Model.BankTransaction) {
    if (entity && entity.id) {
      var url = '/transactions/' + entity.id;
      return url;
    }
    return null;
  }

  public async syncBankTransactionsAndAutoReconcile() {
    this.gls.allow("", 0);
    let result = await this.httpClient.post(Constants.Application.PontoController.SyncBankTransactionsAndAutoReconcile)
    if (result.ok) {
      this.gridOptions.api.refreshInfiniteCache();
    }
  }

  public getDataGridColumns() {
    super.getDataGridColumns();
    this.additionalSelectPropertyPaths.push("bankAccount.logoUri", "bankAccount.workingOffice");
    this.bankAccountService = new ServiceBase<Merlin.Web.Model.BankAccount>(Constants.EntityTypeNames.BankAccount);
    let defs: ColDef[] = [
      {
        headerName: "",
        field: "",
        maxWidth: Constants.AGGridColumnsWidth.IsSelected,
        minWidth: Constants.AGGridColumnsWidth.IsSelected,
        cellRendererParams: {
          i18n: this.i18n,
          gridOptions: (this as any).gridOptions,
          router: this.router,
          service: this.service,
          pathDetail: (this as any).pathDetail,
          listViewModel: this,
          menuItems: this.getMenuItems,
        },
        cellRenderer: "customButtonRenderer",
        suppressColumnsToolPanel: true,
        sortable: false,
        resizable: false,
        filter: false,
        suppressMenu: true,
        suppressMovable: true,
        pinned: "left",
      },
      new GridHelperMerlin().selectedColDef(this),
      {
        headerName: this.i18n.tr("banktransaction.counterpartName"),
        field: "counterpartName",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("banktransaction.counterpartReference"),
        field: "counterpartReference",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("banktransaction.communication"),
        field: "communication",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("banktransaction.amount"),
        field: "amount",
        cellClass: (params) => {
          if (params?.data?.amount >= 0) {
            return 'transaction__cell--amount positive';
          }
          else {
            return 'transaction__cell--amount negative';
          }
        },
        type: FieldType.Currency,
        valueGetter: (params) => {
          return Math.abs(params?.data?.amount);
        }
      },
      {
        headerName: this.i18n.tr("banktransaction.reconciledAmount"),
        field: "reconciledAmount",
        type: FieldType.Currency
      },
      {
        headerName: this.i18n.tr("banktransaction.statusId"),
        field: "status.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.TransactionStatus,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.TransactionStatus,
        }
      },
      {
        headerName: this.i18n.tr("banktransaction.valueDate"),
        field: "valueDate",
        type: FieldType.Date
      },
      {
        headerName: this.i18n.tr("banktransaction.bankreference"),
        field: "bankAccount.iBAN",
        type: FieldType.OneToMany,
        valueGetter: (params) => {
          const uri = params?.data?.bankAccount?.logoUri;
          const iban = params?.data?.bankAccount?.iBAN;
          return `<img style='max-height: 70%; margin-right: 5px; vertical-align: middle' src='${uri}'></img>${iban}`;
        },
        cellRenderer: (data) => {
          if (data.value != null) {
            return data.value;
          }
          return;
        },
        filterParams: {
          service: this.bankAccountService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: this.bankAccountService
        }
      },
      // {
      //   headerName: "Person - Id",
      //   field: "personId",
      //   type: FieldType.Number,
      //   hide: true
      // },
      // {
      //   headerName: this.i18n.tr("banktransaction.person"),
      //   field: "person.fullName",
      //   type: FieldType.String
      // },
      // {
      //   headerName: "Person - " + this.i18n.tr("person.accountingExternalId"),
      //   field: "person.accountingExternalId",
      //   type: FieldType.String,
      //   hide: true
      // }
    ];
    return defs;
  }

  private setMenuItems(): void {
    this.menuItems = [
      {
        label: this.i18n.tr("banktransaction.ignore"),
        icon: "fas fa-ban",
        handler: () => {
          this.ignoreTransactions();
        },
        disabled: () => {
          return this.selectedEntities.length <= 0;
        }
      },
      {
        label: this.i18n.tr("banktransaction.forceSynchroAndAutoReconcile"),
        icon: "fas fa-sync",
        handler: () => {
          this.syncBankTransactionsAndAutoReconcile();
        }
      }
    ];
  }

  getMenuItems() {
    return this.menuItems;
  }

}
