import { PrecisionParameter, ProjectResumeBase, ProjectResumeNumber } from './../projects/project-resume/project-resume';
import { HttpClient } from 'aurelia-fetch-client';
import { ProjectResumeAddress, ProjectResumeCompany, ProjectResumeDates, ProjectResumeExtern, ProjectResumeIntern, ProjectResumePartner } from '../projects/project-resume/project-resume';
import * as Constants from '../constants';
import { autoinject } from 'aurelia-framework';
import moment from 'moment';

@autoinject
export class ProjectApiService {
  constructor(private httpClient: HttpClient) {

  }

  async getBaseInfo(projectId: any): Promise<ProjectResumeBase> {
    const baseResult = await this.httpClient.get(Constants.Application.ProjectController.GetBase.format(projectId.toString()));
    if (baseResult) {
      const base = await baseResult.json();
      return base;
    }
    return null;
  }

  async getAddress(projectId: any): Promise<ProjectResumeAddress> {
    const addressResult = await this.httpClient.get(Constants.Application.ProjectController.GetAddress.format(projectId.toString()));
    if (addressResult) {
      const address = await addressResult.json();
      return address;
    }
    return null;
  }
  async getDates(projectId: any): Promise<ProjectResumeDates> {
    const datesResult = await this.httpClient.get(Constants.Application.ProjectController.GetDates.format(projectId.toString()));
    if (datesResult) {
      const dates = await datesResult.json();
      return dates;
    }
    return null;
  }
  async getResumeExtern(projectId: any): Promise<ProjectResumeExtern> {
    const externResult = await this.httpClient.get(Constants.Application.ProjectController.GetResumeExtern.format(projectId.toString()));
    if (externResult) {
      const extern = await externResult.json();
      return extern;
    }
    return null;
  }
  async getResumeIntern(projectId: any): Promise<ProjectResumeIntern> {
    const internResult = await this.httpClient.get(Constants.Application.ProjectController.GetResumeIntern.format(projectId.toString()));
    if (internResult) {
      const intern = await internResult.json();
      return intern;
    }
    return null;
  }
  async getResumePartner(projectId: any): Promise<ProjectResumePartner> {
    const partnerResult = await this.httpClient.get(Constants.Application.ProjectController.GetResumePartner.format(projectId.toString()));
    if (partnerResult) {
      const partner = await partnerResult.json();
      return partner;
    }
    return null;
  }
  async getCompany(projectId: any): Promise<ProjectResumeCompany> {
    const companyResult = await this.httpClient.get(Constants.Application.ProjectController.GetCompany.format(projectId.toString()));
    if (companyResult) {
      const company = await companyResult.json();
      return company;
    }
    return null;
  }
  async getNumber(projectId: any): Promise<ProjectResumeNumber> {
    const companyResult = await this.httpClient.get(Constants.Application.ProjectController.GetNumber.format(projectId.toString()));
    if (companyResult) {
      const company = await companyResult.json();
      return company;
    }
    return null;
  }
  async getPrecisionParameter(projectId: any): Promise<PrecisionParameter> {
    const result = await this.httpClient.get(Constants.Application.ProjectController.GetPrecisionParameter.format(projectId.toString()));
    if (result) {
      return await result.json();
    }
    return null;
  }
  async changeCurrentVersion(projectId: any, workquotationVersionId: any): Promise<any> {
    const result = await this.httpClient.get(Constants.Application.ProjectController.ChangeCurrentVersion.format(projectId.toString(), workquotationVersionId.toString()));
    if (result) {
      return await result.json();
    }
    return null;
  }
  async updateWeatherDelayDays(projectId: any, newVal: any): Promise<any> {
    const result = await this.httpClient.patch(Constants.Application.ProjectController.UpdateWeatherDelayDays.format(projectId.toString(), newVal.toString()));
  }
  async updateApprovedStopDays(projectId: any, newVal: any): Promise<any> {
    const result = await this.httpClient.patch(Constants.Application.ProjectController.UpdateApprovedStopDays.format(projectId.toString(), newVal.toString()));
  }
  async updateFinalAcceptanceDate(projectId: any, newVal: any): Promise<any> {
    const result = await this.httpClient.patch(Constants.Application.ProjectController.UpdateFinalAcceptanceDate.format(projectId.toString()), JSON.stringify({ Date: newVal }));
  }
  async updateProvisionalAcceptanceDate(projectId: any, newVal: any): Promise<any> {
    const result = await this.httpClient.patch(Constants.Application.ProjectController.UpdateProvisionalAcceptanceDate.format(projectId.toString()), JSON.stringify({ Date: newVal }));
  }
  async validateLastImport(versionId: number) {
    let query = await this.httpClient.post(Constants.Application.ProjectController.ValidateLastImport.format(versionId.toString()));
    if (query.ok) {
      return;
    }
  }
}
