import { DialogController } from 'aurelia-dialog';
import { Merlin } from 'generated';
import { Router } from 'aurelia-router';
import { EditingModeEnum, ServiceBase, CustomLogger, EntityDetailViewModelBase, EnumerationType, Various, IMenuGroup, validateEnumerationType } from 'digiwall-lib';
import { autoinject, observable, BindingEngine, computedFrom } from 'aurelia-framework';
import * as Constants from '../../constants';
import { DataFormat } from 'select2';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { ValidationRules } from 'aurelia-validation';

@autoinject
export class EnumerationTypeMerlinDetail extends EntityDetailViewModelBase<Merlin.Web.Model.EnumerationTypeMerlin> {
  private enumerationTypeColorService: ServiceBase<Merlin.Web.Model.EnumerationTypeColor>;
  private enumerationTypeAliasService: ServiceBase<Merlin.Web.Model.EnumerationTypeAlias>;
  private allAlias: Merlin.Web.Model.EnumerationTypeAlias[];

  @observable
  private enumerationTypeColor: DataFormat;
  private menuItems: Array<IMenuGroup>;
  private errors: string;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private dialogController: DialogController) {
    super(router, logger);
    this.enumerationTypeAliasService = new ServiceBase<Merlin.Web.Model.EnumerationTypeAlias>(Constants.EntityTypeNames.EnumerationTypeAlias);

    this.enumerationTypeColorService = new ServiceBase<Merlin.Web.Model.EnumerationTypeColor>(Constants.EntityTypeNames.EnumerationTypeColor);
    super.initialize(new ServiceBase<Merlin.Web.Model.EnumerationTypeMerlin>(Constants.EntityTypeNames.EnumerationTypeMerlin));

    ValidationRules.customRule('validation-code', async (value: string, obj) => {
      return await this.isCodeExists(value);
    }, "enumerationtypemerlin.uniqueCodeError");
  }

  private paramType: string;

  public async activate(params): Promise<void> {
    let id = params.param1;
    this.paramType = params.param2;
    await super.activate(params);
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      this.entity.active = true;
      this.entity.default = false;
      this.entity.backgroundColor = "#F2F4FE";
      this.entity.textColor = "#3C54E4";
      if (params.callback != null) {
        params.callback(this.entity);
        this.setParamType();
      }
      else {
        this.setCategory();
      }
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id, 'aliases');
      this.setParamType();
      this.controller.addObject(this.entity);
    }
    this.setMenuItems();

    validateEnumerationType(this.entity);

    ValidationRules
      .ensure('code')
      .satisfiesRule('validation-code')
      .on(this.entity);

  }

  private async isCodeExists(code: string) {
    let enumsPredicate: Predicate = new Predicate('code', FilterQueryOp.Equals, code);
    let currentEnum: Predicate = new Predicate('id', FilterQueryOp.NotEquals, this.entity.id);
    let nbItems = await this.service.getCount(enumsPredicate.and(currentEnum));
    return nbItems <= 0;
  }

  private setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-trash",
            handler: () => {
              this.delete()
            },
            hidden: () => {
              return this.entity.uniqueCode != null
            }
          }
        ]
      },
    ];
  }

  @computedFrom('editingMode', 'entity.denomination._translation')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      switch (this.paramType) {
        case Constants.EnumerationTypes.ActivityCode.toString():
          return this.i18n.tr("enumerationtype.addActivityCode");

      }
    } else {
      if (this.entity)
        return (this.entity.denomination as any)._translation;
    }
  }
  @computedFrom('service')
  public get ressourceName(): string {
    return Constants.EntityTypeNames.EnumerationTypeMerlin;
  }

  public setCategory() {
    this.entity.category = parseInt(this.paramType);
  }

  public setParamType() {
    this.paramType = this.entity.category.toString();
  }

  public async save() {
    await this.validateAlias();
    await super.save(false, false, true);
    if (this.dialogController?.controller != null)
      this.dialogController.ok(this.entity);
  }

  public attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this, 'enumerationTypeColor').subscribe(async (newValue, oldValue) => {
        if (newValue != null) {
          let enumerationTypeColor = await this.enumerationTypeColorService.firstEntity(new Predicate("id", FilterQueryOp.Equals, newValue));
          if (enumerationTypeColor != null) {
            this.entity.textColor = enumerationTypeColor.textColor;
            this.entity.backgroundColor = enumerationTypeColor.backgroundColor;
          }
        }
      })
    )
  }
  public formatEnumerationTypeColor(event: any) {
    if (event.data != null) {
      return `<div style="width: fit-content; margin-right: 10px; border-radius: 10px; color: ${event.data.textColor}; background-color: ${event.data.backgroundColor};"><span style="margin: 0 10px; font-size: 15px;">${event.data.description._translation}<span></div>`;
    }
  }
  public async validateAlias(): Promise<any> {
    this.allAlias = await this.enumerationTypeAliasService.getAllEntities();

    this.entity.aliases.forEach(alias => {
      this.controller.removeObject(alias);
    });

    this.entity.aliases.forEach(alias => {
      let validRule = ValidationRules
        .ensure((u: Merlin.Web.Model.UnitAlias) => u.alias)
        .required()
        .satisfies((val, obj) => {
          let allUnitCount = 0
          let result = this.entity.aliases.filter(x => x != alias && x.alias == val);
          let allAlias = this.allAlias.filter(x => x != alias && x.alias == val);
          return val.length > 0 && result.length == 0 && allAlias.length == 0 && allUnitCount == 0;
        })
        .withMessage(this.i18n.tr("unit.aliasUnique"))
        .on(alias).rules;
      this.controller.addObject(alias, validRule);
    });
  }
}
