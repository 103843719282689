import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import moment from 'moment';
import { MonthlyExecutionChartDataMapper, MonthlyExecutionChartData } from 'resources/charts/chart-mappers';
import { DashboardService } from './../services/dashboard-service';
import * as Constants from '../constants';
import { Box, ServiceBase } from 'digiwall-lib';
import { Merlin } from 'generated';
import { autoinject } from 'aurelia-framework';
import { ChartProject } from 'projects/realization/chart-project/chart-project';

@autoinject
export class FinancialHubSummary {
  private nbDecimalForPriceDisplay = 2;
  private totalIncome: number;
  private totalExpense: number;
  private monthlyExecutionChartConfigDataMapper = new MonthlyExecutionChartDataMapper();
  protected monthlyExecutionChartData: MonthlyExecutionChartData;
  constructor(public router: Router, private dashboardService: DashboardService, private i18n: I18N, private box: Box) {

  }

  public async activate(params: any) {
    this.nbDecimalForPriceDisplay = (await new ServiceBase<Merlin.Web.Model.ApplicationParameter>(Constants.EntityTypeNames.ApplicationParameter).getEntityById(1)).nbDecimalForPriceDisplay;
    await this.configMonthlyExecutionChart();
  }

  private async configMonthlyExecutionChart() {
    let data = await this.dashboardService.getProgressStatementByMonth(null, moment().add(-2, 'M').toDate(), moment().toDate());
    this.totalIncome = data.totalIncome;
    this.totalExpense = data.totalExpense;
    this.monthlyExecutionChartData = this.monthlyExecutionChartConfigDataMapper.mapData(data.executionChartDTO, this.i18n);
  }
  openAllChart() {
    this.box.showFloatingBox(new ChartProject(null, this.dashboardService, this.i18n, 6), false, false);
  }
}
