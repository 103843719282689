@import "./../../../css/scss/variables";

invoice-table {
  > div.invoice-header {
    display: grid !important;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-areas: "left middle right";
    align-items: center;
    > .invoice-title {
      grid-area: left;
      display: flex;
      align-items: center;
      gap: 10px;
      ui-icon {
        color: $blue-mid;
        font-size: 18px;
      }
      span {
        font-size: 16px;
        font-weight: 600;
      }
    }
    > .selection-button {
      grid-area: middle;
      width: 100%;
      display: flex;
      justify-content: space-around;
    }
    > .right-part {
      grid-area: right;
      > span {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  ui-table {
    display: block;
    overflow-y: auto;
    max-height: 350px;
    .ui-table__header__cell {
      cursor: unset;
    }
    > .ui-table > tbody > tr > td:first-child > div,
    > .ui-table > tbody > tr > td:first-child a {
      width: unset;
    }
  }
}
