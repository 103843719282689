@import "../../css/scss/variables";

ux-dialog.dialog-articles {
  > ux-dialog-header {
    padding-bottom: 20px;
    border-bottom: solid 1px $purple-lighter;
    .dialog-header-content {
      display: flex;
      align-items: center;

      .title-search {
        flex: 1;
      }
    }
  }

  > ux-dialog-body {
    padding: 0 30px !important;
    overflow: hidden;

    ui-sidebar {
      margin-left: -30px;
      margin-top: -8px;
      background-color: white;
      position: absolute;

      &[data-collapsed="true"] {
        .ui-sidebar__body {
          display: none;
        }
      }
      &[data-collapsed="false"] {
        height: calc(100% - 143px); // = ux-dialog header + footer
        box-shadow: 10px 0 5px -2px rgba(0, 0, 0, 0.15);
      }
    }

    .tables-container {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: row;

      > div {
        flex: 67%;
        height: 100%;
        display: block;
        position: relative;

        &.col-right {
          margin-top: 75px;
          flex: 33%;

          label.no-item {
            margin: auto;
            display: block;
            width: fit-content;
            padding-top: 40%;
          }

          ui-table {
            height: calc(100% - 80px);
            display: block;
            overflow: auto;

            .ui-table__cell {
              &,
              & > div {
                height: 38px;
              }
            }
            th > div > div {
              font-size: 13px;
            }
            td {
              input,
              .select2-selection__rendered {
                font-size: 14px;
              }
              .ui-input--disabled {
                border: none !important;
              }
            }
          }
        }
      }

      custom-ag-grid {
        display: block;
        height: 100%;

        .grid-header-bar {
          margin: 0 10px;
          border-bottom: none;
        }
      }

      ag-grid-aurelia {
        padding-right: 10px !important;
      }
    }
  }

  > ux-dialog-footer {
    display: flex;
    justify-content: flex-end;
  }
}
