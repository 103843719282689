import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, EnumerationTypeService, Various, IAnchorTab, IMenuGroup } from 'digiwall-lib';
import { Merlin } from "../generated";
import { autoinject, BindingEngine } from 'aurelia-framework';
import * as Constants from '../constants';
import { FilterQueryOp, Predicate } from 'breeze-client';

@autoinject
export class StaffDetail extends EntityDetailViewModelBase<Merlin.Web.Model.Staff> {
  public Constants: any = Constants;
  public ressourceName: string = Constants.EntityTypeNames.Staff;
  private menuItems: Array<IMenuGroup>;

  public tabList: Array<IAnchorTab>;
  userFunctionService: EnumerationTypeService;
  staffTypeService: EnumerationTypeService;
  activityService: EnumerationTypeService;
  merlinUserService: ServiceBase<Merlin.Web.Model.MerlinUser>;


  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine) {
    super(router, logger);
    this.userFunctionService = new EnumerationTypeService(Constants.EnumerationTypes.UserFunctions);
    this.staffTypeService = new EnumerationTypeService(Constants.EnumerationTypes.StaffType);
    this.activityService = new EnumerationTypeService(Constants.EnumerationTypes.ActivityCode);
    this.merlinUserService = new ServiceBase<Merlin.Web.Model.MerlinUser>(Constants.EntityTypeNames.MerlinUser);
    super.initialize(new ServiceBase<Merlin.Web.Model.Staff>(Constants.EntityTypeNames.Staff));
  }

  public async activate(params) {
    let id = params.param1;
    await super.activate(params);
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      this.entity.active = true;
      if (params.callback) params.callback(this);
    } else {
      this.editingMode = EditingModeEnum.Update;
      await this.loadEntity(id);
      this.controller.addObject(this.entity);
    }
    this.setTabList();
    this.setMenuItems();
  }

  private attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this.entity, "userId").subscribe(async (newValue, oldValue) => {
        if (newValue != oldValue && newValue != null) {
          let user = await this.merlinUserService.getEntityById(this.entity.userId);
          this.entity.firstName = user.firstName;
          this.entity.lastName = user.lastName;
        }
      })
    );
  }

  private async loadEntity(id: number) {
    this.entity = await this.service.firstEntity(
      new Predicate("id", FilterQueryOp.Equals, id),
      ['type', 'user', 'primaryStaffFunction', 'staffActivities.activity', 'staffFunctions.staffFunctionEnum'],
      { onlyActive: false }
    );
  }


  private setTabList() {
    this.tabList = new Array<IAnchorTab>(
      {
        id: "tab1",
        name: this.i18n.tr("groupTabPanel.generalInformation"),
        isVisible: true
      },
      // {
      //   id: "tab2",
      //   name: this.i18n.tr("groupTabPanel.addresses"),
      //   isVisible: !this.isFromPopup && !this.isCreationMode
      // },
      // {
      //   id: "tab3",
      //   name: this.i18n.tr("groupTabPanel.contactPersons"),
      //   isVisible: this.entity.isCompany && !this.isCreationMode
      // },
      // {
      //   id: "tab4",
      //   name: this.i18n.tr("groupTabPanel.linkedCompany"),
      //   isVisible: !this.entity.isCompany && !this.isCreationMode
      // }
    );
  }

  private setMenuItems() {
    this.menuItems = [
      // {
      //   group: "1",
      //   hiddenLabel: true,
      //   items: [
      //     {
      //       label: this.i18n.tr("notificationsent.send"),
      //       icon: "digi-mail-send-line",
      //       handler: () => {
      //         this.sendEmail();
      //       }
      //     },
      //     {
      //       label: this.i18n.tr("menu.inviteCustomerToLancelot"),
      //       icon: "digi-mail",
      //       hidden: !this.lancelotInviteEnabled,
      //       disabled: this.entity.emailAddress === null || this.entity.emailAddress === '' || this.hasAlreadyBeenInvited() === true,
      //       handler: async () => {
      //         await this.inviteCustomerToLancelot();
      //       }
      //     }
      //   ]
      // },
      // {
      //   group: "2",
      //   hiddenLabel: true,
      //   items: [
      //     {
      //       label: this.i18n.tr("thirdparty.activated"),
      //       icon: "digi-lock-unlock",
      //       handler: () => {
      //         this.changeActiveStatus()
      //       },
      //       hidden: this.entity.active
      //     },
      //     {
      //       label: this.i18n.tr("thirdparty.deactivated"),
      //       icon: "digi-lock",
      //       handler: () => {
      //         this.changeActiveStatus()
      //       },
      //       hidden: !this.entity.active
      //     }
      //   ]
      // },
      {
        group: "2",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-trash",
            handler: () => {
              this.delete()
            }
          }
        ]
      }
    ];
  }

  public get documentTitle() {
    if (this.isCreationMode) return "";
    return this.entity.lastName + ' ' + this.entity.firstName;
  }
}
