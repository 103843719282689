
import { Merlin } from 'generated';
import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, EnumerationType, EnumerationTypeService, Various, IAnchorTab, IMenuGroup } from 'digiwall-lib';
import * as Constants from '../constants';
import { autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import { ModelUpdatedEvent } from 'ag-grid-community';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { EditDialogAction } from 'resources/utilities/edit-dialog-actions';
import { NotificationTaskService } from 'module-tasks/service/notification-task-service';

@autoinject
export class TaskDetail extends EntityDetailViewModelBase<Merlin.Web.Model.TaskJob> {
  public ressourceName: string = Constants.EntityTypeNames.TaskJob;
  private userService: ServiceBase<Merlin.Web.Model.MerlinUser>;
  private parentService: ServiceBase<Merlin.Web.Model.TaskJob>;
  private taskTypeService: EnumerationTypeService;
  private taskStatusService: EnumerationTypeService;
  private taskCriticityLevelService: EnumerationTypeService;
  private taskTagService: EnumerationTypeService;
  private taskDocuments: ServiceBase<Merlin.Web.Model.TaskDocument>;
  private projectService: ServiceBase<Merlin.Web.Model.Project>;
  private taskPredicate: Predicate;
  public fromPopup: boolean = false;
  public tabList: Array<IAnchorTab>;
  private menuItems: Array<IMenuGroup>;
  public parentTaskId: number;

  private disabledProjectSelect: boolean = true;
  private responsibleHasChanged: boolean = false;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private notificationService: NotificationTaskService) {
    super(router, logger);
    this.userService = new ServiceBase<Merlin.Web.Model.MerlinUser>(Constants.EntityTypeNames.MerlinUser);
    this.parentService = new ServiceBase<Merlin.Web.Model.TaskJob>(Constants.EntityTypeNames.TaskJob);
    this.taskTypeService = new EnumerationTypeService(Constants.EnumerationTypes.TaskType);
    this.taskStatusService = new EnumerationTypeService(Constants.EnumerationTypes.TaskStatus);
    this.taskCriticityLevelService = new EnumerationTypeService(Constants.EnumerationTypes.TaskCriticityLevel);
    this.taskTagService = new EnumerationTypeService(Constants.EnumerationTypes.TaskTag);
    this.taskDocuments = new ServiceBase<Merlin.Web.Model.TaskDocument>(Constants.EntityTypeNames.TaskDocument);
    this.projectService = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);
    super.initialize(new ServiceBase<Merlin.Web.Model.TaskJob>(Constants.EntityTypeNames.TaskJob));
  }

  @computedFrom('editingMode', 'entity.title')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("taskjob.addTask");
    }
    else {
      return this.entity.title;
    }
  }

  public async activate(params: any) {
    let id = params.param1;
    let parentId = params.parentId;
    let projectPhaseId = params.projectPhaseId
    await super.activate(params);
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity({ taskTypeId: null, taskStatusId: null, responsibleId: null });
      this.entity.responsibleId = this.authService.currentUser.id;
      this.entity.taskStatusId = Constants.TaskStatusId.Open;
      this.entity.taskStatus = await this.taskStatusService.getEntityById(this.entity.taskStatusId);
      this.entity.taskCriticityLevelId = Constants.TaskCriticityLevelId.Low;
      this.entity.taskCriticityLevel = await this.taskCriticityLevelService.getEntityById(Constants.TaskCriticityLevelId.Low);
      this.parentService.gridDataSource.queryParameters = { onlyParent: true };
      if (parentId != null && parentId != -100) {
        this.entity.parentTaskId = parentId;
        this.entity.isSubTask = true;
      }
      if (params.projectId != null) {
        this.entity.projectId = params.projectId;
      } else {
        this.disabledProjectSelect = false;
      }

      if (projectPhaseId != null) {
        this.entity.projectPhaseId = projectPhaseId
      }
      this.responsibleHasChanged = true;
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id, 'taskType', 'taskStatus', 'taskCriticityLevel', 'parentTask', 'responsible', 'taskTags.taskTag', 'taskDocuments.document.fileType', 'taskDocuments.document.language', 'taskDocuments.document.documentStatus', 'project');
      this.parentService.gridDataSource.queryParameters = { taskToRemoveId: this.entity.id, onlyParent: true };
      this.controller.addObject(this.entity);
      this.taskPredicate = new Predicate("parentTaskId", FilterQueryOp.Equals, this.entity.id);
      this.bindingEngine.propertyObserver(this.entity, 'responsibleId').subscribe((newValue, oldValue) => {
        if (newValue != null && oldValue != newValue) {
          this.responsibleHasChanged = true;
        }
      })
    }

    this.fetchSubTasks();

    this.setDocumentList();
    this.setTabList();
    this.setMenuItems();
    if (params.callback) params.callback(this);
    await this.setParentTask();
  }

  public async save() {
    await super.save();
    if (this.responsibleHasChanged) {
      await this.notificationService.sendNotificationTaskAssignement(this.entity.responsible, this.entity.project, this.entity.id, this.entity.title);
    }
  }

  private async setParentTask() {
    if (this.parentTaskId != null) {
      this.entity.parentTaskId = this.parentTaskId;
      this.entity.parentTask = await this.service.getEntityById(this.parentTaskId, ...['project']);
      this.setProjectFromParent();
    }
  }

  private setProjectFromParent() {
    if (this.entity?.parentTask?.projectId != null) {
      this.entity.projectId = this.entity.parentTask.projectId;
      this.disabledProjectSelect = true;
    }
  }

  private setTabList() {
    this.tabList = new Array<IAnchorTab>(
      {
        id: "tab1",
        name: this.i18n.tr("groupTabPanel.generalInformation"),
        isVisible: true
      },
      {
        id: "tab2",
        name: this.i18n.tr("groupTabPanel.subTasks"),
        isVisible: true
      },
      {
        id: "tab3",
        name: this.i18n.tr("groupTabPanel.documents"),
        isVisible: true
      }
    );
  }

  private setMenuItems() {
    this.menuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.delete"),
            icon: "digi-trash",
            handler: async () => {
              await this.delete()
            }
          }
        ]
      },
    ];
  }

  public getMenuItemsSubTask(params) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.remove"),
            icon: "digi-trash",
            handler: async () => {
              await this.deleteSubTask(params);
            }
          }
        ]
      }
    ];
  }

  //#region sub task
  private loadingSubTasks: boolean = true;
  public async fetchSubTasks() {
    if (this.loadingSubTasks) {
      await this.parentService.getEntities(this.taskPredicate, ['taskType', 'taskStatus', 'taskCriticityLevel', 'parentTask', 'responsible'])
      this.loadingSubTasks = false;
    }
  }

  public async addSubTask() {
    this.box.showEditDialog(TaskDetail, -100, this.i18n.tr('taskjob.addTask'), (VM: TaskDetail) => {
      VM.entity.isSubTask = true;
      VM.fromPopup = true;
      VM.parentTaskId = this.entity.id;
      VM.entity.isTemplate = this.entity.isTemplate;
    },
      {
        canSave: false,
        actions: [
          ...EditDialogAction.GetBaseAction(this.i18n)
        ]
      })
  }

  public async goToSubTask(subTask: Merlin.Web.Model.TaskJob) {
    this.box.showEditDialog(TaskDetail, subTask.id, this.i18n.tr('taskjob.task'), (VM: TaskDetail) => {
      VM.fromPopup = true;
    })
  }

  public async deleteSubTask(subTask: Merlin.Web.Model.TaskJob) {
    await this.parentService.deleteEntities([subTask], true);
  }

  //#endregion

  saveCurrentEntity(silentSave?: boolean): Promise<Merlin.Web.Model.TaskJob> {
    if (this.parentTaskId != null) {
      this.entity.parentTaskId = this.parentTaskId;
    }
    return super.saveCurrentEntity(silentSave);
  }

  public documentList: Array<Merlin.Web.Model.Document> = [];
  public setDocumentList() {
    this.documentList.splice(0);
    this.documentList.push(...this.entity.taskDocuments.map(x => x.document));
  }

  async refreshDocument() {
    let p1 = new Predicate("taskJobId", FilterQueryOp.Equals, this.entity.id);
    await this.taskDocuments.getEntities(p1, ['document.fileType']);
    this.setDocumentList();
  }

  async deleteFile(document: Merlin.Web.Model.Document) {
    let docToDelete = this.entity.taskDocuments.find(x => x.documentId == document.id);
    await this.taskDocuments.deleteEntities([docToDelete], true);
    this.setDocumentList();
  }

}


