import { I18N } from 'aurelia-i18n';
import { ActionDialogBoxInputParameters, DialogBoxViewModel } from 'digiwall-lib';

export class EditDialogAction {
  public static GetBaseAction(i18n: I18N, closeIfMissingEntity: boolean = true) {
    return [
      EditDialogAction.GetCancelAction(i18n),
      EditDialogAction.GetSaveAction(i18n),
    ]
  }
  public static GetCancelAction(i18n: I18N) {
    return {
      label: i18n.tr("menu.cancel"),
      closeDialog: false,
      theme: "secondary",
      type: "ghost",
      fn: async (dialogBoxViewModel: DialogBoxViewModel) => {
        if (dialogBoxViewModel.customVM.currentViewModel.cancel != null)
          await dialogBoxViewModel.customVM.currentViewModel?.cancel();
        dialogBoxViewModel.controller.cancel();
      },
      icon: "digi-cross-circle"
    } as ActionDialogBoxInputParameters
  }
  public static GetSaveAction(i18n: I18N, closeIfMissingEntity: boolean = true) {
    return {
      label: i18n.tr("menu.save"),
      closeDialog: false,
      theme: "primary",
      type: "solid",
      fn: async (dialogBoxViewModel: DialogBoxViewModel) => {
        let entity = await dialogBoxViewModel.customVM.currentViewModel.save(false);
        if (closeIfMissingEntity || entity != null) {
          dialogBoxViewModel.controller.ok(entity);
        }
      },
      icon: "digi-check"
    } as ActionDialogBoxInputParameters
  }
}
