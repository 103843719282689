import { singleton, autoinject } from "aurelia-framework";
import { BaseApiService } from "./base-api-service";
import { BulkUpdateOperation, IDataLineApiService, MeteringTotalPrices, RequestCreatePriceOfferLine, RequestFilterPriceOfferLine, RequestMovePriceOfferLine } from "./i-data-line-api-service";
import { I18N } from "aurelia-i18n";
import { Box, GlobalLoaderService, CustomLogger, ActionDialogBoxInputParameters, DialogBoxViewModel, KeyboardShortcut } from "digiwall-lib";
import { HttpClient } from 'aurelia-fetch-client';
import * as Constants from '../constants';
import { PriceOfferLineFromItem } from "projects/quotes/add-items/add-items";
import { JSONPatchOperation } from "./json-patch-operation";

@singleton()
@autoinject
export class CostCenterApiService extends BaseApiService implements IDataLineApiService {
  constructor(private httpClient: HttpClient, public i18n: I18N, public box: Box, private gls: GlobalLoaderService, private logger: CustomLogger) {
    super(i18n, box);
  }
  getLastChapterId(versionId: number): Promise<number> {
    throw new Error("Method not implemented.");
  }

  createFromItems(versionId: number, selectedItems: PriceOfferLineFromItem[]): Promise<number[]> {
    throw new Error("Method not implemented.");
  }

  async children(versionId: number, lineId: number, displayHidden: boolean, refToDisplay: Constants.RefToDisplay): Promise<any[]> {
    let requestUri = Constants.Application.CostCenterController.GetChildren.format(versionId.toString(), lineId.toString(), displayHidden.toString());
    let query = await this.httpClient.get(requestUri);
    if (query.ok) {
      return await query.json();
    }
  }
  public async filter(versionId: number, filterParams: any, quickFilter: string, displayMode: Constants.TreeDisplayMode, displayHidden: boolean, refToDisplay: Constants.RefToDisplay): Promise<Array<any>> {
    let requestParams: RequestFilterPriceOfferLine = {
      filterModel: filterParams,
      quickFilter: quickFilter,
      displayMode: displayMode,
      displayHidden: displayHidden,
      refToDisplay: refToDisplay
    };
    let requestUri = Constants.Application.CostCenterController.GetAll.format(versionId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestParams));
    if (response.ok) {
      return await response.json();
    }
  }
  public async patch(versionId: number, lineId: number, propertyName: string, propertyValue: any): Promise<Array<any>> {
    let requestUri = Constants.Application.CostCenterController.Patch.format(versionId.toString(), lineId.toString());
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(JSONPatchOperation.operateReplace(propertyName, propertyValue)));
    if (patchResponse.ok) {
      return await patchResponse.json();
    }
    return [];
  }
  public async bulkPatch(versionId: number, bulkUpdateOperation: BulkUpdateOperation[]): Promise<Array<any>> {
    let requestUri = Constants.Application.CostCenterController.BulkPatch.format(versionId.toString());
    const params = bulkUpdateOperation.map(x => {
      return {
        lineId: x.lineId,
        patchDoc: JSONPatchOperation.operateReplace(x.propertyName, x.propertyValue)
      }
    });
    let patchResponse = await this.httpClient.patch(requestUri, JSON.stringify(params));
    if (patchResponse.ok) {
      return await patchResponse.json();
    }
    return [];
  }
  async duplicateLine?(versionId: number, lineId: number): Promise<number[]> {
    let requestUri = Constants.Application.CostCenterController.DuplicateLine.format(versionId.toString(), lineId.toString());
    let response = await this.httpClient.post(requestUri);
    if (response.ok) {
      return await response.json();
    }
  }
  async create(versionId: number, targetId: number, categoryId: number, action: Constants.PriceOfferLineMoveAction): Promise<number[]> {
    let requestCreatePriceOfferLine: RequestCreatePriceOfferLine = {
      targetId: targetId,
      categoryId: categoryId,
      fK: versionId,
      action: action
    };

    let requestUri = Constants.Application.CostCenterController.CreateLine.format(versionId.toString());
    let query = await this.httpClient.post(requestUri, JSON.stringify(requestCreatePriceOfferLine));
    if (query.ok) {
      return await query.json();
    }
    return [];
  }
  async move(versionId: number, lineIds: number[], targetId: number, moveType: Constants.PriceOfferLineMoveAction): Promise<number[]> {

    let requestMovePriceOfferLine: RequestMovePriceOfferLine = {
      action: moveType,
      targetId: targetId,
      priceOfferLineIds: lineIds
    };

    let requestUri = Constants.Application.CostCenterController.Move.format(versionId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestMovePriceOfferLine));
    if (response.ok) {
      return await response.json();
    }

    return [];
  }
  async paste(versionId: number, lineIds: number[], targetId: number, moveType: Constants.PriceOfferLineMoveAction): Promise<number[]> {
    let requestMovePriceOfferLine: RequestMovePriceOfferLine = {
      action: moveType,
      targetId: targetId,
      priceOfferLineIds: lineIds
    };

    let requestUri = Constants.Application.CostCenterController.Paste.format(versionId.toString());
    let response = await this.httpClient.post(requestUri, JSON.stringify(requestMovePriceOfferLine));
    if (response.ok) {
      return await response.json();
    }

    return [];
  }
  subscribe(callback: () => void) {
    throw new Error("Method not implemented.");
  }
  async delete(versionId: number, lineIds: number[]): Promise<number[]> {
    let msg = lineIds.length == 1 ? this.i18n.tr('menu.delete_confirmation') : this.i18n.tr('menu.delete_confirmation_plural', { count: lineIds.length });
    let ids = [];

    let buttonYes: ActionDialogBoxInputParameters = {
      label: this.i18n.tr("general.ok", { ns: "common" }),
      title: this.i18n.tr("general.ok", { ns: "common" }),
      theme: 'primary',
      type: 'solid',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(true);
      },
      keyboardShortcut: KeyboardShortcut.Enter
    };
    let buttonNo: ActionDialogBoxInputParameters = {
      label: this.i18n.tr("general.cancel", { ns: "common" }),
      title: this.i18n.tr("general.cancel", { ns: "common" }),
      theme: 'dark',
      type: 'ghost',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(false);
      },
      keyboardShortcut: KeyboardShortcut.Escape
    };
    await this.box.showQuestion(msg, this.i18n.tr("menu.question", { ns: "common" }), [buttonNo, buttonYes])
      .whenClosed(async (result) => {
        if (!result.wasCancelled && result.output) {
          let requestUri = Constants.Application.CostCenterController.DeleteLine.format(versionId.toString());
          let response = await this.httpClient.delete(requestUri, JSON.stringify(lineIds));
          if (response.ok) {
            let temp = await response.json();
            ids.push(...temp);
          } else {
            if (response.status == 400) {
              let text = await response.text();
              this.logger.LogError(this.i18n.tr(text.slice(1, -1)), null, null, true);
            }
          }
        }
      });
    return ids;
  }
  async fetch(versionId: number, ids: number[], displayHidden: boolean, refToDisplay: Constants.RefToDisplay): Promise<any[]> {
    let result = await this.httpClient.post(Constants.Application.CostCenterController.GetByIds.format(versionId.toString()), JSON.stringify(ids));
    if (result.ok) {
      let entities: Array<any> = await result.json();
      return entities;
    }
    else {
      return null;
    }
  }
  computePAR?(versionId: number): Promise<boolean> {
    throw new Error("Method not implemented.");
  }
  deletePAR?(versionId: number, lineId: number): Promise<boolean> {
    throw new Error("Method not implemented.");
  }
  versionHasLines?(versionId: number): Promise<boolean> {
    throw new Error("Method not implemented.");
  }
  getMeteringTotalPrices?(versionId: number): Promise<MeteringTotalPrices> {
    throw new Error("Method not implemented.");
  }
  createVariantGroup?(versionId: number, lineIds: number[]): Promise<number[]> {
    throw new Error("Method not implemented.");
  }
  deleteVariantGroup?(versionId: number, lineId: number): Promise<number[]> {
    throw new Error("Method not implemented.");
  }
  async recomputeLines(versionId: number): Promise<MeteringTotalPrices> {
    throw new Error("Method not implemented.");
  }
  select(lineId: number, filterModel?: { [key: string]: any; }) {
    throw new Error("Method not implemented.");
  }
  unselect(lineId: number, filterModel?: { [key: string]: any; }) {
    throw new Error("Method not implemented.");
  }
}
