import { autoinject, bindable, customElement } from 'aurelia-framework';
import { RealizationApiService } from 'services/realization-api-service';

@autoinject
@customElement('invoice-table')
export class InvoiceTable {
  @bindable
  private projectId: number = null;
  @bindable
  private nbDecimalForPriceDisplay: number = 2;

  private inComingSelected: boolean = true;
  private outGoingSelected: boolean = true;
  private invoicesList: Array<InvoiceDTO> = [];

  constructor(private api: RealizationApiService) {

  }

  async attached() {
    await this.loadInvoicesList();
  }

  private async loadInvoicesList() {
    this.invoicesList.splice(0);
    this.invoicesList.push(...await this.api.getInvoicesList(this.projectId, this.inComingSelected, this.outGoingSelected));
  }

  async clickInComing() {
    this.inComingSelected = !this.inComingSelected;
    await this.loadInvoicesList();
  }
  async clickOutGoing() {
    this.outGoingSelected = !this.outGoingSelected;
    await this.loadInvoicesList();
  }
}

export interface InvoiceDTO {
  payementStatusLabel: string;
  totalExclVAT: number | null;
  total: number | null;
  senderName: string;
  invoiceTitle: string;
  isIncomingInvoice: boolean;
}

