import { Router } from 'aurelia-router';
import { autoinject } from "aurelia-framework";
import { ActionDialogBoxInputParameters, Box, DialogBoxViewModel, ImportMode, ImportService } from "digiwall-lib";
import * as Constants from '../../constants';
import { ImportPopUp } from "projects/quotes/import-pop-up/import-pop-up";
import { I18N } from "aurelia-i18n";
import { PriceOfferLinesGrid } from "price-offer-lines/price-offer-lines-grid";
import { EditDialogAction } from './edit-dialog-actions';

@autoinject
export class ImportManager {
  private router: Router;
  constructor(private importService: ImportService, private box: Box, private i18n: I18N) {

  }

  public async importFile(context: string, versionId: number, priceOfferLinesGrid: PriceOfferLinesGrid, deleteSheets: (versionId: number, fileId: number, sheets: Array<number>) => Promise<void>, router: Router, params: any) {
    this.router = router;
    let session = await this.importService.getInProcessSession(context, versionId);

    if (session != null) {
      let buttonYes: ActionDialogBoxInputParameters =
      {
        label: this.i18n.tr("general.yes", { ns: "common" }),
        title: this.i18n.tr("general.yes", { ns: "common" }),
        theme: 'primary',
        type: 'solid',
        disabled: false,
        fn: async (thisBox: DialogBoxViewModel) => {
          await thisBox.controller.ok(true);
        }
      };
      let buttonNo: ActionDialogBoxInputParameters =
      {
        label: this.i18n.tr("general.no", { ns: "common" }),
        title: this.i18n.tr("general.no", { ns: "common" }),
        theme: 'dark',
        type: 'ghost',
        disabled: false,
        fn: async (thisBox: DialogBoxViewModel) => {
          await thisBox.controller.ok(false);
        }
      };
      await this.box.showQuestion(this.i18n.tr("importmetering.sessionInProcess"), null, [buttonNo, buttonYes]).whenClosed(async result => {
        if (!result.wasCancelled) {
          if (result.output) {
            this.navigateToImport(params, session.id);
          } else {
            await this.importService.cancelSession(session.id);
            this.checkExisteSession(context, versionId, priceOfferLinesGrid, deleteSheets, params);
          }
        }
      });
    } else {
      await this.checkExisteSession(context, versionId, priceOfferLinesGrid, deleteSheets, params)
    }
  }

  private async checkExisteSession(context: string, versionId: number, priceOfferLinesGrid: PriceOfferLinesGrid, deleteSheets: (versionId: number, fileId: number, sheets: Array<number>) => Promise<void>, params: any) {
    let existSession = await this.importService.existNotCancelSession(context, versionId);
    if (existSession) {
      this.newImportMode(context, versionId, priceOfferLinesGrid, deleteSheets, params);
    } else {
      this.navigateToImport(params);
    }
  }

  private newImportMode(context: string, versionId: number, priceOfferLinesGrid: PriceOfferLinesGrid, deleteSheets: (versionId: number, fileId: number, sheets: Array<number>) => Promise<void>, params: any) {
    this.box.showEditDialog(ImportPopUp, versionId, this.i18n.tr("importdata.import"), {
      canSave: false,
      actions: [
        EditDialogAction.GetCancelAction(this.i18n),
        {
          label: this.i18n.tr("importdata.import"),
          title: this.i18n.tr("importdata.import"),
          theme: 'primary',
          type: 'solid',
          disabled: false,
          fn: async (thisBox: DialogBoxViewModel) => {
            await thisBox.customVM.currentViewModel.ok();
          }
        }
      ],
      callback: (popup: ImportPopUp) => {
        popup.context = context;
      }
    }
    ).whenClosed(async (resultBox) => {
      if (!resultBox.wasCancelled) {
        let output = resultBox.output;
        switch (output.excelModeType) {
          case Constants.ImportMetering.Replace:
            try {
              await deleteSheets(versionId, (typeof output.fileSelected === "object") ? output.fileSelected.id : output.fileSelected, output.sheetSelecteds);
              this.navigateToImport(params, null, ImportMode.NewFile);
            } catch (e) {
              priceOfferLinesGrid?.refreshServerSideRows([]);
            }
            break;
          case Constants.ImportMetering.NewFile:
            this.navigateToImport(params, null, ImportMode.NewFile);
            break;
          case Constants.ImportMetering.NewSheet:
            this.navigateToImport(params, null, ImportMode.NewSheet, (typeof output.fileSelected === "object") ? output.fileSelected.id : output.fileSelected);
            break;
        }
      }
    });
  }

  private navigateToImport(params: any, sessionId: number = null, importMode: number = ImportMode.NewFile, fileSessionId: number = null) {
    params.sessionId = sessionId;
    params.importMode = importMode;
    params.fileSessionId = fileSessionId;
    this.router.navigateToRoute(`import-data`, params);
  }
}
