import { ListViewModelBase, SelectAllHeaderComponent, CustomLogger, ServiceBase, EnumerationType, FieldType, Various } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Merlin } from "../../generated";
import { ColDef, RowNode } from "ag-grid-community";
import * as Constants from '../../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { GridHelperMerlin } from 'resources/elements/grid-helper';

@autoinject
export class StaffFunctionEnumList extends ListViewModelBase<Merlin.Web.Model.StaffFunctionEnum> {
  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.StaffFunctionEnum>(Constants.EntityTypeNames.StaffFunctionEnum));
  }
  public ressourceName: string = Constants.EntityTypeNames.StaffFunctionEnum;

  private paramType: string;
  private paramRoute: string;
  private isCloseEnum: boolean = false;
  public buttonLabel: string;

  public initializeGridOptions() {
    super.initializeGridOptions(false, false);
  }

  public async activate(params): Promise<void> {
    this.paramType = params.param2;
    this.service.gridDataSource.queryParameters = { category: this.paramType };
    switch (this.paramType) {
      default:
        break;
    }
  }

  public getDetailsUrl(self, entity: EnumerationType) {
    if (entity?.id) {
      return this.router.baseUrl + `enumeration-types/staff-function/${Constants.EnumerationTypes.UserFunctions}/${entity.id}`;
    }
    return null;
  }

  public addEnumerationType() {
    this.navigateTo(this.router.baseUrl + `enumeration-types/staff-function/${Constants.EnumerationTypes.UserFunctions}/-100`);
  }

  protected isSelectable(entity: EnumerationType): boolean {
    return !entity?.uniqueCode;
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this),
      {
        headerName: this.i18n.tr("enumerationtype.denomination") + this.i18n.tr("general.inBracketsFR"),
        field: "denomination.lang1",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("enumerationtype.denomination") + this.i18n.tr("general.inBracketsNL"),
        field: "denomination.lang2",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("enumerationtype.denomination") + this.i18n.tr("general.inBracketsEN"),
        field: "denomination.lang3",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("enumerationtype.hourlyCost"),
        field: "hourlyCost",
        type: FieldType.Currency,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("enumerationtype.active"),
        field: "active",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("enumerationtype.default"),
        field: "default",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }
}
