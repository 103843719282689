import { Subscription } from 'aurelia-event-aggregator';
import { bindable, customElement, BindingEngine } from 'aurelia-framework';
import { MonthlyExecutionChartData } from "../chart-mappers";

@customElement('monthly-execution-chart-wrapper')
export class MonthlyExecutionChartWrapper {
  @bindable()
  public data: MonthlyExecutionChartData;
  @bindable() protected zooming = false;
  @bindable protected showLoading = false;
  @bindable private maxMonthToDisplay: number;

  private bindSubscription: Subscription;

  protected plotLine: Highcharts.YAxisPlotLinesOptions;
  protected series: Array<Highcharts.SeriesOptionsType>;

  constructor(bindingEngine: BindingEngine) {
    this.bindSubscription = bindingEngine.propertyObserver(this, 'data').subscribe(data => {
      if (this.data != null) {
        this.setData();
      } else {
        this.plotLine = null;
        this.series = null;
      }
    })
  }

  detached() {
    this.bindSubscription.dispose();
  }

  private setData() {
    this.plotLine = this.data?.forecastMarginPlotLineOption ?? null;
    this.series = [
      this.data?.outgoingsAmountsSerie,
      this.data?.billedAmountsSerie,
      this.data?.progressStatesPayOutAmountsSerie,
      this.data?.progressStatesInitialAmountsSerie,
      this.data?.realMarginsSerie ?? null
    ];
  }
}
