import { autoinject } from "aurelia-framework";
import { Merlin } from "generated";
import * as Constants from '../constants';
import { Config, CustomColDef, FieldType, GlobalLoaderService, IMenuGroup, ServiceBase } from "digiwall-lib";
import { I18N } from "aurelia-i18n";
import { CellValueChangedEvent, ColDef, ColSpanParams, GridOptions, ICellRendererParams, RowNode } from "ag-grid-community";
import { HighlightSearchTextValueConverter } from "resources/value-converters";
import { ModuleListTreeData } from "module-list-tree-data/module-list-tree-data";
import { CostCenterApiService } from "services/cost-center-api-service";
import { Router } from 'aurelia-router';
import { MeteringMenuItems } from "resources/metering/metering-menu-items";
import { CostCenterFullWidthCellRenderer, CostCenterOverlayComp } from "./cost-center-overlay-comp";
import * as toastr from 'toastr';

@autoinject
export class CostCenter {
  private appParameterService: ServiceBase<Merlin.Web.Model.ApplicationParameter>;
  private appParameter: Merlin.Web.Model.ApplicationParameter
  public noDataLabel = this.i18n.tr("grid.noRowsToShow");
  public gridOptions: GridOptions = {};
  public listTreeData: ModuleListTreeData;
  public versionId: number;
  public screenExpand: boolean = false;
  public title: string;
  private highlightSearchTextValueConverter: HighlightSearchTextValueConverter;
  public quickFilter: string;
  private menuItems: Array<IMenuGroup>;
  private userService: ServiceBase<Merlin.Web.Model.MerlinUser>;
  private userList: Array<Merlin.Web.Model.MerlinUser>;

  constructor(public router: Router, public i18n: I18N, public element: Element, private costCenterApi: CostCenterApiService, private meteringMenuItems: MeteringMenuItems, public config: Config, public gls: GlobalLoaderService) {
    this.appParameterService = new ServiceBase<Merlin.Web.Model.ApplicationParameter>(Constants.EntityTypeNames.ApplicationParameter);
    this.highlightSearchTextValueConverter = new HighlightSearchTextValueConverter();
    this.userService = new ServiceBase<Merlin.Web.Model.MerlinUser>(Constants.EntityTypeNames.MerlinUser);
  }

  public async activate(params: any) {
    this.appParameter = await this.appParameterService.getEntityById(1);
    this.versionId = this.appParameter.id;
    this.title = this.i18n.tr("costcenter.costcenter");
    this.userList = await this.userService.getAllEntities();
  }

  getGroupKey(data) {
    return data.name;
  }

  public getDataGridColumns(): ColDef[] {
    let defs: CustomColDef[] = [
      {
        colId: "selected",
        headerName: '',
        field: "isSelected",
        width: Constants.AGGridColumnsWidth.IsSelected,
        maxWidth: Constants.AGGridColumnsWidth.IsSelected,
        suppressMovable: true,
        suppressSizeToFit: true,
        suppressAutoSize: true,
        resizable: false,
        cellRenderer: "customHtmlRendererEditor",
        cellClass: (params) => {
          let returnClass = 'ag-cell-center ag-selection';

          // For metering line color, set a style property for the last child line.
          setTimeout(() => {
            if (params.node.alreadyRendered && params.node.lastChild) {
              let rowElementHtml = this.element.querySelector(`[row-id="${params.node.id}"]`);
              if (rowElementHtml != null) {
                let cellSelected = rowElementHtml.querySelector(`[col-id="${params.colDef.colId}"]`)
                if (cellSelected != null) {
                  let spanLine = cellSelected.querySelector(`[class="metering-line-color"]`) as HTMLElement;
                  if (spanLine != null && params.node.parent != null) {
                    let parentTop = params.node.rowTop - params.node.parent.rowTop - params.node.parent.rowHeight;
                    spanLine.style.setProperty("--parentTop", parentTop + "px");
                  }
                }
              }
            }
          }, 100);
          return returnClass;
        },
        cellRendererParams: {
          canComment: false,
          getHtml: (currentThis) => {
            return `<span class="metering-line-color"></span><ui-checkbox style="margin: auto" class="cell-selected" checked.bind="params.data.isSelected" change.delegate='params.change(params, $event)'></ui-checkbox>`;
          },
          change: async (params, event) => {
            this.listTreeData.shiftSelect(params);
            (<RowNode>params.node).setSelected(event.detail.checked);
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        colSpan: (params: ColSpanParams) => {
          if (params?.node?.rowPinned == 'bottom') {
            return 3;
          }
          return 1;
        }
      },
      {
        colId: "name",
        headerName: this.i18n.tr("costcenter.name"),
        field: "name",
        type: FieldType.String,
        editable: (params) => this.canEditCell({ line: params.data, colField: params.colDef.field }),
        suppressMenu: true,
        pinned: "left",
        lockPosition: 'left',
        hideInParameter: true,
        suppressMovable: true,
        sortable: false,
        cellClass: "metering-cell-description ag-cell-column-resize-pinned-left",
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          canComment: false,
          suppressCount: true,
          suppressDoubleClickExpand: true
        }
      },
      {
        colId: "code",
        headerName: this.i18n.tr("costcenter.code"),
        field: "code",
        type: FieldType.String,
        editable: (params) => this.canEditCell({ line: params.data, colField: params.colDef.field }),
        suppressMenu: false,
        sortable: false,
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            const lineDesc = this.highlightSearchTextValueConverter.toView(currentThis.params.data.code, this.quickFilter);
            let result = `<div class="metering-line-description">`;
            if (lineDesc != null) {
              result += `<div class="metering-line-type-description-overflow" title="${lineDesc}">${lineDesc}</div>`;
            }
            result += `</div>`;
            return result;
          },
        }
      },
      {
        colId: "description",
        headerName: this.i18n.tr("costcenter.description"),
        field: "description",
        type: FieldType.String,
        editable: (params) => this.canEditCell({ line: params.data, colField: params.colDef.field }),
        suppressMenu: false,
        sortable: false,
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            const lineDesc = this.highlightSearchTextValueConverter.toView(currentThis.params.data.description, this.quickFilter);
            let result = `<div class="metering-line-description">`;
            if (lineDesc != null) {
              result += `<div class="metering-line-type-description-overflow" title="${lineDesc}">${lineDesc}</div>`;
            }
            result += `</div>`;
            return result;
          },
        }
      },
      {
        colId: "active",
        headerValueGetter: () => this.i18n.tr("costcenter.active"),
        field: "active",
        type: FieldType.Boolean,
        suppressMenu: false,
        sortable: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.canEditCell({ line: params.data, colField: params.colDef.field }),
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            return `<div>
              <ui-checkbox
                change.delegate="params.change(value, params)"
                checked.two-way="params.data.active" >
              </ui-checkbox>
            </div>`;
          },
          change: async (value, params) => {
            let result = await this.costCenterApi.patch(this.versionId, parseInt(params.data.id), params.column.colId, !params.data.active);
            // Manage api result: all modified entities
            if (result != null && result.length) {
              this.refreshVisibleNodes(result);
            } else {
              toastr.error(this.i18n.tr("metering.errorSave"));
            }
          }
        },
        onCellClicked: () => {
          return false
        }
      },
      {
        colId: "responsibleId",
        headerValueGetter: () => this.i18n.tr("costcenter.responsibleId"),
        field: "responsibleId",
        valueGetter: (params) => params?.data?.responsibleName,
        valueSetter: (params) => ModuleListTreeData.customEnumsValueSetter(params, this.userList, ["fullName"]),
        type: FieldType.OneToMany,
        filterParams: {
          service: this.userService,
          customFieldPath: ["fullName"]
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: this.userService,
          customFieldPath: ["fullName"]
        },
        suppressMenu: false,
        sortable: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.canEditCell({ line: params.data, colField: params.colDef.field }),
        cellClass: (params) => {
          return 'metering-cell-select';
        },
        cellEditor: "customHtmlRendererEditor",
        cellEditorParams: {
          isPopup: true,
          getHtml: (currentThis) => {
            return `<ui-field no-label style="min-width: ${currentThis.params.column.actualWidth > 200 ? currentThis.params.column.actualWidth : 200}px;"> 
                <ui-select value.one-time="params.data.responsibleId" on-change.call="params.change(value, params)" allow-clear="true">
                  <option repeat.for="user of params.userList" value.bind="user.id">
                    \${user.fullName}
                  </option>
                </ui-select>
              </ui-field>`;
          },
          userList: this.userList,
          change: async (value, params) => {
            params.value = value;
            this.gridOptions?.api?.stopEditing();
            this.gridOptions?.api?.setFocusedCell(params.node.rowIndex, params.colDef.field);
          }
        },
      },
      {
        colId: "allocatedBudget",
        headerName: this.i18n.tr("costcenter.allocatedBudget"),
        field: "allocatedBudget",
        type: FieldType.Number,
        suppressMenu: false,
        sortable: false,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation"),
        editable: (params) => this.canEditCell({ line: params.data, colField: params.colDef.field }),
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR" }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        cellRenderer: 'customHtmlRendererEditor',
        cellRendererParams: {
          getHtml: (currentThis) => {
            return currentThis.params.valueFormatted ?? ""
          },
          maximumFractionDigits: this.appParameter.nbDecimalForPriceDisplay,
        },
      },
    ];

    if (this.getGridMenuItems != null) {
      defs.unshift({
        colId: "menuIems",
        headerName: "",
        field: "",
        maxWidth: Constants.AGGridColumnsWidth.IsSelected,
        minWidth: Constants.AGGridColumnsWidth.IsSelected,
        suppressMovable: true,
        cellRendererParams: {
          canComment: false,
          i18n: this.i18n,
          gridOptions: this.gridOptions,
          router: this.router,
          menuItems: (params: ICellRendererParams) => this.getGridMenuItems != null ? this.getGridMenuItems(params) : [],
        },
        cellRenderer: "customButtonRenderer",
        suppressColumnsToolPanel: true,
        resizable: false,
        filter: false,
        pinned: "left",
        lockPosition: 'left'
      });
    }

    defs.forEach(def => {
      if (def.headerValueGetter != null) {
        def.headerName = def.headerTooltip = (<any>def).headerValueGetter();
        def.sortable = false;
      }
    });

    return defs;
  }

  public getGridMenuItems(params: ICellRendererParams): Array<IMenuGroup> {
    return [
      {
        group: "0",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("costcenter.addCostCenter"),
            icon: "digi-plus-cercle",
            disabled: () => {
              return this.gridOptions.api.isAnyFilterPresent()
            },
            items: [
              {
                label: this.i18n.tr("costcenter.onTopOfLine"),
                handler: () => {
                  this.createLine(parseInt(params.node.id), Constants.PriceOfferLineMoveAction.Up);
                },
                icon: "digi-arrow-up-line"
              },
              {
                label: this.i18n.tr("costcenter.childOfLine"),
                handler: () => {
                  this.createLine(parseInt(params.node.id), Constants.PriceOfferLineMoveAction.Into);
                },
                icon: "digi-arrow-right-line"
              },
              {
                label: this.i18n.tr("costcenter.belowLine"),
                handler: () => {
                  this.createLine(parseInt(params.node.id), Constants.PriceOfferLineMoveAction.Down);
                },
                icon: "digi-arrow-down-line"
              },

            ]
          }]
      },
      {
        group: "1",
        hiddenLabel: true,
        items: [
          this.meteringMenuItems.getDuplicate(params, this.costCenterApi, this as any, this.appParameter.id.toString(), null, this),
          this.meteringMenuItems.getCopy(params, this as any, Constants.LocalStorageName.CostCenter),
          this.meteringMenuItems.getPaste(params, Constants.LocalStorageName.CostCenter, this as any, this.costCenterApi, this.appParameter.id.toString(), null, this),
          this.meteringMenuItems.getMove(params, this.costCenterApi, this as any, false, this.appParameter.id.toString(), null, this)
        ]
      },
      {
        group: "4",
        hiddenLabel: true,
        items: [
          this.meteringMenuItems.getDeleteLine(params, this.costCenterApi, this as any, this.appParameter.id.toString(), null, this)
        ]
      }
    ];
  }
  private canEditCell(cell: { line: any, colField: string }): boolean {
    switch (cell.colField) {
      case "name":
      case "code":
      case "description":
      case "active":
      case "responsibleId":
      case "allocatedBudget":
        return true
      default:
        return false;
    }
  }
  getBottomRowRenderer() {
    return CostCenterFullWidthCellRenderer;
  }

  afterInitGridOption(gridOptions: GridOptions) {
    gridOptions.noRowsOverlayComponent = CostCenterOverlayComp;
    gridOptions.noRowsOverlayComponentParams = {
      priceOfferLinesGrid: this,
      noDataLabel: this.i18n.tr("grid.noRowsToShow"),
      meteringApi: this.costCenterApi,
      hideAddItem: true
    };
    gridOptions.fullWidthCellRendererParams = {
      listTree: this,
      apiService: this.costCenterApi,
      versionId: this.appParameter.id
    };
    gridOptions.getRowId = (params) => params.data.id;
    gridOptions.rowClassRules = {
      'ag-row-post': (params) => {
        return params.data?.priceOfferLineCategoryId == Constants.PriceOfferLineCategory.Data
      }
    }
  }


  timeoutId: NodeJS.Timeout | null = null;
  events: {
    lineId: number,
    propertyName: string,
    propertyValue: any
  }[] = [];

  async onCellValueChanged(event: CellValueChangedEvent) {
    if (event?.colDef == null) return;
    // Patch model
    let colField: string = event.colDef.field;
    let value: any = event.data[colField];
    this.events.push({ lineId: parseInt(event.data.id), propertyName: colField, propertyValue: value });

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(async () => {
      // Call api
      if (this.events.length == 0) return;

      let patchEvents = this.events

      this.events = [];
      this.timeoutId = null;

      let result = patchEvents.length == 1 ?
        await this.costCenterApi.patch(this.versionId, patchEvents[0].lineId, patchEvents[0].propertyName, patchEvents[0].propertyValue) :
        await this.costCenterApi.bulkPatch(this.versionId, patchEvents);
      // Manage api result: all modified entities
      if (result != null && result.length) {
        this.refreshVisibleNodes(result);
      } else {
        toastr.error(this.i18n.tr("metering.errorSave"));
      }
    }, 600);
  }

  public async refreshVisibleNodes(ids: number[], flashCells = true): Promise<Array<RowNode>> {
    return await this.listTreeData.refreshVisibleNodes(ids, flashCells);
  }

  public refreshServerSideRows(ids: number[], refreshAllTable = false, forcePurge = false): Array<RowNode> {
    return this.listTreeData.refreshServerSideRows(ids, refreshAllTable, forcePurge);
  }

  private async createLine(targetId: number, action: Constants.PriceOfferLineMoveAction) {
    let targetNode = this.gridOptions.api.getRowNode(targetId.toString());
    if (!targetNode) {
      console.warn('Target node not found!');
      return;
    }

    targetNode.data.isSelected = false;

    this.gridOptions.api.showLoadingOverlay();

    this.gls.allow();

    let ids = await this.costCenterApi.create(this.versionId, targetId, 0, action);
    this.gridOptions.api.hideOverlay();

    this.refreshServerSideRows(ids, targetNode.data.parentId == null && action != Constants.PriceOfferLineMoveAction.Into);

    switch (action) {
      case Constants.PriceOfferLineMoveAction.Into:
        targetNode.setExpanded(true);
        setTimeout(() => {
          this.gridOptions.api.startEditingCell({ rowIndex: (targetNode.childStore as any).allRowNodes[(targetNode.childStore as any).allRowNodes.length - 1].rowIndex, colKey: "name" });
        }, 250)
        break;
      case Constants.PriceOfferLineMoveAction.Up:
        setTimeout(() => {
          let newLine = this.listTreeData.dataLines.filter(x => x.lineLevel == targetNode.data.lineLevel && x.parentId == targetNode.data.parentId && x.order == targetNode.data.order)[0];
          let newNode = this.gridOptions.api.getRowNode(newLine.id.toString());
          this.gridOptions.api.startEditingCell({ rowIndex: newNode.rowIndex, colKey: "name" });
        }, 750)
        break;
      case Constants.PriceOfferLineMoveAction.Down:
        setTimeout(() => {
          let newLine = this.listTreeData.dataLines.filter(x => x.lineLevel == targetNode.data.lineLevel && x.parentId == targetNode.data.parentId && x.order == targetNode.data.order + 1)[0];
          let newNode = this.gridOptions.api.getRowNode(newLine.id.toString());
          this.gridOptions.api.startEditingCell({ rowIndex: newNode.rowIndex, colKey: "name" });
        }, 750)
        break;
    }
  }
}
