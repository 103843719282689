import { HttpClient } from 'aurelia-fetch-client';
import { DialogController } from 'aurelia-dialog';
import { CustomLogger, ServiceBase, ListViewModelBase, FieldType, ActionDialogBoxInputParameters, DialogBoxViewModel, GlobalLoaderService, Various } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import * as Constants from '../../constants';
import { Merlin } from 'generated';
import { GridHelperMerlin } from 'resources/elements/grid-helper';
import { ColDef } from 'ag-grid-community';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { SellingInvoiceStatusId } from '../../constants';
import { ReconciliationApiService } from 'services/reconciliation-service';

@autoinject
export class AddInvoice extends ListViewModelBase<Merlin.Web.Model.SellingInvoice> {
  projectId: any;

  get ribbonHeaderText() {
    return ""
  }

  private agGrid: any;

  private selectedInvoices: Array<InvoiceForReconciliation> = [];
  private nbDecimalForPriceDisplay: number;
  private appParameterService: ServiceBase<Merlin.Web.Model.ApplicationParameter>;

  private targetId: number
  private workQuotationVersionId: number;
  private priceOfferVersionId: number;
  private supplierOfferId: number;
  private clientAdditionalWorkVersionId: number;
  private itemCompostionId: number;
  private parent: any;
  private title: string;

  private selectedItemsUITable: HTMLElement;
  private viewModule: string;
  private transactionId: number;


  constructor(router: Router, logger: CustomLogger, private dialogController: DialogController, private httpClient: HttpClient, private globalLoaderService: GlobalLoaderService, private reconciliationApiService: ReconciliationApiService) {
    super(router, logger, new ServiceBase<Merlin.Web.Model.SellingInvoice>(Constants.EntityTypeNames.SellingInvoice));
    this.service.gridDataSource.queryParameters = {
      "invoiceStatusId": SellingInvoiceStatusId.Validated
    };
    this.service.gridDataSource.expands = ['invoiceStatus', 'invoiceType']
    this.appParameterService = new ServiceBase<Merlin.Web.Model.ApplicationParameter>(Constants.EntityTypeNames.ApplicationParameter);
  }

  public ressourceName: string = Constants.EntityTypeNames.RequestSupplierOffer;

  public async activate(params: any) {
    if (params.transactionId != null) {
      this.transactionId = params.transactionId;
    }

    this.nbDecimalForPriceDisplay = (await this.appParameterService.getEntityById(1)).nbDecimalForPriceDisplay;

    this.title = this.i18n.tr("sellinginvoice.linkTransactionwithInvoice")

  }

  public async attached() {
    await super.attached();
    this.agGrid.calculateBodyHeight(0);
  }

  async close() {
    if (this.selectedInvoices.length > 0) {
      let buttonYes: ActionDialogBoxInputParameters =
      {
        label: this.i18n.tr("general.yes", { ns: "common" }),
        title: this.i18n.tr("general.yes", { ns: "common" }),
        theme: 'primary',
        type: 'solid',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.ok();
        }
      };
      let buttonNo: ActionDialogBoxInputParameters =
      {
        label: this.i18n.tr("general.no", { ns: "common" }),
        title: this.i18n.tr("general.no", { ns: "common" }),
        theme: 'dark',
        type: 'ghost',
        disabled: false,
        fn: (thisBox: DialogBoxViewModel) => {
          thisBox.controller.cancel();
        }
      };
      await this.box.showQuestion(this.i18n.tr('priceofferline.cancelAddItem'), this.i18n.tr('menu.question'), [buttonNo, buttonYes]).whenClosed(
        async (result) => {
          if (!result.wasCancelled) {
            await this.dialogController.close(true);
          }
        }
      )
    }
    else {
      await this.dialogController.close(true);
    }

  }

  async ok() {
    this.globalLoaderService.allow();
    let result = await this.reconciliationApiService.linkTransactionToInvoices(this.transactionId, this.selectedInvoices.map(invoice => invoice.invoiceId));
    if (result) {
      this.dialogController.close(true, result);
    }

    return [];
  }

  onCellClicked(entity: Merlin.Web.Model.SellingInvoice): boolean | null {
    let newLine = this.createLineFromItem(entity);
    this.selectedInvoices.push(newLine);

    setTimeout(() => {
      this.selectedItemsUITable?.scrollTo({ top: this.selectedItemsUITable.scrollHeight });
    }, 300);

    return true;
  }

  public getMenuItems(params) {
    return [
      {
        group: "1",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.remove"),
            icon: "digi-trash",
            handler: () => {
              this.removeItem(params);
            }
          }
        ]
      },
      {
        group: "2",
        hiddenLabel: true,
        items: [
          {
            label: this.i18n.tr("menu.removeAll"),
            icon: "digi-trash",
            handler: () => {
              this.removeAllItems();
            }
          }
        ]
      }
    ];
  }

  removeAllItems() {
    this.selectedInvoices.splice(0);
  }
  removeItem(record: InvoiceForReconciliation) {
    this.selectedInvoices.splice(this.selectedInvoices.indexOf(record), 1);
  }

  createLineFromItem(invoice: Merlin.Web.Model.SellingInvoice): InvoiceForReconciliation {
    if (this.selectedInvoices.findIndex(x => x.invoiceId == invoice.id) == -1) {
      return {
        invoiceId: invoice.id,
        invoice: invoice,
      };
    }
  }

  public initializeGridOptions() {
    super.initializeGridOptions(false, false);
  }

  public afterFilterChanged() {
    setTimeout(() => {
      this.agGrid.calculateBodyHeight(0);
    }, 1);
  }

  public getDataGridColumns() {
    let thirdPartiesService: ServiceBase<Merlin.Web.Model.ThirdParty> = new ServiceBase<Merlin.Web.Model.ThirdParty>(Constants.EntityTypeNames.ThirdParty);
    let projectsService: ServiceBase<Merlin.Web.Model.Project> = new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project);
    let vatService: ServiceBase<Merlin.Web.Model.VATLevel> = new ServiceBase<Merlin.Web.Model.VATLevel>(Constants.EntityTypeNames.VATLevel);

    let defs: ColDef[] = [
      ...new GridHelperMerlin().contextMenuColdDef(this, this.getMenuItems),
      {
        headerName: this.i18n.tr("sellinginvoice.invoiceTypeId"),
        field: "invoiceType.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.SellingInvoiceType,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.SellingInvoiceType,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.invoiceStatusId"),
        field: "invoiceStatus.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.SellingInvoiceStatus,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.SellingInvoiceStatus,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.clientId"),
        field: "client.fullName",
        type: FieldType.OneToMany,
        filterParams: {
          service: thirdPartiesService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: thirdPartiesService
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.projectId"),
        field: "project.projectName",
        type: FieldType.OneToMany,
        filterParams: {
          service: projectsService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: projectsService
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.invoiceDate"),
        field: "invoiceDate",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormat
        },
        type: FieldType.Date,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.imposedInvoiceDate"),
        field: "imposedInvoiceDate",
        type: FieldType.Boolean,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.invoiceNumber"),
        field: "invoiceNumber",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.payementStatusId"),
        field: "payementStatus.denomination._translation",
        type: FieldType.Enumeration,
        filterParams: {
          category: Constants.EnumerationTypes.PaymentStatus,
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.PaymentStatus,
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.dueDate"),
        field: "dueDate",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormat
        },
        type: FieldType.Date,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.noteMultiline"),
        field: "noteMultiline",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.payementCommunication"),
        field: "paymentCommunication",
        type: FieldType.String,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.lastEmailSendingDateTime"),
        field: "lastEmailSendingDateTime",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormat
        },
        type: FieldType.Date,
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.defaultVATSchemeId"),
        field: "defaultVATScheme.code",
        type: FieldType.OneToMany,
        filterParams: {
          service: vatService
        },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: vatService
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.totalExclVAT"),
        field: "totalExclVAT",
        type: FieldType.Number,
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.totalVAT"),
        field: "totalVAT",
        type: FieldType.Number,
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      {
        headerName: this.i18n.tr("sellinginvoice.totalIncVat"),
        field: "total",
        type: FieldType.Number,
        valueFormatter: (data) => {
          if (data.data?.[data.colDef.field] != null) {
            return (!isNaN(data.data[data.colDef.field]) ? new Intl.NumberFormat(this.config.globalConfig.defaultLocale, { style: "currency", currency: "EUR", minimumFractionDigits: this.nbDecimalForPriceDisplay, maximumFractionDigits: this.nbDecimalForPriceDisplay }).format(data.data[data.colDef.field]) : data.data[data.colDef.field]);
          }
          return;
        },
        showRowGroup: this.i18n.tr("groupTabPanel.generalInformation")
      },
      ...new GridHelperMerlin().baseEntityColDef(this),
    ];
    return defs;
  }
}

export class InvoiceForReconciliation {
  invoiceId: number;
  invoice: Merlin.Web.Model.SellingInvoice;
}
