@import "./../css/scss/variables";

.row.summary {
  height: 100%;
  .flex-container-grid-financial-center {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "chart-1 invoice-container";
    gap: 20px;
    .chart-1 {
      grid-area: chart-1;
      display: grid;
      grid-template-columns: 15% 85%;
      > .first-part {
        align-self: center;
        > div {
          text-align: center;
        }
        .label-primary {
          color: $primary;
        }
      }
    }
    div.rounded.invoice-container {
      grid-area: invoice-container;
    }

    div.rounded {
      border: solid 1px $gray-lighter;
      border-radius: $radius;
      padding: 1.5rem;
      position: relative;
    }

    ui-button[data-type="tool"] {
      padding: 5px;
      border-radius: 50%;
      &.btn-floating-project {
        position: absolute;
        bottom: 10px;
        right: 10px;
      }
    }
  }
}
