import { FilterQueryOp, Predicate } from 'breeze-client';
import { PriceOfferLineCategory } from './../../../constants';
import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, IMenuGroup, DialogBoxViewModel, ActionDialogBoxInputParameters, Various, EnumerationType, EnumerationTypeService, IMenuItems, lab, KeyboardShortcut, Box, GlobalLoaderService, PictureHelper } from 'digiwall-lib';
import { autoinject, BindingEngine, computedFrom, Container, observable } from 'aurelia-framework';
import * as Constants from '../../../constants';
import { Merlin } from 'generated';
import { ColumnVisible } from 'module-list-tree-data/module-list-tree-data';
import { ContractSupplierOfferApiService } from 'services/contract-supplier-offer-api-service';
import { QuillDialog } from 'quill-dialog/quill-dialog';
import { ProjectApiService } from 'services/project-api-service';
import moment = require('moment');
import * as toastr from 'toastr';
import { PriceOfferLinesGrid } from 'price-offer-lines/price-offer-lines-grid';
import { ThirdPartyContractMenuItems } from './third-party-contract-menu-item';
import { AmendmentCreation } from './amendment/amendment-creation';
import { PriceOfferGroupId, ExcelGenerationId } from 'projects/quotes/price-offer-version/price-offer-version-detail';
import { SelectMappingInject } from 'projects/quotes/select-mapping-inject/select-mapping-inject';
import { SendOffer, ReturnParams, GenerationApiParams } from 'projects/quotes/send-offers/send-offer';
import { I18N } from 'aurelia-i18n';
import { ReportApiService } from 'services/report-api-service';
import { EditDialogAction } from 'resources/utilities/edit-dialog-actions';
@autoinject
export class ThirdPartyContractDetail extends EntityDetailViewModelBase<Merlin.Web.Model.ThirdPartyContract> {
  public ressourceName: string = Constants.EntityTypeNames.ThirdPartyContract;
  private menuItems: Array<IMenuGroup>;
  private totalPrice: number;
  private screenExpand: boolean = false;
  public specificConditionsService: ServiceBase<Merlin.Web.Model.ContentTemplate>;
  private signatoryService: ServiceBase<Merlin.Web.Model.ContractSignatory>;
  private params: any
  private nbDecimalForPriceDisplay: number
  private nbDecimalForQuantityDisplay: number

  private thirdPartyContractStatusValidated = Constants.ThirdPartyContractStatusId.Validated;
  public thirdPartyContractStatus: Array<EnumerationType>;

  private thirdPartyContractStatusService: EnumerationTypeService;

  public priceOfferLinesGrid: PriceOfferLinesGrid;
  public canCreateAmendment: boolean = false;

  public project: Merlin.Web.Model.Project;

  @observable
  public selectedContractId: number;
  public selectedContract: Merlin.Web.Model.ThirdPartyContract;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private contractSupplierOfferVersionApiService: ContractSupplierOfferApiService, private projectApiService: ProjectApiService, private httpClient: HttpClient, private reportApiService: ReportApiService, private gls: GlobalLoaderService, private fileHelper: PictureHelper) {
    super(router, logger);
    super.initialize(new ServiceBase<Merlin.Web.Model.ThirdPartyContract>(Constants.EntityTypeNames.ThirdPartyContract));
    this.specificConditionsService = new ServiceBase<Merlin.Web.Model.ContentTemplate>(Constants.EntityTypeNames.ContentTemplate);
    this.specificConditionsService.gridDataSource.queryParameters = { contentTypeId: Constants.ContentTypeId.SpecificBuyingCondition }
    this.thirdPartyContractStatusService = new EnumerationTypeService(Constants.EnumerationTypes.ThirdPartyContractStatus);
    this.signatoryService = new ServiceBase<Merlin.Web.Model.ContractSignatory>(Constants.EntityTypeNames.ContractSignatory);
  }
  @computedFrom("isCreationMode", "selectedContract.id")
  get ribbonHeaderText(): string {
    if (!this.isCreationMode && this.entity.id == this.selectedContractId)
      return this.i18n.tr('thirdpartycontract.contract') + (this.entity?.numContract != null ? ': ' + this.entity?.numContract : "");
    else if (this.isCreationMode)
      return this.i18n.tr('thirdpartycontract.contract') + ': ' + this.i18n.tr('thirdpartycontract.draft');
    else
      return this.i18n.tr('amendment.amendment') + (this.selectedContract?.numContract != null ? ': ' + this.selectedContract?.numContract : "");

  }
  @computedFrom("entity", "entity.numContract")
  public get documentTitle() {
    return this.entity?.numContract;
  }

  @computedFrom("selectedContract", "selectedContract.thirdPartyContractStatusId", "selectedContract.id")
  public get isValidated() {
    return this.selectedContract?.thirdPartyContractStatusId == Constants.ThirdPartyContractStatusId.Validated
  }

  public async activate(params: any) {
    this.contractSupplierOfferVersionApiService.showAmendment = false;
    let id: number = params.contractId;
    this.params = params
    await super.activate(params);
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      this.contractSupplierOfferVersionApiService.supplierOfferId = params.supplierOfferId;
      this.contractSupplierOfferVersionApiService.contractId = null;
    }
    else {
      this.contractSupplierOfferVersionApiService.supplierOfferId = null;
      this.editingMode = EditingModeEnum.Update;
      await this.loadEntity(id);
      if (params.amendmentId != null) {
        this.selectedContractId = params.amendmentId;
      }
      else {
        this.selectedContractId = this.entity.id;
      }
      this.contractSupplierOfferVersionApiService.contractId = this.selectedContractId;

      this.controller.addObject(this.entity);
      //this.updateOnChanges();
      this.canCreateAmendment = await this.contractSupplierOfferVersionApiService.canCreateAmendment(this.entity.id);
    }
    this.thirdPartyContractStatus = await this.thirdPartyContractStatusService.getAll();
    this.getTotalPrice();
    this.setMenuItems();

    this.project = await new ServiceBase<Merlin.Web.Model.Project>(Constants.EntityTypeNames.Project).getEntityById(parseInt(params.projectId), 'mainThirdParty');

    this.nbDecimalForPriceDisplay = this.project.nbDecimalForPriceDisplay;
    this.nbDecimalForQuantityDisplay = this.project.nbDecimalForQuantityDisplay;
  }

  private async loadEntity(id: number) {
    this.entity = await this.service.getEntityById(id, 'specificConditions.contentTemplate', 'amendments', 'supplierType', 'contractType');
    this.computeAmendmentTotalPrices();
  }

  async deactivate() {
    await this.priceOfferLinesGrid?.deactivate()
  }
  public canDeactivate(): Promise<boolean> {
    if (!this.isCreationMode) {
      return super.canDeactivate()
    }
    else {
      this.entity.entityAspect.setDetached();
      return new Promise<boolean>((resolve, reject) => {
        return resolve(true);
      });
    }
  }

  private updateOnChanges(): void {
    this.disposables?.forEach(x => x.dispose());
    this.disposables.push(this.bindingEngine.propertyObserver(this.selectedContract, "signedDate").subscribe(async (newValue, oldValue) => {
      if (oldValue == newValue) return;
      await this.service.saveEntity(this.selectedContract, true);
    }));
    this.disposables.push(this.bindingEngine.propertyObserver(this.entity, "additionalSpecificConditionHtml").subscribe(async (newValue, oldValue) => {
      if (oldValue == newValue) return;
      await this.service.saveEntity(this.entity, true);
    }));
    this.disposables.push(this.bindingEngine.collectionObserver(this.entity.specificConditions).subscribe(async (changes) => {
      await this.service.saveEntity(this.entity, true);
    }));
    this.disposables.push(this.bindingEngine.propertyObserver(this.selectedContract, "thirdPartyContractStatusId").subscribe(async (newValue, oldValue) => {
      await this.loadEntity(this.entity.id)
    }))
  }

  public editAdditionalSpecificConditionHtml(): void {
    this.box.showEditDialog(QuillDialog, null, this.i18n.tr('thirdpartycontract.additionalSpecificConditionHtml'),
      {
        canSave: false,
        model: { bodyText: this.entity.additionalSpecificConditionHtml },
        size: 'md',
        actions: [
          EditDialogAction.GetCancelAction(this.i18n),
          {
            label: this.i18n.tr("menu.save"),
            closeDialog: false,
            theme: "primary",
            type: "solid",
            icon: "digi-check",
            fn: async (dialogBoxViewModel: DialogBoxViewModel) => {
              let bodyText = await dialogBoxViewModel.customVM?.currentViewModel?.bodyText;
              this.entity.additionalSpecificConditionHtml = bodyText;
              dialogBoxViewModel.controller.ok();
            }
          } as ActionDialogBoxInputParameters
        ]
      });
  }

  public override async save(silentSave: boolean) {
    if (!this.isCreationMode) {
      super.save(silentSave);
    }
    else {
      let thirdPartyTypeId = await ThirdPartyContractDetail.getSupplierTypeId(this.params.supplierOfferId);
      let id = await this.contractSupplierOfferVersionApiService.save(this.params.projectId, this.params.supplierOfferId, thirdPartyTypeId)
      if (id != null) {
        this.entity.entityAspect.setDetached();
        let fragment = this.router.currentInstruction.fragment;
        if (fragment == null || fragment == '') {
          fragment = this.router.baseUrl;
        }
        if (this.router.currentInstruction.queryString != null) {
          fragment += '?' + this.router.currentInstruction.queryString;
        }
        let newRoute = fragment.replace('-100', id);
        this.router.navigate(newRoute, { replace: true, trigger: true });
        return
      }
    }
  }

  public static async getSupplierTypeId(supplierOfferId: number) {
    let i18n = Container.instance.get(I18N);
    let box = Container.instance.get(Box);
    let contractSupplierOfferVersionApiService = Container.instance.get(ContractSupplierOfferApiService);

    let buttonSupplier: ActionDialogBoxInputParameters =
    {
      label: i18n.tr("thirdparty.supplier", { ns: "common" }),
      title: i18n.tr("thirdparty.supplier", { ns: "common" }),
      theme: 'primary',
      type: 'solid',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(Constants.ThirdPartyType.Supplier);
      },
      keyboardShortcut: KeyboardShortcut.Enter
    };
    let buttonSubContractor: ActionDialogBoxInputParameters =
    {
      label: i18n.tr("thirdparty.subContractor", { ns: "common" }),
      title: i18n.tr("thirdparty.subContractor", { ns: "common" }),
      theme: 'primary',
      type: 'solid',
      disabled: false,
      fn: (thisBox: DialogBoxViewModel) => {
        thisBox.controller.ok(Constants.ThirdPartyType.SubContractor);
      },
      keyboardShortcut: KeyboardShortcut.Escape
    };

    var thirdPartyTypes = (await contractSupplierOfferVersionApiService.getThirdPartyType(supplierOfferId)).filter(id => id == Constants.ThirdPartyType.Supplier || id == Constants.ThirdPartyType.SubContractor);
    var thirdPartyTypeId: number;
    if (thirdPartyTypes.length != 1) {
      await box.showQuestion(i18n.tr('thirdpartycontract.askThirdPartyType'), i18n.tr("menu.question", { ns: "common" }), [buttonSupplier, buttonSubContractor])
        .whenClosed(async (result) => {
          thirdPartyTypeId = result.output
        });
    }
    else {
      thirdPartyTypeId = thirdPartyTypes[0];
    }
    return thirdPartyTypeId;
  }

  public canEditCell(line: { priceOfferLineCategoryId: number }, colField: string) {
    switch (colField) {
      case "supplierPrice":
      case "supplierQuantity":
        return line.priceOfferLineCategoryId == PriceOfferLineCategory.Data && this.editingMode == EditingModeEnum.Update && this.selectedContract.thirdPartyContractStatusId != Constants.ThirdPartyContractStatusId.Validated;
      default:
        return false;
    }
  }

  public cellValueChanged(colField: string) {
    if (colField == null || colField.trim().length == 0) return;
    switch (colField) {
      case "lineDescription":
      case "additionalDescriptionMultiline":
      case "quantityTypeId":
      case "unitId":
      case "activityCodeId":
      case "tags":
      case "priceOfferLineFileCount":
        return;// Don't getMeteringTotalPrices for these properties
    }

    this.getTotalPrice();
    this.loadEntity(this.entity.id);
  }

  //#region Grid Settings

  private async getTotalPrice() {
    if (this.isCreationMode) {
      this.totalPrice = await this.contractSupplierOfferVersionApiService.getTotalPriceDraft(this.params.supplierOfferId);
    }
    else {
      this.totalPrice = await this.contractSupplierOfferVersionApiService.getTotalPrice(this.selectedContractId);
    }
  }

  public getGridMenuItems = () => [];

  public triggerExpand() {
    this.screenExpand = !this.screenExpand;
  }

  private setMenuItems() {
    if (this.isCreationMode || this.selectedContract == null) {
      this.menuItems = [];
    } else {
      this.menuItems = [
        {
          group: "1",
          hiddenLabel: true,
          items: ThirdPartyContractMenuItems.getStatusMenuItems(this.selectedContract, this.thirdPartyContractStatus, null, this.service)
        },
        AmendmentCreation.getCreateAmendmentMenuItem(this.params.projectId, null, this.selectedContractId, !this.canCreateAmendment || this.selectedContract.thirdPartyContractStatusId != Constants.ThirdPartyContractStatusId.Validated,
          async () => {
            this.canCreateAmendment = await this.contractSupplierOfferVersionApiService.canCreateAmendment(this.selectedContractId);
            this.setMenuItems();
          }),
        {
          group: "2",
          hiddenLabel: true,
          items: [
            {
              label: this.selectedContract.contractTypeId == Constants.ThirdPartyContractTypeId.BaseContract ? this.i18n.tr('thirdpartycontract.previewContract') : this.i18n.tr('thirdpartycontract.previewAmendment'),
              icon: "digi-search",
              handler: async () => {
                await this.previewContract();
              },
            },
            {
              label: this.selectedContract.contractTypeId == Constants.ThirdPartyContractTypeId.BaseContract ? this.i18n.tr('thirdpartycontract.sendContract') : this.i18n.tr('thirdpartycontract.sendAmendment'),
              icon: "digi-mail-send-line",
              handler: () => {
                this.showSendOfferBox();
              }
            }
          ]
        }
      ];
    }
  }

  public columnVisible: Array<ColumnVisible> =
    [
      { colId: "menuIems", visibility: true },
      { colId: "selected", visibility: true },
      { colId: "merlinRef", visibility: true },
      { colId: "merlinRefMetering", visibility: false },
      { colId: "merlinRefContext", visibility: true },
      { colId: "lineDescription", visibility: true },
      { colId: "lineRef", visibility: true },
      { colId: "originalMeasurementXlsLineNumber", visibility: true },
      { colId: "sheetIndex", visibility: true },
      { colId: "importFileName", visibility: true },
      { colId: "originalProposedQuantity", visibility: false },
      { colId: "quantityTypeId", visibility: true },
      { colId: "unitId", visibility: true },
      { colId: "proposedQuantity", visibility: false },
      { colId: "proposedQuantityFormula", visibility: false },
      { colId: "priceOfferLineFileCount", visibility: true },
      { colId: "additionalDescriptionMultiline", visibility: false },
      { colId: "requestedQuantity", visibility: false },
      { colId: "dataLineDeliveries", visibility: false },
      { colId: "dataLineActivities", visibility: false },
      { colId: "buyingUnitPrice", visibility: false },
      { colId: "definedSellingUnitPrice", visibility: false },
      { colId: "definedDirectMarginPrice", visibility: false },
      { colId: "totalBuyingPrice", visibility: false },
      { colId: "totalSellingPrice", visibility: false },
      { colId: "tags", visibility: false },
      { colId: "supplierPrice", visibility: true },
      { colId: "supplierQuantity", visibility: true },
      { colId: "supplierQuantityTypeId", visibility: true },
      { colId: "supplierTotal", visibility: true },
      { colId: "supplierComment", visibility: true },
      { colId: "acceptedPriceOfferBuyingUnitPrice", visibility: false },
      { colId: "buildingCostsId", visibility: true },
    ];

  //#endregion

  internalCustomSettingMenuItems(): IMenuItems[] {
    //if (this.selectedContract.contractTypeId == Constants.ThirdPartyContractTypeId.BaseContract) {
    return [
      {
        group: "2",
        hiddenLabel: true,
        items: [
          {

            label: this.i18n.tr("thirdpartycontract.showAmendment"),
            hidden: () => this.contractSupplierOfferVersionApiService.showAmendment || this.entity.id != this.contractSupplierOfferVersionApiService.contractId,
            handler: async () => {
              this.contractSupplierOfferVersionApiService.showAmendment = true;
              this.priceOfferLinesGrid.refreshServerSideRows(null, true, true);
              await this.getTotalPrice();
            }
          },
          {
            label: this.i18n.tr("thirdpartycontract.hideAmendment"),
            hidden: () => !this.contractSupplierOfferVersionApiService.showAmendment || this.entity.id != this.contractSupplierOfferVersionApiService.contractId,
            handler: async () => {
              this.contractSupplierOfferVersionApiService.showAmendment = false;
              this.priceOfferLinesGrid.refreshServerSideRows(null, true, true);
              await this.getTotalPrice();
            }
          }

        ]
      },
    ];
    // }
    // else {
    //   return [];
    // }
  }

  private computeAmendmentTotalPrices() {
    if (this.entity.amendments.length > 0) {
      this.entity.amendments.forEach(async amendment => {
        (amendment as any).totalPrice = await this.contractSupplierOfferVersionApiService.getTotalPrice(amendment.id);
      });
    }
  }

  private async changeDisplayedContract(id: number) {
    if (id != this.selectedContractId) {
      this.selectedContractId = id;
      this.contractSupplierOfferVersionApiService.contractId = id;
      this.contractSupplierOfferVersionApiService.showAmendment = false;
      await this.getTotalPrice();

      this.priceOfferLinesGrid.refreshServerSideRows(null, true, true);
    }
  }

  private async selectedContractIdChanged(newVal: number, oldVal: number) {
    if (newVal != oldVal && newVal != null) {
      this.selectedContract = await this.service.getEntityById(newVal, 'specificConditions.contentTemplate', 'contractType', 'supplierType', 'contractSignatories');
      this.updateOnChanges();
      this.setMenuItems();
    }
  }

  private showSendOfferBox() {
    this.box.showCustomDialog(
      SendOffer,
      this.selectedContract.id,
      this.selectedContract.contractTypeId == Constants.ThirdPartyContractTypeId.BaseContract ? this.i18n.tr('thirdpartycontract.sendContract') : this.i18n.tr('thirdpartycontract.sendAmendment'),
      {
        canSave: false,
        size: 'lg',
        model: {
          generationParams: {
            projectTypeId: this.project.projectTypeId,
            projectId: this.project.id,
            companyIds: [this.selectedContract.supplierOrSubcontractorId],
            coverLetterContentHtml: this.selectedContract.coverLetterContentHtml,
            annexContentHtml: this.selectedContract.annexContentHtml,
            coverLetterContext: this.getCoverLetterContext(),
            annexContext: this.getAnnexContext(),
            emailContext: this.getEmailContext(),
            languageId: this.project.communicationLanguageId,
            signatoryIds: this.selectedContract.contractSignatories.map(x => x.signatoryUserId),
            previewRequestParams: {
              projectId: this.project.id,
              contractId: this.entity.id,
            },
            hasSignatoryExternalStep3: false,
            hasSignatoryInternalStep3: true,
            afterStep2: async (data: ReturnParams) => {
              await this.handleGenerationReturnParams(data);
            },
            items: [
              {
                id: 1,
                name: this.i18n.tr("priceofferversion.pdf"),
                items: [
                  {
                    id: 1,
                    icon: "digi-file-sync",
                    title: this.i18n.tr("priceofferversion.generate"),
                    description: this.i18n.tr("priceofferversion.pfdHasInternalModel"),
                    selected: true,
                    disabled: false,
                    handler: async (data: GenerationApiParams) => {
                      return await this.reportApiService.getContractPdf(this.selectedContractId, data.internSelectedId, data.selectedRefToDisplay);
                    }
                  }
                ]
              }
            ]
          }
        }
      }
    ).whenClosed(async (result) => {
      if (!result.wasCancelled) {
        await this.handleGenerationReturnParams(result.output)
      }
    });
  }

  async handleGenerationReturnParams(data: ReturnParams) {
    this.selectedContract.annexContentHtml = data.annexContentHtml;
    this.selectedContract.coverLetterContentHtml = data.coverLetterContentHtml;
    this.service.saveEntity(this.selectedContract, true);

    if (this.selectedContract.contractSignatories.length > 0)
      await this.signatoryService.deleteEntities(this.selectedContract.contractSignatories, false, null, true);

    data.signatoryIds.forEach(async (signatoryId) => {
      this.selectedContract.contractSignatories.push(await this.signatoryService.createEntity({ signatoryUserId: signatoryId, thirdPartyContractId: this.selectedContract.id }));
    });
    await this.signatoryService.saveEntities(this.selectedContract.contractSignatories, true);
  }

  private getCoverLetterContext() {
    if (this.selectedContract.contractTypeId == Constants.ThirdPartyContractTypeId.BaseContract) {
      return this.selectedContract.supplierTypeId == Constants.ThirdPartyType.Supplier ? Constants.ContentTypeId.SupplierContractCoverLetter : Constants.ContentTypeId.SubContractorContractCoverLetter;
    }
    else {
      return this.selectedContract.supplierTypeId == Constants.ThirdPartyType.Supplier ? Constants.ContentTypeId.SupplierAmendmentCoverLetter : Constants.ContentTypeId.SubContractorAmendmentCoverLetter;
    }
  }

  private getAnnexContext() {
    if (this.selectedContract.contractTypeId == Constants.ThirdPartyContractTypeId.BaseContract) {
      return this.selectedContract.supplierTypeId == Constants.ThirdPartyType.Supplier ? Constants.ContentTypeId.SupplierContractAnnex : Constants.ContentTypeId.SubContractorContractAnnex;
    }
    else {
      return this.selectedContract.supplierTypeId == Constants.ThirdPartyType.Supplier ? Constants.ContentTypeId.SupplierAmendmentAnnex : Constants.ContentTypeId.SubContractorAmendmentAnnex;
    }
  }

  private getEmailContext() {
    if (this.selectedContract.contractTypeId == Constants.ThirdPartyContractTypeId.BaseContract) {
      return this.selectedContract.supplierTypeId == Constants.ThirdPartyType.Supplier ? Constants.EmailContextId.SupplierContract : Constants.EmailContextId.SubContractorContract;
    }
    else {
      return this.selectedContract.supplierTypeId == Constants.ThirdPartyType.Supplier ? Constants.EmailContextId.SupplierAmendment : Constants.EmailContextId.SubContractorAmendment;
    }
  }
  private async previewContract() {
    this.gls.allow();
    var result = await this.reportApiService.getPreviewContractPdf(this.selectedContractId);
    this.fileHelper.previewFile(result);
  }
  public async getExcelFileName() {
    return this.project.projectName + "_" + this.i18n.tr("projectindex.buying") + "_" + this.i18n.tr("buyingindex.contract") + "_" + this.entity.numContract
  }
}
