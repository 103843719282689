import { DataFormat } from 'select2';
import { TaskDTO, TaskFileDTO } from './model/module-task-model';
import { autoinject, BindingEngine, computedFrom, Disposable, TaskQueue } from "aurelia-framework";
import { bindable, customElement } from "aurelia-templating";
import { TaskApiService } from './service/task-api-service';
import { IMenuGroup, ServiceBase, EnumerationTypeService, EnumerationType, Box, UIInternal, PictureHelper } from 'digiwall-lib';
import { Merlin } from 'generated';
import * as Constants from '../constants';
import { TaskDetail } from 'tasks/task-detail';
import { I18N } from 'aurelia-i18n';
import { EditDialogAction } from 'resources/utilities/edit-dialog-actions';
import { NotificationTaskService } from './service/notification-task-service';

@autoinject
@customElement('task-component')
export class TaskComponent {
  @bindable task: TaskDTO;
  @bindable menuItems: Array<IMenuGroup>;
  @bindable refreshData: (params: { purge: boolean, id: number | null }) => Promise<void>;

  private userService: ServiceBase<Merlin.Web.Model.MerlinUser>;
  private projectService: ServiceBase<Merlin.Web.Model.Project>;
  private taskTypeService: EnumerationTypeService;
  private taskStatusService: EnumerationTypeService;
  private taskCriticityService: EnumerationTypeService;
  private taskTagsService: EnumerationTypeService;

  public statusMenuItems: Array<IMenuGroup> = [];

  private userList: Array<Merlin.Web.Model.MerlinUser>;
  private taskTypeList: Array<EnumerationType>
  private taskStatusList: Array<EnumerationType>
  private taskCriticityList: Array<EnumerationType>
  private taskTagsList: Array<EnumerationType>

  private files: Array<TaskFileDTO> = [];

  private isEditingAssigned: boolean = false;
  private isEditingType: boolean = false;
  private isEditingCriticity: boolean = false;
  private isEditingStartingDate: boolean = false;
  private isEditingDeadlineDate: boolean = false;
  private isEditingTag: boolean = false;
  private isEditingDurationInDay: boolean = false;

  public selectedResponsible: DataFormat = null;

  constructor(public api: TaskApiService, public bindingEngine: BindingEngine, private box: Box, private i18n: I18N, private fileHelper: PictureHelper, private notificationService: NotificationTaskService) {
    this.userService = new ServiceBase(Constants.EntityTypeNames.MerlinUser);
    this.projectService = new ServiceBase(Constants.EntityTypeNames.Project);
    this.taskStatusService = new EnumerationTypeService(Constants.EnumerationTypes.TaskStatus);
    this.taskTypeService = new EnumerationTypeService(Constants.EnumerationTypes.TaskType);
    this.taskCriticityService = new EnumerationTypeService(Constants.EnumerationTypes.TaskCriticityLevel);
    this.taskTagsService = new EnumerationTypeService(Constants.EnumerationTypes.TaskTag);
  }

  async attached() {
    this.userList = await this.userService.getAllEntities();
    this.taskStatusList = await this.taskStatusService.getAll();
    this.taskTypeList = await this.taskTypeService.getAll();
    this.taskCriticityList = await this.taskCriticityService.getAll();
    this.taskTagsList = await this.taskTagsService.getAll();
    await this.init();
    document.body.addEventListener('click', this.clickOutsideListener);
  }

  detached() {
    document.body.removeEventListener('click', this.clickOutsideListener);
  }

  private async init() {
    let responsible = await this.userService.getEntityById(this.task.responsibleId);
    this.selectedResponsible = { id: this.task.responsibleId, text: responsible.firstName }
    this.setMenuItems();
    await this.getFiles();
  }

  async taskChanged(newVal, oldVal) {
    this.resetEditField();
    await this.init();
  }

  //Save
  async valueChanged(value, propertyName) {
    await this.api.patch(this.task.id, propertyName, value);
    await this.refreshData({ purge: true, id: null });
    this.resetEditField();
    if (propertyName == "ResponsibleId") {
      let user = await this.userService.getEntityById(value);
      let project = await this.projectService.getEntityById(this.task.projectId);
      await this.notificationService.sendNotificationTaskAssignement(user, project, this.task.id, this.task.title);
    }
    if (propertyName == 'TaskStatusId' && value == Constants.TaskStatusId.Finalized) {
      let user = await this.userService.getEntityById(this.task.createdById);
      let project = await this.projectService.getEntityById(this.task.projectId);
      await this.notificationService.sendNotificationTaskDone(user, project, this.task.responsibleId, this.task.id);
    }
  }

  private createSubTask() {
    this.box.showEditDialog(TaskDetail, -100, this.i18n.tr('taskjob.addSubTask') + ': ' + this.task.title, (VM: TaskDetail) => {
      VM.entity.isSubTask = true;
      VM.fromPopup = true;
      VM.parentTaskId = this.task.id;
    },
      {
        canSave: false,
        actions: [
          ...EditDialogAction.GetBaseAction(this.i18n)
        ]
      }).whenClosed(async result => {
        if (!result.wasCancelled) {
          await this.refreshData({ purge: true, id: null });
        }
      });
  }

  @computedFrom('task.taskStatus.id', 'task.taskStatus', 'taskStatusList')
  public get getButtonStatusLabel() {
    return this.taskStatusList?.filter(x => x.id == this.task.taskStatus.id)[0]?.denomination._translation;
  }

  private setMenuItems() {
    let temp = this.createStatusItem();
    this.statusMenuItems = [
      {
        group: "1",
        hiddenLabel: true,
        items: temp
      }
    ];
  }

  private createStatusItem() {
    let items = []
    this.taskStatusList?.forEach(status => {
      items.push(
        {
          label: status.denomination._translation,
          handler: async () => {
            await this.valueChanged(status.id, 'TaskStatusId');
          }
        }
      )
    });
    return items;
  }

  previewDocument(document: Merlin.Web.Model.Document) {
    this.fileHelper.previewFile(document);
  }

  private async getFiles() {
    this.files.splice(0)
    let result = await this.api.getFiles(this.task.id);
    this.files.push(...result);
  }

  resetEditField() {
    this.isEditingAssigned = false;
    this.isEditingType = false;
    this.isEditingCriticity = false;
    this.isEditingStartingDate = false;
    this.isEditingDeadlineDate = false;
    this.isEditingTag = false;
    this.isEditingDurationInDay = false;
  }

  editingAssigned() {
    this.resetEditField();
    this.isEditingAssigned = true;
  }
  editingType() {
    this.resetEditField();
    this.isEditingType = true;
  }
  editingCriticity() {
    this.resetEditField();
    this.isEditingCriticity = true;
  }
  editingStartingDate() {
    this.resetEditField();
    this.isEditingStartingDate = true;
  }
  editingDeadlineDate() {
    this.resetEditField();
    this.isEditingDeadlineDate = true;
  }
  editingTag() {
    this.resetEditField();
    this.isEditingTag = true;
  }
  editingDurationInDay() {
    this.resetEditField();
    this.isEditingDurationInDay = true;
  }

  private clickOutsideListener = (event: Event) => {
    if (!hasParent(event.target as HTMLElement, "task-component__edit-column") && !hasParent(event.target as HTMLElement, 'ui-calendar')) {
      this.resetEditField();
    }
  };
  getStatusIcon(taskDTO) {
    switch (taskDTO.taskStatus.id) {
      case Constants.TaskStatusId.Open:
        return "digi-todo-line";
      case Constants.TaskStatusId.InProgress:
        return "digi-timer-line";
      case Constants.TaskStatusId.Finalized:
        return "digi-task-line";
      case Constants.TaskStatusId.Cancelled:
        return "digi-forbid-2-line";
    }
    return ""
  }
  async selectTask(taskDTO) {
    await this.refreshData({ purge: false, id: taskDTO.id });
  }
}
