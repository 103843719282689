import { I18N } from 'aurelia-i18n';
import { ICellRendererComp, ICellRendererParams, IHeaderComp, IHeaderParams } from "ag-grid-community";
import { createOverrideContext, Container, ViewResources, autoinject } from 'aurelia-framework';
import { View, ViewCompiler, ViewSlot } from "aurelia-templating";
import moment from 'moment';
import { TaskDeadlineType, TaskStatusId } from '../../constants';
import { TaskDTO } from 'module-tasks/model/module-task-model';

@autoinject
export default class CustomTaskAgGroupCellRenderer implements ICellRendererComp {
  protected eGui: HTMLElement;

  protected view: View;
  protected viewSlot: ViewSlot;
  protected params: ICellRendererParams
  public i18n: I18N;
  public value;
  public isGroup: boolean;
  public rotation: string


  constructor() { }

  getGui(): HTMLElement {
    return this.eGui;
  }
  refresh(params: ICellRendererParams<any, any>): boolean {
    return true;
  }

  public init(params: any) {
    this.params = params;
    this.isGroup = params.node.group;
    this.rotation = params.node.expanded ? 'rotate(90deg)' : 'rotate(0deg)';
    this.setupGui();
  }

  public setupGui() {
    this.i18n = Container.instance.get(I18N);
    this.eGui = document.createElement('div');
    this.eGui.style.width = '100%';
    this.eGui.style.height = '100%';

    let content: string = this.setupContentGui();
    let html: string = `<template>${content}</template>`;

    let viewCompiler = Container.instance.get(ViewCompiler);
    let viewResources = Container.instance.get(ViewResources);
    let viewFactory = viewCompiler.compile(html, viewResources);
    this.view = viewFactory.create(Container.instance);
    this.viewSlot = new ViewSlot(this.eGui, true);
    this.viewSlot.add(this.view);
    this.view.bind(this, createOverrideContext(this));
    this.viewSlot.attached();
  }

  onClick() {
    this.params.node.setExpanded(!this.params.node.expanded);
  }

  destroy() {
    this.params.node.removeEventListener('expandedChanged', this.onExpand);
  }
  onExpand = () => {
    this.rotation = this.params.node.expanded
      ? 'rotate(90deg)'
      : 'rotate(0deg)';
  };

  setupContentGui() {
    return CustomTaskAgGroupCellRenderer.getHtml(this.params.data, this.isGroup, this.i18n);
  }

  public static getHtml(data: TaskDTO, isGroup: boolean, i18n: I18N) {
    let date = data.deadlineDate ? moment(data.deadlineDate).format("DD/MM/yy") : '';
    let deadlineBackgroundColor: string;
    let deadlineTextColor: string;
    let iconStatus: string;
    if (date != null) {
      switch (data.deadlineDateType) {
        case TaskDeadlineType.Normal:
          deadlineBackgroundColor = "#FFFFFF";
          deadlineTextColor = "#000000";
          break;
        case TaskDeadlineType.Near:
          deadlineBackgroundColor = "#FEF6ED";
          deadlineTextColor = "#FF5C00";
          break;
        case TaskDeadlineType.Critical:
          deadlineBackgroundColor = "#FEEFEF";
          deadlineTextColor = "#FF0000";
          break;
        case TaskDeadlineType.Overdue:
          deadlineBackgroundColor = "#6400E2";
          deadlineTextColor = "#FFFFFF";
          break;
      }
    }
    switch (data.taskStatus.id) {
      case TaskStatusId.Open:
        iconStatus = "digi-todo-line";
        break;
      case TaskStatusId.InProgress:
        iconStatus = "digi-timer-line";
        break;
      case TaskStatusId.Finalized:
        iconStatus = "digi-task-line";
        break;
      case TaskStatusId.Cancelled:
        iconStatus = "digi-forbid-2-line";
        break;
    }

    let duration: number;
    let durationText: string;
    let temp = moment.utc(data.createdTime);
    let diff = moment().diff(temp, 'hour');
    if (diff < 24) {
      duration = diff;
      durationText = " " + i18n.tr("moduletask.hour", { duration: duration });
    } else {
      duration = moment().utc().diff(data.createdTime, 'day');
      durationText = " " + i18n.tr("moduletask.day", { duration: duration });
    }

    let html = `
    <div class="task-cell">
      <div class="task-button-expand">        
        <ui-button style="${isGroup ? '' : 'display: none'}"style.bind="params.node.expanded? 'transform: rotate(90deg)' : 'transform: rotate(0deg)'" icon="digi-arrow-drop-right-line" type="tool" click.delegate="onClick()"></ui-button>
      </div>
      <div>
        <div>
          <div class="task-header">
            <div>
              <div class="task-link-name">${data.linkName ?? ''}</div>
              <div class="task-title">
                <span>${data.title}</span>
              </div>
            </div>
            <ui-icon icon="${iconStatus}"></ui-icon>
          </div>
        </div>
        <div class="task-info">
          <div>
            <img class="avatar" src="${data.responsibleUri}" />
            <div>`;

    if (data.deadlineDate != null) {
      html +=
        `<div class="task-date">
                <ui-tag label="${date}" text-color="${deadlineTextColor}"
                  background-color="${deadlineBackgroundColor}" size="md" type="solid"
                  icon="digi-calendar-event-line"></ui-tag>
              </div>`
    }

    html += `
              <div class="task-type">
                <ui-tag label="${data.taskType.name}" text-color="${data.taskType.textColor}"
                  background-color="${data.taskType.backgroundColor}" size="md" type="solid"></ui-tag>
              </div>`

    if (data.taskCriticityLevel.name != null) {
      html += `<div class="task-status">
                <ui-tag label="${data.taskCriticityLevel.name}" text-color="${data.taskCriticityLevel.textColor}"
                  background-color="${data.taskCriticityLevel.backgroundColor}" size="md" type="solid"></ui-tag>
              </div>`
    }

    html += `</div>
          </div>
          <div class="task-diff">
            ${durationText}
          </div>
        </div>
      </div>
    </div>`

    return html;
  }

}
